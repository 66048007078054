/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from 'constants/index';

const updateUrl = (inUrl, key) => {
  return inUrl.replace(/%key%/, key);
};

const sendSecretExistsRequest = async ({ key }) => {
  try {
    const url = updateUrl(urls.URL_WORKSPACE_SECRETS, key);

    const result = await axios.get(url, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return { success: false, payload: { exists: true } };
    }

    return { success: true, payload: { exists: !!data.key } };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { exists: true } };
  }
};

const sendAddNewSecretRequest = async (formModel) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_SECRETS,
      formModel,
      // {
      //   name,
      //   description,
      //   key
      // },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    console.log(ex);

    return { success: false };
  }
};

export { sendSecretExistsRequest, sendAddNewSecretRequest };

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApps } from './network';
import ListTabs from 'components/listTabs/ListTabs';
import { appsNavigationInfos } from 'assets/allData';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './appList/ListView';
import GridView from './appList/GridView';

export const Apps = () => {
  const [apps, setApps] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [layoutView, setLayoutView] = useState('list');

  const navigate = useNavigate();
  
  useEffect(() => {
    getApps().then(({ success, payload }) => {
      if (success) {
        setApps(payload.internalApps);
        setTableData(payload.internalApps);
      }
    });
  }, []);

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  useEffect(() => {
    let filteredDatas;
    filteredDatas = apps.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, apps]);

  return (
    <React.Fragment>
    <ListTabs
      handleSearch={handleSearch}
      handleAddClick={() => navigate(`${appsNavigationInfos.createNewPath}`)}
    />
    <React.Fragment>
      <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
      {layoutView === 'list' ? (
        <ListView
          list={tableData}
          rowClick={(item) => navigate(`${appsNavigationInfos.edit}/${item.id}`)}
        />
      ) : (
        <GridView
          list={tableData}
          rowClick={(item) => navigate(`${appsNavigationInfos.edit}/${item.id}`)}
        />
      )}
    </React.Fragment>
  </React.Fragment>
  );
};

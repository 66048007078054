import { ValueCell } from './ValueCell';
import { Actions } from './Actions';
import { useNavigate } from 'react-router-dom';
import { deleteEnvironment } from './network';
import { useToast } from 'hooks/use-toast';

export default function ListView({ list, rowClick }) {
  const navigate = useNavigate();
  const { toast } = useToast();
  return (
    <div className=" overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="bg-secondary text-xs uppercase text-white dark:bg-gray-700 dark:text-gray-400">
          <tr className="bg-secondary">
            <th scope="col" className="px-6 py-3 text-white">
              Key
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              Value
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              Description
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((item, i) => (
              <tr
                onClick={() => rowClick(item)}
                className="border-b bg-white hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {item.key}
                </th>
                <ValueCell item={item} />

                <td className="px-6 py-4">{item.description ? item.description : '-'}</td>
                <td className="px-6 py-4 text-right">
                  <Actions
                    item={item}
                    onEdit={() => {
                      navigate(`../settings/environmentVariable/edit/${item.key}`);
                    }}
                    onDelete={() => {
                      deleteEnvironment({ key: item.key }).then(({ success }) => {
                        if (success) {
                          toast({
                            title: 'Successfully',
                            description: 'Key successfully deleted'
                          });
                          window.location.reload();
                        } else {
                          toast({
                            variant: 'destructive',
                            title: 'Uh oh! Something went wrong.',
                            description: 'There was a problem. Try again later.'
                          });
                        }
                      });
                    }}
                    rightOption={true}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-transparent hover:bg-transparent">
              <th className="whitespace-nowrap px-6 py-8 font-medium text-gray-900 dark:text-white">
                No items found
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  executionCommand: false
};

const executionCommandSlice = createSlice({
  name: 'executionCommand',
  initialState,
  reducers: {
    setExecutionCommand: (state, action) => {
      state.executionCommand = action.payload;
    }
  }
});

export const { setExecutionCommand } = executionCommandSlice.actions;
export default executionCommandSlice.reducer;

import { ChevronRight } from 'lucide-react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'components/ui/collapsible';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem
} from 'components/ui/sidebar';
import { Flags } from '../contexts/FlagProvider';
import { features } from '../constants/index';

function SubMenu({ subItem }) {
  return subItem.isHasAFlag ? (
    <Flags
      authorizedFlags={[features[subItem.id]]}
      renderOn={() => (
        <SidebarMenuSubItem>
          <SidebarMenuSubButton asChild>
            <a href={subItem.url}>
              <span>{subItem.title}</span>
            </a>
          </SidebarMenuSubButton>
        </SidebarMenuSubItem>
      )}
      renderOff={() => (
        <SidebarMenuSubItem>
          <SidebarMenuSubButton asChild>
            <a href="/subscription-plan">
              <span>{subItem.title} (Upgrade)</span>
            </a>
          </SidebarMenuSubButton>
        </SidebarMenuSubItem>
      )}
    />
  ) : (
    <SidebarMenuSubItem>
      <SidebarMenuSubButton asChild>
        <a href={subItem.url}>
          <span>{subItem.title}</span>
        </a>
      </SidebarMenuSubButton>
    </SidebarMenuSubItem>
  );
}

function MenuItem({ item }) {
  return item.isHasAFlag ? (
    <Flags
      authorizedFlags={[features[item.id]]}
      renderOn={() => (
        <Collapsible asChild defaultOpen={item.isActive} className="group/collapsible">
          <SidebarMenuItem>
            <CollapsibleTrigger asChild>
              <SidebarMenuButton tooltip={item.title}>
                {item.icon && <item.icon />}
                <span>{item.title}</span>
                <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
              </SidebarMenuButton>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <SidebarMenuSub>
                {item.items?.map((subItem) => (
                  <SubMenu key={subItem.title} subItem={subItem} />
                ))}
              </SidebarMenuSub>
            </CollapsibleContent>
          </SidebarMenuItem>
        </Collapsible>
      )}
      renderOff={() => (
        <SidebarMenuItem>
          <SidebarMenuButton asChild>
            <a href="/subscription-plan">
              <span>{item.title} (Upgrade)</span>
            </a>
          </SidebarMenuButton>
        </SidebarMenuItem>
      )}
    />
  ) : (
    <Collapsible asChild defaultOpen={item.isActive} className="group/collapsible">
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton tooltip={item.title}>
            {item.icon && <item.icon />}
            <span>{item.title}</span>
            <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            {item.items?.map((subItem) => (
              <SubMenu key={subItem.title} subItem={subItem} />
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  );
}

export function NavMain({ items }) {
  return (
    <SidebarGroup className='text-[#ffffffc2]'>
      <SidebarMenu>
        {items.map((item) => (
          <MenuItem key={item.title} item={item} />
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}

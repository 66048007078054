import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid';
import ReactPaginate from 'react-paginate';

const initialFilterFeatures = [
  { label: 'All', checked: true },
  { label: 'Checked', checked: false },
  { label: 'Unchecked', checked: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MultiSelectDataTable({
  title,
  tableData,
  selectedTableData,
  setSelectedTableDataCallback
}) {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterFeatures, setFilterFeatures] = useState(initialFilterFeatures);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedTableData.length > 0 && selectedTableData.length < tableData.length;
    setChecked(selectedTableData.length === tableData.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedTableData]);

  useEffect(() => {
    setFilteredTableData(tableData);
  }, [tableData]);

  function toggleAll() {
    setSelectedTableDataCallback(checked || indeterminate ? [] : tableData);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const filterTableData = (filterData) => {
    const filteredData = tableData.filter((data) => {
      const valueMatch = data.value.toLowerCase().includes(filterData.toLowerCase());
      const kindMatch = data.kind
        ? data.kind.toLowerCase().includes(filterData.toLowerCase())
        : false;
      return kindMatch || valueMatch;
    });
    setFilteredTableData(filteredData);
    setCurrentPage(0);
  };

  const handleFilterChange = (filterType) => {
    setFilterFeatures(
      filterFeatures.map((feature) =>
        feature.label === filterType
          ? { ...feature, checked: true }
          : { ...feature, checked: false }
      )
    );
    if (filterType === 'Checked') {
      setFilteredTableData(selectedTableData);
    } else if (filterType === 'Unchecked') {
      setFilteredTableData(
        tableData.filter((data) => !selectedTableData.some((item) => item.value === data.value))
      );
    } else {
      setFilteredTableData(tableData);
    }
    setCurrentPage(0);
  };

  const handlePageChange = (event) => {
    setCurrentPage(event.selected);
  };

  const currentItems = filteredTableData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className="my-12">
      <div className="py-2 sm:flex-auto">
        <h1 className="text-base font-semibold text-gray-900">{title}</h1>
      </div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <input
            className="w-1/2 rounded-md border border-gray-200 px-5  py-2 shadow-inner outline-none focus:border-[#405270]"
            type="text"
            placeholder={`Search ${title}`}
            onChange={(e) => filterTableData(e.target.value)}
          />
        </div>
        <div className="mt-4 sm:mt-0 sm:flex-none">
          <Menu as="div" className="relative">
            <Menu.Button className="flex items-center gap-2 rounded-md border px-5 py-2 shadow-sm">
              <div>
                <span>
                  {filterFeatures.map((feature) => feature.checked === true && feature.label)}
                </span>
              </div>
              <div>
                <ChevronDownIcon className="h-5 w-5" />
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute -bottom-[7.2rem] z-50 w-full origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {filterFeatures.map((feature) => (
                  <Menu.Item key={feature.label}>
                    {({ active }) => (
                      <div
                        onClick={() => handleFilterChange(feature.label)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleFilterChange(feature.label);
                          }
                        }}
                        tabIndex={0}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'flex cursor-pointer gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        {feature.checked && <CheckIcon className="h-5 w-5" />}
                        {feature.label}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative">
              <table className="min-w-full divide-y rounded-md border">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="relative rounded-tl-lg bg-[#033B64] px-7 sm:w-12 sm:px-6"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded accent-[#405270] outline-none"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] bg-[#033B64] py-2.5 pr-3 text-left text-sm font-semibold normal-case text-white"
                    >
                      Name
                    </th>
                    {filteredTableData.some((item) => item.kind) && (
                      <th
                        scope="col"
                        className="bg-[#033B64] px-3 py-2.5 text-left text-sm font-semibold normal-case text-white"
                      >
                        Kind
                      </th>
                    )}
                    <th
                      scope="col"
                      className="rounded-tr-lg bg-[#033B64] px-3 py-2.5 text-left text-sm font-semibold normal-case text-white"
                    >
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentItems.length > 0 ? (
                    currentItems.map((data) => (
                      <tr
                        key={data.value}
                        className={selectedTableData.includes(data) ? 'bg-[#efefef]' : undefined}
                      >
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded accent-[#405270] outline-none"
                            value={data.value}
                            checked={selectedTableData.some((item) => item.value === data.value)}
                            onChange={(e) =>
                              setSelectedTableDataCallback(
                                e.target.checked
                                  ? [...selectedTableData, { value: data.value, label: data.label }]
                                  : selectedTableData.filter((item) => item.value !== data.value)
                              )
                            }
                          />
                        </td>
                        <td className="p-2 text-sm lowercase text-[#09090B]">{data.label}</td>
                        {filteredTableData.some((item) => item.kind) && (
                          <td className="p-2 text-sm lowercase text-[#09090B]">{data.kind}</td>
                        )}

                        <td className="p-2 text-sm lowercase text-[#09090B]">
                          {data.description
                            ? data.description.length < 15
                              ? data.description
                              : `${data.description.slice(0, 15)}...`
                            : '-'}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="p-2 text-center text-sm text-[#09090B]">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="flex items-center justify-between py-2.5">
                <div className="p-2 text-center text-sm text-[#71717A]">
                  {selectedTableData.length} data selected
                </div>
                <ReactPaginate
                  className="isolate inline-flex items-center gap-1 -space-x-px rounded-md border shadow-sm"
                  nextLabel={<ChevronRightIcon className="h-4 w-4" />}
                  previousLabel={<ChevronLeftIcon className="h-4 w-4" />}
                  breakLabel={'...'}
                  pageCount={Math.ceil(filteredTableData.length / itemsPerPage)}
                  onPageChange={handlePageChange}
                  pageClassName="px-2 py-1"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  disabledClassName="text-gray-400"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="bg-[#033B64] text-white"
                  forcePage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';

const sendSaveNewWebhookRequest = async ({
  kind,
  name,
  description,
  connections,
  commands,
  workflows,
  webHookTriggerInfo
}) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_TRIGGERS,
      {
        kind: 'webHook',
        name,
        description,
        connections,
        commands,
        workflows,
        webHookTriggerInfo: {}
      },
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json; charset=utf-8'
        }
      }
    );
    // Webhook URL
    const url = result.data.url;
    return { success: true, url: url };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export { sendSaveNewWebhookRequest };

import { addNewGroupViewStates } from 'constants';
import { useEffect, useState } from 'react';
import {
  addOrUpdateGroup,
  fetchGroupDataDetail,
  fetchGroups,
  fetchPolicies,
  fetchUsers
} from './io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { Navigate, useParams } from 'react-router-dom';
import { postMethods } from 'constants';
import { globalViewStates } from 'constants';
import { deleteGroup } from '../GroupsList/io';
import { fetchGroupsData } from '../GroupsList/io';
import MultiSelectDataTable from 'components/multiSelectDataTable';
import { useToast } from 'hooks/use-toast';

const initialFormModel = {
  name: '',
  description: '',
  users: [],
  policies: [],
  subGroups: []
};

const GroupNew = () => {
  const [viewState, setViewState] = useState(addNewGroupViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false
  });
  const { toast } = useToast();
  const [subGroups, setSubGroups] = useState([]);
  const [selectedSubGroups, setSelectedSubGroups] = useState([]);
  const [groupList, setGroupList] = useState([]);

  // Get users and policies
  useEffect(() => {
    fetchUsers().then(({ success, payload }) => {
      void success;
      const { users } = payload;
      setUsers(users.map((user) => ({ value: user.email, label: user.email })));
    });
  }, []);

  useEffect(() => {
    fetchGroups().then(({ success, payload }) => {
      void success;
      const { groups } = payload;
      const filteredGroups = groups.filter((group) => !group.subGroups);
      setSubGroups(filteredGroups.map((group) => ({ value: group.name, label: group.name })));
    });
  }, []);

  useEffect(() => {
    fetchPolicies().then(({ success, payload }) => {
      void success;
      const { policies } = payload;
      setPolicies(policies.map((policy) => ({ value: policy.name, label: policy.name })));
    });
  }, []);

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const onFormSubmit = (type, e) => {
    e.preventDefault();

    const obj = {
      name: type === postMethods.UPDATE ? false : formModel.name.trim() === '',
      uniqueName:
        type === postMethods.UPDATE
          ? false
          : groupList.find((group) => group.name.toLowerCase() === formModel.name.toLowerCase())
          ? true
          : false
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    }
    setFormModel({
      ...formModel
    });

    if (type === postMethods.SAVE) {
      onFormSave();
    } else if (type === postMethods.UPDATE) {
      onFormUpdate();
    }
  };

  const params = useParams(); // get param
  const groupName = params.groupName ? params.groupName : null; 
  useEffect(() => {
    if (groupName) {
      fetchGroupDataDetail(groupName).then(
        ({ success, payload }) => {
          void success;

          const { group } = payload;

          const temporaryForm = {
            name: group.name,
            description: group.description,
            users: group.userIds,
            policies: group.policies,
            subGroups: group.subGroups
          };
          setSelectedUsers(
            group.userIds
              ? group.userIds.map((user) => {
                  return { value: user, label: user };
                })
              : []
          );
          setSelectedPolicies(
            group.policies
              ? group.policies.map((policy) => {
                  return { value: policy, label: policy };
                })
              : []
          );
          setSelectedSubGroups(
            group.subGroups
              ? group.subGroups.map((group) => {
                  return { value: group, label: group };
                })
              : []
          );
          setFormModel(temporaryForm);
          setViewState(addNewGroupViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [groupName]);

  useEffect(() => {
    fetchGroupsData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { groups } = payload;
        setGroupList(groups);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    if (!groupName) {
      const checkIfNameExists = () => {
        if (groupList.find((group) => group.name.toLowerCase() === formModel.name.toLowerCase())) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      };
      checkIfNameExists();
    }
  }, [formModel.name, groupList]);

  const onFormSave = async (evt) => {
    
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successAdd } = await addOrUpdateGroup({
        ...formModel,
        users: selectedUsers.map((selectedUsers) => {
          return selectedUsers.value;
        }),
        policies: selectedPolicies.map((selectedPolicies) => {
          return selectedPolicies.value;
        }),
        subGroups: selectedSubGroups.map((selectedSubGroup) => {
          return selectedSubGroup.value;
        })
      });

      if (!successAdd) {
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem saving your group. Please try again later.'
      });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
      }
      toast({
        title: 'Successfully',
        description: 'Group saved'
      });
      
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem saving your group. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (isDelete = false) => {
    

    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateGroup(
        {
          ...formModel,
          users: selectedUsers.map((selectedUser) => {
            return selectedUser.value;
          }),
          policies: selectedPolicies.map((selectedPolicy) => {
            return selectedPolicy.value;
          }),
          subGroups: selectedSubGroups.map((selectedSubGroup) => {
            return selectedSubGroup.value;
          })
        },
        groupName
      );
      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem updating your group. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        if (!isDelete) {
          toast({
            title: 'Successfully',
            description: 'Command successfully updated'
          });
        }
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem updating your group. Please try again later.'
      });
    }
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(globalViewStates.REDIRECT_TO_PARENT);
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/settings/group" />;
  }

  const disabled = viewState === globalViewStates.POSTING;

  //delete group
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    setShowModal(!showModal);
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    await onFormUpdate(true); // delete process
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!groupName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteGroup(groupName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${groupName} was deleted successfully`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
      }
    );
  };

  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';
  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {groupName ? 'UPDATE GROUP' : 'NEW GROUP'}
            </h2>

            <div className="flex flex-col gap-6"></div>
            <div>
              <label htmlFor="name" className="label-main">
                Group Name *
              </label>

              <input
                name="name"
                id="name"
                disabled={disabled || groupName}
                readOnly={groupName}
                placeholder=""
                maxLength="250"
                required
                className={`${groupName ? 'input-main-disabled' : 'input-main'} ${
                  formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                }`}
                value={groupName ? groupName : formModel.name}
                onChange={(e) => {
                  onChangeInput(e.target.value, 'name');
                }}
              />

              <span className="label-main">
                Choose a <strong>single word</strong> with <strong>no spaces</strong>.
              </span>

              {formErrorModel.name && (
                <span className="label-second my-1 block !text-red-500">
                  This area is required!
                </span>
              )}

              {formErrorModel.uniqueName && (
                <span className="label-second my-1 block !text-red-500">
                  {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                </span>
              )}
            </div>
            <div className="mt-8">
              <label htmlFor="description" className="label-main">
                Description
              </label>

              <input
                id="description"
                name="description"
                disabled={disabled}
                required
                placeholder=""
                className={`input-main`}
                value={formModel.description}
                onChange={(e) => {
                  onChangeInput(e.target.value, 'description');
                }}
              />
            </div>

            {/* Users */}
            <MultiSelectDataTable
             title={'Users'}
             tableData={users} 
             selectedTableData={selectedUsers}
             setSelectedTableDataCallback={setSelectedUsers}
             />
            {/* Subgroups */}
            <MultiSelectDataTable
             title={'Subgroups'}
             tableData={subGroups} 
             selectedTableData={selectedSubGroups}
             setSelectedTableDataCallback={setSelectedSubGroups}
             />
            {/* Policies */}
            <MultiSelectDataTable
             title={'Policies'}
             tableData={policies} 
             selectedTableData={selectedPolicies}
             setSelectedTableDataCallback={setSelectedPolicies}
             />

            {groupName && (
              <>
                <h2 className="py-6 text-xl font-extrabold">Danger Zone</h2>

                <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                  <p className="label-second mb-4">
                    By clicking the button below you delete this group. This action is{' '}
                    <strong>irreversible</strong>.
                  </p>
                  <button
                    disabled={disabled}
                    type="button"
                    onClick={onDeleteClick}
                    className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                  >
                    I understand the consequences. Delete this group
                  </button>
                </div>
              </>
            )}

            <div className="mt-5 flex justify-center">
              <button type="button" className="btn-danger" onClick={onBackClick}>
                Cancel
              </button>
              {groupName ? (
                <button
                  type="button"
                  className="btn-success"
                  onClick={(e) => onFormSubmit('UPDATE', e)}
                >
                  Update
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-success"
                  onClick={(e) => onFormSubmit('SAVE', e)}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this group?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default GroupNew;

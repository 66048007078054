/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';
import { Navigate } from 'react-router-dom';

import { loginActivationViewStates } from 'constants/index';

import LoginFormActivationWrap from 'features/login/LoginFormActivationWrap';
import LoginFormHeading from 'features/login/LoginFormHeading';

import { useLoginActivation, useEvents } from './hooks';

const LoginActivationMain = () => {
  const {
    viewState,
    // userInfo,
    email,
    token,
    fullName,
    setFullName,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    setViewState
    //setUserInfo
  } = useLoginActivation();

  const { onFullNameChange, onPasswordChange, onPasswordConfirmChange, onFormSubmit } = useEvents({
    setFullName,
    setPassword,
    setPasswordConfirm,
    setViewState,
    // setUserInfo,
    email,
    token,
    password,
    passwordConfirm,
    fullName
  });

  if (viewState === loginActivationViewStates.REDIRECT_USER_DASHBOARD) {
    return <Navigate to="/" />;
  }

  if (viewState === loginActivationViewStates.LOADING) {
    return (
      <LoginFormActivationWrap>
        <LoginFormHeading />
        <h2
          style={{
            textAlign: 'center',
            marginBottom: '300px',
            fontSize: '32px',
            fontWeight: '100'
          }}
        >
          Loading…
        </h2>
      </LoginFormActivationWrap>
    );
  }

  if (viewState === loginActivationViewStates.ERR_GENERAL_LOGIN) {
    return (
      <LoginFormActivationWrap>
        <LoginFormHeading />
        <h2 style={{ textAlign: 'left', fontSize: '32px', fontWeight: '700' }}>Oops!</h2>
        <p>There was a problem logging you in.</p>
        <p>Please try again later.</p>
        <p>
          If the issue persists, please send us an email at{' '}
          <a href="mailto:support@opsbeacon.com">support@opsbeacon.com</a>.
        </p>
      </LoginFormActivationWrap>
    );
  }

  if (viewState === loginActivationViewStates.ERR_BAD_TOKEN) {
    return (
      <LoginFormActivationWrap>
        <LoginFormHeading />
        <h2 style={{ textAlign: 'left', fontSize: '32px', fontWeight: '700' }}>Oops!</h2>
        <p>We cannot find your activation token.</p>
        <p>
          If you think this is a mistake, please contact us at{' '}
          <a href="mailto:support@opsbeacon.com">support@opsbeacon.com</a>.
        </p>
      </LoginFormActivationWrap>
    );
  }

  if (viewState === loginActivationViewStates.ERR_REGISTRATION_FAILED) {
    return (
      <LoginFormActivationWrap>
        <LoginFormHeading />
        <h2 style={{ textAlign: 'left', fontSize: '32px', fontWeight: '700' }}>
          Houston, We’ve Had a Problem
        </h2>
        <p>We were unable to activate your account.</p>
        <p>Please try again a few minutes later.</p>
        <p>
          If you get the same message again, please contact us at{' '}
          <a href="mailto:support@opsbeacon.com">support@opsbeacon.com</a>.
        </p>
        <p>Thank you for your consideration,</p>
        <p>Team OpsBeacon.</p>
      </LoginFormActivationWrap>
    );
  }

  return (
    <div style={{ marginTop: '-55px' }}>
      <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="login-logo flex flex-wrap content-center justify-center">
            <svg width="62px" height="46px" viewBox="0 0 62 46">
              <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                  <stop stopColor="#EFF9FC" offset="0%" />
                  <stop stopColor="#EFF9FC" offset="45.1383024%" />
                  <stop stopColor="#C8E1E9" offset="87.8272804%" />
                  <stop stopColor="#9AC6D3" offset="100%" />
                </linearGradient>
              </defs>
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-12.000000, -4.000000)">
                  <path
                    d="M82,41.0121933 C82,18.3617844 63.6436747,0 41,0 C18.3563253,0 0,18.3617844 0,41.0121933 C0,63.6626022 82,63.6626022 82,41.0121933 Z"
                    id="Shape"
                    fill="url(#linearGradient-1)"
                    fillRule="nonzero"
                    opacity="0"
                  />
                  <g id="Group" transform="translate(12.000000, 4.000000)">
                    <ellipse
                      id="Oval"
                      fill="#E67A33"
                      cx="29"
                      cy="3.83333333"
                      rx="3.7826087"
                      ry="3.83333333"
                    />
                    <path
                      d="M29,16.6111111 C28.3036848,16.6111111 27.7391304,16.0389861 27.7391304,15.3333333 L27.7391304,7.66666667 C27.7391304,6.96101389 28.3036848,6.38888889 29,6.38888889 C29.6963152,6.38888889 30.2608696,6.96101389 30.2608696,7.66666667 L30.2608696,15.3333333 C30.2608696,16.0389861 29.6963152,16.6111111 29,16.6111111 Z"
                      id="Path"
                      fill="#E67A33"
                      fillRule="nonzero"
                    />
                    <path
                      d="M46.6521739,34.5 L11.3478261,34.5 C10.6515109,34.5 10.0869565,33.927875 10.0869565,33.2222222 L10.0869565,30.6666667 C10.0869565,20.0981667 18.5713478,11.5 29,11.5 C39.4286522,11.5 47.9130435,20.0981667 47.9130435,30.6666667 L47.9130435,33.2222222 C47.9130435,33.927875 47.3484891,34.5 46.6521739,34.5 Z"
                      id="Path"
                      fill="#156B80"
                      fillRule="nonzero"
                    />
                    <ellipse
                      id="Oval"
                      fill="#20A8C9"
                      fillRule="nonzero"
                      transform="translate(22.894334, 21.465492) rotate(-24.929286) translate(-22.894334, -21.465492) "
                      cx="22.8943341"
                      cy="21.4654921"
                      rx="5.05553934"
                      ry="3.82441629"
                    />
                    <path
                      d="M58,39.6111111 L0,39.6111111 L0,39.6111111 C0,36.0826172 2.82254478,33.2222222 6.30434783,33.2222222 L51.6956522,33.2222222 C55.1774552,33.2222222 58,36.0826172 58,39.6111111 L58,39.6111111 Z"
                      id="Path"
                      fill="#FFC764"
                      fillRule="nonzero"
                    />
                    <path
                      d="M58,39.6111111 L0,39.6111111 L0,39.6111111 C0,43.139605 2.82254478,46 6.30434783,46 L51.6956522,46 C55.1774552,46 58,43.139605 58,39.6111111 L58,39.6111111 Z"
                      id="Path"
                      fill="#FFA200"
                      fillRule="nonzero"
                    />
                    <path
                      d="M34.0434783,40.8888889 L44.1304348,40.8888889 C44.8267938,40.8888889 45.3913043,40.3168082 45.3913043,39.6111111 C45.3913043,38.905414 44.8267938,38.3333333 44.1304348,38.3333333 L34.0434783,38.3333333 C33.3471193,38.3333333 32.7826087,38.905414 32.7826087,39.6111111 C32.7826087,40.3168082 33.3471193,40.8888889 34.0434783,40.8888889 Z"
                      id="Shape"
                      fill="#8D4311"
                      fillRule="nonzero"
                    />
                    <ellipse
                      id="Shape"
                      fill="#E67A33"
                      fillRule="nonzero"
                      cx="17.6521739"
                      cy="39.6111111"
                      rx="2.52173913"
                      ry="2.55555556"
                    />
                    <ellipse
                      id="Shape"
                      fill="#C46221"
                      fillRule="nonzero"
                      cx="26.4782609"
                      cy="39.6111111"
                      rx="2.52173913"
                      ry="2.55555556"
                    />
                  </g>
                  <circle
                    id="Oval"
                    fill="#02404F"
                    opacity="0.418643043"
                    cx="71.5"
                    cy="33.5"
                    r="2.5"
                  />
                  <circle id="Oval" fill="#4B90A0" opacity="0.418643043" cx="22" cy="11" r="4" />
                </g>
              </g>
            </svg>
            <div className="ml-2">
              <span
                style={{
                  fontSize: '32px',
                  color: '#000000',
                  fontWeight: '300',
                  position: 'relative',
                  top: '6px'
                }}
              >
                Ops
              </span>
              <span
                style={{
                  fontSize: '32px',
                  color: 'rgb(22,96,134)',
                  fontWeight: '400',
                  position: 'relative',
                  top: '6px'
                }}
              >
                Beacon
              </span>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold leading-9 text-gray-900">
            You Are Almost There
          </h2>{' '}
          <p className="max-w mb-2 text-center leading-5 text-gray-500">
            select a password to finish your registration.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form action="/activate" method="POST" onSubmit={onFormSubmit}>
              <div>
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Email Address
                </label>
                <div className="mt-1 rounded-md font-semibold">{email}</div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Full Name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="fullName"
                    type="text"
                    maxLength="250"
                    value={fullName}
                    onChange={onFullNameChange}
                    required
                    autoFocus
                    className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
                  />
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="password"
                    type="password"
                    maxLength="250"
                    value={password}
                    onChange={onPasswordChange}
                    required
                    className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
                  />
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="passwordConfirm"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Password Confirmation
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="passwordConfirm"
                    type="password"
                    maxLength="250"
                    value={passwordConfirm}
                    onChange={onPasswordConfirmChange}
                    required
                    className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
                  />
                </div>
              </div>

              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="focus:shadow-outline-orange flex w-full cursor-default justify-center rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white transition duration-150 ease-out hover:bg-teal-500 focus:border-orange-500 focus:outline-none active:bg-orange-400"
                  >
                    Activate Your Account
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginActivationMain;

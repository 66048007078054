import { useEffect } from "react";
import { useToast } from "hooks/use-toast";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "components/ui/toast";

export function Toaster() {
  const { toasts, dismiss } = useToast();

  useEffect(() => {
    toasts.forEach(({ id }) => {
      setTimeout(() => {
        dismiss(id); 
      }, 3000); 
    });
  }, [toasts, dismiss]);

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, ...props }) => (
        <Toast key={id} {...props}>
          <div className="grid gap-1">
            {title && <ToastTitle>{title}</ToastTitle>}
            {description && <ToastDescription>{description}</ToastDescription>}
          </div>
          {action}
          <ToastClose />
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  );
}

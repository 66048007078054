import { nanoid } from 'nanoid';
import cloneDeep from 'lodash/cloneDeep';

export const createConditionAreaNode = (parent = null) => {
  const nanoId = nanoid();
  const id = `condition_${nanoId}`;
  return parent === null
    ? {
        id,
        data: {
          stepId: id,
          nextStepId: ''
        }
      }
    : {
        id,
        parent,
        data: {
          stepId: id,
          nextStepId: ''
        }
      };
};

export const createNewButtonNode = (type, parent = null) => {
  const nanoId = nanoid();
  const id = `${type}#${nanoId}`;
  return parent !== null
    ? {
        id,
        width: 150,
        height: 32,
        parent: parent,
        data: {
          stepId: id,
          type: type
        }
      }
    : {
        id,
        width: 150,
        height: 32,
        data: {
          stepId: id,
          type: type
        }
      };
};

export const createNewFirstChildNode = (stepId = null, parent = null) => {
  const id = `stepId_${stepId}`;
  return parent !== null
    ? {
        id,
        width: 700,
        height: 150,
        parent: parent,
        data: {
          type: 'step',
          stepId: id,
          runCommandStep: '',
          runParserStep: '',
          runPrintStep: '',
          workflowStepType: null,
          nextStepId: ''
        }
      }
    : {
        id,
        width: 700,
        height: 150,
        data: {
          type: 'step',
          stepId: id,
          runCommandStep: '',
          runParserStep: '',
          runPrintStep: '',
          workflowStepType: null,
          nextStepId: ''
        }
      };
};

export const generateStepId = (id) => {
  return `stepId_${id}`;
};

export const createNewNode = (stepId = null, parent = null) => {
  const id = `stepId_${stepId}`;
  return parent !== null
    ? {
        id,
        width: 700,
        height: 150,
        parent: parent,
        data: {
          type: 'step',
          stepId: id,
          runCommandStep: '',
          runParserStep: '',
          runPrintStep: '',
          workflowStepType: null,
          nextStepId: ''
        }
      }
    : {
        id,
        width: 700,
        height: 150,
        data: {
          type: 'step',
          stepId: id,
          runCommandStep: '',
          runParserStep: '',
          runPrintStep: '',
          workflowStepType: null,
          nextStepId: ''
        }
      };
};

export const createNewNodeFromSteps = (step, parent = null) => {
  return parent !== null
    ? {
        id: step.stepId,
        width: 700,
        height: 150,
        parent: parent,
        data: {
          type: 'step',
          stepId: step.stepId,
          runCommandStep: step.runCommandStep,
          runConditionStep: step.runConditionStep,
          runParserStep: step.runParserStep,
          runPrintStep: step.runPrintStep,
          workflowStepType: step.workflowStepType,
          nextStepId: step.nextStepId
        }
      }
    : {
        id: step.stepId,
        width: 700,
        height: 150,
        data: {
          type: 'step',
          stepId: step.stepId,
          runCommandStep: step.runCommandStep,
          runParserStep: step.runParserStep,
          runConditionStep: step.runConditionStep,
          runPrintStep: step.runPrintStep,
          workflowStepType: step.workflowStepType,
          nextStepId: step.nextStepId
        }
      };
};

export const convertNodesToStepObjects = (nodes) => {
  const stepObjects = cloneDeep(nodes)
    .filter((node) => {
      return (
        !node.id.startsWith('button#') &&
        !node.id.startsWith('conditionButton#') &&
        !node.id.startsWith('condition_')
      );
    })
    .map((node) => {
      if (node.data.workflowStepType === 'run-command') {
        node.data.runParserStep = null;
        node.data.runPrintStep = null;
        node.data.runConditionStep = null;
      }
      if (node.data.workflowStepType === 'run-parser') {
        node.data.runCommandStep = null;
        node.data.runPrintStep = null;
        node.data.runConditionStep = null;
      }
      if (node.data.workflowStepType === 'run-print') {
        node.data.runCommandStep = null;
        node.data.runParserStep = null;
        node.data.runConditionStep = null;
      }
      if (node.data.workflowStepType === 'run-condition') {
        node.data.runCommandStep = null;
        node.data.runParserStep = null;
        node.data.runPrintStep = null;
      }
      delete node.data.type;

      return node.data;
    });

  return stepObjects;
};

export const convertStepsToNodes = (steps) => {
  const stepObjects = cloneDeep(steps).map((step) => {
    return createNewNodeFromSteps(step);
  });

  return stepObjects;
};

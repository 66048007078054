/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import {
  sendAddOrUpdateGroupRequest,
  sendFetchGroupDetailRequest,
  sendFetchGroupsRequest
} from './network';
import { sendFetchUsersRequest } from 'network/sendFetchUsersRequest';
import { sendFetchPoliciesRequest } from 'network/sendFetchPoliciesRequest';

const fetchUsers = async () => {
  try {
    const { success, payload } = await sendFetchUsersRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          users: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { users: [] } };
    }

    const { users } = payload;

    return {
      success: true,
      payload: { users }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { users: [] } };
  }
};

const fetchPolicies = async () => {
  try {
    const { success, payload } = await sendFetchPoliciesRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          policies: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { policies: [] } };
    }

    const { policies } = payload;

    return {
      success: true,
      payload: { policies }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { policies: [] } };
  }
};

const addOrUpdateGroup = async (formModel, groupName) => {
  try {
    const { success } = await sendAddOrUpdateGroupRequest(formModel, groupName);

    return { success };
  } catch (e) {
    console.log('addOrUpdateGroup:error', e);

    return { success: false };
  }
};

const fetchGroupDataDetail = async (groupName) => {
  try {
    const { success, payload } = await sendFetchGroupDetailRequest(groupName);

    if (!success) {
      return {
        success: false,
        payload: {
          group: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { group: [] } };
    }

    const { group } = payload;

    return {
      success: true,
      payload: { group }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { group: [] } };
  }
};
const fetchGroups = async () => {
  try {
    const { success, payload } = await sendFetchGroupsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          groups: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { groups: [] } };
    }

    const { groups } = payload;

    return {
      success: true,
      payload: { groups }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { group: [] } };
  }
};

export { addOrUpdateGroup, fetchUsers, fetchPolicies, fetchGroupDataDetail, fetchGroups };

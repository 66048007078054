import {
  sendUpdateLinkRequest,
  sendDeleteLinkRequest,
  sendUpdateRegenarateUrl
} from './network';

const deleteLink = async ({ name }) => {
  try {
    const { success } = await sendDeleteLinkRequest({
      name
    });

    if (!success) {
      return { success: false };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const regenerateUrl = async ({ name }) => {
  try {
    const result = await sendUpdateRegenarateUrl({
      name
    });

    if (result.success) {
      return { success: true, url: result.payload.linkUrl };
    }

    return {
      success: false
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const updateLink = async ({
  name,
  kind,
  description,
  commands,
  connections,
  workflows,
  linkTriggerInfo
}) => {
  try {
    const { success } = await sendUpdateLinkRequest({
      name,
      kind,
      description,
      commands,
      connections,
      workflows,
      linkTriggerInfo: {}
    });

    if (!success) {
      return { success: false };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { updateLink, deleteLink, regenerateUrl };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import sendFetchKeysRequest from 'network/sendFetchKeysRequest';
import { sendDeleteKeyRequest } from './network';

const fetchData = async () => {
  try {
    const { success, payload } = await sendFetchKeysRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          secrets: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { secrets: [] } };
    }

    const { secrets } = payload;

    return {
      success: true,
      payload: { secrets }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { secrets: [] } };
  }
};

const deleteKey = async (keyName) => {
  try {
    await sendDeleteKeyRequest(keyName);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

export { fetchData, deleteKey };

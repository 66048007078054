import axios from 'axios';
import { urls } from 'constants/index';

export const getInvocations = async ({
  startDate,
  endDate,
  direction,
  page,
  limit,
  invocationKind,
  containsText
}) => {
  try {
    const result = await axios.get(`${urls.URL_DASHBOARD_INVOCATIONS}`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        direction: direction,
        page: page,
        limit: limit,
        invocationKind,
        containsText
      },
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { invocationData: [], dataCount: 1, currentPage: 1 }
      };
    }

    const { invocationData, dataCount, currentPage } = data;

    if (!invocationData) {
      return {
        success: false,
        payload: { invocationData: [], dataCount: 1, currentPage: 1 }
      };
    }

    return {
      success: true,
      payload: {
        invocationData,
        dataCount,
        currentPage
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { invocationData: [], dataCount: 1, currentPage: 1 } };
  }
};

export const getInvocationSeries = async ({ startDate, endDate, direction }) => {
  try {
    const result = await axios.get(`${urls.URL_DASHBOARD_INVOCATION_SERIES}`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        direction: direction
      },
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { invocationData: [] }
      };
    }

    const { invocationData } = data;

    if (!invocationData) {
      return {
        success: false,
        payload: { invocationData: [] }
      };
    }

    return {
      success: true,
      payload: {
        invocationData
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { invocationData: [] } };
  }
};

export const getBasicData = async () => {
  try {
    const result = await axios.get(`${urls.URL_DASHBOARD_BASIC_DATA}`, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { basicData: [] }
      };
    }

    const { basicData } = data;

    if (!basicData) {
      return {
        success: false,
        payload: { basicData: [] }
      };
    }

    return {
      success: true,
      payload: {
        basicData
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { basicData: [] } };
  }
};

import React from 'react';
import { Link } from 'react-router-dom';
import { userRoles } from 'constants/index';
import { useSelector } from 'react-redux';
import Helper from 'components/help/Helper';
import PageHeader from 'components/pageHeader/PageHeader';
import IconIntegrationSlack from 'components/icons/IconIntegrationSlack';

const info = {
  title: 'Projects',
  btnName: '',
  btnPath: ''
};

const CreateNewProjectInfo = () => {
  const userState = useSelector((state) => state.userState);
  return (
    <>
     <PageHeader info={info} />
     <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <div className="flex justify-center">
        <IconIntegrationSlack customHeight='256px' customWidth='256px'/>
      </div>
      <div
        style={{
          maxWidth: '510px',
          margin: '0 auto'
        }}
        className='text-center !my-6'
      >
        <Helper
          message={
            'Projects serve as a comprehensive framework within OpsBeacon, seamlessly integrating commands, connections, and workflows. Each project is associated with a Slack channel, providing a centralized hub for managing your operations. This integration enables you to execute actions directly from the connected Slack channels.'
          }
        />
      </div>
      {userState.userRole.role !== userRoles.READ_ONLY && (
        <div className="mb-8 border-t border-gray-200 pt-5 dark:border-gray-800">
          <div className="flex justify-center">
            <span className="inline-flex rounded-md">
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to="/projects/new"
                  className="focus:shadow-outline-teal inline-flex cursor-pointer justify-center rounded-md border border-transparent bg-secondary px-6 py-2 text-base font-medium leading-5 text-white transition duration-150 ease-out hover:bg-white focus:border-blue-600 focus:bg-white focus:outline-none active:bg-white hover:text-secondary hover:border-blue-600"
                >
                  Create a New Project
                </Link>
              </span>
            </span>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default CreateNewProjectInfo;

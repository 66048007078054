import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { globalViewStates } from 'constants/index';
import { secretExists, addNewSecret } from './io';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { fetchData } from '../KeyList/io';
import { useToast } from 'hooks/use-toast';

const KeyNew = () => {
  const initialFormModel = {
    name: '',
    kind: 'ssh',
    description: '',
    sshInfo: {
      username: '',
      privateKey: '',
      passphrase: ''
    }
  };

  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [formErrorModel, setFormErrorModel] = useState({
    sshName: false,
    uniqueName: false,
    sshUserName: false,
    sshKey: false
  });
  const [secretList, setSecretList] = useState([]);
  const { toast } = useToast();

  const onFormSubmitSecret = (e) => {
    //e.preventDefault()
    const obj = {
      sshName: formModel.name.trim() === '',
      uniqueName: secretList.find(
        (secret) => secret.name.toLowerCase() === formModel.name.toLowerCase()
      )
        ? true
        : false,
      sshUserName: formModel.sshInfo.username.trim() === '',
      sshKey: formModel.sshInfo.privateKey.trim() === ''
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onSshFormModelSave();
    }
  };

  const onSshFormModelSave = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(formModel.name);

      if (!success) {
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
        // alert('There was a problem. Try again later.');
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addNewSecret(formModel);

      if (!successAdd) {
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
        setViewState(globalViewStates.IDLE);
        return;
      }

      setViewState(globalViewStates.REDIRECT_TO_PARENT);
      toast({
        title: 'Successfully',
        description: 'New secret added'
      });
      // Check if key exists by getting key by name.
      // add key, go back to listing.
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
    }
  };

  const params = useParams(); // get param
  const secretName = params.secretName ? params.secretName : null;

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  //Change Headers
  const onChangeShhInfo = (value, field) => {
    setFormModel((prevState) => {
      if (field === 'username') {
        prevState.sshInfo.username = value;
      } else if (field === 'privateKey') {
        prevState.sshInfo.privateKey = value;
      } else if (field === 'passphrase') {
        prevState.sshInfo.passphrase = value;
      }

      return {
        ...prevState
      };
    });
  };

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { secrets } = payload;
        setSecretList(secrets);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!secretName) {
        if (
          secretList.find((secret) => secret.name.toLowerCase() === formModel.name.toLowerCase())
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  }, [formModel.name, secretList]);

  // clear form
  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  // const onBackClick = (evt) => {
  //   evt.preventDefault();
  //   setViewState(globalViewStates.REDIRECT_TO_PARENT);
  // };

  const disabled = viewState === globalViewStates.POSTING;
  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/secrets" />;
  }

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW SSH KEY</h2>

          <form>
            <div className="mb-4 rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm leading-5 text-blue-700">
                    We take your security and privacy very seriously:{' '}
                    <strong>Your data will be encrypted</strong> in transit and at rest.
                    <br />
                    We also <strong>encrypt your SSH private key</strong> using a secure token
                    uniquely generated for you.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Name
                </label>

                <input
                  // value={name}
                  name="name"
                  id="name"
                  disabled={disabled}
                  placeholder=""
                  maxLength="250"
                  required
                  className={`${btnExtraClassName} input-main ${
                    formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                  }`}
                  value={formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />

                <span className="label-second">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.sshName && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="description" className="label-main">
                  Username
                </label>

                <input
                  id="username"
                  name="username"
                  disabled={disabled}
                  required
                  placeholder=""
                  className={`${btnExtraClassName} input-main`}
                  value={formModel.sshInfo.username}
                  onChange={(e) => {
                    onChangeShhInfo(e.target.value, 'username');
                  }}
                />

                {formErrorModel.sshUserName && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="description" className="label-main">
                  Passphrase (Optional)
                </label>

                <input
                  id="passphrase"
                  name="passphrase"
                  type="password"
                  disabled={disabled}
                  required
                  placeholder=""
                  className={`${btnExtraClassName} input-main`}
                  value={formModel.sshInfo.passphrase}
                  onChange={(e) => {
                    onChangeShhInfo(e.target.value, 'passphrase');
                  }}
                />
              </div>

              <div>
                <label htmlFor="key" className="label-main">
                  SSH Private Key
                </label>

                <textarea
                  // onChange={onKeyChange}
                  // value={key}
                  disabled={disabled}
                  id="privateKey"
                  name="privateKey"
                  rows={15}
                  required
                  placeholder="-----BEGIN RSA PRIVATE KEY-----
                  MIIEpAIBAAKCAQEA14b1CAPmqsj3oCTPTdBypCcLDHvYPmA44TaTEQEGGJR0J3om
                  YGpcX+YuibyvAKHwHCRDtoNKDLQIVuV1ZTI0e2s7KP1yWXvRVOeKfGs/Z7APKaCz
                  ZxPDh0p9a+gTPJSDZjuiOgBDY3RYB92bICVl8ShJOEnXu+AERI/coE8TpoTAlLwa
                  1g6YU/0CbFZu1wcwjjLsc8XBGlRFyqRipTtKCmvUYveliZZcjZuE9vzpkLC4eNhT
                  Ydn1xRIYXM/YLInIRJWaQDzSr1jMBehe70aQ0yVV6N4RPeG50lZKDayDGxqZ4ouf
                  ybxp4vMtRQ+aYLiRW4m3IkJVcedkOelKVPMlnwIDAQABAoIBAH9kTNb0/1W5PeyR
                  dVsIN4rhbP1vAk00MMqlqF3nngPPU6y13ZMlqv4gHyfYuBQGo9budHuftJg3napH
                  LJGpEC5XHnvG+tz7e6rUxR1vguW1wfCYBRKLMwp3tBSg8yXenqBB5UylNS/G5/WW
                  PgfNMylM56J2uyZYbg1cf0reEr5cKR2tVPhUMA8rwsDKbl7pCsWsXJ2lb47jSxtl
                  24jHJg7whd1pCrTqg6D98lh4LjpraKJlr0JctOSw4oEBL+e/pzoEAob6PpKkbybX
                  RVcZ1GshEVieMm06S0leI17Sc5jUFowWi0RbFdhJGnku8nv5mCt6s7JGSTOagbev
                  bWWzpBkCgYEA+HnVFZmOBbSYIfMoFDGmS1mrPGlSmz7NcDSol0FehrV/NW3dWF2P
                  DfN3Tfxrx7iyNKtArw+nnpYXj5Cmb12hhd6MsL8TonSxjDn19dvJDP0ytdRpaEDa
                  wGMYZKQcLyVvvTczp+9MgYRnWgkpGMgklwqxSjuC21zIV5RCn2mLnmsCgYEA3g21
                  ZEVvpTeRApII+XGGLvx7JHqZO3c6WHqtQezytHLMWiKcU4Q/Bxl+Sec7q7/cmvzS
                  iLQBUodD7B6zD9zSSSRAJM+RUUsELoGuuShTiIWEXPbfKJRDCYJRdaPkoBikgIDv
                  aPsq5FytILf+xoSxKSTzlEoHtNxZH8+aFz98ep0CgYBELjIRJjx5Rk/EaRNjTyhh
                  Xq6E+iWHodj/ZAOq1W4Zqq3IPGhTOgDqBBeFl7D/cae8mg06vCHzex3l7/wrbNbI
                  hAO48AV0O28ImlLqbq+hV8zVZf6yc7FSvx5PZ1SwfyZFFmOfPiORGoK9iuvy9+BK
                  bc2uH9xNUw1KMs2BLpX4FwKBgQDU6KQB7qnr6ml543gDZJxaNPAFvB9saxdges/h
                  JEZaSrAxxHnLgkPElOcICVSniyEE4mEjfiS+cr/niE/8Z6ihRMbUBMm6/oiV5vYW
                  xtnBTvPpBzRVfkMAvRlKCMlP0BD6vaXQLPOlXk8JziHVSqlrX+jFSG43NgemfrGq
                  lM7P6QKBgQDDXU0OMjO4uZpG7/5ouErlrXLmn/5LExn3d89dorXMd3mJPQ41djm5
                  fLpJGJdfN6YDxiRmMy6vzvXhhSZPQzKSCt3N9kJAZ2eBRVn5Dh9fN6/evF8lDfoq
                  EZZd0b3jCs1L1No7cUUz5JU1epe5A9lwB7XdY26IU+Q1hHcjQfAn5A==
                  -----END RSA PRIVATE KEY-----"
                  className={`input-main !h-auto !p-3`}
                  value={formModel.sshInfo.privateKey}
                  onChange={(e) => {
                    onChangeShhInfo(e.target.value, 'privateKey');
                  }}
                />

                <span className="label-second">
                  Your private key typically begins with ‘ssh-rsa’, ‘ssh-ed25520’,
                  ‘ecdsa-sha2-nistp256’, ‘ecdsa-sha2-nistp384’, or ‘ecdsa-sha2-nistp521’.
                </span>

                {formErrorModel.sshKey && (
                  <span className="my-1 block text-sm !text-red-500">This area is required!</span>
                )}
              </div>
            </div>

            <div className="flex justify-center">
              <button type="button" className="btn-danger" onClick={clearForm}>
                Clear
              </button>

              <button type="button" className="btn-success" onClick={onFormSubmitSecret}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default KeyNew;

import axios from 'axios';
import { urls } from 'constants/index';

const updateUrl = (inUrl, key) => {
  return inUrl.replace(/%workspaceId%/, key);
};

const getWorkspaceID = async () => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_ID}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { workspaceId: '' }
      };
    }

    const { workspaceId } = data;
    if (!workspaceId) {
      return {
        success: false,
        payload: { workspaceId: '' }
      };
    }

    return {
      success: true,
      payload: {
        workspaceId
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { workspaceId: '' } };
  }
};

const getDebuggerWithWorkspaceId = (workspaceID, onMessage, onError) => {
  const eventSource = new EventSource(updateUrl(urls.URL_WORKSPACE_DEBUGGER, workspaceID), {
    withCredentials: true
  });

  eventSource.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      if (onMessage) {
        onMessage(data);
      }
    } catch (error) {
      void error;
    }
  };

  eventSource.onerror = (error) => {
    if (onError) {
      onError(error);
    }
    eventSource.close();
  };

  return eventSource;
};

export { getWorkspaceID, getDebuggerWithWorkspaceId };

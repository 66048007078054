import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addOrUpdateConnection, fetchKeys } from './io';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { RiCloseLine } from 'react-icons/ri';
import { deleteConnection, fetchConnectionDataDetail } from '../connectionList/io';
import { postMethods } from 'constants/index';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { ConnectionEnvironment } from '../connectionEnvironment';
import { useToast } from 'hooks/use-toast';

export default function ConnectionNewSsm({ isInnerForm = false, setIsInnerFormSuccess = null }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, defaultValues },
    setValue,
    control,
    getValues
  } = useForm({
    defaultValues: {
      name: '',
      kind: 'ssm',
      description: '',
      secret: '',
      ssmConnectionInfo: {
        region: '',
        targets: []
      }
    }
  });
  // target object
  const { fields, append, remove } = useFieldArray({
    name: 'ssmConnectionInfo.targets',
    control,
    rules: {
      required: 'This area is required'
    }
  });

  const navigate = useNavigate();
  const [uniqueNameError, setUniqueNameError] = useState(false);
  const [secretList, setSecretList] = useState([]);
  const [instructionsModal, setInstructionsModal] = useState(false);
  const modalRef = useRef(null);
  const params = useParams(); // get param
  const connectionName = params.connectionName ? params.connectionName : null;
  const [showModal, setShowModal] = useState(false);
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  useEffect(() => {
    async function loadData() {
      const keysData = await fetchKeys();
      if (keysData.success) {
        const secretsSSM = keysData.payload.secrets.filter(
          item => item.kind === 'aws-credential' || item.kind === 'aws-role-base'
        );
        setSecretList(secretsSSM);
  
        if (connectionName) {
          const connectionData = await fetchConnectionDataDetail(connectionName);
          if (connectionData.success) {
            const { connection } = connectionData.payload;
            reset({
              ...connection,
              ssmConnectionInfo: {
                region: connection.ssmConnectionInfo.region,
                targets: connection.ssmConnectionInfo.targets
              }
            });
          }
        }
      }
    }
  
    loadData();
  }, [connectionName, reset]);
  

  const onFormSubmit = (type, e) => {
    e.preventDefault();
    if (type === postMethods.SAVE) {
      handleSubmit(
        async (data) => {
          if (!uniqueNameError) {
            const { success: successAdd } = await addOrUpdateConnection(data);

            if (!successAdd) {
              toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
              return;
            } else {
              toast({
                title: 'Successfully',
                description: 'Connection successfully added'
              });
              if (!isInnerForm) {
                navigate('/connections');
              } else {
                setIsInnerFormSuccess(true);
              }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      )(e);
    } else if (type === postMethods.UPDATE) {
      handleSubmit(
        async (data) => {
          const { success: successUpdate } = await addOrUpdateConnection(
            { ...data },
            connectionName
          );
          if (!successUpdate) {
            toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
            return;
          } else {
            toast({
              title: 'Successfully',
              description: `${connectionName} updated.`
            });
            if (!isInnerForm) {
              navigate('/connections');
            } else {
              setIsInnerFormSuccess(true);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      )(e);
    }
  };

  const checkIfNameExists = (name) => {
    if (name !== '') {
      if (secretList.find((secret) => secret.name.toLowerCase() === name.toLowerCase())) {
        setUniqueNameError(true);
      } else {
        setUniqueNameError(false);
      }
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setInstructionsModal(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const deletionConfirmed = (evt) => {
    const triggerNames = getValues('triggerNames');
    const projectNames = getValues('projectNames');

    if (!connectionName) {
      return;
    } else if (triggerNames.length !== 0 || projectNames.length !== 0) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'This connection is being used in triggers or projects'
      });
      setShowModal(!showModal);
    } else {
      deleteConnection(connectionName).then(
        () => {
          navigate('/connections');
          toast({
            title: 'Successfully',
            description: `${connectionName} was deleted.`
          });
        },
        (err) => {
          console.log(err);
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:'There was a problem. Please try again later.'
          });
        }
      );
    }
  };

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <div className="flex items-center justify-center gap-2">
            <InformationCircleIcon
            onClick={() => setInstructionsModal(true)}
              className="h-6 w-6 cursor-pointer text-secondary"
            />
            <h2 className="text-center text-base font-bold">
              {connectionName ? 'UPDATE SSM CONNECTION' : 'NEW SSM CONNECTION'}
            </h2>
          </div>
          {/* Instructions Modal*/}
          <div
            ref={modalRef}
            className={`fixed right-0 top-0 z-40  h-full w-[26vw] overflow-auto bg-secondary p-10  text-white duration-300 ease-in-out ${
              instructionsModal ? 'translate-x-0 ' : 'translate-x-full'
            }`}
          >
            <div
              className="absolute right-3 top-3 cursor-pointer"
              onClick={() => setInstructionsModal(false)}
            >
              <RiCloseLine className="h-6 w-6 text-white" />
            </div>
            <p className="text-sm leading-5 text-white">
              If you are using role-based AWS authentication, In addition to this trust policy which
              will allow OpsBeacon AWS account to assume your role in your account - you also need
              to provide necessary permissions for us to be able to send SSM Run Commands to your
              target EC2 instances. Here is a sample permission policy that allows us to execute
              remote commands on any instance. You can limit this further based on your needs. Refer
              to AWS documentation:
            </p>
            <Link className="text-sm leading-5 underline">
              https://docs.aws.amazon.com/systems-manager/latest/userguide/run-command-setting-up.html
            </Link>
            <pre className="whitespace-break-spaces py-2 text-xs">
              {`{
   "Version":"2012-10-17",
   "Statement":[
      {
         "Effect":"Allow",
         "Action":[
            "ssm:SendCommand"
         ],
         "Resource":[
            "arn:aws:ssm:*:*:document/AWS-RunShellScript"
         ]
      },
      {
         "Effect":"Allow",
         "Action":[
            "ssm:SendCommand"
         ],
         "Resource":[
            "arn:aws:ec2:*:*:instance/*"
         ]
         }
      }
   ]
} `}
            </pre>
          </div>
          {/* Instructions Modal*/}
          {/* Form */}
          <div className="flex flex-col gap-6 ">
            <form>
              <div>
                <label htmlFor="name" className="label-main">
                  Connection Name *
                </label>
                <input
                  {...register('name', {
                    required: 'This area is required!',
                    onChange: (e) => {
                      checkIfNameExists(e.target.value);
                    }
                  })}
                  readOnly={connectionName && true}
                  className={`${connectionName ? 'input-main-disabled' : 'input-main'}`}
                />
                {errors.name !== undefined && errors.name.type === 'required' && (
                  <span className="label-second my-1 block !text-red-500">
                    {errors.name.message}
                  </span>
                )}
                {uniqueNameError && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${defaultValues.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>
                <input type="text" className="input-main" {...register('description')} />
              </div>
              <div>
                <label htmlFor="secret" className="label-main">
                  Ssm Credential *
                </label>
                <select
                  id="secret"
                  className={`input-main`}
                  {...register('secret', {
                    required: 'This area is required!'
                  })}
                >
                  {secretList.length === 0 ? (
                    <option value="">There is no authentication record.</option>
                  ) : (
                    <>
                      <option value="">Select an existing key</option>
                      {secretList.map((key) => (
                        <option value={key.name} key={key.name}>
                          {key.name}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {errors.secret !== undefined && errors.secret?.type === 'required' && (
                  <span className="label-second my-1 block !text-red-500">
                    {errors.secret.message}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="region" className="label-main">
                  Region
                </label>
                <input
                  {...register('ssmConnectionInfo.region', {
                    required: 'This area is required!'
                  })}
                  className={`input-main`}
                />
                {errors.ssmConnectionInfo !== undefined &&
                  errors.ssmConnectionInfo.region?.type === 'required' && (
                    <span className="label-second my-1 block !text-red-500">
                      {errors.ssmConnectionInfo.region.message}
                    </span>
                  )}
              </div>
              <div>
                <label htmlFor="targets" className="label-main border-b py-3">
                  Targets
                </label>
                {fields?.map((field, index) => {
                  return (
                    <section key={field.id} className="flex items-center gap-2 pt-2">
                      <div className="w-full">
                        <label className="label-main">Key</label>
                        <input
                          className="input-main"
                          {...register(`ssmConnectionInfo.targets.${index}.key`, {
                            required: true
                          })}
                          defaultValue={field.key}
                        />
                      </div>
                      <div className="w-full">
                        <label className="label-main">Values</label>
                        <input
                          className="input-main"
                          name={`ssmConnectionInfo.targets[${index}].values`}
                          defaultValue={field.values.join(',')} // We used join to separate values ​​with commas
                          onChange={(e) => {
                            const newValues = e.target.value.split(',');
                            setValue(`ssmConnectionInfo.targets[${index}].values`, newValues, {
                              shouldValidate: true
                            });
                          }}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn-delete-second"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <RiCloseLine />
                      </button>
                    </section>
                  );
                })}
                {errors.ssmConnectionInfo?.targets?.root?.message && (
                  <span className="label-second my-1 block !text-red-500">
                    {errors.ssmConnectionInfo.targets.root.message}
                  </span>
                )}
                <div className="flex w-full justify-end">
                  <button
                    type="button"
                    className='dark:border-gray-700" mt-4 flex items-center rounded-md border p-1 text-blue-500 hover:bg-blue-300 hover:text-blue-900'
                    onClick={() => {
                      append({
                        key: '',
                        values: ['']
                      });
                    }}
                  >
                    Add New Target
                  </button>
                </div>
              </div>
              {connectionName && (
                <div>
                  <h2 className="mb-2 text-xl font-extrabold">Environment Variables</h2>
                  <ConnectionEnvironment connectionName={connectionName} />
                </div>
              )}
              {userState.userRole.role !== userRoles.READ_ONLY && connectionName && (
                <>
                  <h2 className="py-6 text-xl font-extrabold">Danger Zone</h2>

                  <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                    <p className="label-second">
                      By clicking the button below you delete this connection. This action is{' '}
                      <strong>irreversible</strong>.
                    </p>
                    <p className="label-second mb-4">
                      You can still re-use this connection's secrets and commands in other
                      connections.
                    </p>
                    <button
                      type="button"
                      onClick={() => setShowModal(!showModal)}
                      className={` text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                    >
                      I understand the consequences. Delete this connection
                    </button>
                  </div>
                </>
              )}
              <div className="flex justify-center">
                {connectionName ? (
                  <>
                    <button
                      type="button"
                      className="btn-danger"
                      onClick={() => navigate('/connections')}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('UPDATE', e)}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <>
                    <button type="button" className="btn-danger" onClick={() => reset()}>
                      Clear
                    </button>
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('SAVE', e)}
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
          {/* Form */}
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this connection?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={deletionConfirmed}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

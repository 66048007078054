import { urls } from 'constants/index';
import axios from 'axios';

export const getEnvironments = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_ENVIRONMENTS, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { environments: [] }
      };
    }

    const { environments, success } = data;

    if (!environments) {
      return {
        success: success,
        payload: { environments: [] }
      };
    }

    return {
      success: success,
      payload: {
        environments
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { environments: [] } };
  }
};

export const postEnvironment = async ({ environment }) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_ENVIRONMENTS,
      { ...environment },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const putEnvironment = async ({ environment }) => {
  try {
    const result = await axios.put(
      `${urls.URL_WORKSPACE_ENVIRONMENTS}/${environment.key}`,
      { ...environment },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const deleteEnvironment = async ({ key }) => {
  try {
    const result = await axios.delete(`${urls.URL_WORKSPACE_ENVIRONMENTS}/${key}`, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const getEnvironment = async ({ key }) => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_ENVIRONMENTS}/${key}`, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { environment: {} }
      };
    }

    const { environment, success } = data;

    if (!environment) {
      return {
        success: success,
        payload: { environment: {} }
      };
    }

    return {
      success: success,
      payload: {
        environment
      }
    };
  } catch (ex) {
    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { environment: {} } };
  }
};

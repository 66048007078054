import React, { useEffect, useLayoutEffect, useState } from 'react';
import { features, globalViewStates, postMethods } from '../../../constants';
import { Navigate, useParams } from 'react-router-dom';
import { commandExists, addOrUpdateCommand } from './io';
import { fetchCommandDataDetail, deleteCommand } from '../commandList/io';
import { fetchData } from 'features/project/ProjectList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import ApprovalPolicyOverride from 'components/ApprovalPolicyOverride';
import ExecutionPolicyOverride from 'components/ExecutionPolicyOverride';
import ApprovalWorkflow from 'components/ApprovalWorkflow';
import {
  createCommandArgumentObject,
  isInvalidArguments,
  validateArguments
} from './helpers/argumentJiraHelper';
import { nanoid } from 'nanoid';
import JiraCommandArgument from 'components/JiraCommandArgument';
import jiraData from 'features/command/commandNew/jiraData.json';
import { Flags } from 'contexts/FlagProvider';
import { fetchPolicies } from 'features/user/io';
import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';
import { BiPlus } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, MinusIcon } from '@heroicons/react/20/solid';
import { useToast } from 'hooks/use-toast';

const initialFormModel = {
  name: '',
  private: false,
  kind: 'jira',
  description: '',
  jiraCommandInfo: {
    arguments: [],
    command: ''
  },
  projectNames: [],
  approvalGroup: '',
  unleashCommand: {},
  leashCommand: {}
};

const CommandNewJira = () => {
  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [showModal, setShowModal] = useState(false);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    jiraCommandKind: false
  });
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const params = useParams(); // get param
  const [commandName, setCommandName] = useState(params.commandName ? params.commandName : null);
  const [postMethodType, setPostMethodType] = useState();
  const [submit, setIsSubmit] = useState(false);
  const [argumentObjects, setArgumentObjects] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [updatedSelectedOptions, setUpdatedSelectedOptions] = useState([]);
  const [requiredOptions, setRequiredOptions] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  useLayoutEffect(() => {
    if (commandName) {
      fetchCommandDataDetail(commandName).then(
        ({ success, payload }) => {
          void success;

          const { command } = payload;

          const temporaryForm = {
            name: command.name,
            kind: 'jira',
            description: command.description,
            jiraCommandInfo: {
              arguments: createCommandArgumentObject(command.jiraCommandInfo.arguments),
              command: command.jiraCommandInfo.command
            },
            private: command.private,
            projectNames: command.projectNames,
            approvalGroup: command.approvalGroup,
            unleashCommand: command.unleashCommand,
            leashCommand: command.leashCommand
          };
          setSelectedProjects(
            command.projectNames
              ? command.projectNames.map((project) => {
                  return { value: project, label: project };
                })
              : []
          );
          setArgumentObjects(temporaryForm.jiraCommandInfo.arguments);
          setFormModel(temporaryForm);
          setViewState(globalViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [commandName]);

  const onFormSubmit = async (type, e) => {
    e.preventDefault();
    setPostMethodType(type);
    setIsSubmit(true);

    const jiraArguments =
      argumentObjects !== undefined
        ? argumentObjects.map((argument) => ({
            name: argument.name,
            default:
              typeof argument.default == 'boolean' ? argument.default.toString() : argument.default,
            shortName: argument.shortName,
            type: argument.type,
            required: argument.required
          }))
        : [];

    setFormModel({
      ...formModel,
      jiraCommandInfo: {
        ...formModel.jiraCommandInfo,
        arguments: [...jiraArguments]
      }
    });
  };

  useEffect(() => {
    if (submit) {
      const obj = {
        name: postMethodType === postMethods.UPDATE ? false : formModel.name.trim() === '',
        jiraCommandKind: formModel.jiraCommandInfo.command.trim() === ''
      };
      setFormErrorModel(obj);

      let showErrorToast = Object.values(obj).includes(true);

      if (argumentObjects !== undefined) {
        const validatedArgumentObjects = validateArguments(argumentObjects);
        setArgumentObjects([...validatedArgumentObjects]);
        showErrorToast = showErrorToast || isInvalidArguments(validatedArgumentObjects);
      }

      if (showErrorToast) {
        setIsSubmit(false);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'Please fill the required areas!'
        });
        return;
      } else {
        if (postMethodType === postMethods.SAVE) {
          onFormSave();
        } else if (postMethodType === postMethods.UPDATE) {
          onFormUpdate();
        }
        setIsSubmit(false);
      }
    }
  }, [formModel]);

  // Get Projects, triggers and policies
  useEffect(() => {
    fetchData().then(({ success, payload }) => {
      void success;

      const { projects } = payload;

      const allProjectsOption = projects.length && { value: 'selectAll', label: 'All' };
      setProjects(
        allProjectsOption
          ? [
              allProjectsOption,
              ...projects.map((connection) => ({
                value: connection.name,
                label: connection.name
              }))
            ]
          : projects.map((connection) => ({ value: connection.name, label: connection.name }))
      );
    });
    fetchPolicies().then(({ success, payload }) => {
      void success;

      const { policies } = payload;

      setPolicies(policies);
    });
    sendFetchTriggersRequest().then(({ success, payload }) => {
      void success;

      const { triggers } = payload;

      setTriggers(triggers);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.commands && policy.commands.includes(commandName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, commandName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.commands && trigger.commands.includes(commandName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, commandName]);

  //Change Input
  const onChangeInput = (value, field) => {
    setFormModel({
      ...formModel,
      [field]: value
    });
  };

  const handleSelectedJiraCommandKind = (e) => {
    const commandKind = e.target.value;

    setFormModel({
      ...formModel,
      jiraCommandInfo: {
        ...formModel.jiraCommandInfo,
        command: commandKind
      }
    });

    setArgumentObjects([]);
  };

  // const onBackClick = (evt) => {
  //   evt.preventDefault();
  //   setViewState(globalViewStates.REDIRECT_TO_PARENT);
  // };

  const onFormSave = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await commandExists(formModel.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addOrUpdateCommand({
        ...formModel
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem saving your command. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'New command added'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem saving your command. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateCommand(
        {
          ...formModel
        },
        commandName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem updating your command. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'Command successfully updated'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem updating your command. Please try again later.'
      });
    }
  };

  /********************   Change Argument   *********************/

  // clear form
  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  const onProjeUpdate = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateCommand(formModel, commandName);

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem updating your command. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem updating your command. Please try again later.'
      });
    }
  };

  //delete command
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    if (
      selectedTriggers.length !== 0 ||
      selectedPolicies.length !== 0 ||
      selectedProjects.length !== 0
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: `This command is being used in ${
          selectedTriggers.length !== 0 || selectedProjects.length !== 0
            ? `${selectedTriggers.map((trigger) => `'${trigger.name}'`).join(', ')} ${
                selectedProjects &&
                selectedProjects.map((project) => `'${project.value}'`).join(', ')
              } triggers`
            : ''
        }
        ${
          selectedPolicies.length !== 0
            ? `${selectedPolicies.map((policy) => `'${policy.name}'`).join(', ')} policies`
            : ''
        } 
        `
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    await onProjeUpdate();
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!commandName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteCommand(commandName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${commandName} was deleted.`
        });
        
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
      }
    );
  };

  const handleChangeApprovalPolicyGroup = (selectedGroup) => {
    setFormModel({
      ...formModel,
      unleashCommand: {
        ...formModel.unleashCommand,
        group: selectedGroup
      }
    });
  };
  const handleChangeApprovalPolicyFlags = (selectedFlags) => {
    setFormModel({
      ...formModel,
      unleashCommand: {
        ...formModel.unleashCommand,
        list: [...selectedFlags.map((command) => command.value)]
      }
    });
  };
  const handleChangeExecutionPolicyGroup = (selectedGroup) => {
    setFormModel({
      ...formModel,
      leashCommand: {
        ...formModel.leashCommand,
        group: selectedGroup
      }
    });
  };
  const handleChangeExecutionPolicyFlags = (selectedFlags) => {
    setFormModel({
      ...formModel,
      leashCommand: {
        ...formModel.leashCommand,
        list: [...selectedFlags.map((command) => command.value)]
      }
    });
  };

  useEffect(() => {
    if (formModel.jiraCommandInfo.command !== null) {
      const options =
        jiraData?.find((item) => item.value === formModel.jiraCommandInfo.command)?.options || [];
      setSelectedOptions(options);

      if (options && options.length > 0) {
        const filteredOptions = options.filter((option) => option.required === true) || [];
        setRequiredOptions(filteredOptions);
      }
    }
  }, [formModel.jiraCommandInfo.command, jiraData]);

  // Add Argument
  const addArgument = (e) => {
    e.preventDefault();
    if (argumentObjects.length < selectedOptions.length) {
      setArgumentObjects([
        ...argumentObjects,
        {
          id: nanoid(),
          name: '',
          shortName: '',
          required: false,
          type: '',
          default: '',
          invalidName: false,
          invalidDefault: false,
          invalidType: false,
          invalidMessage: '',
          duplicatedName: true
        }
      ]);
    } else {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'No more arguments to add'
      });
    }
  };

  // add required arguments
  const createArgumentsFromRequiredOptions = () => {
    const newArguments = requiredOptions?.map((option) => ({
      id: nanoid(),
      name: option.name,
      shortName: '',
      required: option.required,
      type: option.type,
      default: '',
      invalidName: false,
      invalidDefault: false,
      invalidType: false,
      invalidMessage: '',
      duplicatedName: true
    }));

    setArgumentObjects([...newArguments]);
  };

  useEffect(() => {
    if (!commandName && requiredOptions.length > 0) {
      createArgumentsFromRequiredOptions();
    }
  }, [requiredOptions]);
  // Delete Argument
  const removeArgument = (removedArgumentId) => {
    const filteredArguments = argumentObjects.filter(
      (argument) => argument.id !== removedArgumentId
    );
    setArgumentObjects(filteredArguments);
  };

  const handleChangedArgument = (updatedArgument) => {
    const updatedArgumentObjectIndex = argumentObjects.findIndex(
      (argument) => argument.id === updatedArgument.id
    );

    const copyArgumentObjects = argumentObjects;
    copyArgumentObjects.splice(updatedArgumentObjectIndex, 1, updatedArgument);
    setArgumentObjects([...copyArgumentObjects]);
  };

  useEffect(() => {
    const updatedOptions = selectedOptions.filter((selectedOption) => {
      return !argumentObjects.some((argumentObject) => argumentObject.name === selectedOption.name);
    });
    setUpdatedSelectedOptions(updatedOptions);
  }, [selectedOptions, argumentObjects]);

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/commands" />;
  }

  const disabled = viewState === globalViewStates.POSTING;
  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {commandName ? 'UPDATE JIRA COMMAND' : 'NEW JIRA COMMAND'}
            </h2>
            <div className="mb-4 rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm leading-5 text-blue-700">
                    For more information please checkout the
                    <a href=" https://docs.ob2.ai/opsbeacon/args-in-cmd-workflow" target="_blank">
                      <u> How to use arguments in commands and workflows</u>
                    </a>{' '}
                    page.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Name *
                </label>

                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  disabled={commandName}
                  readOnly={commandName}
                  maxLength="250"
                  placeholder=""
                  className={`${btnExtraClassName} ${
                    commandName ? 'input-main-disabled' : 'input-main'
                  } ${formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''}`}
                  value={commandName ? commandName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />

                <span className="label-second">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="name" className="label-main">
                  Jira command kind *
                </label>

                {commandName ? (
                  <input
                    id="command"
                    className="input-main-disabled"
                    value={formModel.jiraCommandInfo.command}
                    readOnly
                  />
                ) : (
                  <select
                    id="command"
                    className={`input-main`}
                    value={formModel.jiraCommandInfo.command}
                    required
                    onChange={handleSelectedJiraCommandKind}
                  >
                    {jiraData.length === 0 ? (
                      <option>There is no name</option>
                    ) : (
                      <option value="">Select a name</option>
                    )}

                    {jiraData &&
                      jiraData.map((key) => {
                        return (
                          <option value={key.label} key={key.label}>
                            {key.label}
                          </option>
                        );
                      })}
                  </select>
                )}

                {formErrorModel.jiraCommandKind && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formModel.jiraCommandInfo.command && (
                  <span className="label-second my-1 block">
                    {
                      jiraData.find((option) => option.label === formModel.jiraCommandInfo.command)
                        ?.desc
                    }
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>

                <input
                  name="description"
                  id="description"
                  placeholder="description"
                  value={formModel.description}
                  disabled={disabled}
                  className={`${btnExtraClassName} input-main`}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'description');
                  }}
                />
              </div>

              {/* Arguments */}
              {(formModel.jiraCommandInfo.command || commandName) && (
                <div className="sm:col-span-6">
                  <label
                    htmlFor="command"
                    className="label-main mb-3 block border-b pb-1 dark:border-gray-700"
                  >
                    Arguments
                  </label>

                  {argumentObjects?.length > 0 && (
                    <>
                      <div className="command-arguments !pb-0">
                        <div className="w-full">
                          <label className="label-main">Name:</label>
                        </div>
                        <div className="w-full">
                          <label className="label-main">Type:</label>
                        </div>
                        <div className="w-full">
                          <label className="label-main">Default Value:</label>
                        </div>
                        <div className="w-full">
                          <label className="label-main">Required:</label>
                        </div>
                        <div className="w-full">
                          <label className="label-main">Short Name:</label>
                        </div>
                      </div>
                      {argumentObjects?.map((argument) => (
                        <JiraCommandArgument
                          key={argument.id}
                          argumentObject={argument}
                          commandName={commandName ? commandName : formModel.name}
                          options={
                            updatedSelectedOptions ? updatedSelectedOptions : selectedOptions
                          }
                          onRemove={removeArgument}
                          onChange={handleChangedArgument}
                        />
                      ))}
                    </>
                  )}

                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="flex items-center rounded-md border p-1.5 px-2 text-blue-500 hover:bg-blue-300 hover:text-blue-900 dark:border-gray-700"
                      onClick={addArgument}
                    >
                      <BiPlus className="mr-1 !text-base" /> Add New
                    </button>
                  </div>
                </div>
              )}

              <Disclosure as="div" className="border-b border-gray-200 py-6">
                {({ open }) => (
                  <>
                    <h3 className="-my-3 flow-root">
                      <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm">
                        <h2 className="text-xl font-extrabold">Advanced Settings</h2>
                        <span className="ml-6 flex items-center">
                          {open ? (
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <ChevronDownIcon
                              className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="flex flex-col gap-y-4 py-4">
                        <div className="rounded-md border p-4 dark:border-gray-700">
                          <div className="flex items-center">
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="private"
                                name="private"
                                checked={formModel.private}
                                onChange={(e) => {
                                  onChangeInput(!formModel.private, 'private');
                                }}
                              />
                              <span className="slider round"></span>
                            </label>

                            <label
                              htmlFor="private"
                              className="label-main ml-2 mt-1 cursor-pointer "
                            >
                              Enable Direct Message Output
                            </label>
                          </div>

                          <label className="label-second mt-2 block">
                            Enable this setting to send the output of this command directly to the
                            person who triggered it.
                          </label>
                        </div>

                        {/* Approval Workflow */}
                        <Flags authorizedFlags={[features.APPROVAL_POLICY]}>
                          <ApprovalWorkflow
                            defaultGroup={{
                              value: formModel.approvalGroup,
                              label: formModel.approvalGroup
                            }}
                            onChange={(approvalGroup) => {
                              setFormModel({
                                ...formModel,
                                approvalGroup: approvalGroup
                              });
                            }}
                          />
                        </Flags>
                      </div>
                      <div className="space-y-4">
                        {/* Approval Policy Override */}
                        <ApprovalPolicyOverride
                          defaultGroup={
                            formModel.unleashCommand ? formModel.unleashCommand.group : ''
                          } // for difference between old command object type
                          defaultFlags={formModel.unleashCommand.list} // for difference between old command object type
                          onChangeApproverGroup={handleChangeApprovalPolicyGroup}
                          onChangeFlags={handleChangeApprovalPolicyFlags}
                        />

                        {/* Execution Policy Override */}
                        <ExecutionPolicyOverride
                          defaultGroup={formModel.leashCommand ? formModel.leashCommand.group : ''} // for difference between old command object type
                          defaultFlags={formModel.leashCommand.list} // for difference between 'old command object' type
                          onChangeApproverGroup={handleChangeExecutionPolicyGroup}
                          onChangeFlags={handleChangeExecutionPolicyFlags}
                        />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {userState.userRole.role !== userRoles.READ_ONLY && commandName && (
                <>
                  <h2 className="text-xl font-extrabold">Danger Zone</h2>

                  <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                    <p className="label-second">
                      By clicking the button below you delete this command. This action is{' '}
                      <strong>irreversible</strong>.
                    </p>
                    <p className="label-second mb-4">
                      You can still re-use this command's features in other commands.
                    </p>
                    <button
                      disabled={disabled}
                      type="button"
                      onClick={onDeleteClick}
                      className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                    >
                      I understand the consequences. Delete this command
                    </button>
                  </div>
                </>
              )}

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="mt-8 flex justify-center">
                  <button type="button" className="btn-danger" onClick={clearForm}>
                    Clear
                  </button>

                  {commandName ? (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('UPDATE', e)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('SAVE', e)}
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this command?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CommandNewJira;

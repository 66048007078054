import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const Modal = (props) => {
  const modalRef = useRef();

  useEffect(() => {
    const clickOutsideContent = (e) => {
      if (e.target === modalRef.current) {
        props.setShow(false);
      }
    };
    window.addEventListener('click', clickOutsideContent);
    return () => {
      window.removeEventListener('click', clickOutsideContent);
    };
  }, [props]);

  return (
    <div ref={modalRef} className={`ops-modal ${props.show ? 'active' : ''}`}>
      <div
        className={`ops-modal__content ops-modal__content-${props.size} ${
          props.height ? props.height : ''
        }`}
      >
        {props.closeButton === true ? (
          <span
            className="absolute top-2.5 right-5 cursor-pointer text-4xl opacity-80 hover:opacity-100"
            onClick={() => props.setShow(false)}
          >
            &times;
          </span>
        ) : (
          ''
        )}
        {props.children}
      </div>
    </div>
  );
};

export default Modal;

export const ModalHeader = (props) => {
  return (
    <div className={`${props.height ? props.height : 'h-12'} rounded-md p-5 text-center text-base`}>
      {props.children}
    </div>
  );
};

export const ModalBody = (props) => {
  return <div className="px-4 py-5 text-center">{props.children}</div>;
};

export const ModalFooter = (props) => {
  return <div className="px-4 py-5 text-center">{props.children}</div>;
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIncomplete = () => (
  <svg width="24" height="24" x="0px" y="0px" viewBox="0 0 24 24" className="m-auto">
    <g fill="#374151">
      <path d="M17.375 4.188a.25.25 0 0 1 .125.212V5a.25.25 0 0 1-.25.25h-.134a.18.18 0 0 0-.124.049l-7.524 7.524a.25.25 0 0 0 .177.427h4.739a.18.18 0 0 0 .124-.049l7.524-7.524a.25.25 0 0 0-.177-.427H19.75A.25.25 0 0 1 19.5 5v-.6a.25.25 0 0 1 .125-.216 2.25 2.25 0 1 0-2.25 0z" />
      <path d="M17.006 12.823a.25.25 0 0 0 .177.427H19.5a.25.25 0 0 1 .25.25v9.25a1.25 1.25 0 0 0 2.5 0V13.5a.25.25 0 0 1 .25-.25 1.5 1.5 0 0 0 1.5-1.5v-5a1.432 1.432 0 0 0-.042-.343.25.25 0 0 0-.42-.116z" />
      <path d="M7.119 5.677a.25.25 0 0 0-.177-.427H6.75A.25.25 0 0 1 6.5 5v-.6a.25.25 0 0 1 .125-.216 2.25 2.25 0 1 0-2.25 0A.25.25 0 0 1 4.5 4.4V5a.25.25 0 0 1-.25.25H1.5A1.5 1.5 0 0 0 0 6.75v5a1.452 1.452 0 0 0 .074.459.249.249 0 0 0 .414.1z" />
      <path d="M6.969 13.2l7.522-7.522a.25.25 0 0 0-.177-.427H9.741a.18.18 0 0 0-.124.049l-7.909 7.908a.025.025 0 0 0 0 .027.025.025 0 0 0 .023.015.024.024 0 0 1 .024.024v9.476a1.25 1.25 0 0 0 2.5 0V13.5a.25.25 0 0 1 .25-.25H6.85a.171.171 0 0 0 .119-.05z" />
    </g>
  </svg>
);

export default IconIncomplete;

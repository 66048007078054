/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationWebhooks = ({ customWidth, customHeight }) => (
  <svg
    width={customWidth ? customWidth : '48'}
    height={customHeight ? customHeight : '48'}
    viewBox="0 0 86 81"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="m-auto"
  >
    <use x=".5" y=".5" />
    <g stroke="none">
      <path
        d="M39.836 33.532l-10.38 17.603c-.888 1.483-1.328 2.7-.618 4.575 1.96 5.206-.804 10.273-5.998 11.648-4.898 1.297-9.67-1.957-10.64-7.258-.86-4.692 2.74-9.292 7.858-10.025.43-.062.866-.07 1.587-.124l7.784-13.195c-4.896-4.92-7.8-10.675-7.165-17.803.456-5.04 2.416-9.394 5.998-12.963 6.86-6.835 17.33-7.942 25.408-2.695 7.76 5.04 11.313 14.855 8.284 23.256L54.84 24.6c.95-4.67.248-8.865-2.868-12.46-2.06-2.372-4.7-3.616-7.705-4.074-6.022-.92-11.935 2.992-13.69 8.967-1.99 6.782 1.022 12.322 9.26 16.497h0z"
        fill="#c73a63"
      />
      <path
        d="M49.933 26.426l7.527 13.42c12.67-3.963 22.223 3.128 25.65 10.72 4.14 9.17 1.3 20.03-6.82 25.688-8.344 5.808-18.897 4.815-26.3-2.645l5.8-4.907c7.302 4.782 13.69 4.556 18.43-1.106 4.043-4.83 3.956-12.032-.205-16.76-4.8-5.457-11.233-5.623-19.007-.385L45.4 33.103c-1.053-1.963-2.215-3.102-4.588-3.518-3.963-.695-6.522-4.135-6.676-8-.15-3.812 2.07-7.258 5.543-8.6a8.59 8.59 0 0 1 9.79 2.7c1.89 2.416 2.492 5.134 1.497 8.114-.277.83-.635 1.634-1.023 2.62h0z"
        fill="#4b4b4b"
      />
      <path
        d="M55.887 62.743H40.634c-1.462 6.08-4.62 10.987-10.06 14.108-4.23 2.426-8.788 3.248-13.64 2.456C8 77.85.695 69.72.052 60.565c-.728-10.37 6.323-19.59 15.722-21.66l1.953 7.164C9.104 50.517 6.12 56.12 8.533 63.128c2.125 6.167 8.16 9.547 14.712 8.24 6.69-1.335 10.065-6.957 9.653-15.98l19.037.033c2.477.04 4.4-.22 6.256-2.428 3.073-3.633 8.728-3.305 12.037.126 3.382 3.507 3.22 9.15-.36 12.508-3.453 3.24-8.908 3.068-12.138-.424-.664-.72-1.187-1.574-1.844-2.46h0z"
        fill="#4a4a4a"
      />
    </g>
  </svg>
);

export default IconIntegrationWebhooks;

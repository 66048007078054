/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import {
  sendUpdateWebhookRequest,
  sendDeleteWebhookRequest,
  sendUpdateRegenarateUrl
} from './network';

const deleteWebhook = async ({ name }) => {
  try {
    const { success } = await sendDeleteWebhookRequest({
      name
    });

    if (!success) {
      return { success: false };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const regenerateUrl = async ({ name }) => {
  try {
    const result = await sendUpdateRegenarateUrl({
      name
    });

    if (result.success) {
      return { success: true, url: result.payload.webhookurl };
    }

    return {
      success: false
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const updateWebhook = async ({
  name,
  kind,
  description,
  commands,
  connections,
  workflows,
  webHookTriggerInfo
}) => {
  try {
    const { success } = await sendUpdateWebhookRequest({
      name,
      kind,
      description,
      commands,
      connections,
      workflows,
      webHookTriggerInfo: {}
    });

    if (!success) {
      return { success: false };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { updateWebhook, deleteWebhook, regenerateUrl };

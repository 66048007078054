import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronUpIcon, UserIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const userNavigation = [
  { name: 'Account', href: '/account' },
  { name: 'Sign out', href: '/logout' }
];

export const AccountMenu = () => {
  const userState = useSelector((state) => state.userState);
  const sidebarState = useSelector((state) => state.sidebarState.toggleSidebar);
  const location = useLocation();

  return (
    <Menu
      as="div"
      className={`absolute ${location.pathname === '/pricing' ? 'left-4' : ''} bottom-4 -mx-4`}
    >
      <Menu.Button
        className={`${
          location.pathname === '/pricing' ? 'bg-secondaries_blue_600' : ''
        } flex items-center rounded-lg p-2 hover:bg-secondaries_blue_600`}
      >
        <span className="sr-only">Open user menu</span>
        <UserIcon className="*:col-start-1 *:row-start-1 *:rounded-[--avatar-radius] inline-grid h-10 w-10 shrink-0 rounded-[--avatar-radius] bg-gray-100 align-middle outline outline-1 -outline-offset-1 outline-black/[--ring-opacity] [--avatar-radius:20%] [--ring-opacity:20%] dark:outline-white/[--ring-opacity] fill-secondary" />
        {!sidebarState && (
          <>
            <span className="hidden lg:ml-2 lg:flex lg:flex-col lg:items-start">
              <span className="block truncate text-sm/5 font-medium text-white" aria-hidden="true">
                {userState.userRole.fullName.length < 15
                  ? userState.userRole.fullName
                  : userState.userRole.fullName.slice(0, 15)}
              </span>
              <span
                className="block truncate text-xs/5 font-normal text-white"
                aria-hidden="true"
              >
                {userState.userRole.role || ''}
              </span>
            </span>
            <ChevronUpIcon
              className="ml-7 hidden h-5 w-5 text-white lg:block"
              aria-hidden="true"
            />
          </>
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${
            sidebarState ? 'w-32 ' : 'w-full'
          } absolute -top-24 z-50 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}
        >
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <Link
                  to={item.href}
                  className={classNames(
                    active ? 'bg-gray-50' : '',
                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                  )}
                >
                  {item.name}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

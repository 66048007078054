/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationAws = () => (
  <svg
    width="48"
    height="48"
    className="m-auto"
    viewBox="0 0 67 63"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <use x="1" y="1" />
    <path
      d="M31.26 60.886L17 55.766v-20.9l14.27 4.86zm2.6 0l14.27-5.12v-20.9L33.86 39.72zm-15.4-27.6l14.4-4.066 13.392 4.32-13.392 4.546zm-4.184-1.6L0 26.55V5.6l14.27 4.86zm2.614 0l14.27-5.12V5.6l-14.274 4.884zM1.5 4.086L15.9 0l13.392 4.32L15.9 8.866zm46.104 27.6l-14.27-5.12V5.6l14.27 4.86zm2.6 0l14.27-5.12V5.6l-14.27 4.86zm-15.4-27.6L49.194.02l13.392 4.32-13.392 4.546z"
      stroke="none"
      fill="#f90"
    />
  </svg>
);

export default IconIntegrationAws;

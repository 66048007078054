/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from '../../../../constants';

const updateUrl = (inUrl, key) => {
  return inUrl.replace(/%webhook%/, key);
};

const sendDeleteWebhookRequest = async ({ name }) => {
  try {
    const webhookName = name.trim();
    if (!webhookName) {
      return {
        success: false
      };
    }

    const result = await axios.delete(updateUrl(urls.URL_WORKSPACE_TRIGGER_WEBHOOK, webhookName), {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' },
      params: {},
      data: {}
    });

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const sendUpdateWebhookRequest = async ({
  name,
  kind,
  description,
  commands,
  connections,
  workflows,
  triggerInfo
}) => {
  try {
    const webhookName = name.trim();
    if (!webhookName) {
      return {
        success: false
      };
    }

    const webhookCommands = commands || [];
    const webhookConnections = connections || [];
    const webhookWorkflows = workflows || [];
    const webhookDescription = description;
    const webHookTriggerInfo = triggerInfo || {};

    const result = await axios.put(
      updateUrl(urls.URL_WORKSPACE_TRIGGER_WEBHOOK, webhookName),
      {
        name: webhookName,
        description: webhookDescription,
        connections: webhookConnections,
        commands: webhookCommands,
        workflows:webhookWorkflows,
        kind,
        webHookTriggerInfo
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    const { email, fullName } = data;

    return {
      success: true,
      payload: {
        email,
        fullName
      }
    };
  } catch (ex) {
    // Handle.
    console.log(ex);

    return {
      success: false
    };
  }
};

const sendUpdateRegenarateUrl = async ({ name }) => {
  try {
    const webhookName = name.trim();
    if (!webhookName) {
      return {
        success: false
      };
    }
    const regenerateUrl = updateUrl(urls.URL_WORKSPACE_TRIGGER_WEBHOOKS_REGENERATEURL, webhookName);
    const result = await axios.put(
      regenerateUrl,
      {},
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const webhookurl = result.data.url;

    if (!webhookurl) {
      return {
        success: false
      };
    }

    return {
      success: true,
      payload: {
        webhookurl
      }
    };
  } catch (ex) {
    // Handle.
    console.log(ex);

    return {
      success: false
    };
  }
};

export { sendUpdateWebhookRequest, sendDeleteWebhookRequest, sendUpdateRegenarateUrl };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { sendLogoutRequest } from './network';

const logOut = async () => {
  try {
    const { success } = await sendLogoutRequest();

    return { success };
  } catch (ex) {
    // Handle this.
    console.log(ex);

    return { success: false };
  }
};

export { logOut };

import {
  sendDeletePolicyRequest,
  sendDowngradePlan,
  sendFetchPolicyDetailRequest,
  sendFetchPolicyRequest
} from './network';

const fetchPolicyDataDetail = async (policyName) => {
  try {
    const { success, payload } = await sendFetchPolicyDetailRequest(policyName);

    if (!success) {
      return {
        success: false,
        payload: {
          policies: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { policies: [] } };
    }

    const { policies } = payload;

    return {
      success: true,
      payload: { policies }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { policies: [] } };
  }
};

const fetchPolicyData = async () => {
  try {
    const { success, payload } = await sendFetchPolicyRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          policies: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { policies: [] } };
    }

    const { policies } = payload;

    return {
      success: true,
      payload: { policies }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { policies: [] } };
  }
};

const deletePolicy = async (policyName) => {
  try {
    await sendDeletePolicyRequest(policyName);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

const fetchDowngradePlan = async (data) => {
  try {
    await sendDowngradePlan(data);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

export { deletePolicy, fetchPolicyData, fetchPolicyDataDetail, fetchDowngradePlan };

import axios from 'axios';
import { urls } from 'constants/index';

const sendSaveSlackStateRequest = async ({ project, state }) => {
  try {
    const result = await axios.post(
      urls.URL_INTEGRATION_SLACK_SAVE_STATE,
      {
        project,
        state
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export { sendSaveSlackStateRequest };
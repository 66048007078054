import axios from 'axios';
import { urls } from 'constants/index';

const SignupRequest = async ({ email, lookupKey, trial }) => {
  try {
    const result = await axios.post(
      urls.URL_IDM_SIGNUP,
      {
        email,
        lookupKey,
        trial
      },
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    );
    if (result) {
      return { success: true };
    }
    return { success: false };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

const ForgotPasswordRequest = async ({ email }) => {
  try {
    axios.post(
      urls.URL_IDM_FORGOT_PASSWORD,
      {
        email
      },
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    );
  } catch (err) {
    console.error(err);
  }
};

const ResetPasswordRequest = async ({ email, password, token }) => {
  try {
    axios.post(
      urls.URL_IDM_RESET_PASSWORD,
      {
        email,
        password,
        token
      },
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    );
  } catch (err) {
    console.error(err);
  }
};

const logUserIn = async (email) => {
  const url = `${urls.URL_IDM_LOGIN}/${email}`
  try {
    const result = await axios.get(
      url,
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    // void result;

    if (result && result.data) {
      return { success: true, result: result.data };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

const logGoogleIn = async ({ accessToken, optIn }) => {
  try {
    const result = await axios.post(
      urls.URL_IDM_GOOGLE_LOGIN,
      {
        accessToken,
        optIn
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.token) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

const logSlackIn = async (accessToken, optIn) => {
  try {
    const result = await axios.post(
      urls.URL_IDM_SLACK_LOGIN,
      {
        accessToken,
        optIn
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.token) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

const RegisterRequest = async ({ email, fullName, password, optIn, token, lookupKey, trial }) => {
  try {
    const requestObject =
      lookupKey !== 'undefined'
        ? { fullName, email, password, optIn, token, lookupKey, trial }
        : { fullName, email, password, optIn, token };
    const result = await axios.post(
      urls.URL_IDM_REGISTER,
      {
        ...requestObject
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.token) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

export {
  SignupRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  logUserIn,
  RegisterRequest,
  logGoogleIn,
  logSlackIn
};

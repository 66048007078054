import sendFetchProjectsRequest from 'network/sendFetchProjectsRequest';
import { sendSaveSlackStateRequest } from './network';

const fetchData = async () => {
  try {
    const { success, payload, reason } = await sendFetchProjectsRequest();

    if (!success) {
      if (reason) {
        return { success, reason, payload: { projects: [], info: {} } };
      }

      return { success: false, payload: { projects: [], info: {} } };
    }

    if (!payload) {
      return { success: false, payload: { projects: [], info: {} } };
    }

    const { projects, info } = payload;

    if (!projects) {
      return { success: false, payload: { projects: [], info: {} } };
    }

    return { success: true, payload: { projects, info } };
  } catch (ex) {
    // Handle this.
    console.log(ex);

    return { success: false, payload: { projects: [], info: {} } };
  }
};

const saveSlackState = async ({ project, state }) => {
  if (!project) {
    return;
  }

  try {
    const { success } = await sendSaveSlackStateRequest({ project, state });

    return { success };
  } catch (e) {
    console.log('saveSlackState:error', e);

    return { success: false };
  }
};

export { fetchData,saveSlackState };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { useEffect, useState } from 'react';

import { loginActivationViewStates } from 'constants/index';

import { makeTargetValueSetter, submitForm } from './event';
import { fetchData } from './io';

const useEvents = ({
  setFullName,
  setPassword,
  setPasswordConfirm,
  setViewState,
  // setUserInfo,
  email,
  token,
  password,
  passwordConfirm,
  fullName
}) => {
  const onFullNameChange = makeTargetValueSetter(setFullName);
  const onPasswordChange = makeTargetValueSetter(setPassword);
  const onPasswordConfirmChange = makeTargetValueSetter(setPasswordConfirm);
  const onFormSubmit = (evt) => {
    evt.preventDefault();
    submitForm({ evt, email, fullName, password, passwordConfirm, token, setViewState });
  };

  return {
    onFullNameChange,
    onPasswordChange,
    onPasswordConfirmChange,
    onFormSubmit
  };
};

const useLoginActivation = () => {
  const [viewState, setViewState] = useState(loginActivationViewStates.LOADING);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  // const [userInfo, setUserInfo] = useState({});

  const hydrate = async () => {
    const { success, email, fullName, token } = await fetchData();

    if (!success) {
      setViewState(loginActivationViewStates.ERR_BAD_TOKEN);
      return;
    }

    if (!email) {
      setViewState(loginActivationViewStates.ERR_BAD_TOKEN);
      return;
    }

    setEmail(email);
    setFullName(fullName);
    setToken(token);
    setViewState(loginActivationViewStates.DEFAULT);
  };

  useEffect(() => {
    setViewState(loginActivationViewStates.LOADING);

    hydrate().then(
      () => {},
      () => {}
    );
  }, []);

  return {
    viewState,
    email,
    token,
    fullName,
    password,
    // userInfo,
    setFullName,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    setViewState
    //setUserInfo
  };
};

export { useEvents, useLoginActivation };

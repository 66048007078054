/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from '../../../../constants';

const updateUrl = (inUrl, key) => {
  return inUrl.replace(/%cron%/, key);
};

const sendDeleteCronRequest = async ({ name }) => {
  try {
    const cronName = name.trim();
    if (!cronName) {
      return {
        success: false
      };
    }

    const result = await axios.delete(updateUrl(urls.URL_WORKSPACE_TRIGGER_CRON, cronName), {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' },
      params: {},
      data: {}
    });

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const sendUpdateCronRequest = async ({ cron }) => {
  try {
    const result = await axios.put(
      updateUrl(urls.URL_WORKSPACE_TRIGGER_CRON, cron.name),
      {
        name: cron.name,
        description: cron.description,
        connections: cron.connections,
        commands: cron.commands,
        workflows:cron.workflows,
        kind: cron.kind,
        cronTriggerInfo: cron.cronTriggerInfo
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    const { email, fullName } = data;

    return {
      success: true,
      payload: {
        email,
        fullName
      }
    };
  } catch (ex) {
    // Handle.
    console.log(ex);

    return {
      success: false
    };
  }
};

export { sendUpdateCronRequest, sendDeleteCronRequest };

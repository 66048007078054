import { BiSearch } from 'react-icons/bi';

export default function LogSearch({ onSearchChange }) {
  const handleSearchChange = (e) => {
    onSearchChange(e.target.value);
  };
  return (
    <div class="mx-auto mb-4 flex max-w-lg items-center">
      <label htmlFor="search" class="sr-only">
        Search
      </label>
      <div class="relative w-full">
        <input
          type="text"
          id="search"
          onChange={handleSearchChange}
          class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-inner outline-none"
          placeholder="Search Audit Logs..."
          required
        />
        <button type="button" class="absolute inset-y-0 end-0 flex items-center pe-3">
          <BiSearch class="h-4 w-4 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
        </button>
      </div>
    </div>
  );
}

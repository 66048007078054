/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import styled from 'styled-components';

const section = styled.section`
  flex: 1;
  background: #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 710px;
`;

const form = styled.form`
  max-width: 586px;
  width: 586px;
  background: #ffffff;
  margin: 50px;
  margin-left: 0;
  margin-right: 0;
  padding: 14px;
  padding-left: 28px;
  padding-right: 28px;
  border-top: 6px #7bb026 solid;
  border-radius: 4px;

  p {
    padding-bottom: 14px;
    padding-top: 7px;
  }

  button.is-primary {
    transition: all 0.243s ease-out;

    &:hover {
      background: #f28135;
    }
  }
`;

export default {
  section,
  form
};

import {
  sendFetchWorkflowsRequest,
  sendFetchWorkflowDetailRequest
} from 'network/sendFetchWorkflowsRequest';

import { sendDeleteWorkflowRequest } from './network';

const fetchData = async () => {
  try {
    const { success, payload } = await sendFetchWorkflowsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          workflows: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { workflows: [] } };
    }

    const { workflows } = payload;

    return {
      success: true,
      payload: { workflows }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { workflows: [] } };
  }
};

const fetchWorkflowDataDetail = async (workflowName) => {
  try {
    const { success, payload } = await sendFetchWorkflowDetailRequest(workflowName);

    if (!success) {
      return {
        success: false,
        payload: {
          workflow: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { workflow: [] } };
    }

    const { workflow } = payload;

    return {
      success: true,
      payload: { workflow }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { workflow: [] } };
  }
};

const deleteWorkflow = async (workflowName) => {
  try {
    await sendDeleteWorkflowRequest(workflowName);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

export { fetchData, fetchWorkflowDataDetail, deleteWorkflow };

import axios from 'axios';
import { urls } from 'constants/index';

const getEventLogs = async ({
  startDate,
  endDate,
  page,
  limit,
  orderBy,
  direction,
  actionType,
  entityType,
  containsText
}) => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_EVENTLOGGS}`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        page: page,
        limit: limit,
        orderBy: orderBy,
        direction: direction,
        actionType: actionType,
        entityType: entityType,
        containsText: containsText
      },
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { logs: [], dataCount: 1, currentPage: 1 }
      };
    }

    const { logs, dataCount, currentPage } = data;
    if (!logs) {
      return {
        success: false,
        payload: { logs: [], dataCount: 1, currentPage: 1 }
      };
    }

    return {
      success: true,
      payload: {
        logs,
        dataCount,
        currentPage
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { logs: [], dataCount: 1, currentPage: 1 } };
  }
};

const getEventLogsDailyData = async ({
  startDate,
  endDate,
  orderBy,
  direction,
  period,
}) => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_EVENTLOGS_DAILYDATA}`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        period: period,
        orderBy: orderBy,
        direction: direction,
      },
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { eventLogsDailyData: [] }
      };
    }

    const { eventLogsDailyData } = data;
    if (!eventLogsDailyData) {
      return {
        success: false,
        payload: { eventLogsDailyData: [] }
      };
    }

    return {
      success: true,
      payload: { eventLogsDailyData }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { eventLogsDailyData: [] } };
  }
};

export { getEventLogs, getEventLogsDailyData };

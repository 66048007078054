/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from 'constants/index';

const sendFetchPolicyDetailRequest = async (policyName) => {
  try {
    const result = await axios.get(
      `${urls.URL_WORKSPACE_POLICY}${policyName ? '/' + policyName : ''}`,
      {
        withCredentials: true,
        data: {},
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { policies: [] }
      };
    }

    const { policies } = data;

    if (!policies) {
      return {
        success: false,
        payload: { policies: [] }
      };
    }

    return {
      success: true,
      payload: {
        policies
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { policies: [] } };
  }
};

const sendFetchPolicyRequest = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_POLICY, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { policies: [] }
      };
    }

    const { policies } = data;

    if (!policies) {
      return {
        success: false,
        payload: { policies: [] }
      };
    }

    return {
      success: true,
      payload: {
        policies
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { policies: [] } };
  }
};

const sendDeletePolicyRequest = async (policyName) => {
  try {
    const result = await axios.delete(`${urls.URL_WORKSPACE_POLICY}/${policyName}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    void result;

    return { success: true };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

const sendDowngradePlan = async (data) => {
  try {
    const result = await axios.post(
      `${urls.URL_WORKSPACE_STRIPE_DOWNGRADE}`,
      {
        ...data
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    return { success: true };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export {
  sendDeletePolicyRequest,
  sendFetchPolicyDetailRequest,
  sendFetchPolicyRequest,
  sendDowngradePlan
};

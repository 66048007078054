import { sendAddOrUpdateWorkflowRequest } from './network';
import { sendFetchWorkflowsRequest } from 'network/sendFetchWorkflowsRequest';

const addOrUpdateWorkflow = async (formModel, workflowName) => {
  console.log(formModel);
  try {
    const { success } = await sendAddOrUpdateWorkflowRequest(formModel, workflowName);

    return { success };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const fetchWorkflowsData = async () => {
  try {
    const { success, payload } = await sendFetchWorkflowsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          workflows: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { workflows: [] } };
    }

    const { workflows } = payload;

    return {
      success: true,
      payload: { workflows }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { workflows: [] } };
  }
};



export { addOrUpdateWorkflow, fetchWorkflowsData };

import React, { useEffect, useState } from 'react';
import { fetchData, saveSlackState } from './io';
import { projectListViewStates } from 'constants/index';
import Loading from 'components/loading/Loading';
import CreateNewProjectInfo from '../CreateNewProjectInfo';
import { useDispatch } from 'react-redux';
import { setSlackTriggers } from 'redux/slices/triggerSlice';
import { useSelector } from 'react-redux';
import ListTabs from 'components/listTabs/ListTabs';
import Helper from 'components/help/Helper';
import { useNavigate } from 'react-router-dom';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './ListView';
import GridView from './GridView';
import { projectNavigationInfos } from 'assets/allData';
import { makeRandomState } from 'crypto/index';
import { slackCallbackUrl as callbackUrl } from '../../../constants/';

const hydrate = async () => {
  const { success, payload } = await fetchData();
  return { success, payload };
};

const ProjectList = () => {
  const [viewState, setViewState] = useState(projectListViewStates.LOADING);
  const dispatch = useDispatch();
  const triggerState = useSelector((state) => state.triggerState);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [layoutView, setLayoutView] = useState('list');

  const navigate = useNavigate();

  useEffect(() => {
    hydrate().then(
      ({ success, payload: { projects } }) => {
        if (!success) {
          setViewState(projectListViewStates.ERROR);
          return;
        }

        dispatch(setSlackTriggers(projects));

        setViewState(projectListViewStates.DONE);
      },
      () => {
        setViewState(projectListViewStates.ERROR);
      }
    );
  }, [dispatch]);

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    setTableData(triggerState.slackTriggers);
  }, [triggerState.slackTriggers]);

  useEffect(() => {
    let filteredDatas;
    filteredDatas = triggerState.slackTriggers.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, triggerState.slackTriggers]);

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  const onConnectToSlackClick = (projectId) => {
    const newSlackState = makeRandomState();
    saveSlackState({ project: projectId, state: newSlackState }).then(() => {
      window.location.href = `${callbackUrl}&state=${newSlackState}&redirect_uri=${window.ob.config.slackRedirectUrl}`;
    });
  };

  if (viewState === projectListViewStates.LOADING) {
    return <Loading />;
  }

  if (viewState === projectListViewStates.ERROR) {
    return <CreateNewProjectInfo />;
  }

  const projectCount = [...triggerState?.slackTriggers].length;

  if (projectCount === 0) {
    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <CreateNewProjectInfo />
      </div>
    );
  }

  // return (
  //   <React.Fragment>
  //   <Helper
  //     message={
  //      "Projects serve as a comprehensive framework within OpsBeacon, seamlessly integrating commands, connections, and workflows. Each project is associated with a Slack channel, providing a centralized hub for managing your operations. This integration enables you to execute actions directly from the connected Slack channels."
  //     }
  //     infoIcon={true}
  //   />
  //   <ListTabs
  //     handleSearch={handleSearch}
  //     handleAddClick={() => navigate(`${projectNavigationInfos.createNewPath}`)}
  //   />
  //   <React.Fragment>
  //     <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
  //     {layoutView === 'list' ? (
  //       <ListView
  //         list={tableData}
  //         rowClick={(item) => navigate(`${projectNavigationInfos.edit}/${item.id}`)}
  //         onConnectToSlackClick={(projectId)=> onConnectToSlackClick(projectId)}
  //       />
  //     ) : (
  //       <GridView
  //         list={tableData}
  //         rowClick={(item) => navigate(`${projectNavigationInfos.edit}/${item.id}`)}
  //         onConnectToSlackClick={(projectId)=> onConnectToSlackClick(projectId)}
  //       />
  //     )}

  //   </React.Fragment>
  // );

  return (
    <React.Fragment>
      <Helper
        message={
          'Projects serve as a comprehensive framework within OpsBeacon, seamlessly integrating commands, connections, and workflows. Each project is associated with a Slack channel, providing a centralized hub for managing your operations. This integration enables you to execute actions directly from the connected Slack channels.'
        }
        infoIcon={true}
      />
      <ListTabs
        handleSearch={handleSearch}
        handleAddClick={() => navigate(`${projectNavigationInfos.createNewPath}`)}
      />
      <React.Fragment>
        <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
        {layoutView === 'list' ? (
          <ListView
            list={tableData}
            rowClick={(item) => navigate(`${projectNavigationInfos.edit}/${item.id}`)}
            onConnectToSlackClick={(projectId) => onConnectToSlackClick(projectId)}
          />
        ) : (
          <GridView
            list={tableData}
            rowClick={(item) => navigate(`${projectNavigationInfos.edit}/${item.id}`)}
            onConnectToSlackClick={(projectId) => onConnectToSlackClick(projectId)}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default ProjectList;

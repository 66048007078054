import React, { useMemo } from 'react';
import { Label, Pie, PieChart } from 'recharts';

import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from 'components/ui/chart';

export function PieCharts({ chartData, title }) {
  const chartConfig = {
    count: {
      label: 'count'
    },
    chrome: {
      label: 'Chrome',
      color: 'hsl(var(--chart-1))'
    },
    safari: {
      label: 'Safari',
      color: 'hsl(var(--chart-2))'
    },
    firefox: {
      label: 'Firefox',
      color: 'hsl(var(--chart-3))'
    },
    edge: {
      label: 'Edge',
      color: 'hsl(var(--chart-4))'
    },
    other: {
      label: 'Other',
      color: 'hsl(var(--chart-5))'
    }
  };

  const allCountsAreZero = useMemo(() => {
    return chartData.every(item => item.count === 0);
  }, [chartData]);

  const totalCount = useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.count, 0);
  }, [chartData]);

  const displayChartData = useMemo(() => {
    if (allCountsAreZero) {
      return [{ browser: 'No Data', count: 1, fill: '#ccc' }];
    }
    return chartData.map((item) => ({
      ...item,
    }));
  }, [chartData, allCountsAreZero]);

  return (
    <Card className="flex w-full flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[250px]">
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
            <Pie
              data={displayChartData}
              dataKey="count"
              nameKey="browser"
              innerRadius={50}
              strokeWidth={5}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {totalCount.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Total
                        </tspan>
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
        <div className="mt-4 flex flex-wrap gap-x-2.5 gap-y-1 justify-center max-w-full overflow-hidden pb-4">
          {displayChartData.map((item, index) => (
            <div
              key={index}
              className="flex items-center gap-2 [&>svg]:h-3 [&>svg]:w-3 [&>svg]:text-slate-500 dark:[&>svg]:text-slate-400"
            >
              <div className="h-2 w-2 shrink-0 rounded-[2px]" style={{ background: item.fill }} />
              <span className='text-xs' style={{ color: item.fill }}>{item.browser}</span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

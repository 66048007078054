import React, { useEffect, useState } from 'react';
import LoginFooter from '../login/LoginFooter';
import LoginHeader from 'features/login/LoginHeader';
import { globalViewStates } from 'constants';
import { useToast } from 'hooks/use-toast';
import logo from '../../assets/img/opsbeacon-light-logo.png';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  UserGoogleRegisterRequest,
  UserRegisterRequest,
  UserSlackRegisterRequest
} from './network';
import RequiredField from 'components/toolbox/RequiredField';
import NotConfirmedPassword from 'components/toolbox/NotConfirmedPassword';
import IconGoogle from 'components/icons/iconGoogle';
import { useGoogleLogin } from '@react-oauth/google';
import IconIntegrationSlack from 'components/icons/IconIntegrationSlack';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { validatePassword, passwordRequirementsMet } from 'utils/passwordUtils';
import IconMicrosoft from 'components/icons/iconMicrosoft';
import { authWithMicrosoft } from 'features/login/LoginActions/network';

const clientId = window.ob.config.slackClientId;
const redirectUri = `${window.location.origin}/workspace-register-slack-auth`;
const clientSecret = '328e5355fc18787f24c6666bbf9d0d22';

const RegisterUserRole = () => {
  const {toast} = useToast();
  const [viewState, setViewState] = useState(globalViewStates.DEFAULT);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [lookupKey, setLookupKey] = useState('');
  const [trial, setTrial] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [optIn, setOptIn] = useState(true);
  const [slackTokenWorkspace, setSlackTokenWorkspace] = useState('');
  const [formErrorModel, setFormErrorModel] = useState({
    email: false,
    token: false,
    lookupKey: false,
    trial: false,
    fullName: false,
    password: false,
    passwordConfirm: false
  });
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const onSlackSubmit = () => {
    const authorizeUrl = `https://slack.com/openid/connect/authorize?response_type=code&scope=openid%20profile%20email&client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = authorizeUrl;
  };

  useEffect(() => {
    const getEmailUrl = decodeURIComponent(params.email);
    const getTokenUrl = decodeURIComponent(params.token);
    const getLookupKey = decodeURIComponent(params.lookUpKey);
    const getTrial = decodeURIComponent(params.trial);

    setEmail(getEmailUrl);
    setToken(getTokenUrl);
    setLookupKey(getLookupKey);
    setTrial(getTrial);
  }, [params.email, params.token]);

  const onFormSubmit = async (evt) => {
    evt.preventDefault();

    const sanitizedEmail = email.trim();
    const sanitizedFullName = fullName.trim();

    const obj = {
      fullName: fullName.trim() === '',
      email: email.trim() === '',
      token: params.token.trim() === '',
      lookupKey: lookupKey.trim() === '',
      trial: trial.trim() === '',
      password: password.trim() === '',
      confirmPassword: confirmPassword.trim() === ''
    };

    setFormErrorModel(obj);

    if (!fullName || !email || !token || !password || !confirmPassword) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill all required areas.'
      });
      return;
    }

    if (!sanitizedEmail) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please provide a valid email.'
      });
      return;
    }

    if (lookupKey === '' || trial === '') {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please provide plan.'
      });
      return;
    }

    if (password !== confirmPassword) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Password did not match! Please try again.'
      });
      return;
    }
    try {
      const result = await UserRegisterRequest({
        email: sanitizedEmail,
        fullName: sanitizedFullName,
        password,
        optIn,
        token
      });

      void result;

      if (result.success === true) {
        setTimeout(function () {
          toast({
            title: 'Successfully',
            description: 'You are being redirected to the dashboard.'
          });
        }, 1000);

        setTimeout(function () {
          navigate('/', { replace: true });
        }, 2000);
        setViewState(globalViewStates.SUCCESS);
      } else {
        setViewState(globalViewStates.ERROR);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'Please make sure you enter the information correctly.'
        });
      }
    } catch (err) {
      console.log(err);
      setViewState(globalViewStates.ERROR);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was an error. Please try again later!'
      });
    }
  };

  const onGoogleRegisterSubmit = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      let accessToken = tokenResponse.access_token;
      const getTokenUrl = decodeURIComponent(params.token);
      try {
        const result = UserGoogleRegisterRequest({
          accessToken: accessToken,
          optIn,
          token: getTokenUrl
        });

        void result;
        setTimeout(function () {
          toast({
            title: 'Successfully',
            description: 'You are being redirected to the log in page.'
          });
        }, 1000);

        setTimeout(function () {
          navigate('/', { replace: true });
        }, 2000);

        setViewState(globalViewStates.SUCCESS);
      } catch (err) {
        console.log(err);
        setViewState(globalViewStates.ERROR);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was an error. Please try again later!'
        });
      }
    }
  });

  useEffect(() => {
    const getAccessToken = async () => {
      const code = new URLSearchParams(window.location.search).get('code');
      const redirectUri = `${window.location.origin}/workspace-register-slack-auth`;

      const response = await fetch('https://slack.com/api/oauth.v2.access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          client_id: clientId,
          client_secret: clientSecret,
          code,
          redirect_uri: redirectUri
        })
      });

      const data = await response.json();
      setSlackTokenWorkspace(data.authed_user.access_token);
    };
    getAccessToken();
  }, []);

  useEffect(() => {
    if (slackTokenWorkspace) {
      let accessToken = slackTokenWorkspace;
      try {
        const result = UserSlackRegisterRequest({
          accessToken: accessToken,
          optIn,
          token
        });

        void result;
        setTimeout(function () {
          toast({
            title: 'Successfully',
            description: 'You are being redirected to the log in page.'
          });
        }, 1000);

        setTimeout(function () {
          navigate('/dashboard', { replace: true });
        }, 2000);

        setViewState(globalViewStates.SUCCESS);
      } catch (err) {
        console.log(err);
        setViewState(globalViewStates.ERROR);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was an error. Please try again later!'
        });
      }
    }
  }, [slackTokenWorkspace]);

  const onPasswordChange = (onChange) => {
    const newPassword = onChange.target.value.trim();
    setPassword(newPassword);
    const isValid = validatePassword(newPassword);
    setIsPasswordValid(isValid);
  };

  const registerWithMicrosoft = async () => {
    try {
      const { success, location } = await authWithMicrosoft({
        trial: true,
        optIn: false,
        lookupKey: 'opsbeacon-starter'
      });
      if (success && location) {
        window.location.href = location;
      } else if (success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'Redirect failed'
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
        });
        setViewState(globalViewStates.SUCCESS);
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
      });
      setViewState(globalViewStates.ERROR);
    }
  };

  if (viewState === globalViewStates.REDIRECT_TO_DASHBOARD) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 pb-48 sm:px-6 lg:px-8">
      <Link to="/" className="flex w-full items-center justify-center">
        <img src={logo} width={210} alt="Opsbeacon Logo" />
      </Link>

      <div className="m-5 mt-8 bg-white px-4 py-8 shadow sm:mx-auto sm:w-full sm:max-w-md sm:rounded-lg sm:px-10">
        <LoginHeader title={'Register'} description={'We will get you in in no-time.'} />

        <div className="grid grid-cols-1 gap-y-3">
          <div>
            <label htmlFor="fullName" className="block text-sm font-medium leading-5 text-gray-700">
              Name *
            </label>

            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="fullName"
                type="text"
                placeholder=""
                value={fullName}
                onChange={(onChange) => setFullName(onChange.target.value)}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
            {formErrorModel.fullName && <RequiredField />}
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email *
            </label>

            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                type="email"
                placeholder=""
                value={email}
                autoFocus
                required
                disabled
                readOnly
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>

            {formErrorModel.email && <RequiredField />}
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
              Password *
            </label>

            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="password"
                type="password"
                placeholder=""
                value={password}
                onChange={(e) => onPasswordChange(e)}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
            <div className="password-required">
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).length ? 'text-green-500' : ''
                  }`}
                />
                Minimum 12 characters
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).specialChar ? 'text-green-500' : ''
                  }`}
                />
                One special character
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).uppercase ? 'text-green-500' : ''
                  }`}
                />{' '}
                One uppercase letter
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).number ? 'text-green-500' : ''
                  }`}
                />{' '}
                One number
              </div>
            </div>
            {formErrorModel.password && <RequiredField />}
          </div>

          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Password Confirm *
            </label>

            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="confirmPassword"
                type="password"
                placeholder=""
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>

            {formErrorModel.confirmPassword && <RequiredField />}
            {password !== confirmPassword ? <NotConfirmedPassword /> : ''}
          </div>

          <div>
            <input
              id="opt-in"
              className="w-8"
              type="checkbox"
              checked={optIn}
              onChange={() => setOptIn(!optIn)}
            />{' '}
            <label htmlFor="opt-in" className="cursor-pointer">
              Register to our newsletter and be the first to know about the cool stuff we create to
              help you <strong>sleep more</strong>.
            </label>
          </div>
          <div className="mb-4">
            <label
              htmlFor="acceptPolicy"
              className="mt-2 cursor-pointer text-center text-sm text-gray-400"
            >
              By continuing, you agree to the{' '}
              <Link
                to="https://www.ob2.ai/terms-of-service"
                target="_blank"
                className="font-black text-[#374151] underline"
              >
                Terms of Service
              </Link>{' '}
              and
              <Link
                to="https://www.ob2.ai/privacy"
                target="_blank"
                className="font-black text-[#374151] underline"
              >
                {' '}
                Privacy Policy
              </Link>{' '}
            </label>
          </div>
          <div>
            <span className="block w-full rounded-md shadow-sm">
              <button className="btn-login" onClick={onFormSubmit}>
                Register
              </button>
              <span className="flex justify-center py-4 text-sm text-gray-400">Or</span>
              <button
                type="submit"
                className="-mt-2 flex w-full justify-center rounded-md border bg-white px-4 py-2 text-gray-400 hover:text-black"
                onClick={onGoogleRegisterSubmit}
              >
                <span className="flex items-center gap-2">
                  <IconGoogle /> Continue with Google{' '}
                </span>
              </button>
              <button
                type="submit"
                className="mt-4 flex w-full justify-center rounded-md border bg-white px-4 py-2 text-gray-400 hover:text-black"
                onClick={onSlackSubmit}
              >
                <span className="flex items-center gap-2">
                  <IconIntegrationSlack customHeight={32} customWidth={32} /> Continue with Slack{' '}
                </span>
              </button>
              <button
                type="submit"
                className="mt-4 flex w-full justify-center rounded-md border bg-white px-4 py-2 text-gray-400 hover:text-black"
                onClick={registerWithMicrosoft}
              >
                <span className="flex items-center gap-2">
                  <IconMicrosoft customHeight={32} customWidth={32} /> Continue with Microsoft{' '}
                </span>
              </button>
            </span>
          </div>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default RegisterUserRole;

import React, { useEffect, useState } from 'react';
import { fetchData } from './io';
import { triggerListViewStates } from 'constants/index';
import Loading from 'components/loading/Loading';
import CreateNewCronInfo from '../../CreateNewCronInfo';
import { useDispatch } from 'react-redux';
import { setCronTriggers } from 'redux/slices/triggerSlice';
import { useSelector } from 'react-redux';
import Helper from 'components/help/Helper';
import ListTabs from 'components/listTabs/ListTabs';
import { cronNativagationInfos } from 'assets/allData';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './ListView';
import GridView from './GridView';
import { useNavigate } from 'react-router-dom';

const hydrate = async () => {
  const { success, payload } = await fetchData();

  return { success, payload };
};

const TriggerCronList = () => {
  const [viewState, setViewState] = useState(triggerListViewStates.LOADING);
  const dispatch = useDispatch();
  const triggerState = useSelector((state) => state.triggerState);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [layoutView, setLayoutView] = useState('list');

  const navigate = useNavigate();

  useEffect(() => {
    hydrate()
      .then(({ success, payload: { triggers } }) => {
        if (!success) {
          setViewState(triggerListViewStates.ERROR);
          return;
        }
        dispatch(setCronTriggers(triggers));

        setViewState(triggerListViewStates.DONE);
      })
      .catch(() => {
        setViewState(triggerListViewStates.ERROR);
      });
  }, [dispatch]);

  useEffect(() => {
    setTableData(triggerState.cronTriggers);
  }, [triggerState.cronTriggers]);

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    setTableData(triggerState.cronTriggers);
  }, [triggerState.cronTriggers]);

  useEffect(() => {
    let filteredDatas;
    filteredDatas = triggerState.cronTriggers.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, triggerState.cronTriggers]);

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  if (viewState === triggerListViewStates.LOADING) {
    return <Loading />;
  }

  if (viewState === triggerListViewStates.ERROR) {
    return <CreateNewCronInfo />;
  }
  const cronCount = triggerState.cronTriggers.length;

  if (cronCount === 0) {
    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <CreateNewCronInfo/>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Helper
        message={
          'Crons provide a scheduling framework for your executions, allowing you to precisely determine when your actions are initiated. Within this schedule pattern, you have two options: On-time schedule and Recurring schedule.'
        }
        infoIcon={true}
      />
      <ListTabs
        handleSearch={handleSearch}
        handleAddClick={() => navigate(`${cronNativagationInfos.createNewPath}`)}
      />
      <React.Fragment>
        <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
        {layoutView === 'list' ? (
          <ListView
            list={tableData}
            rowClick={(item) => navigate(`${cronNativagationInfos.edit}/${item.name}`)}
          />
        ) : (
          <GridView
            list={tableData}
            rowClick={(item) => navigate(`${cronNativagationInfos.edit}/${item.name}`)}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default TriggerCronList;

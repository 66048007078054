import { getEventLogsDailyData } from 'features/eventLogs/network';
import { useEffect, useState } from 'react';

export const useEventLogDailyData = ({
  initialStartDate,
  initialEndDate,
  initialOrderBy,
  initialDirection,
  initialPeriod
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [direction, setDirection] = useState(initialDirection);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [period, setPeriod] = useState(initialPeriod);

  const [dailyData, setDailyData] = useState(null);
  const [dailyLoading, setDailyLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
        setDailyLoading(true);
      try {
        const { success, payload } = await getEventLogsDailyData({
          startDate: startDate,
          endDate: endDate,
          orderBy: orderBy,
          direction,
          period
        });
        if (success) {
            setDailyData(payload);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }finally {
        setDailyLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, orderBy, direction, period]);

  return {
    dailyData,
    dailyLoading,
    setStartDate,
    setEndDate,
    setPeriod,
    setOrderBy,
    setDirection
  };
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import {
  sendDeleteUserRequest,
  sendFetchUsersRequest,
  sendAddNewUserRoleRequest,
  updateUserRoleRequest,
  sendFetchPoliciesRequest,
  sendFetchGroupsRequest,
  sendFetchUserDetailRequest,
  sendAddOrUpdateUserRequest
} from './network';

const fetchUserData = async () => {
  try {
    const { success, payload } = await sendFetchUsersRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          users: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { users: [] } };
    }

    const { users } = payload;

    return {
      success: true,
      payload: { users }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { users: [] } };
  }
};

const fetchUserDataDetail = async (userMail) => {
  try {
    const { success, payload } = await sendFetchUserDetailRequest(userMail);

    if (!success) {
      return {
        success: false,
        payload: {
          user: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { user: [] } };
    }
    const { user } = payload;
    return {
      success: true,
      payload: { user }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { user: [] } };
  }
};

const deleteUserRole = async (userMail) => {
  try {
    await sendDeleteUserRequest(userMail);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

const addNewUserRole = async (formModel) => {
  try {
    const { success } = await sendAddNewUserRoleRequest(formModel);

    return { success };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

const updateUserRole = async (userRole, userMail) => {
  try {
    const result = await updateUserRoleRequest(userRole, userMail);

    void result;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const fetchPolicies = async () => {
  try {
    const { success, payload } = await sendFetchPoliciesRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          policies: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { policies: [] } };
    }

    const { policies } = payload;

    return {
      success: true,
      payload: { policies }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { policies: [] } };
  }
};

const fetchGroups = async () => {
  try {
    const { success, payload } = await sendFetchGroupsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          groups: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { groups: [] } };
    }

    const { groups } = payload;

    return {
      success: true,
      payload: { groups }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { groups: [] } };
  }
};

const addOrUpdateUser = async (formModel, userMail) => {
  try {
    const { success } = await sendAddOrUpdateUserRequest(formModel, userMail);

    return { success };
  } catch (e) {
    console.log('addOrUpdateUser:error', e);

    return { success: false };
  }
};

export {
  fetchUserData,
  deleteUserRole,
  addNewUserRole,
  updateUserRole,
  fetchPolicies,
  fetchGroups,
  fetchUserDataDetail,
  addOrUpdateUser
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconCheckCircle = () => (
  <svg
    className="ml-1 inline"
    style={{ position: 'relative', top: '-1px' }}
    width="18"
    height="18"
    viewBox="0 0 24 24"
  >
    <title>check circle</title>
    <g fill="#0e9f6e">
      <path d="M12 0a12 12 0 1 0 12 12A12.014 12.014 0 0 0 12 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 0 1-1.43.188l-4.888-3.908a1 1 0 1 1 1.25-1.562l4.076 3.261 6.227-8.451a1 1 0 1 1 1.61 1.183z" />
    </g>
  </svg>
);

export default IconCheckCircle;

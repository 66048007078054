/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from 'constants/index';

const sendDeleteKeyRequest = async (keyName) => {
  try {
    const result = await axios.delete(`${urls.URL_WORKSPACE_SECRETS}/${keyName}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    void result;

    return { success: true };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { sendDeleteKeyRequest };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';

const sendFetchConnectionsRequest = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_CONNECTIONS, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { connections: [] }
      };
    }

    const { connections } = data;

    if (!connections) {
      return {
        success: false,
        payload: { connections: [] }
      };
    }

    return {
      success: true,
      payload: {
        connections
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { connections: [] } };
  }
};

export { sendFetchConnectionsRequest };

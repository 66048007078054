/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { loginActivationViewStates } from 'constants/index';

import { sendRegisterUserRequest } from './io';

const processFormSubmission = async ({
  email,
  password,
  fullName,
  token,
  setViewState
  //setUserInfo
}) => {
  const { success } = await sendRegisterUserRequest({ email, password, fullName, token });

  if (!success) {
    setViewState(loginActivationViewStates.ERR_REGISTRATION_FAILED);
    return;
  }

  // setUserInfo({ fullName, role });
  setViewState(loginActivationViewStates.REDIRECT_USER_DASHBOARD);
};

const submitForm = ({
  evt,
  email,
  fullName,
  password,
  passwordConfirm,
  token,
  setViewState
  // setUserInfo
}) => {
  evt.preventDefault();

  if (password.length < 8) {
    alert('Your password needs to be at least eight characters.');

    return;
  }

  if (password !== passwordConfirm) {
    alert('Your password and password confirmation do not match.');

    return;
  }

  processFormSubmission({ email, password, fullName, token, setViewState }).then(
    () => {},
    () => {}
  );
};

const makeTargetValueSetter = (setter) => (evt) => setter(evt.target.value);

export { submitForm, makeTargetValueSetter };

import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { globalViewStates } from 'constants/index';
import { secretExists, addNewSecret } from './io';
import BasicInformation from 'components/information/BasicInformation';
import { fetchData } from '../KeyList/io';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setNextStep, setOnboardActiveStatus } from 'redux/slices/onboardSlice';
import { useToast } from 'hooks/use-toast';

const KeyNewRestTokenAuth = () => {
  const initialFormModel = {
    name: '',
    kind: 'rest-token-auth',
    description: '',
    credentialInfo: {
      username: '',
      password: ''
    }
  };

  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    userName: false,
    password: false
  });

  const params = useParams(); // get param
  const secretName = params.secretName ? params.secretName : null;
  const [secretList, setSecretList] = useState([]);
  const { toast } = useToast();
  const onboardState = useSelector((state) => state.onboardState);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (onboardState.tourActive && !onboardState.run && onboardState.stepIndex === 3) {
        dispatch(setNextStep(onboardState.stepIndex));
        dispatch(setOnboardActiveStatus(true));
      }
    }, 300);
  }, [onboardState.run]);

  useEffect(() => {
    if (onboardState.tourActive && onboardState.run) {
      setFormModel({
        ...formModel,
        name: onboardState.restTokenAuthGuideObject.name,
        credentialInfo: {
          username: onboardState.restTokenAuthGuideObject.username,
          password: onboardState.restTokenAuthGuideObject.password
        }
      });
    }
  }, [onboardState.run]);

  const onFormSubmitSecret = (e) => {
    e.preventDefault();

    const obj = {
      name: formModel.name.trim() === '',
      uniqueName: secretList.find(
        (secret) => secret.name.toLowerCase() === formModel.name.toLowerCase()
      )
        ? true
        : false,
      userName: formModel.credentialInfo.username.trim() === '',
      password: formModel.credentialInfo.password.trim() === ''
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onFormSave();
    }
  };

  const onFormSave = async (evt) => {
    

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(formModel.name);

      if (!success) {
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addNewSecret(formModel);

      if (!successAdd) {
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'New rest token auth secret added.'
        });
      }

      // Check if credential exists by getting credential by name.
      // add credential, go back to listing.
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
    }
  };

  // clear form
  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const onChangeUserInfo = (value, field) => {
    setFormModel((prevState) => {
      if (field === 'username') {
        prevState.credentialInfo.username = value;
      } else if (field === 'password') {
        prevState.credentialInfo.password = value;
      }

      return {
        ...prevState
      };
    });
  };

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { secrets } = payload;
        setSecretList(secrets);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!secretName) {
        if (
          secretList.find((secret) => secret.name.toLowerCase() === formModel.name.toLowerCase())
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  }, [formModel.name, secretList]);

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/secrets" />;
  }

  return (
    <div className="form-container">
      <div className="hero-section">
        <div
          data-id="rest-token-auth-form"
          className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 "
        >
          <h2 className="mb-4 mt-0 text-center text-base font-bold">REST TOKEN AUTH</h2>
          <BasicInformation
            content={`This is a two step authentication flow mechanism. We will first 
      make an HTTP Basic Auth request with these username/password credentials 
      and then use the token from the response as the HTTP Bearer token for 
      subsequent requests. The Authentication URL will be provided as part of connection settings.`}
          />

          <div className="flex flex-col gap-6">
            <div>
              <label htmlFor="name" className="label-main">
                Name
              </label>

              <input
                id="name"
                name={formModel.name}
                placeholder=""
                required
                className={`input-main ${
                  formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                }`}
                value={formModel.name}
                onChange={(e) => {
                  onChangeInput(e.target.value, 'name');
                }}
              />

              <span className="label-second">A friendly name for your credentials</span>

              {formErrorModel.name && (
                <span className="my-1 block text-sm !text-red-500">This area is required!</span>
              )}

              {formErrorModel.uniqueName && (
                <span className="label-second my-1 block !text-red-500">
                  {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="username" className="label-main !flex">
                <span>Username *</span>
                <span className="group flex items-center">
                  <BsFillInfoCircleFill className="mx-2 cursor-pointer text-blue-500" />
                  <span className="hidden transition-all group-hover:block">
                    This is your API Username
                  </span>
                </span>
              </label>

              <input
                id="username"
                name={formModel.username}
                type="text"
                maxLength="250"
                placeholder=""
                className="input-main"
                value={formModel.credentialInfo.username}
                onChange={(e) => {
                  onChangeUserInfo(e.target.value, 'username');
                }}
              />

              {formErrorModel.userName && (
                <span className="my-1 block text-sm !text-red-500">This area is required!</span>
              )}
            </div>

            <div>
              <label htmlFor="name" className="label-main !flex">
                <span>Password *</span>
                <span className="group flex items-center">
                  <BsFillInfoCircleFill className="mx-2 cursor-pointer text-blue-500" />
                  <span className="hidden transition-all group-hover:block ">
                    This is your API Password
                  </span>
                </span>
              </label>

              <input
                id="password"
                name={formModel.password}
                type="password"
                placeholder=""
                className="input-main"
                value={formModel.credentialInfo.password}
                onChange={(e) => {
                  onChangeUserInfo(e.target.value, 'password');
                }}
              />

              {formErrorModel.password && (
                <span className="my-1 block text-sm !text-red-500">This area is required!</span>
              )}
            </div>

            <div className="flex justify-center">
              <button type="button" className="btn-danger" onClick={clearForm}>
                Clear
              </button>

              <button
                data-id="rest-token-auth-form-save"
                type="button"
                className="btn-success"
                onClick={onFormSubmitSecret}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyNewRestTokenAuth;

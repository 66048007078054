import React from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';

const BasicInformation = ({ content }) => {
  return (
    <div className="mb-4 rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm leading-5 text-blue-700">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;

import axios from 'axios';
import { urls } from 'constants/index';

export const getDeleteCode = async () => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_DELETE_GET_CODE,
      {},
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const deleteAccount = async ({ code }) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_DELETE_ACCOUNT,
      { code },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const putProfile = async ({
  fullName,
  privacyAccepted = true,
  subscribedToMailingList = true
}) => {
  try {
    const result = await axios.put(
      urls.URL_IDM_PROFILE,
      { fullName, privacyAccepted, subscribedToMailingList },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const getProfile = async () => {
  try {
    const result = await axios.get(urls.URL_IDM_PROFILE, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    if (result && result.data && result.data.success) {
      return { success: result.data.success, profileInfo: result.data.profileInfo };
    }

    return { success: false, profileInfo: null };
  } catch (ex) {
    console.log(ex);

    return { success: false, profileInfo: null };
  }
};

export const deleteApiKey = async () => {
  try {
    const result = await axios.delete(urls.URL_IDM_API_KEY, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const postApiKey = async ({ name, description = '' }) => {
  try {
    const result = await axios.post(
      urls.URL_IDM_API_KEY,
      { name, description },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    if (result && result.data && result.data.success) {
      return { success: result.data.success, apiKeyInfo: result.data.apiKeyInfo };
    }

    return { success: false, apiKeyInfo: '' };
  } catch (ex) {
    console.log(ex);

    return { success: false, apiKeyInfo: '' };
  }
};

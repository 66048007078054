/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import MainTitle from 'components/title/MainTitle';
import React from 'react';

const SettingsMain = () => (
  <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
    <MainTitle title="Settings" />
    <div className="project-cards py-4" style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-base font-bold leading-5 text-yellow-800">Work in Progress</h3>
            <div className="mt-2 text-base leading-5 text-yellow-700">
              <p>Here, you’ll be able to manage your profile and billing details.</p>
              <p>We are still working on this section, we’ll let you know as soon as it’s ready.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SettingsMain;

import axios from 'axios';
import { urls } from 'constants/index';

const sendFetchWorkspaceSettings = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_SETTINGS, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return { success: false, payload: {} };
    }

    return { success: true, payload: data };
  } catch (ex) {
    console.log('sendFetchWorkspaceSettings:ex', ex);
    return { success: false, payload: {} };
  }
};

const sendUpdateWorkspaceSettingsRequest = async ({workspaceSettings}) => {
  try {
    const result = await axios.put(
      urls.URL_WORKSPACE_SETTINGS,
      {settings:{
        workspaceLoginSettings:workspaceSettings
      }
       
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );
    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }
  } catch (ex) {
    console.log(ex);

    return {
      success: false
    };
  }
};

export { sendFetchWorkspaceSettings, sendUpdateWorkspaceSettingsRequest };

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SchedulePatternContext } from 'contexts/cronEdit/SchedulePatternContext';
import cronValidate from 'cron-validate';
import cronstrue from 'cronstrue';

const indicies = [0, 1, 2, 3, 4, 5];
const cronLabels = ['Minutes', 'Hours', 'Day of Month', 'Month', 'Day of Week', 'Year'];

function simpleError(e) {
  const i = e.indexOf(' (Input cron: ');
  if (i < 0) return e;
  const clipped = e.slice(0, i);
  if (
    clipped ===
    'Cannot specify both daysOfMonth and daysOfWeek field when mustHaveBlankDayField option is enabled.'
  )
    return 'Cannot specify both daysOfMonth and daysOfWeek field.';
  if (clipped.slice(0, 9) === "Element '") return 'Element ' + clipped.slice(9);
  return clipped;
}

const timeMapping = [
  { label: 'OFF', value: null },
  { label: '15 Minutes', value: 15 },
  { label: '30 Minutes', value: 30 },
  { label: '45 Minutes', value: 45 },
  { label: '1 Hours', value: 60 },
  { label: '2 Hours', value: 120 },
  { label: '4 Hours', value: 240 }
];

export default function CronBasedSchedule() {
  const { scheduleExpression, maximumWindowInMinutes, setFormModel } =
    useContext(SchedulePatternContext);

  const [flexibleTimeWindowMode, setFlexibleTimeWindowMode] = useState(
    timeMapping.map((value) => value.label)
  );

  const [selectedMaximumWindowInMinutes, setSelectedMaximumWindowInMinutes] = useState('');

  const [cronExpression, setCronExpression] = useState(['', '', '', '', '', '']);

  useEffect(() => {
    if (scheduleExpression.startsWith('cron'))
      setCronExpression(scheduleExpression.slice(5, scheduleExpression.length - 1).split(' '));
  }, [scheduleExpression]);

  useEffect(() => {
    setSelectedMaximumWindowInMinutes(
      timeMapping.filter((option) => option.value === maximumWindowInMinutes)[0].label
    );
  }, [maximumWindowInMinutes]);

  const refs = useRef([]);

  const cronValidateMessages = useMemo(() => {
    const result = cronValidate(cronExpression.toString().replace(/,/g, ' '), {
      preset: 'aws-cloud-watch'
    });
    if (result.isValid()) return;
    return result.getError().map(simpleError);
  }, [cronExpression]);

  const cronstrueResult = useMemo(() => {
    try {
      return {
        isValid: true,
        msg: cronstrue.toString('0 ' + cronExpression.toString().replace(/,/g, ' '))
      };
    } catch (e) {
      return { isValid: false, msg: `${e}` };
    }
  }, [cronExpression]);

  useEffect(() => {
    if (cronstrueResult.isValid && !cronValidateMessages) {
      setFormModel((prevState) => {
        return {
          ...prevState,
          cronTriggerInfo: {
            ...prevState.cronTriggerInfo,
            scheduleExpression: `cron(${cronExpression.toString().replace(/,/g, ' ')})`
          }
        };
      });
    }
  }, [cronExpression]);

  return (
    <>
      <div className="mt-2 block">
        <h2 className="font-extrabold">Cron expression</h2>
        <p className="text-gray-500">Define the cron expression for the schedule.</p>
      </div>
      <div className="flex items-center gap-4 py-4">
        <h2 className="font-extrabold text-black ">Cron</h2>
        <span className="font-extrabold text-black ">(</span>
        <div className="flex items-center gap-2">
          {indicies.map((i) => (
            <div className="block" key={i}>
              <input
                ref={(el) => {
                  refs.current[i] = el;
                }}
                value={cronExpression[i]}
                onChange={(e) => {
                  setCronExpression((prevState) => {
                    let expression = [...prevState];
                    expression[i] = e.target.value;
                    return expression;
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault();
                    refs.current[e.shiftKey ? i - 1 : i + 1]?.select();
                  }
                }}
                className="input-secondary"
              />
              <div className="flex items-center justify-center">
                <label className="text-xs text-gray-500">{cronLabels[i]}</label>
              </div>
            </div>
          ))}
        </div>
        <span className="font-extrabold text-black ">)</span>
      </div>
      <div>
        <h2>
          <code className="text-xs text-secondary">Validate</code>
        </h2>
        {!cronValidateMessages && (
          <div className="my-2">
            <code className="rounded-md bg-green-100 py-0.5 px-2 text-xs text-green-700">
              valid
            </code>
          </div>
        )}
        {cronValidateMessages?.map((e, i) => (
          <div key={i} className="my-2">
            <code className="rounded-md bg-red-100 py-0.5 px-2 text-xs text-red-500">{e}</code>
          </div>
        ))}
      </div>
      <div className="my-5">
        <div>
          {cronstrueResult.isValid && (
            <>
              <h2>
                <code className="text-xs text-secondary">Cron detail</code>
              </h2>
              <code className="text-xs text-gray-500">{cronstrueResult.msg}</code>
            </>
          )}
        </div>
      </div>
      <div className="mt-2">
        <h2 className="py-1 font-extrabold">Flexible time window</h2>
        <p className="text-gray-500">
          If you choose a flexbile window, Scheduler invokes your schedule within the time window
          you specify. For example, if you choose 15 minutes, your schedule runs within 15 minutes
          after the schedule start time.
        </p>
        <div className="mt-2">
          <select
            id="maximumWindowInMinutes"
            name="maximumWindowInMinutes"
            className="input-main w-1/2"
            value={selectedMaximumWindowInMinutes}
            onChange={(e) => {
              setFormModel((prevState) => {
                return {
                  ...prevState,
                  cronTriggerInfo: {
                    ...prevState.cronTriggerInfo,
                    flexibleTimeWindow: {
                      ...prevState.cronTriggerInfo.flexibleTimeWindow,
                      maximumWindowInMinutes: timeMapping.filter(
                        (options) => options.label === e.target.value
                      )[0].value
                    }
                  }
                };
              });
              setSelectedMaximumWindowInMinutes(e.target.value);
            }}
          >
            <option value="" disabled>
              Select a flexible time
            </option>
            {flexibleTimeWindowMode.map((twindow, index) => {
              return (
                <option key={index} defaultValue={twindow}>
                  {twindow}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );
}

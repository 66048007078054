/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';
import { Link } from 'react-router-dom';

const PageHeader = ({ info }) => {
  return (
    <nav
      className="form-container mb-5 flex h-auto flex-col flex-wrap items-center 
      justify-between rounded-lg border border-gray-300 px-5 text-left text-xs font-medium
      leading-4 tracking-wider text-gray-700 dark:border-gray-900 dark:bg-slate-900 dark:bg-opacity-70 dark:text-gray-300 dark:shadow-lg
      dark:shadow-theme_background sm:flex-row bg-blue-50"
    >
      <div className="flex h-10 w-full flex-1 flex-col flex-wrap items-center justify-between text-center uppercase sm:flex-row">
        {info && info.btnName !== '' ? (
          <>
            <p className="flex h-10 w-full flex-1 flex-col flex-wrap items-center text-center uppercase sm:flex-row">
              {info.title || ''}
            </p>
            <span className="rounded-md shadow-sm">
              <Link to={info.btnPath} className="btn-main">
                {info.btnName}
              </Link>
            </span>
          </>
        ) : (
          <span className="mx-auto py-1 text-center"> {info && info.title} </span>
        )}
      </div>
    </nav>
  );
};

export default PageHeader;

import {
  Album,
  AppWindowMac,
  BookText,
  Combine,
  EthernetPort,
  FilePlus,
  LayoutGridIcon,
  PieChart,
  Settings2,
  SquareAsterisk,
  SquareChevronRight,
  Workflow
} from 'lucide-react';
import { NavMain } from 'components/nav-main';
import { NavProjects } from 'components/nav-projects';
import { NavUser } from 'components/nav-user';
import { NavHeader } from 'components/nav-header';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail
} from 'components/ui/sidebar';
import { useSelector } from 'react-redux';

export function AppSidebar({ ...props }) {
  const userState = useSelector((state) => state.userState);

  const data = {
    user: {
      name: userState.userRole.fullName,
      role: userState.userRole.role,
      avatar: '/avatars/shadcn.jpg'
    },
    projects: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: LayoutGridIcon,
        isHasAFlag: false,
        requiredUserRole: 'readonly'
      },
      {
        name: 'Triggers',
        url: '/triggers',
        icon: PieChart,
        isHasAFlag: false,
        requiredUserRole: 'readonly'
      },
      {
        name: 'Commands',
        url: '/commands',
        icon: SquareChevronRight,
        isHasAFlag: false,
        requiredUserRole: 'readonly'
      },
      {
        id: 'INTERNAL_APP',
        name: 'Apps',
        url: '/apps',
        icon: AppWindowMac,
        isHasAFlag: true,
        requiredUserRole: 'readonly'
      },
      {
        name: 'Workflows',
        url: '/workflows',
        icon: Workflow,
        isHasAFlag: false,
        requiredUserRole: 'readonly'
      },
      {
        name: 'Secrets',
        url: '/secrets',
        icon: SquareAsterisk,
        isHasAFlag: false,
        requiredUserRole: 'readonly'
      },
      {
        name: 'Connections',
        url: '/connections',
        icon: EthernetPort,
        isHasAFlag: false,
        requiredUserRole: 'readonly'
      },
      {
        id: 'EVENT_LOGS',
        name: 'Event Logs',
        url: '/event-logs',
        icon: BookText,
        isHasAFlag: true,
        requiredUserRole: 'readonly'
      },
      {
        id: 'AUDIT_LOGS',
        name: 'Audit Logs',
        url: '/audit-logs',
        icon: Album,
        isHasAFlag: true,
        requiredUserRole: 'readonly'
      },
      {
        name: 'File Storage',
        url: '/file-storage',
        icon: FilePlus,
        isHasAFlag: false,
        requiredUserRole: 'readonly'
      },
      {
        name: 'Integrations',
        url: '/integrations',
        icon: Combine,
        isHasAFlag: false,
        requiredUserRole: 'readonly'
      }
    ],
    navMain: [
      {
        title: 'Settings',
        url: '#',
        icon: Settings2,
        isHasAFlag: false,
        requiredUserRole: 'operator',
        items: [
          {
            title: 'Users',
            url: '/settings/users',
            isHasAFlag: false,
            requiredUserRole: 'operator'
          },
          {
            title: 'Groups',
            url: '/settings/group',
            isHasAFlag: false,
            requiredUserRole: 'operator'
          },
          {
            title: 'Execution Policies',
            url: '/settings/execution-policies',
            isHasAFlag: false,
            requiredUserRole: 'operator'
          },
          {
            id: 'APPROVAL_POLICY',
            title: 'Approval Policies',
            url: '/settings/approval-policies',
            isHasAFlag: true,
            requiredUserRole: 'operator'
          },
          {
            title: 'Environment Variables',
            url: '/settings/environmentVariables',
            isHasAFlag: false,
            requiredUserRole: 'operator'
          }
        ]
      }
    ]
  };

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <NavHeader />
      </SidebarHeader>
      <SidebarContent>
        <NavProjects projects={data.projects} />
        <NavMain items={data.navMain} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}

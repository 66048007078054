/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import sendFetchKeysRequest from 'network/sendFetchKeysRequest';
import {
  sendConnectionExistsRequest,
  sendAddOrUpdateConnectionRequest,
  sendAddOrUpdateAgentConnectionRequest
} from './network';

const fetchKeys = async () => {
  try {
    const { success, payload } = await sendFetchKeysRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          secrets: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { secrets: [] } };
    }

    const { secrets } = payload;

    return {
      success: true,
      payload: { secrets }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { secrets: [] } };
  }
};

const connectionExists = async ({ key }) => {
  try {
    const { success, payload } = await sendConnectionExistsRequest({ key });

    if (!success) {
      return { success: false, payload: { exists: true } };
    }

    const exists = payload.exists;

    return {
      success: true,
      payload: { exists }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { exists: true } };
  }
};

const addOrUpdateConnection = async (formModel, connectionName) => {
  try {
    const { success } = await sendAddOrUpdateConnectionRequest(formModel, connectionName);

    return { success };
  } catch (e) {
    console.log('addOrUpdateConnection:error', e);

    return { success: false };
  }
};

const addOrUpdateAgentConnection = async (formModel, connectionName) => {
  try {
    const result = await sendAddOrUpdateAgentConnectionRequest(formModel, connectionName);
    return { result: true, configurationToken: result.configurationToken };
  } catch (e) {
    console.log('addOrUpdateConnection:error', e);
    return { result: false };
  }
};

export { fetchKeys, connectionExists, addOrUpdateConnection, addOrUpdateAgentConnection };

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import LogListSkeleton from 'components/skeleton/eventLogs/LogList';
import { getEventLogs } from 'features/eventLogs/network';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import get from 'lodash/get';
import ReactCodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { githubDark } from '@uiw/codemirror-theme-github';

const statuses = {
  update: '!text-secondary !bg-secondary/10',
  create: '!text-green-400 !bg-green-400/10',
  delete: '!text-rose-400 !bg-rose-400/10',
  login: '!text-amber-400 !bg-amber-400/10'
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function List({
  tableData,
  totalPages,
  startDate,
  endDate,
  dataCount,
  selectedActionType,
  selectedEntityType,
  loading,
  searchedLogs
}) {
  const [data, setData] = useState([]);
  const [newPageCount, setNewPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [newDataCount, setNewDataCount] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  // log detail states
  const [selectedLog, setSelectedLog] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [logInfoPrev, setLogInfoPrev] = useState(null);
  const [logInfoNow, setLogInfoNow] = useState(null);

  // handle selected log
  const handleLogClick = async (logIndex) => {
    if (!data || data.length === 0 || logIndex >= data.length) {
      console.error('Invalid log index or data is not available.');
      return;
    }

    const log = data[logIndex];
    if (!log) {
      console.error('Log is undefined or null.');
      return;
    }

    setSelectedLog(logIndex);
    setDetailModal(!detailModal);

    // Calculate diff info when log is clicked
    try {
      const prevLogInfo = getLogInfoPrev(log);
      setLogInfoPrev(prevLogInfo);

      const newLogInfo = getLogInfoNow(log);
      setLogInfoNow(newLogInfo);
    } catch (error) {
      console.error('Error while processing log details:', error);
    }
  };

  useEffect(() => {
    // Set current page to 1 when any type changes
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [selectedActionType, selectedEntityType, searchedLogs]);

  useEffect(() => {
    // fetch event log data
    const fetchData = async () => {
      try {
        let logsData = [];
        setSearchLoading(true);
        const { success, payload } = await getEventLogs({
          startDate: startDate,
          endDate: endDate,
          page: currentPage,
          limit: 10,
          orderBy: 'timestamp',
          direction: 'desc',
          actionType: selectedActionType.join(','),
          entityType: selectedEntityType.join(','),
          containsText: searchedLogs
        });
        if (success) {
          logsData = payload.logs || []; //if logs null, set to empty array
          setNewDataCount(payload.dataCount);
          setNewPageCount(Math.ceil(payload.dataCount / 10));
        }
        setData(logsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setSearchLoading(false);
      }
    };

    // We expect it to update asynchronously
    const timeout = setTimeout(() => {
      fetchData();
    }, 0);

    return () => clearTimeout(timeout); // clear timeout
  }, [
    currentPage,
    selectedActionType,
    selectedEntityType,
    tableData,
    searchedLogs,
    startDate,
    endDate
  ]);

  const handlePageClick = async (event) => {
    const selectedPage = event.selected + 1;
    setCurrentPage(selectedPage);
    setDetailModal(false);
  };

  const getEventLogInfoDesc = (log) => {
    const entityInfo = get(log.eventLogInfo, `${log.entityType}Info`);
    if (entityInfo) {
      return entityInfo.name !== undefined ? entityInfo.name : entityInfo.email;
    }
  };

  const getLogInfoPrev = (log) => {
    if (!log?.eventLogInfo) {
      return null;
    }

    const entityInfoKey =
      log.entityType === 'execution-policy' ? 'executionPolicyInfo' : `${log.entityType}Info`;
    const entityInfo = get(log.eventLogInfo, entityInfoKey);

    if (!entityInfo) {
      return null;
    }

    if (log.entityType === 'execution-policy') {
      return entityInfo['policy'];
    }

    if (log.entityType !== 'login') {
      return entityInfo[log.entityType];
    }

    return null;
  };

  let previousLogInfo = logInfoPrev ? JSON.stringify(logInfoPrev, null, 2) : '';

  const getLogInfoNow = (log) => {
    if (!log?.eventLogInfo) {
      return null;
    }

    const entityTypeCapitalized = log.entityType[0].toUpperCase() + log.entityType.slice(1);
    const entityInfoKey =
      log.entityType === 'execution-policy' ? 'executionPolicyInfo' : `${log.entityType}Info`;
    const entityInfo = get(log.eventLogInfo, entityInfoKey);

    if (!entityInfo) {
      return null;
    }

    if (log.entityType === 'execution-policy') {
      return entityInfo['newPolicy'];
    }

    if (log.entityType !== 'login') {
      return entityInfo[`new${entityTypeCapitalized}`];
    }

    return null;
  };

  let nowLogInfo = logInfoNow ? JSON.stringify(logInfoNow, null, 2) : '';
  return (
    <div className="flex w-full flex-col ">
      <ul className="divide-y rounded-lg border shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] dark:divide-white/10 dark:border-gray-100/20">
        {loading || searchLoading ? (
          <LogListSkeleton />
        ) : data !== null && data.length > 0 ? (
          <>
            {data.map((log, index) => (
              <>
                <li
                  key={index}
                  onClick={() => handleLogClick(index)}
                  className="relative flex cursor-pointer items-center space-x-4 p-1 hover:bg-gray-200"
                >
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-center gap-x-3">
                      <div
                        className={classNames(
                          statuses[log.actionType],
                          'flex-none rounded-full bg-amber-400/10 p-1 text-amber-400'
                        )}
                      >
                        <div className="h-2 w-2 rounded-full bg-current" />
                      </div>
                      <h2 className="min-w-0 text-sm font-semibold leading-6">
                        <a href={log.href} className="flex gap-x-2">
                          <span className="truncate text-lg">{log.actionType}</span>
                          <span className="text-lg text-gray-400">/</span>
                          <span className="whitespace-nowrap text-lg">{log.entityType}</span>
                          <span className="absolute inset-0" />
                        </a>
                      </h2>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
                      <div className="block flex-wrap items-center gap-1 lg:flex">
                        <div className="flex items-center gap-1">
                          <p className="eventlog-description"> {`${log.entityType}`}</p>
                          <p className="eventlog-description text-[#374151]">
                            {getEventLogInfoDesc(log)}
                          </p>
                        </div>
                        <div className="flex items-center gap-1">
                          <p className="eventlog-description"> {`${log.actionType}d`}</p>
                          <p className="eventlog-description">by</p>
                          <p className="eventlog-description text-[#374151]"> {log.email}</p>
                        </div>
                        <div className="flex items-center gap-1">
                          <p className="eventlog-description"> at UTC</p>
                          <p> {log.timestamp.slice(0, 10)}</p>
                          <p>{log.timestamp.slice(11, 19)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ChevronRightIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </li>
                {selectedLog === index && logInfoPrev !== false && detailModal && (
                  <div className="p-2.5">
                    <div>
                      <h2 className="py-2.5 text-center text-base">Log Details</h2>
                    </div>
                    <div className="flex flex-wrap items-start gap-2.5">
                      <div className="!min-w-[300px] flex-1">
                        <>
                          <div className="my-2 w-fit rounded-md border border-red-300 bg-red-100 p-1 text-xs text-red-500">
                            Previous
                          </div>
                          {logInfoPrev && (
                            <ReactCodeMirror
                              extensions={[json()]}
                              theme={githubDark}
                              className="max-w-md cursor-default rounded-md !text-xs"
                              value={previousLogInfo}
                              readOnly={true}
                            />
                          )}
                        </>
                      </div>
                      <div
                        className={`${
                          log.actionType !== 'update' && 'hidden'
                        } min-w-[300px] flex-1`}
                      >
                        <>
                          <div className="my-2 w-fit rounded-md border border-green-300 bg-green-100 p-1 text-xs text-green-500">
                            Now
                          </div>
                          {logInfoNow && (
                            <ReactCodeMirror
                              extensions={[json()]}
                              theme={githubDark}
                              options={{
                                lineNumbers: true,
                                mode: 'json'
                              }}
                              className="max-w-md cursor-default rounded-md !text-xs"
                              value={nowLogInfo}
                              readOnly={true}
                            />
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
            <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
              <p className="text-[13px] font-bold text-gray-400">
                Showing page <span className="text-[#374151] dark:text-white">{currentPage}</span>{' '}
                of <span className="text-[#374151] dark:text-white">{newDataCount}</span> results
              </p>
              <ReactPaginate
                className="isolate inline-flex items-center gap-1 -space-x-px rounded-md border shadow-sm"
                nextLabel={<ChevronRightIcon className="h-4 w-4" />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={newPageCount}
                previousLabel={<ChevronLeftIcon className="h-4 w-4" />}
                pageClassName="px-2 py-1"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                disabledClassName="text-gray-400"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="bg-secondary text-white"
                renderOnZeroPageCount={null}
                forcePage={currentPage - 1}
              />
            </div>
          </>
        ) : (
          <div className="flex h-56 items-center justify-center text-lg text-gray-400">
            It looks like you don’t have any data.
          </div>
        )}
      </ul>
    </div>
  );
}

import { urls } from 'constants/index';
import axios from 'axios';

export const getFiles = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_FILES, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { files: [] }
      };
    }

    const { files, success } = data;

    if (!files) {
      return {
        success: success,
        payload: { files: [] }
      };
    }

    return {
      success: success,
      payload: {
        files
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { files: [] } };
  }
};

export const postFile = async ({ data }) => {
  try {
    const result = await axios.post(urls.URL_WORKSPACE_FILES, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const deleteFile = async ({ fileName }) => {
  try {
    const result = await axios.delete(`${urls.URL_WORKSPACE_FILES}/${fileName}`, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const getFileUrl = async ({ fileName }) => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_FILE_URL}/${fileName}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { url: '' }
      };
    }

    const { url, success } = data;

    if (!url) {
      return {
        success: success,
        payload: { url: '' }
      };
    }

    return {
      success: success,
      payload: {
        url: url
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { url: '' } };
  }
};

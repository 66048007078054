/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconSlack = () => (
  <svg width="28" height="28" viewBox="0 0 64 64">
    <title>slack logo</title>
    <g >
      <path data-color="color-2" d="M24,2a6,6,0,0,0,0,12h6V8a6,6,0,0,0-6-6"fill="#E01E5A"/>
      <path d="M24,18H8A6,6,0,0,0,8,30H24a6,6,0,0,0,0-12" fill="#E01E5A" />
      <path data-color="color-2" d="M62,24a6,6,0,1,0-12,0v6h6a6,6,0,0,0,6-6" fill="#36C5F0"/>
      <path d="M46,24V8A6,6,0,1,0,34,8V24a6,6,0,1,0,12,0" fill="#36C5F0" />
      <path data-color="color-2" d="M40,62a6,6,0,1,0,0-12H34v6a6,6,0,0,0,6,6" fill='#2EB67D' />
      <path d="M40,46H56a6,6,0,0,0,0-12H40a6,6,0,0,0,0,12h0" fill="#2EB67D" />
      <path data-color="color-2" d="M2,40a6,6,0,0,0,6,6H8a6,6,0,0,0,6-6V34H8a6,6,0,0,0-6,6" fill="#ECB22E"/>
      <path d="M18,40V56a6,6,0,1,0,12,0V40a6,6,0,0,0-6-6h0a6,6,0,0,0-6,6" fill="#ECB22E" />
    </g>
  </svg>
);

export default IconSlack;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationServiceDesk = () => (
  <svg className="m-auto" width="48" height="48" viewBox="0 0 59.14 75.76">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#0558d2"
          d="M35.34,29.92,56,30a3.22,3.22,0,0,1,2.55,5.14L28.08,75.76a21.61,21.61,0,0,1-4.39-30.25h0Z"
        />
        <path
          fill="#2684ff"
          d="M23.69,45.51,3.2,45.38A3.21,3.21,0,0,1,0,42.16a3.16,3.16,0,0,1,.65-1.92L30.77,0a21.62,21.62,0,0,1,4.38,30.25h0Z"
        />
      </g>
    </g>
  </svg>
);

export default IconIntegrationServiceDesk;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const LoginInputEmail = ({ value, onChange }) => (
  <div>
    <label htmlFor="email"  className="block text-sm font-medium leading-6 text-gray-900">
      Email adress
    </label>
    <div className="mt-1 rounded-md shadow-sm">
      <input
        id="email"
        type="email"
        placeholder="e.g., hermionie.granger@hogwarts.magic"
        value={value}
        onChange={onChange}
        autoFocus
        required
        className="block w-full rounded-md border-0 px-4 py-2.5 shadow-inner outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
      />
    </div>
  </div>
);

export default LoginInputEmail;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

const API_ROOT = window.ob.config.apiRoot;
const redirectUrl = window.ob.config.slackRedirectUrl;
const clientId = window.ob.config.slackClientId;
const slackScope = window.ob.config.slackScope;
const slackUserScope = window.ob.config.slackUserScope;

export const slackCallbackUrl =
  'https://slack.com/oauth/v2/authorize?' +
  'client_id=' +
  clientId +
  '&scope=' +
  slackScope +
  '&user_scope=' +
  slackUserScope +
  '&redirect_uri=' +
  redirectUrl;

export const urls = {
  URL_IDM_REGISTER: `${API_ROOT}/idm/v2/register`,
  URL_IDM_SIGNUP: `${API_ROOT}/idm/v2/signup`,
  URL_IDM_LOGIN: `${API_ROOT}/idm/v2/login-info`,
  URL_IDM_LOGIN_WITH_PASSWORD: `${API_ROOT}/idm/v2/login`,
  URL_IDM_LOGIN_WITH_MICROSOFT: `${API_ROOT}/idm/v2/login/microsoft`,
  URL_IDM_GOOGLE_LOGIN: `${API_ROOT}/idm/v2/login/google`,
  URL_IDM_SLACK_LOGIN: `${API_ROOT}/idm/v2/login/slack`,
  URL_IDM_LOGOUT: `${API_ROOT}/idm/v2/logout`,
  URL_IDM_FORGOT_PASSWORD: `${API_ROOT}/idm/v2/forgotpassword`,
  URL_IDM_RESET_PASSWORD: `${API_ROOT}/idm/v2/resetpassword`,
  //ONBOARDING
  URL_IDM_ONBOARDING: `${API_ROOT}/idm/v2/onboarded`,

  URL_IDM_ACTIVATION_META: `${API_ROOT}/idm/v1/activation-meta`,
  URL_IDM_INFO: `${API_ROOT}/idm/v2/info`,
  URL_WORKSPACE_STRIPE_INFO: `${API_ROOT}/workspace/v2/stripe/info`,
  URL_WORKSPACE_STRIPE_CHECKOUT: `${API_ROOT}/workspace/v2/stripe/checkout`,
  URL_WORKSPACE_STRIPE_TRIAL: `${API_ROOT}/workspace/v2/stripe/trial`,
  URL_WORKSPACE_STRIPE_PORTAL: `${API_ROOT}/workspace/v2/stripe/portal`,
  URL_WORKSPACE_STRIPE_DOWNGRADE: `${API_ROOT}/workspace/v2/stripe/downgrade`,
  URL_IDM_ONBOARD: `${API_ROOT}/idm/v1/onboard`,

  URL_IDM_RESEND_INVITE: `${API_ROOT}/idm/v2/workspace/user/resend-invitation`,

  URL_WORKSPACE_EXECUTIONLOGS: `${API_ROOT}/workspace/v2/executionlogs`,
  URL_WORKSPACE_EXECUTIONLOG_DETAILS: `${API_ROOT}/workspace/v2/executionlog`,
  URL_WORKSPACE_AUDITLOGS: `${API_ROOT}/workspace/v2/audit-log`,
  URL_WORKSPACE_AUDITLOGSCHANNELNAME: `${API_ROOT}/workspace/v2/audit-log-user-name`,
  URL_WORKSPACE_AUDITLOGS_PRESIGNED_URL: `${API_ROOT}/workspace/v2/presigned-url`,
  //TRIGGERS
  URL_WORKSPACE_TRIGGERS: `${API_ROOT}/workspace/v2/triggers`,
  URL_WORKSPACE_TRIGGER_WEBHOOK: `${API_ROOT}/workspace/v2/triggers/%webhook%`,
  URL_WORKSPACE_TRIGGER_WEBHOOKS_REGENERATEURL: `${API_ROOT}/workspace/v2/triggers/url/%webhook%`,
  URL_WORKSPACE_TRIGGER_LINK: `${API_ROOT}/workspace/v2/triggers/%link%`,
  URL_WORKSPACE_TRIGGER_LINK_REGENERATEURL: `${API_ROOT}/workspace/v2/triggers/url/%link%`,
  URL_WORKSPACE_TRIGGER_CRON: `${API_ROOT}/workspace/v2/triggers/%cron%`,

  URL_WORKSPACE_PROJECTS: `${API_ROOT}/workspace/v2/projects`,
  URL_WORKSPACE_PROJECT: `${API_ROOT}/workspace/v2/projects/%project%`,
  // URL_WORKSPACE_COMMAND: `${API_ROOT}/workspace/v1/commands/%command%`,
  URL_WORKSPACE_INTEGRATIONS: `${API_ROOT}/workspace/v2/integrations/slack`,
  // URL_WORKSPACE_CONNECTION: `${API_ROOT}/workspace/v1/connections/%connection%`,

  // URL_WORKSPACE_KEYS: `${API_ROOT}/workspace/v1/keys/ssh`,
  // URL_WORKSPACE_KEY: `${API_ROOT}/workspace/v1/keys/ssh/%key%`,
  URL_WORKSPACE_ADD_SLACK_CHANNEL: `${API_ROOT}/workspace/v2/link-slack-channel`,
  // URL_WORKSPACE_ADD_FIRST_CONNECTION: `${API_ROOT}/workspace/v1/add-first-connection`,
  URL_WORKSPACE_LIST_PROJECT_CONNECTIONS: `${API_ROOT}/workspace/v2/projects/%project%/connections`,
  URL_WORKSPACE_LIST_PROJECT_COMMANDS: `${API_ROOT}/workspace/v2/projects/%project%/commands`,
  URL_INTEGRATION_SLACK_SAVE_STATE: `${API_ROOT}/integration/v1/slack/save-state`,
  URL_SLACK_LIST_CHANNELS: `${API_ROOT}/slack/v2/channels?project=%project%`,
  // REST
  URL_WORKSPACE_SECRETS: `${API_ROOT}/workspace/v2/secrets`,
  URL_WORKSPACE_CONNECTIONS: `${API_ROOT}/workspace/v2/connections`,
  URL_WORKSPACE_COMMANDS: `${API_ROOT}/workspace/v2/commands`,
  // WORKFLOW
  URL_WORKSPACE_WORKFLOWS: `${API_ROOT}/workspace/v2/workflow`,
  //SETTINGS
  // USERS
  URL_WORKSPACE_USERS: `${API_ROOT}/workspace/v2/users`,
  URL_WORKSPACE_USERS_REGISTER: `${API_ROOT}/idm/v2/workspace/user/register`,
  URL_WORKSPACE_USERS_GOOGLE_REGISTER: `${API_ROOT}/idm/v2/workspace/user/register/google`,
  URL_WORKSPACE_USERS_SLACK_REGISTER: `${API_ROOT}/idm/v2/workspace/user/register/slack`,
  //POLICY
  URL_WORKSPACE_POLICY: `${API_ROOT}/workspace/v2/policy`,
  //GROUPS
  URL_WORKSPACE_GROUP: `${API_ROOT}/workspace/v2/policy/group`,
  URL_WORKSPACE_APPS: `${API_ROOT}/workspace/v2/apps`,
  // WORKSPACE SETTINGS
  URL_WORKSPACE_SETTINGS: `${API_ROOT}/workspace/v2/workspace-settings`,

  URL_DASHBOARD_BASIC_DATA: `${API_ROOT}/workspace/v2/basic-data`,
  URL_DASHBOARD_INVOCATION_SERIES: `${API_ROOT}/workspace/v2/invocation-daily`,
  URL_DASHBOARD_INVOCATIONS: `${API_ROOT}/workspace/v2/invocation`,

  URL_WORKSPACE_APP_DETAIL: `${API_ROOT}/workspace/v2/apps/%appId%`,
  URL_WORKSPACE_APP_UPDATE: `${API_ROOT}/workspace/v2/apps/%appId%`,
  URL_WORKSPACE_APP_DELETE: `${API_ROOT}/workspace/v2/apps/%appId%`,
  URL_TRIGGER_COMMAND: `${API_ROOT}/trigger/v1/app/%appId%`,
  //EVENT LOGS
  URL_WORKSPACE_EVENTLOGGS: `${API_ROOT}/workspace/v2/eventlogs`,
  URL_WORKSPACE_EVENTLOGS_DAILYDATA: `${API_ROOT}/workspace/v2/eventlogs-daily`,
  URL_WORKSPACE_DELETE_GET_CODE: `${API_ROOT}/workspace/v2/delete-code`,
  URL_WORKSPACE_DELETE_ACCOUNT: `${API_ROOT}/workspace/v2/delete`,
  URL_IDM_PROFILE: `${API_ROOT}/idm/v2/profile`,
  URL_IDM_API_KEY: `${API_ROOT}/idm/v2/apikey`,
  URL_WORKSPACE_ENVIRONMENTS: `${API_ROOT}/workspace/v2/environments`,
  URL_CONNECTION_ENVIRONMENTS: `${API_ROOT}/workspace/v2/connection-environments`,
  URL_WORKSPACE_FILES: `${API_ROOT}/workspace/v2/files`,
  URL_WORKSPACE_FILE_URL: `${API_ROOT}/workspace/v2/file-url`,

  //EXECUTION COMMAND
  URL_WORKSPACE_EXECUTION_COMMAND: `${API_ROOT}/trigger/v1/api`,

  // DEBUGGER
  URL_WORKSPACE_ID: `${API_ROOT}/workspace/v2/workspace-id`,
  URL_WORKSPACE_DEBUGGER: `${API_ROOT}/trigger/v1/debugger/%workspaceId%/sse`
};

//TRIGGERS
export const addNewTriggersViewStates = {
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  ERROR: 'ERROR',
  FETCHING_COMMANDS: 'FETCHING_COMMANDS',
  FETCHING_CONNECTIONS: 'FETCHING_CONNECTIONS',
  FETCHING_WORKFLOWS: 'FETCHING_WORKFLOWS',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const triggerListViewStates = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  DONE: 'DONE'
};

export const editTriggerViewStates = {
  FETCHING: 'FETCHING',
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  ERROR: 'ERROR',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

//EXECUTION POLICIES
export const addNewPolicyViewStates = {
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const policyListViewStates = {
  LOADING: 'LOADING',
  EXECUTING: 'EXECUTING',
  DONE: 'DONE'
};

//GROUPS
export const addNewGroupViewStates = {
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const groupListViewStates = {
  LOADING: 'LOADING',
  EXECUTING: 'EXECUTING',
  DONE: 'DONE'
};

// USERS
export const addNewUserViewStates = {
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const userListViewStates = {
  LOADING: 'LOADING',
  EXECUTING: 'EXECUTING',
  DONE: 'DONE'
};

export const editProjectViewStates = {
  FETCHING: 'FETCHING',
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  ERROR: 'ERROR',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const addNewProjectViewStates = {
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  ERROR: 'ERROR',
  FETCHING_COMMANDS: 'FETCHING_COMMANDS',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const addNewCommandViewStates = {
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const addNewSshKeyViewStates = {
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const addNewConnectionViewStates = {
  POSTING: 'POSTING',
  IDLE: 'IDLE',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT'
};

export const integrationListViewStates = {
  LOADING: 'LOADING',
  DONE: 'DONE'
};

export const commandListViewStates = {
  LOADING: 'LOADING',
  EXECUTING: 'EXECUTING',
  DONE: 'DONE'
};

export const keyListViewStates = {
  LOADING: 'LOADING',
  EXECUTING: 'EXECUTING',
  DONE: 'DONE'
};

export const connectionListViewStates = {
  LOADING: 'LOADING',
  EXECUTING: 'EXECUTING',
  DONE: 'DONE'
};

export const workflowListViewStates = {
  LOADING: 'LOADING',
  EXECUTING: 'EXECUTING',
  DONE: 'DONE'
};

export const loginActivationViewStates = {
  LOADING: 'LOADING',
  ERR_BAD_TOKEN: 'ERR_BAD_TOKEN',
  ERR_REGISTRATION_FAILED: 'ERR_REGISTRATION_FAILED',
  ERR_GENERAL_LOGIN: 'ERR_GENERAL_LOGIN',
  REDIRECT_USER_DASHBOARD: 'REDIRECT_USER_DASHBOARD',
  DEFAULT: 'DEFAULT'
};

export const firstProjectAddSshViewStates = {
  IDLE: 'IDLE',
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR'
};

export const dashboardFirstProjectViewStates = {
  LOADING: 'LOADING',
  REGISTER_SLACK: 'REGISTER_SLACK',
  ERROR: 'ERROR'
};

export const loginViewStates = {
  LOADING: 'LOADING',
  REDIRECT_TO_DASHBOARD: 'REDIRECT_TO_DASHBOARD',
  DEFAULT: 'DEFAULT'
};

export const topNavViewStates = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  DONE: 'DONE'
};

export const projectListViewStates = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  DONE: 'DONE'
};

export const dashboardViewStates = {
  LOADING: 'LOADING',
  WIZARD: 'WIZARD',
  DEFAULT: 'DEFAULT',
  FIRST_PROJECT: 'FIRST_PROJECT',
  LINK_CHANNEL: 'LINK_CHANNEL',
  ADD_SSH_CONNECTION: 'ADD_SSH_CONNECTION',
  LIST_COMMANDS: 'LIST_COMMANDS',
  ERROR: 'ERROR',
  REDIRECT_TO_HOME: 'REDIRECT_TO_HOME'
};

export const dashboardFirstProjectChannelViewStates = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  IN_TRANSIT: 'IN_TRANSIT',
  ERROR: 'ERROR'
};

export const dashboardFirstProjectChannelListViewStates = {
  IDLE: 'IDLE',
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR'
};

export const firstTimeSshConnectionDetailsStates = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ERROR: 'ERROR'
};

export const dashboardLogoutViewStates = {
  LOADING: 'LOADING',
  REDIRECT_TO_DEFAULT: 'REDIRECT_TO_DEFAULT'
};

//////////////////////////

export const postMethods = {
  SAVE: 'SAVE',
  UPDATE: 'UPDATE'
};

export const globalViewStates = {
  DEFAULT: 'DEFAULT',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  DONE: 'DONE',
  POSTING: 'POSTING',
  GETTING: 'GETTING',
  IDLE: 'IDLE',
  FETCHING_COMMANDS: 'FETCHING_COMMANDS',
  REDIRECT_TO_PARENT: 'REDIRECT_TO_PARENT',
  REDIRECT_TO_DASHBOARD: 'REDIRECT_TO_DASHBOARD'
};

// Connection
export const authTypes = {
  NONE: 'NONE',
  BASIC_AUTH: 'BASIC AUTH',
  API_KEY: 'API KEY',
  REST_TOKEN_AUTH: 'REST TOKEN AUTH',
  BEARER_TOKEN: 'BEARER TOKEN',
  SSH_CREDENTIAL: 'SSH CREDENTIAL',
  CMS_CREDENTIAL: 'CMS CREDENTIAL'
};

export const createOrSelectExistingChoosing = {
  SELECT: 'SELECT',
  CREATE: 'CREATE'
};

//  User Roles

export const userRoles = {
  ADMIN: 'admin',
  OPERATOR: 'operator',
  READ_ONLY: 'read-only',
  SUPERADMIN: 'superadmin'
};

export const userRolesSelectOptions = [
  {
    label: 'admin',
    value: 'admin'
  },
  {
    label: 'operator',
    value: 'operator'
  },
  {
    label: 'read-only',
    value: 'read-only'
  }
];

export const superAdminUserRoleOption = {
  label: 'superadmin',
  value: 'superadmin'
};

export const userRoleTypes = [
  {
    title: 'Admin',
    description: 'Highest level of access and control.',
    current: true,
    type: 'admin'
  },
  {
    title: 'Operator',
    description: 'Most functions and resources, except user management.',
    current: false,
    type: 'operator'
  },
  {
    title: 'Read Only',
    description: 'Only able to view resources, no control.',
    current: false,
    type: 'read-only'
  }
];

export const features = {
  WEBHOOK_TRIGGER: 'webhookTrigger',
  CRON_TRIGGER: 'cronTrigger',
  SLACK_TRIGGER: 'slackTrigger',
  NUMBER_EXECUTION_POLICY: 'numberExecutionPolicy',
  APPROVAL_POLICY: 'approvalPolicy',
  AUDIT_LOGS: 'auditLogs',
  NUMBER_WORKFLOW_STEPS: 'numberWorkflowSteps',
  NUMBER_ADMIN_USER: 'numberAdminUser',
  NUMBER_OPERATOR_USER: 'numberOperatorUser',
  NUMBER_READONLY_USER: 'numberReadOnlyUser',
  AGENT_CONNECTION: 'agentConnection',
  SSO: 'sSO',
  LOGGING_INTEGRATION: 'loggingIntegration',
  MONITORING_INTEGRATION: 'monitoringIntegration',
  INTERNAL_APP: 'internalApp',
  EVENT_LOGS: 'eventLogs'
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';

const sendFetchTriggersRequest = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_TRIGGERS, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false
      };
    }

    const { triggers } = data;

    if (!triggers) {
      return {
        success: true,
        payload: {
          triggers: []
        }
      };
    }

    return {
      success: true,
      payload: {
        triggers
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false };
  }
};

export default sendFetchTriggersRequest;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import styled from 'styled-components';

const h2 = styled.h2`
  font-weight: 300;
  color: #777777;
  text-align: center;
  margin-bottom: 35px;
  cursor: default;

  svg {
    margin-right: 7px;
    vertical-align: middle;
    transform: rotate(14deg);
    transition: all 0.312s ease-out;
  }

  &:hover svg {
    transform: rotate(0deg);
    opacity: 0.85;
  }
`;

const ops = styled.span`
  font-size: 32px;
  color: #000000;
  font-weight: 400;
  position: relative;
  top: 6px;
`;

const beacon = styled.span`
  font-size: 32px;
  color: rgb(22, 96, 134);
  font-weight: 600;
  position: relative;
  top: 6px;
`;

export default {
  h2,
  ops,
  beacon
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconLinkExternal = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className="ml-1 inline"
    style={{ position: 'relative', top: '-1px' }}
  >
    <title>external link</title>
    <g fill="#1a56db">
      <path d="M7,16H1c-0.552,0-1-0.448-1-1V9c0-0.552,0.448-1,1-1h6c0.552,0,1,0.448,1,1v6 C8,15.552,7.552,16,7,16z" />{' '}
      <polygon
        fill="#3f83f8"
        points="15.978,0 9,0 11.789,2.797 8.293,6.293 9.707,7.707 13.2,4.214 15.978,7 "
      />
    </g>
  </svg>
);

export default IconLinkExternal;

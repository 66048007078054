import axios from 'axios';
import { urls } from 'constants/index';


const sendAddOrUpdateWorkflowRequest = async (formModel, workflowName) => {
  try {
    const result = workflowName
      ? await axios.put(`${urls.URL_WORKSPACE_WORKFLOWS}/${workflowName}`, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        })
      : await axios.post(urls.URL_WORKSPACE_WORKFLOWS, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        });

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};


export { sendAddOrUpdateWorkflowRequest };

import { useEffect, useState } from 'react';
import { BiX } from 'react-icons/bi';
import Select from 'react-select';

export default function CommandArgument({
  argumentObject,
  commandName,
  onRemove,
  onChange,
  options
}) {
  const createSelectOption = (value, label = value) => ({ value: value, label: label });

  const [nameOptions, setNameOptions] = useState();
  const [selectedNameOption, setSelectedNameOption] = useState(createSelectOption(''));
  const [defaultValue, setDefaultValue] = useState('');
  const [selectedTypeOption, setSelectedTypeOption] = useState('');
  const [selectedRequireOption, setSelectedRequireOption] = useState('');

  useEffect(()=> {
    const optionalNameOptions = options
    .filter((option) => option.required === false)
    .map((option) => createSelectOption(option.name)); 
    setNameOptions(optionalNameOptions);
  },[options]);
  
  useEffect(() => {
    setSelectedNameOption(createSelectOption(argumentObject.name));
    setDefaultValue(argumentObject.default);
    setSelectedTypeOption(argumentObject.type);
    setSelectedRequireOption(argumentObject.required);
  }, [argumentObject]);

  const handleChangeNameOption = (e) => {
    const findObject = options.find((option) => option.name === e.value);
    setSelectedNameOption(e);
    onChange({
      ...argumentObject,
      name: e.value,
      type: findObject.type,
      required: findObject.required
    });
  };

  const handleChangeDefaultValue = (e) => {
    setDefaultValue(e.target.value);
    onChange({ ...argumentObject, default: e.target.value.trim() });
  };

  return (
    <div className='command-arguments'>
      <div className="relative w-full">
        {/* <label htmlFor="name" className="label-main">
            Name:
          </label> */}
        {selectedRequireOption === false ? (
          <Select
            name="argumentName"
            className="my-2 w-full"
            placeholder="name"
            options={nameOptions}
            classNames={{
              control: (state) =>
                state.isFocused ? 'multi-select-input-focus' : 'multi-select-input',
              menuList: () => 'multi-select-dropdown',
              option: () => 'multi-select-option',
              input: () => 'multi-select-input-text',
              singleValue: () => 'dark:!text-gray-200'
            }}
            value={selectedNameOption.value !== '' && selectedNameOption}
            onChange={handleChangeNameOption}
          />
        ) : (
          <input
            name="argumentName"
            type="text"
            className="input-main-disabled cursor-default"
            placeholder="name"
            defaultValue={selectedNameOption.value}
            readOnly
          />
        )}
      </div>

      <div className="relative w-full">
        {/* <label htmlFor="name" className="label-main">
            Type:
          </label> */}
        <input
          name="type"
          type="text"
          className={selectedTypeOption ? 'input-main-disabled' : 'input-main cursor-default'}
          readOnly
          placeholder="type"
          value={selectedTypeOption}
        />
      </div>

      <div className="relative w-full">
        {/* <label htmlFor="name" className="label-main">
            Default Value:
          </label> */}
        <input
          name="defaultArgumentValue"
          type="text"
          className={`${
            argumentObject.invalidDefault ? 'input-main border-red-500' : 'input-main'
          }`}
          placeholder="default value"
          value={defaultValue}
          onChange={handleChangeDefaultValue}
        />
      </div>
      <div className="relative w-full">
        {/* <label htmlFor="name" className="label-main">
            Required:
          </label> */}
        <input
          name="requried"
          type="text"
          className={
            selectedRequireOption !== '' ? 'input-main-disabled' : 'input-main cursor-default'
          }
          readOnly
          placeholder="requried"
          value={
            selectedRequireOption === true ? 'Yes' : selectedRequireOption === false ? 'No' : ''
          }
        />
      </div>
      <div className="absolute right-0">
        <button
          className={`btn-delete-second !m-0 ${selectedRequireOption === true && 'invisible'}`}
          onClick={() => {
            if (!selectedRequireOption) {
              onRemove(argumentObject.id);
            }
          }}
        >
          <BiX />
        </button>
      </div>

      <div className="absolute bottom-0">
          <span
            className={`label-second block !text-red-500 ${
              argumentObject.invalidMessage === '' ? 'hidden' : 'block'
            }`}
          >{`*${argumentObject.invalidMessage}`}</span>
        </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

export const FeatureFlags = React.createContext();

export const Flags = ({
  children,
  authorizedFlags,
  exactFlags,
  renderOn,
  renderOff,
  existedCount
}) => {
  function getMatchingFlags(flags) {
    return flags.filter((flag) => {
      if (!flag.hasOwnProperty('boundary'))
        return flag.isActive && authorizedFlags.includes(flag.name);
      else
        return flag.isActive && authorizedFlags.includes(flag.name) && flag.boundary > existedCount;
    });
  }

  function resolveRender(matchingFlags) {
    return children && children ? children : renderOn(matchingFlags);
  }

  return (
    <FeatureFlags.Consumer>
      {(flags) => {
        const matchingFlags = getMatchingFlags(flags);
        if (exactFlags) {
          return matchingFlags.length === authorizedFlags.length
            ? resolveRender(matchingFlags)
            : renderOff(matchingFlags);
        } else {
          return matchingFlags.length ? resolveRender(matchingFlags) : renderOff(matchingFlags);
        }
      }}
    </FeatureFlags.Consumer>
  );
};

Flags.propTypes = {
  authorizedFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
  exactFlags: PropTypes.bool,
  renderOn: PropTypes.func,
  renderOff: PropTypes.func,
  existedCount: PropTypes.number
};

Flags.defaultProps = {
  exactFlags: false,
  renderOn: () => null,
  renderOff: () => null,
  existedCount: -1
};

export const FlagsProvider = ({ children, value }) => {
  return <FeatureFlags.Provider value={value}>{children}</FeatureFlags.Provider>;
};

FlagsProvider.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      isActive: PropTypes.bool
    })
  ).isRequired
};

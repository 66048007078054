/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';
const updateUrl = (inUrl, key) => {
  // console.log(inUrl.replace(/%key%/, key));
  return inUrl.replace(/%key%/, key);
};

const sendCronExistsRequest = async ({ key }) => {
  try {
    const url = updateUrl(urls.URL_WORKSPACE_TRIGGERS, key);

    const result = await axios.get(url, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return { success: false, payload: { exists: true } };
    }

    return { success: true, payload: { exists: !!data.key } };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { exists: true } };
  }
};

const sendSaveNewCronRequest = async (formModel) => {
  try {
    await axios.post(urls.URL_WORKSPACE_TRIGGERS, formModel, {
      withCredentials: true,
      headers: { 'content-type': 'text/plain; charset=utf-8' }
    });
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export { sendSaveNewCronRequest, sendCronExistsRequest };

import axios from 'axios';
import { urls } from 'constants/index';

const updateUserOnboard = async (onboardingModel) => {
  try {
    const { success } = await sendUpdateOnboardRequest(onboardingModel);

    return { success };
  } catch (e) {
    console.log('updateUserOnboard:error', e);

    return { success: false };
  }
};

const sendUpdateOnboardRequest = async (onboardingModel) => {
  try {
    const result = await axios.put(`${urls.URL_IDM_ONBOARDING}`, onboardingModel, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });
    void result;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export { updateUserOnboard, sendUpdateOnboardRequest };

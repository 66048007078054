import axios from 'axios';

import { urls } from 'constants/index';

const sendFetchCommandsRequest = async () => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_COMMANDS}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { commands: [] }
      };
    }

    const { commands } = data;

    if (!commands) {
      return {
        success: false,
        payload: { commands: [] }
      };
    }

    return {
      success: true,
      payload: {
        commands
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { command: [] } };
  }
};

export { sendFetchCommandsRequest };

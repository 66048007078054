import React, { useState, useEffect } from 'react';
import { postMethods } from 'constants/index';
import { Navigate, useParams } from 'react-router-dom';
import { globalViewStates } from 'constants/index';
import { addNewSecret, secretExists } from 'features/key/KeyNew/io';
import { addOrUpdateConnection, connectionExists, fetchKeys } from './io';
import { deleteConnection, fetchConnectionDataDetail } from '../connectionList/io';
import { fetchData } from 'features/project/ProjectList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { fetchConnectionData } from '../connectionList/io';
import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';
import { fetchPolicies } from 'features/user/io';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { ConnectionEnvironment } from '../connectionEnvironment';
import { useToast } from 'hooks/use-toast';

const InitialCredentialFormModel = {
  name: '',
  kind: 'jira-credential',
  description: '',
  jiraCredentialInfo: {
    username: '',
    password: ''
  }
};

const initialFormModel = {
  name: '',
  kind: 'jira',
  description: '',
  secret: '',
  jiraConnectionInfo: {
    baseUrl: ''
  }
};

const ConnectionNewJira = () => {
  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [secrets, setSecrets] = useState([]);
  const [formModelJiraKey, setFormModelJiraKey] = useState(InitialCredentialFormModel);
  const [formErrorModelJiraCredential, setFormErrorModelJiraCredential] = useState({
    name: false,
    username: false,
    password: false
  });
  const [newKeyControl, setNewKeyControl] = useState(false);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [showModal, setShowModal] = useState(false);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    baseUrl: false,
    secret: false
  });
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  // Get Projects
  useEffect(() => {
    fetchData().then(({ success, payload }) => {
      void success;

      const { projects } = payload;
      setProjects(projects);
    });
  }, []);

  const onFormSubmit = (type, e) => {
    e.preventDefault();
    const obj = {
      name: type === postMethods.UPDATE ? false : formModel.name.trim() === '',
      baseUrl: formModel.jiraConnectionInfo.baseUrl.trim() === '',
      secret: formModel.secret.trim() === ''
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      setFormModel({
        ...formModel
      });

      if (type === postMethods.SAVE) {
        onFormSave();
      } else if (type === postMethods.UPDATE) {
        onFormUpdate();
      }
    }
  };

  const params = useParams(); // get param
  const connectionName = params.connectionName ? params.connectionName : null;

  useEffect(() => {
    if (connectionName) {
      fetchConnectionDataDetail(connectionName).then(
        ({ success, payload }) => {
          void success;

          const { connection } = payload;

          const temporaryForm = {
            name: connection.name,
            kind: 'jira',
            description: connection.description,
            secret: connection.secret,
            jiraConnectionInfo: {
              baseUrl: connection.jiraConnectionInfo.baseUrl
            }
          };
          setFormModel(temporaryForm);
          setViewState(globalViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [connectionName]);

  useEffect(() => {
    fetchConnectionData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { connections } = payload;
        setConnectionList(connections);
      },
      (err) => {
        console.log('error', err);
      }
    );
    sendFetchTriggersRequest().then(({ success, payload }) => {
      void success;

      const { triggers } = payload;

      setTriggers(triggers);
    });
    fetchPolicies().then(({ success, payload }) => {
      void success;

      const { policies } = payload;

      setPolicies(policies);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.connections && policy.connections.includes(connectionName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, connectionName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.connections && trigger.connections.includes(connectionName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, connectionName]);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!connectionName) {
        if (
          connectionList.find(
            (connection) => connection.name.toLowerCase() === formModel.name.toLowerCase()
          )
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  }, [formModel.name, connectionList]);

  /********************   REST API   *********************/

  //Change Input
  const onChangeInput = (value, field) => {
    if (field === 'baseUrl') {
      setFormModel((prevState) => {
        prevState.jiraConnectionInfo.baseUrl = value;
        return {
          ...prevState
        };
      });
    }
    const trimmedValue = value.trim();
    if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  // clear form
  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  const onFormSave = async (evt) => {
    

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await connectionExists(formModel.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        // alert('There was a problem. Try again later.');
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:
            'There’s an existing connection with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addOrUpdateConnection({
        ...formModel
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'New connection added.'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (isDelete = false) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateConnection(
        {
          ...formModel
        },
        connectionName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        if (!isDelete) {
          toast({
            title: 'Successfully',
            description: 'Connection successfully updated'
          });
        }
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  //delete connection
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    if (
      selectedTriggers.length !== 0 ||
      selectedPolicies.length !== 0 ||
      selectedProjects.length !== 0
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: `This connection is being used in ${
          selectedTriggers.length !== 0 || selectedProjects.length !== 0
            ? `${selectedTriggers.map((trigger) => `'${trigger.name}'`).join(', ')} ${
                selectedProjects &&
                selectedProjects.map((project) => `'${project.value}'`).join(', ')
              } triggers`
            : ''
        }
      ${
        selectedPolicies.length !== 0
          ? `${selectedPolicies.map((policy) => `'${policy.name}'`).join(', ')} policies`
          : ''
      } 
      `
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    setSecrets([]);
    await onFormUpdate(true); // delete process
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!connectionName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteConnection(connectionName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${connectionName} was deleted.`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
      }
    );
  };

  //Create a new key
  const onNewKeyClick = (evt) => {
    evt.preventDefault();
    setNewKeyControl(!newKeyControl);
  };

  useEffect(() => {
    fetchKeys(formModel.name).then(({ success, payload }) => {
      if (!success) {
        return;
      }

      const { secrets } = payload;
      const secretsSHH = secrets.filter((item) => item.kind === 'jira-credential');
      setSecrets(secretsSHH);
    });
  }, []);

  //Change Input
  const onChangeInputCredentialKey = (value, field) => {
    if (field === 'name' || field === 'description') {
      setFormModelJiraKey({
        ...formModelJiraKey,
        [field]: value
      });
    } else {
      setFormModelJiraKey({
        ...formModelJiraKey,
        jiraCredentialInfo: {
          ...formModelJiraKey.jiraCredentialInfo,
          [field]: value
        }
      });
    }
  };

  // clear form
  const clearFormCredential = () => {
    setFormModelJiraKey(InitialCredentialFormModel);
  };

  const onFormSubmitSecretCredential = (e) => {
    e.preventDefault();

    const obj = {
      name: formModelJiraKey.name.trim() === '',
      username: formModelJiraKey.jiraCredentialInfo.username.trim() === '',
      password: formModelJiraKey.jiraCredentialInfo.password.trim() === ''
    };

    setFormErrorModelJiraCredential(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onJiraCredentialFormSave();
    }
  };

  const onJiraCredentialFormSave = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(formModelJiraKey.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There’s an existing key with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { success: successAdd } = await addNewSecret(formModelJiraKey);

      if (successAdd) {
        toast({
          title: 'Successfully',
          description: 'New JIRA Credential key save was successful'
        });
        setNewKeyControl(!newKeyControl);
        setViewState(globalViewStates.IDLE);
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        const secretsCREDENTIALS = secrets.filter((item) => item.kind === 'jira-credential');
        setSecrets(secretsCREDENTIALS);
        setFormModelJiraKey(InitialCredentialFormModel);
      });
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/connections" />;
  }

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {connectionName ? 'UPDATE JIRA CONNECTION' : 'NEW JIRA CONNECTION'}
            </h2>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Connection Name *
                </label>

                <input
                  name="name"
                  type="text"
                  required
                  disabled={connectionName}
                  readOnly={connectionName}
                  maxLength="250"
                  placeholder=""
                  className={`${connectionName ? 'input-main-disabled' : 'input-main'} ${
                    formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                  }`}
                  value={connectionName ? connectionName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />
                <span className="label-second">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required and name is
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              {/*  Description  */}
              <div>
                <label htmlFor="description" className="label-main">
                  Description (optional)
                </label>

                <input
                  type="text"
                  name="description"
                  id="description"
                  className="input-main"
                  value={formModel.description}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'description');
                  }}
                />
              </div>

              {/* Jira Url */}
              <div>
                <label htmlFor="baseUrl" className="label-main">
                  Jira URL *
                </label>

                <input
                  type="text"
                  name="baseUrl"
                  id="baseUrl"
                  className="input-main"
                  value={formModel.jiraConnectionInfo.baseUrl}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'baseUrl');
                  }}
                />
              </div>

              <div>
                <label htmlFor="secret" className="label-main">
                  Jira Credential *
                </label>

                <select
                  id="secret"
                  className={`input-main`}
                  value={formModel.secret}
                  required
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'secret');
                  }}
                >
                  {secrets.length === 0 ? (
                    <option>There is no authentication record.</option>
                  ) : (
                    <option value="">select an existing key</option>
                  )}

                  {secrets &&
                    secrets.map((key) => {
                      return (
                        <option value={key.name} key={key.name}>
                          {key.name}
                        </option>
                      );
                    })}
                </select>

                {formErrorModel.secret && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                <div className="my-4 flex">
                  <span className="label-main mr-3 mt-5 text-center">or</span>
                  <button onClick={onNewKeyClick} className="input-main !w-auto">
                    create a new key
                  </button>
                </div>

                {newKeyControl && (
                  <>
                    <div className="create-new-key relative my-5 rounded  border p-5 dark:border-gray-700">
                      <h3 className="absolute -top-2.5 left-1/2 -translate-x-1/2 bg-white">
                        {' '}
                        NEW JIRA CREDENTIAL KEY{' '}
                      </h3>

                      <div className="flex flex-col gap-6">
                        <div>
                          <label htmlFor="sshName" className="label-main">
                            Name *
                          </label>

                          <input
                            value={formModelJiraKey.name}
                            name="name"
                            placeholder="e.g., pk-hogwarts"
                            maxLength="250"
                            required
                            className="input-main mb-1.5"
                            onChange={(e) => {
                              onChangeInputCredentialKey(e.target.value, 'name');
                            }}
                          />

                          <span className="label-second">
                            Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                          </span>

                          {formErrorModelJiraCredential.name && (
                            <span className="label-second my-1 block !text-red-500">
                              This area is required!
                            </span>
                          )}
                        </div>

                        <div>
                          <label htmlFor="description" className="label-main">
                            Description
                          </label>

                          <input
                            value={formModelJiraKey.description}
                            name="description"
                            className="input-main mb-1.5"
                            onChange={(e) => {
                              onChangeInputCredentialKey(e.target.value, 'description');
                            }}
                          />
                        </div>

                        <div>
                          <label htmlFor="username" className="label-main">
                            Username *
                          </label>

                          <input
                            id="username"
                            name="username"
                            required
                            placeholder=""
                            className={`input-main`}
                            value={formModelJiraKey.jiraCredentialInfo.username}
                            onChange={(e) => {
                              onChangeInputCredentialKey(e.target.value, 'username');
                            }}
                          />

                          {formErrorModelJiraCredential.username && (
                            <span className="label-second my-1 block !text-red-500">
                              This area is required!
                            </span>
                          )}
                        </div>

                        <div>
                          <label htmlFor="password" className="label-main">
                            Password *
                          </label>

                          <input
                            id="password"
                            name="password"
                            type="password"
                            required
                            placeholder=""
                            className={`input-main`}
                            value={formModelJiraKey.jiraCredentialInfo.password}
                            onChange={(e) => {
                              onChangeInputCredentialKey(e.target.value, 'password');
                            }}
                          />
                        </div>
                        {formErrorModelJiraCredential.password && (
                          <span className="label-second my-1 block !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>

                      <div className="flex justify-center">
                        <button type="button" className="btn-danger" onClick={clearFormCredential}>
                          Clear
                        </button>
                        <button
                          type="button"
                          className="btn-success"
                          onClick={onFormSubmitSecretCredential}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {connectionName && (
                <div>
                  <h2 className="mb-2 text-xl font-extrabold">Environment Variables</h2>
                  <ConnectionEnvironment connectionName={connectionName} />
                </div>
              )}
              {userState.userRole.role !== userRoles.READ_ONLY && connectionName && (
                <>
                  <h2 className="py-6 text-xl font-extrabold">Danger Zone</h2>

                  <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                    <p className="label-second">
                      By clicking the button below you delete this connection. This action is{' '}
                      <strong>irreversible</strong>.
                    </p>
                    <p className="label-second mb-4">
                      You can still re-use this connection's secrets and commands in other
                      connections.
                    </p>
                    <button
                      type="button"
                      onClick={onDeleteClick}
                      className={` text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                    >
                      I understand the consequences. Delete this connection
                    </button>
                  </div>
                </>
              )}

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="flex justify-center">
                  <button type="button" className="btn-danger" onClick={clearForm}>
                    Clear
                  </button>
                  {connectionName ? (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('UPDATE', e)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      data-id="rest-connection-form-save"
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('SAVE', e)}
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this connection?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConnectionNewJira;

import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';

const fetchData = async () => {
  try {
    const { success, payload, reason } = await sendFetchTriggersRequest();

    if (!success) {
      if (reason) {
        return { success, reason, payload: { triggers: [], info: {} } };
      }

      return { success: false, payload: { triggers: [], info: {} } };
    }

    if (!payload) {
      return { success: false, payload: { triggers: [], info: {} } };
    }

    let { triggers, info } = payload;

    if (!triggers) {
      return { success: false, payload: { triggers: [], info: {} } };
    }

    triggers = triggers.filter((trigger) => trigger.kind === 'link');

    return { success: true, payload: { triggers, info } };
  } catch (ex) {
    console.log(ex);

    return { success: false, payload: { triggers: [], info: {} } };
  }
};

export { fetchData };

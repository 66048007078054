import axios from 'axios';
import { urls } from '../../../../constants';

const updateUrl = (inUrl, key) => {
  return inUrl.replace(/%link%/, key);
};

const sendDeleteLinkRequest = async ({ name }) => {
  try {
    const linkName = name.trim();
    if (!linkName) {
      return {
        success: false
      };
    }

    const result = await axios.delete(updateUrl(urls.URL_WORKSPACE_TRIGGER_LINK, linkName), {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' },
      params: {},
      data: {}
    });

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const sendUpdateLinkRequest = async ({
  name,
  kind,
  description,
  commands,
  connections,
  workflows,
  triggerInfo
}) => {
  try {
    const linkName = name.trim();
    if (!linkName) {
      return {
        success: false
      };
    }

    const linkCommands = commands || "";
    const linkConnections = connections || "";
    const linkWorkflows = workflows || "";
    const linkDescription = description;
    const linkTriggerInfo = triggerInfo || {};

    const result = await axios.put(
      updateUrl(urls.URL_WORKSPACE_TRIGGER_LINK, linkName),
      {
        name: linkName,
        description: linkDescription,
        connections: linkConnections,
        commands: linkCommands,
        workflows:linkWorkflows,
        kind,
        linkTriggerInfo
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    const { email, fullName } = data;

    return {
      success: true,
      payload: {
        email,
        fullName
      }
    };
  } catch (ex) {
    // Handle.
    console.log(ex);

    return {
      success: false
    };
  }
};

const sendUpdateRegenarateUrl = async ({ name }) => {
  try {
    const linkName = name.trim();
    if (!linkName) {
      return {
        success: false
      };
    }
    const regenerateUrl = updateUrl(urls.URL_WORKSPACE_TRIGGER_LINK_REGENERATEURL, linkName);
    const result = await axios.put(
      regenerateUrl,
      {},
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const linkUrl = result.data.url;

    if (!linkUrl) {
      return {
        success: false
      };
    }

    return {
      success: true,
      payload: {
        linkUrl
      }
    };
  } catch (ex) {
    // Handle.
    console.log(ex);

    return {
      success: false
    };
  }
};

export { sendUpdateLinkRequest, sendDeleteLinkRequest, sendUpdateRegenarateUrl };

import React, { useEffect, useState } from 'react';
import { addNewSecret } from './io';
import { fetchData } from '../KeyList/io';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BiCopy } from 'react-icons/bi';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useToast } from 'hooks/use-toast';

const KeyNewAwsRoleBaseAuth = ({ isInnerForm = false, setIsInnerFormSuccess = null }) => {
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, defaultValues }
  } = useForm({
    defaultValues: {
      name: '',
      kind: 'aws-role-base',
      description: '',
      awsRoleBaseInfo: {
        roleArn: '',
        externalId: ''
      }
    }
  });
  const navigate = useNavigate();
  const [secretList, setSecretList] = useState([]);
  const [uniqueNameError, setUniqueNameError] = useState(false);

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { secrets } = payload;
        setSecretList(secrets);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  const checkIfNameExists = (name) => {
    if (name !== '') {
      if (secretList.find((secret) => secret.name.toLowerCase() === name.toLowerCase())) {
        setUniqueNameError(true);
      } else {
        setUniqueNameError(false);
      }
    }
  };

  //copy url
  const copyToClipboard = async (command) => {
    try {
      await navigator.clipboard.writeText(command);
      toast({
        title: 'Successfully',
        description: 'Copied to clipboard'
      });
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW AWS ROLE BASED AUTH</h2>
          <div className="mb-4 rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm leading-5 text-blue-700">
                  For more information please checkout the
                  <a href=" https://docs.ob2.ai/opsbeacon/aws-cross-account-role" target="_blank">
                    <u> How to add AWS Cross Account Role</u>
                  </a>{' '}
                  page.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 ">
            <form
              onSubmit={handleSubmit(
                async (data) => {
                  if (!uniqueNameError) {
                    const { success: successAdd } = await addNewSecret(data);

                    if (!successAdd) {
                      toast({
                        variant: 'destructive',
                        title: 'Uh oh! Something went wrong.',
                        description: 'There was a problem. Please try again later.'
                      });
                      return;
                    } else {
                      toast({
                        title: 'Successfully',
                        description: 'New secret added'
                      });
                      if (!isInnerForm) {
                        navigate('/secrets');
                      } else {
                        setIsInnerFormSuccess(true);
                      }
                    }
                  }
                },
                (error) => {
                  console.log(error);
                }
              )}
            >

              <div>
                <label htmlFor="name" className="label-main">
                  Name
                </label>

                <input
                  {...register('name', {
                    required: 'This area is required!',
                    onChange: (e) => {
                      checkIfNameExists(e.target.value);
                    }
                  })}
                  className={`input-main`}
                />

                {errors.name !== undefined && errors.name.type === 'required' && (
                  <span className="label-second my-1 block !text-red-500">
                    {errors.name.message}
                  </span>
                )}

                {uniqueNameError && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${defaultValues.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>
                <input type="text" className="input-main" {...register('description')} />
              </div>

              <div>
                <label htmlFor="externalId" className="label-main">
                  ExternalId
                </label>
                <input
                  type="text"
                  className="input-main"
                  {...register('awsRoleBaseInfo.externalId')}
                />
              </div>

              <div>
                <label htmlFor="name" className="label-main">
                  RoleArn (Role Amazon Resource Name)
                </label>

                <input
                  {...register('awsRoleBaseInfo.roleArn', {
                    required: 'This area is required!'
                  })}
                  className={`input-main`}
                />
                {errors.awsRoleBaseInfo !== undefined &&
                  errors.awsRoleBaseInfo.roleArn.type === 'required' && (
                    <span className="label-second my-1 block !text-red-500">
                      {errors.awsRoleBaseInfo.roleArn.message}
                    </span>
                  )}
              </div>
              <div className="my-4">
                <div className="agent-instruction">
                  <div className="agent-instruction-topbar">
                    <div>JSON OF THE TRUST POLICY</div>
                    <div
                      className="agent-copy"
                      onClick={() =>
                        copyToClipboard(`{
                            "Version": "2012-10-17",
                            "Statement": [
                                {
                                    "Effect": "Allow",
                                    "Principal": {
                                        "AWS": "arn:aws:iam::264817245310:root"
                                    },
                                    "Action": "sts:AssumeRole",
                                    "Condition": {}
                                }
                            ]
                        }`)
                      }
                    >
                      <BiCopy className="h-5 w-5 text-white" />
                    </div>
                  </div>
                  <div className="agent-instruction-body">
                    <div className="codeBlock">
                      <div className="codeBlock-scroll">
                        <div class="codeBlock-lines">
                          <div>1</div>
                          <div>2</div>
                          <div>3</div>
                          <div>4</div>
                          <div>5</div>
                          <div>6</div>
                          <div>7</div>
                          <div>8</div>
                          <div>9</div>
                          <div>10</div>
                          <div>11</div>
                          <div>12</div>
                          <div>13</div>
                        </div>
                        <pre className="codeBlock-pre !min-h-min">
                          <code className="language-bash">
                            <span className="comment-line-heading">{`{`}</span>
                            <span className="comment-line-heading comment-line-margin">
                              {`"Version": "2012-10-17",`}
                            </span>
                            <span className="comment-line-heading comment-line-margin">
                              {`"Statement": [`}
                            </span>
                            <span className="comment-line-heading comment-line-margin">{`{`}</span>
                            <span className="comment-line-heading comment-line-margin">{`"Effect": "Allow",`}</span>
                            <span className="comment-line-heading comment-line-margin">{`"Principal": {`}</span>
                            <span className="comment-line-heading comment-line-margin !ml-12">{`"AWS": "arn:aws:iam::264817245310:root"`}</span>
                            <span className="comment-line-heading comment-line-margin">{`},`}</span>
                            <span className="comment-line-heading comment-line-margin">{`"Action": "sts:AssumeRole",`}</span>
                            <span className="comment-line-heading comment-line-margin">{`"Condition": {}`}</span>
                            <span className="comment-line-heading comment-line-margin">{`}`}</span>
                            <span className="comment-line-heading comment-line-margin">{`]`}</span>
                            <span className="comment-line-heading">{`}`}</span>
                          </code>
                        </pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <button type="button" className="btn-danger" onClick={() => reset()}>
                  Clear
                </button>

                <button type="submit" className="btn-success">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyNewAwsRoleBaseAuth;

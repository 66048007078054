import { TrashIcon } from '@heroicons/react/20/solid';
import { userRoles } from 'constants/index';
import React from 'react';
import { useSelector } from 'react-redux';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function GridView({ list, deleteSecret }) {
  const userState = useSelector((state) => state.userState);
  return (
    <div>
      {list.length > 0 ? (
        <ul className="mt-3 grid grid-cols-2 gap-4 xl:grid-cols-4">
          {list.map((item, i) => (
            <li
              className="col-span-1 flex h-20 rounded-md shadow-sm"
            >
        
              <div
                className={classNames(
                  item.bgColor,
                  'flex w-12 flex-shrink-0 items-center justify-center rounded-l-md border-b border-r border-t border-gray-200 text-center text-sm font-medium uppercase'
                )}
                style={{
                  backgroundColor:
                    (item.kind === 'sql-credential' && 'rgb(240 253 250)') ||
                    (item.kind === 'aws-credential' && 'rgb(254 252 232)') ||
                    (item.kind === 'ssh' && 'rgb(250 245 255)') ||
                    (item.kind === 'rest' && 'rgb(240 249 255)') ||
                    (item.kind === 'credential' && 'rgb(240 249 255)') ||
                    (item.kind === 'bearer-token' && 'rgb(240 249 255)') ||
                    (item.kind === 'rest-token-auth' && 'rgb(240 249 255)') ||
                    (item.kind === 'ssh-username' && 'rgb(250 245 255)') ||
                    (item.kind === 'jira-credential' && 'rgb(12,102,228,0.15)') ||
                    (item.kind === 'aws-role-base' && 'rgb(254 252 232)') ||
                    (item.kind === 'jira-credential' && 'rgb(120,102,228,0.15)'),
                  color:
                    (item.kind === 'sql-credential' && ' rgb(15 118 110)') ||
                    (item.kind === 'aws-credential' && 'rgb(161 98 7)') ||
                    (item.kind === 'ssh' && 'rgb(126 34 206)') ||
                    (item.kind === 'ssh-username' && 'rgb(126 34 206)') ||
                    (item.kind === 'rest' && 'rgb(3 105 161)') ||
                    (item.kind === 'credential' && 'rgb(3 105 161)') ||
                    (item.kind === 'bearer-token' && 'rgb(3 105 161)') ||
                    (item.kind === 'rest-token-auth' && 'rgb(3 105 161)') ||
                    (item.kind === 'jira-credential' && 'rgb(12,102,228,1)') ||
                    (item.kind === 'aws-role-base' && 'rgb(161 98 7)') ||
                    (item.kind === 'jira-credential' && 'rgb(120,102,228,1)')
                }}
              >
                {(item.kind === 'sql-credential' && 'SQL') ||
                  (item.kind === 'ssh-username' && 'SSH') ||
                  (item.kind === 'bearer-token' && 'TOKEN') ||
                  (item.kind === 'rest-token-auth' && 'REST') ||
                  (item.kind === 'ssh' && 'SSH') ||
                  (item.kind === 'aws-credential' && 'AWS') ||
                  (item.kind === 'credential' && 'BASIC') ||
                  (item.kind === 'rest' && 'API KEY') ||
                  (item.kind === 'jira-credential' && 'JIRA') ||
                  (item.kind === 'aws-role-base' && 'ARB') ||
                  (item.kind === 'cms-credential' && 'CMS')}
              </div>
              <div className="command-card">
                <div className="flex flex-1 items-center justify-between px-4 py-4">
                  <div>
                    <h2 className="text-md dark:text-blue-400">{item.name}</h2>
                    <span className="text-md text-gray-500">
                      {item.connections.slice(0, 1).map((p) => {
                        return (
                          <span
                            key={p}
                            className="btn-second mt-2"
                            style={{
                              color:
                                (item.kind === 'sql-credential' && ' rgb(15 118 110)') ||
                                (item.kind === 'aws-credential' && 'rgb(161 98 7)') ||
                                (item.kind === 'ssh' && 'rgb(126 34 206)') ||
                                (item.kind === 'ssh-username' && 'rgb(126 34 206)') ||
                                (item.kind === 'rest' && 'rgb(3 105 161)') ||
                                (item.kind === 'credential' && 'rgb(3 105 161)') ||
                                (item.kind === 'bearer-token' && 'rgb(3 105 161)') ||
                                (item.kind === 'rest-token-auth' && 'rgb(3 105 161)') ||
                                (item.kind === 'jira-credential' && 'rgb(12,102,228,1)') ||
                                (item.kind === 'aws-role-base' && 'rgb(161 98 7)') ||
                                (item.kind === 'cms-credential' && 'rgb(120,102,228,1)')
                            }}
                          >
                            {p}
                          </span>
                        );
                      })}
                    </span>
                  </div>
                  <div className="relative text-right">
                    {userState.userRole.role !== userRoles.READ_ONLY && (
                      <TrashIcon onClick={() => deleteSecret(item.name, item.connections)} className={`${item.connections.length > 0 ? 'text-red-500 cursor-pointer hover:bg-gray-100 rounded-full' : 'text-red-200 cursor-default'} w-6 h-6 p-1`}/>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="my-48 flex justify-center text-2xl text-gray-400">No items found</div>
      )}
    </div>
  );
}

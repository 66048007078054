import React from 'react';
import { useState } from 'react';
import LoginFooter from './LoginFooter';
import LoginHeader from 'features/login/LoginHeader';
import { globalViewStates } from 'constants';
import logo from '../../assets/img/opsbeacon-light-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { ForgotPasswordRequest } from './network';
import { useToast } from 'hooks/use-toast';

const ForgotPassword = () => {
  const [viewState, setViewState] = useState(globalViewStates.DEFAULT);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { toast } = useToast();

  const onFormSubmit = async (evt) => {
    evt.preventDefault();

    const sanitizedEmail = email.trim();
    const atSign = email.includes('@');

    if (!sanitizedEmail || !atSign) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'Please provide a valid email'
      });
      return;
    }

    try {
      const result = await ForgotPasswordRequest({
        email: sanitizedEmail
      });

      void result;
      toast({
        title: 'Successfully',
        description:'We have sent an email to you. Please check your inbox.'
      });

      setTimeout(function () {
        //navigate(-1); // go back - you can use -1 -2 ...
        navigate('/', { replace: true });
      }, 1000);

      setViewState(globalViewStates.SUCCESS);
    } catch (err) {
      console.error(err);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem. Please try again later.'
      });
    }
  };

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 pb-48 sm:px-6 lg:px-8">
     <Link to="/" className="flex w-full items-center justify-center">
      <img src={logo} width={210} alt="Opsbeacon Logo" />
    </Link>

      <div className="m-5 mt-8 bg-white px-4 py-8 shadow sm:mx-auto sm:w-full sm:max-w-md sm:rounded-lg sm:px-10">
        <LoginHeader title={'Forgot Password'} description={'We will get you in in no-time.'} />

        <form onSubmit={onFormSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email *
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                type="email"
                placeholder="e.g., hermionie.granger@hogwarts.magic"
                value={email}
                onChange={(onChange) => setEmail(onChange.target.value.trim())}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <button className="btn-login" type='submit'>
                Send Password Reset Mail
              </button>
            </span>
          </div>
        </form>

        <p className="mt-6 block text-slate-400">
          You will receive a link to create a new password via email.
        </p>

        <div className="relative flex items-center py-8">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 flex-shrink text-sm text-gray-500">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div>
          <span className="block w-full rounded-md shadow-sm">
            <Link className="btn-main-outline !w-full" to="/">
              Sign In
            </Link>
          </span>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default ForgotPassword;

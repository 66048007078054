export const IconIntegrationAgent = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 200.000000 200.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M360 1783 c-47 -24 -77 -73 -85 -136 -4 -29 -5 -205 -3 -392 3 -319
4 -342 23 -374 11 -19 33 -43 48 -55 27 -20 42 -21 277 -24 217 -3 250 -1 264
13 20 21 21 49 0 69 -13 14 -50 16 -248 16 -167 0 -235 3 -244 12 -9 9 -12 84
-12 275 l0 263 620 0 620 0 0 -263 c0 -191 -3 -266 -12 -275 -9 -9 -77 -12
-244 -12 -198 0 -235 -2 -248 -16 -21 -20 -20 -48 0 -69 14 -14 47 -16 264
-13 235 3 250 4 277 24 15 12 37 36 48 55 19 32 20 52 20 419 0 367 -1 387
-20 419 -11 19 -33 43 -48 55 -28 21 -36 21 -645 24 -572 2 -620 1 -652 -15z
m1248 -95 c7 -7 12 -39 12 -75 l0 -63 -620 0 -620 0 0 63 c0 36 5 68 12 75 17
17 1199 17 1216 0z"
        />
        <path
          d="M442 1658 c-15 -15 -15 -51 0 -66 28 -28 78 -7 78 33 0 27 -18 45
-45 45 -12 0 -26 -5 -33 -12z"
        />
        <path
          d="M592 1658 c-15 -15 -15 -51 0 -66 28 -28 78 -7 78 33 0 12 -5 26 -12
33 -7 7 -21 12 -33 12 -12 0 -26 -5 -33 -12z"
        />
        <path
          d="M742 1658 c-28 -28 -7 -78 33 -78 12 0 26 5 33 12 7 7 12 21 12 33 0
12 -5 26 -12 33 -7 7 -21 12 -33 12 -12 0 -26 -5 -33 -12z"
        />
        <path
          d="M919 1093 c-70 -66 -81 -95 -50 -126 18 -19 25 -20 51 -10 l30 11 0
-218 0 -218 -30 11 c-26 10 -33 9 -51 -10 -15 -15 -20 -28 -16 -45 9 -33 120
-138 147 -138 27 0 138 105 147 138 4 17 -1 30 -16 45 -18 19 -25 20 -51 10
l-30 -11 0 218 0 218 30 -11 c26 -10 33 -9 51 10 15 15 20 28 16 45 -9 33
-120 138 -147 138 -12 0 -46 -24 -81 -57z"
        />
        <path
          d="M260 682 c-73 -36 -97 -115 -88 -283 6 -96 22 -137 71 -173 28 -21
32 -21 757 -21 725 0 729 0 757 21 58 44 68 75 68 224 0 149 -10 180 -68 224
-27 20 -41 21 -327 24 -263 2 -300 1 -314 -13 -20 -21 -20 -49 0 -69 13 -14
54 -16 298 -16 206 0 285 -3 294 -12 17 -17 17 -259 0 -276 -17 -17 -1399 -17
-1416 0 -17 17 -17 259 0 276 9 9 88 12 294 12 244 0 285 2 298 16 20 20 20
48 0 68 -13 14 -55 16 -302 16 -253 0 -291 -2 -322 -18z"
        />
        <path
          d="M1242 488 c-15 -15 -15 -61 0 -76 15 -15 51 -15 66 0 7 7 12 24 12
38 0 14 -5 31 -12 38 -15 15 -51 15 -66 0z"
        />
        <path
          d="M1392 488 c-7 -7 -12 -24 -12 -38 0 -31 17 -50 45 -50 28 0 45 19 45
50 0 31 -17 50 -45 50 -12 0 -26 -5 -33 -12z"
        />
        <path
          d="M1542 488 c-7 -7 -12 -24 -12 -38 0 -31 17 -50 45 -50 28 0 45 19 45
50 0 31 -17 50 -45 50 -12 0 -26 -5 -33 -12z"
        />
      </g>
    </svg>
  );
};

import { useState } from 'react';
import { BsArrowRepeat, BsClock, BsThreeDots } from 'react-icons/bs';
import { resendInvitation } from './network';
import { useToast } from 'hooks/use-toast';

export default function ListView({ list, rowClick }) {
  const { toast } = useToast();
  const [preventNavigation, setPreventNavigation] = useState(true);
  const [resend, setResend] = useState(false);
  let invitationClick = false;

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="bg-secondary text-xs uppercase text-white dark:bg-gray-700 dark:text-gray-400">
          <tr className="bg-secondary">
            <th scope="col" className="px-6 py-3 text-white">
              User name
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              Mail
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              Role
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              Status
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((item, i) => (
              <tr
                onClick={() => rowClick(item)}
                className="cursor-pointer border-b bg-white hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {item.fullName ? item.fullName : '-'}
                </th>
                <td className="px-6 py-4">{item.email ? item.email : '-'}</td>
                <td className="px-6 py-4 font-black">{item.role}</td>
                <td className="px-6 py-4 font-black text-green-600">
                  {item.status === 'active' ? (
                    item.status
                  ) : (
                    <div className="flex flex-col gap-0.5">
                      <div className="flex items-center gap-1 text-secondary">
                        <BsClock /> Invite Pending
                      </div>
                      <div
                        className="my-1 flex w-fit items-center gap-0.5 rounded-lg border border-blue-600 px-1 text-secondary hover:bg-secondary hover:text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          setPreventNavigation(true);
                          setResend(true);
                          invitationClick = true;
                          resendInvitation({ email: item.email }).then(({ result }) => {
                            toast({
                              title: 'Successfully',
                              description: 'Re-invitation has been sent to user'
                            });
                          });
                        }}
                      >
                        <BsArrowRepeat />
                        Resend
                      </div>
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 text-right">
                  <BsThreeDots className="h-6 w-6 px-1 font-medium text-secondary" />
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-transparent hover:bg-transparent">
              <th className="whitespace-nowrap px-6 py-8 font-medium text-gray-900 dark:text-white">
                No user found
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { globalViewStates, postMethods } from '../../../constants';
import { Link, Navigate, useParams } from 'react-router-dom';
import { addOrUpdateWorkflow } from './io';
import { BiPlus } from 'react-icons/bi';
import { useToast } from 'hooks/use-toast';
import { fetchWorkflowDataDetail, deleteWorkflow } from '../WorkflowList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import CommandArgument from 'components/CommandArgument';
import { nanoid } from 'nanoid';
import {
  createCommandArgumentObject,
  isInvalidArguments,
  validateArguments
} from 'features/command/commandNew/helpers/argumentHelper';
import { fetchWorkflowsData } from './io';
import ApprovalWorkflow from 'components/ApprovalWorkflow';
import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';
import { fetchPolicies } from 'features/user/io';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import ReactCodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { githubDark } from '@uiw/codemirror-theme-github';

const initialFormModel = {
  name: '',
  description: '',
  kind: 'script',
  arguments: [],
  workflowScriptInfo: {
    script: 'def lambda_handler(ob,event): \n\n\n\n',
    state: 'Active'
  },
  approvalGroup: '',
  metadata: {}
};

const WorkflowLambda = () => {
  const { toast } = useToast();
  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [showModal, setShowModal] = useState(false);
  const [submit, setIsSubmit] = useState(false);
  const [postMethodType, setPostMethodType] = useState();
  const [argumentObjects, setArgumentObjects] = useState([]);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    steps: false
  });
  const params = useParams();
  const workflowName = params.workflowName ? params.workflowName : null;
  const [workflowList, setWorkflowList] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const userState = useSelector((state) => state.userState);
  const [script, setScript] = useState('');

  useEffect(() => {
    sendFetchTriggersRequest().then(({ success, payload }) => {
      const { triggers } = payload;
      setTriggers(triggers);
    });
    fetchPolicies().then(({ success, payload }) => {
      const { policies } = payload;
      setPolicies(policies);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.workflows && policy.workflows.includes(workflowName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, workflowName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.workflows && trigger.workflows.includes(workflowName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, workflowName]);

  useEffect(() => {
    if (workflowName) {
      fetchWorkflowDataDetail(workflowName).then(
        ({ success, payload }) => {
          if (success) {
            const { workflow } = payload;
            const temporaryForm = {
              name: workflow.name,
              description: workflow.description,
              kind: workflow.kind,
              approvalGroup: workflow.approvalGroup,
              arguments: createCommandArgumentObject(workflow.arguments),
              metadata: workflow.metadata,
              workflowScriptInfo: {
                script: workflow.workflowScriptInfo.script,
                state: workflow.workflowScriptInfo.state
              }
            };
            setArgumentObjects(temporaryForm.arguments);
            setFormModel(temporaryForm);
            setViewState(globalViewStates.DONE);
          }
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [workflowName]);

  const validateForm = (formModel, workflowList, postMethodType) => {
    const nameError = postMethodType === postMethods.UPDATE ? false : formModel.name.trim() === '';
    const uniqueNameError =
      postMethodType === postMethods.UPDATE
        ? false
        : workflowList.some(
            (workflow) => workflow.name.toLowerCase() === formModel.name.toLowerCase()
          );

    return {
      name: nameError,
      uniqueName: uniqueNameError
    };
  };

  const showErrorToast = () => {
    toast({
      variant: 'destructive',
      title: 'Uh oh! Something went wrong.',
      description: 'Please fill the required areas!'
    });
  };

  useEffect(() => {
    if (!submit) return;

    const formErrors = validateForm(formModel, workflowList, postMethodType);
    setFormErrorModel(formErrors);

    let hasErrors = Object.values(formErrors).includes(true);

    if (argumentObjects !== undefined) {
      const validatedArguments = validateArguments(argumentObjects);
      setArgumentObjects([...validatedArguments]);
      hasErrors = hasErrors || isInvalidArguments(validatedArguments);
    }

    if (hasErrors) {
      showErrorToast();
      setIsSubmit(false);
      return;
    }

    if (postMethodType === postMethods.SAVE) {
      onFormSave();
    } else if (postMethodType === postMethods.UPDATE) {
      onFormUpdate();
    }

    setIsSubmit(false);
  }, [formModel]);

  useEffect(() => {
    fetchWorkflowsData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { workflows } = payload;
        setWorkflowList(workflows);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!workflowName) {
        if (
          workflowList.find(
            (workflow) => workflow.name.toLowerCase() === formModel.name.toLowerCase()
          )
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  }, [formModel.name, workflowList]);

  const onFormSubmit = (type, e) => {
    e.preventDefault();
    setPostMethodType(type);
    setIsSubmit(true);

    const newArguments =
      argumentObjects !== undefined
        ? argumentObjects.map((argument) => ({
            name: argument.name,
            default:
              typeof argument.default == 'boolean' ? argument.default.toString() : argument.default,
            shortName: argument.shortName,
            type: argument.type,
            required: argument.required
          }))
        : [];

    setFormModel({
      ...formModel,
      arguments: [...newArguments],
      metadata: {}
    });
  };

  const onChangeInput = (value, field, e) => {
    if (field === 'name' || value === '') {
      const trimmedValue = value.trim();
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const onFormSave = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateWorkflow(
        {
          ...formModel
        },
        workflowName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem updating your workflow. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'Added new workflow.'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem updating your workflow. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateWorkflow(
        {
          ...formModel
        },
        workflowName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem updating your workflow. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'Workflow updated.'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem updating your workflow. Please try again later.'
      });
    }
  };

  const addArgument = (e) => {
    e.preventDefault();
    setArgumentObjects([
      ...argumentObjects,
      {
        id: nanoid(),
        name: '',
        shortName: '',
        required: false,
        type: '',
        default: '',
        invalidName: false,
        invalidDefault: false,
        invalidType: false,
        invalidMessage: '',
        duplicatedName: true
      }
    ]);
  };

  const removeArgument = (removedArgumentId) => {
    const filteredArguments = argumentObjects.filter(
      (argument) => argument.id !== removedArgumentId
    );
    setArgumentObjects(filteredArguments);
  };

  const handleChangedArgument = (updatedArgument) => {
    const updatedArgumentObjectIndex = argumentObjects.findIndex(
      (argument) => argument.id === updatedArgument.id
    );

    const copyArgumentObjects = argumentObjects;
    copyArgumentObjects.splice(updatedArgumentObjectIndex, 1, updatedArgument);
    setArgumentObjects([...copyArgumentObjects]);
  };

  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  const onDeleteClick = async (evt) => {
    evt.preventDefault();

    if (selectedTriggers.length !== 0 || selectedPolicies.length !== 0) {
      const triggerNames =
        selectedTriggers.length !== 0
          ? selectedTriggers.map((trigger) => `'${trigger.name}'`).join(', ')
          : '';
      const policyNames =
        selectedPolicies.length !== 0
          ? selectedPolicies.map((policy) => `'${policy.name}'`).join(', ')
          : '';

      let description = '';
      if (triggerNames) {
        description += `This command is being used in ${triggerNames} triggers`;
      }
      if (policyNames) {
        description += (description ? ' and ' : '') + `${policyNames} policies`;
      }

      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!workflowName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteWorkflow(workflowName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${workflowName} was deleted.`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again!'
        });
      }
    );
  };

  const handleScriptChange = (value) => {
    setScript(value);
    setFormModel({
      ...formModel,
      workflowScriptInfo: { script: value }
    });
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/workflows" />;
  }

  const disabled = viewState === globalViewStates.POSTING;
  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <div className="flex flex-col gap-5">
      <div className="contanier">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {workflowName ? 'UPDATE LAMBDA' : 'NEW LAMBDA'}
            </h2>
            <div className="mb-4 rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm leading-5 text-blue-700">
                    For more information please checkout the
                    <Link to=" https://docs.ob2.ai/opsbeacon/args-in-cmd-workflow" target="_blank">
                      <u> How to use arguments in commands and workflows</u>
                    </Link>{' '}
                    page.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Name *
                </label>

                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  disabled={disabled || workflowName}
                  readOnly={workflowName}
                  maxLength="250"
                  placeholder="e.g., restart-cluster"
                  className={`${btnExtraClassName} ${
                    workflowName ? 'input-main-disabled' : 'input-main'
                  } ${formErrorModel.uniqueName ? 'border-2 border-red-500 bg-red-500' : ''}`}
                  value={workflowName ? workflowName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />

                <span className="label-second">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>

                <input
                  name="description"
                  id="description"
                  placeholder="worfklow description"
                  value={formModel.description}
                  disabled={disabled}
                  className={`${btnExtraClassName} input-main`}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'description');
                  }}
                />
              </div>
              {/* Script Section */}
              <div className="w-full">
                <label htmlFor="command" className="label-main">
                  Script
                </label>
                <div className="relative w-full py-2.5">
                  <ReactCodeMirror
                    value={formModel.workflowScriptInfo.script}
                    extensions={[python()]}
                    onChange={handleScriptChange}
                    theme={githubDark}
                    options={{
                      lineNumbers: true,
                      mode: 'python'
                    }}
                    placeholder="Command line can be modified as needed. So, you can use either hardcoded or dynamic parameters."
                  />
                </div>
                {formErrorModel.command && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
              </div>

              {/* Arguments */}
              <div className="sm:col-span-6">
                <label
                  htmlFor="command"
                  className="label-main mb-3 block border-b pb-1 dark:border-gray-700"
                >
                  Arguments
                </label>

                {argumentObjects?.length > 0 && (
                  <>
                    <div className="command-arguments !pb-0">
                      <div className="w-full">
                        <label className="label-main">Name:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Type:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Default Value:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Required:</label>
                      </div>
                    </div>
                    {argumentObjects?.map((argument) => (
                      <CommandArgument
                        key={argument.id}
                        argumentObject={argument}
                        onRemove={removeArgument}
                        onChange={handleChangedArgument}
                      />
                    ))}
                  </>
                )}
                <span className="label-second">
                  {`You can determine the dynamic parameters in this section. You must specify this argument parameter as {{ argName }} in argument section if you want to use.`}
                  <br></br>
                  {`The value will be replace with the user input via triggers. For example, in Slack trigger:
@obot workflow workflowName --myArgument 1234 the value of {{ myArgument }} in command text will be set to 1234 at run time`}
                </span>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mt-1 flex items-center rounded-md border p-1.5 px-2 text-blue-500 hover:bg-blue-300 hover:text-blue-900 dark:border-gray-700"
                  onClick={addArgument}
                >
                  <BiPlus className="mr-1 !text-base" /> Add New
                </button>
              </div>
              {/* Approval Workflow */}
              <ApprovalWorkflow
                defaultGroup={{ value: formModel.approvalGroup, label: formModel.approvalGroup }}
                onChange={(approvalGroup) => {
                  setFormModel({
                    ...formModel,
                    approvalGroup: approvalGroup
                  });
                }}
              />

              {userState.userRole.role !== userRoles.READ_ONLY && workflowName && (
                <>
                  <h2 className="text-xl font-extrabold">Danger Zone</h2>

                  <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                    <p className="label-second">
                      By clicking the button below you delete this workflow. This action is{' '}
                      <strong>irreversible</strong>.
                    </p>
                    <p className="label-second mb-4">
                      You can still re-use this workflows connections and commands in other
                      workflows.
                    </p>
                    <button
                      disabled={disabled}
                      type="button"
                      onClick={onDeleteClick}
                      className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                    >
                      I understand the consequences. Delete this workflow
                    </button>
                  </div>
                </>
              )}

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="mt-8 flex justify-center">
                  <button type="button" className="btn-danger" onClick={clearForm}>
                    Clear
                  </button>

                  {workflowName ? (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('UPDATE', e)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('SAVE', e)}
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} size={'md'} closeButton={true} setShow={setShowModal}>
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this workflow?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WorkflowLambda;

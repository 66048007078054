import { useEffect, useState } from 'react';
import ListView from './ListView';
import { getEnvironments } from './network';

export const ConnectionEnvironment = ({ connectionName }) => {
  const [environments, setEnvironments] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    if (connectionName || isRefresh) {
      getEnvironments({ connectionName: connectionName }).then(({ success, payload }) => {
        if (success) {
          setIsRefresh(false);
          setEnvironments(payload.environments);
        }
      });
    }
  }, [connectionName, isRefresh]);

  return <ListView list={environments} rowClick={() => {}} setRefresh={setIsRefresh} />;
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  debugger: false,
  debuggerSize: 250,
};

const debuggerSlice = createSlice({
  name: 'debugger',
  initialState,
  reducers: {
    setDebugger: (state, action) => {
      state.debugger = action.payload;
    },
    setDebuggerSize: (state, action) => {
      state.debuggerSize = action.payload;
    }
  }
});

export const { setDebugger,setDebuggerSize } = debuggerSlice.actions;
export default debuggerSlice.reducer;

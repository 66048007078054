/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const LoginWrapper = ({ children }) => (
  <div className="main-wrapper">
    <div className="main-row" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {children}
    </div>
  </div>
);

export default LoginWrapper;

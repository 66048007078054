import axios from 'axios';
import { urls } from 'constants/index';

const updateUrl = (inUrl, linkId) => {
  return inUrl.replace(/%link%/, linkId);
};

const sendFetchLinkRequest = async (linkName) => {
  try {
    const url = updateUrl(urls.URL_WORKSPACE_TRIGGER_LINK, linkName);

    const result = await axios.get(url, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return { success: false, payload: {} };
    }

    return { success: true, payload: { info: data } };
  } catch (ex) {
    console.log('sendFetchLinkRequest:ex', ex);
    return { success: false, payload: {} };
  }
};

export { sendFetchLinkRequest };

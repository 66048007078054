import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import LogListSkeleton from 'components/skeleton/eventLogs/LogList';
import { getAuditLogs } from 'features/log/io';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

const statuses = {
  cron: '!text-secondary !bg-secondary/10',
  workflow: '!text-green-400 !bg-green-400/10',
  slack: '!text-rose-400 !bg-rose-400/10',
  app: '!text-amber-400 !bg-amber-400/10',
  webhook: '!text-purple-400 !bg-purple-400/10',
  api: '!text-red-400 !bg-red-400/10',
  agent: '!text-gray-400 !bg-gray-400/10'
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function List({
  tableData,
  totalPages,
  startDate,
  endDate,
  dataCount,
  selectedLogKind,
  loading,
  searchedLogs
}) {
  const [data, setData] = useState([]);
  const [newPageCount, setNewPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [newDataCount, setNewDataCount] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Set current page to 1 when any type changes
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [selectedLogKind, searchedLogs]);

  useEffect(() => {
    // fetch event log data
    const fetchData = async () => {
      try {
        let logsData = [];
        setSearchLoading(true);
        const { success, payload } = await getAuditLogs({
          startDate: startDate,
          endDate: endDate,
          page: currentPage,
          limit: 10,
          orderBy: 'timestamp',
          direction: 'desc',
          logTypes: selectedLogKind.join(','),
          containsText: searchedLogs
        });
        if (success) {
          logsData = payload.logs || []; //if logs null, set to empty array
          setNewDataCount(payload.dataCount);
          setNewPageCount(Math.ceil(payload.dataCount / 10));
        }
        setData(logsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setSearchLoading(false);
      }
    };

    // We expect it to update asynchronously
    const timeout = setTimeout(() => {
      fetchData();
    }, 0);

    return () => clearTimeout(timeout); // clear timeout
  }, [currentPage, selectedLogKind, tableData, searchedLogs, startDate, endDate]);

  const handlePageClick = async (event) => {
    const selectedPage = event.selected + 1;
    setCurrentPage(selectedPage);
  };

  const getAuditLogKindDesc = (log) => {
    return log.logKind === 'workflow'
      ? log.workflowName
      : log.connection + ' ' + log.command + ' ' + log.commandArgs;
  };

  const handleAuditLogDetailsPage = (logId) => {
    navigate(`/audit-logs/${logId}`);
  };

  return (
    <div className="flex w-full flex-col md:w-3/4">
      <ul className="divide-y rounded-lg border shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] dark:divide-white/10 dark:border-gray-100/20">
        {loading || searchLoading ? (
          <LogListSkeleton />
        ) : data !== null && data.length > 0 ? (
          <>
            {data.map((log, index) => (
              <>
                <li
                  key={index}
                  onClick={() => handleAuditLogDetailsPage(log.id)}
                  className="relative flex cursor-pointer items-center space-x-4 p-1 hover:bg-gray-200"
                >
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-center gap-x-3">
                      <div
                        className={classNames(
                          statuses[log.logKind],
                          'flex-none rounded-full bg-amber-400/10 p-1 text-amber-400'
                        )}
                      >
                        <div className="h-2 w-2 rounded-full bg-current" />
                      </div>
                      <h2 className="min-w-0 text-sm font-semibold leading-6">
                        <a href={log.href} className="flex gap-x-2">
                          <span className="truncate text-lg">
                            {log.logKind ? log.logKind : 'Slack'}
                          </span>
                          <span className="absolute inset-0" />
                        </a>
                      </h2>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
                      <div className="block items-center gap-1 lg:flex flex-wrap">
                        <div className="flex items-center gap-1">
                          <p className="eventlog-description"> {log.userEmail}</p>
                          <p className="eventlog-description">triggered</p>
                          <p className="eventlog-description text-[#374151]">
                            {' '}
                            {getAuditLogKindDesc(log)}
                          </p>
                        </div>
                        <div className="flex items-center gap-1">
                          <p className="eventlog-description"> at UTC</p>
                          <p> {log.timestamp.slice(0, 10)}</p>
                          <p>{log.timestamp.slice(11, 19)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span
                    className={`${
                      log.executionResult
                        ? 'bg-green-50 text-green-700 ring-green-600/40'
                        : 'bg-red-50 text-red-700 ring-red-600/40'
                    } "inline-flex font-medium" flex-shrink-0 items-center  rounded-full px-1.5 py-0.5 text-xs ring-1 ring-inset"`}
                  >
                    {log.executionResult ? 'Success' : 'Failed'}
                  </span>
                  <ChevronRightIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </li>
              </>
            ))}
            <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
              <p className="text-[13px] font-bold text-gray-400">
                Showing page <span className="text-[#374151] dark:text-white">{currentPage}</span>{' '}
                of <span className="text-[#374151] dark:text-white">{newDataCount}</span> results
              </p>
              <ReactPaginate
                className="isolate inline-flex items-center gap-1 -space-x-px rounded-md border shadow-sm"
                nextLabel={<ChevronRightIcon className="h-4 w-4" />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={newPageCount}
                previousLabel={<ChevronLeftIcon className="h-4 w-4" />}
                pageClassName="px-2 py-1"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                disabledClassName="text-gray-400"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="bg-secondary text-white"
                renderOnZeroPageCount={null}
                forcePage={currentPage - 1}
              />
            </div>
          </>
        ) : (
          <div className="flex h-56 items-center justify-center text-lg text-gray-400">
            It looks like you don’t have any data.
          </div>
        )}
      </ul>
    </div>
  );
}

import axios from 'axios';

import { urls } from 'constants/index';

const sendFetchWorkflowsRequest = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_WORKFLOWS, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { workflows: [] }
      };
    }

    const { workflows } = data;

    if (!workflows) {
      return {
        success: false,
        payload: { workflows: [] }
      };
    }

    return {
      success: true,
      payload: {
        workflows
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { workflows: [] } };
  }
};

const sendFetchWorkflowDetailRequest = async (workflowName) => {
  try {
    const result = await axios.get(
      `${urls.URL_WORKSPACE_WORKFLOWS}${workflowName ? '/' + workflowName : ''}`,
      {
        withCredentials: true,
        data: {},
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { workflow: [] }
      };
    }

    const { workflow } = data;

    if (!workflow) {
      return {
        success: false,
        payload: { workflow: [] }
      };
    }

    return {
      success: true,
      payload: {
        workflow
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { workflow: [] } };
  }
};

export { sendFetchWorkflowsRequest, sendFetchWorkflowDetailRequest };

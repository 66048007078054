import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchData } from './io';
import LinkEdit from '../../TriggerEdit/LinkEdit';

const LinkEditBody = () => {
  const params = useParams(); // get param
  const linkName = params.linkName;

  const [title, setTitle] = useState('…');
  const [link, setLink] = useState({});
  const [commands, setCommands] = useState([]);
  const [connections, setConnections] = useState([]);
  const [workflows, setWorkflows] = useState([]);

  useEffect(() => {
    fetchData({ linkName }).then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        setTitle(`Edit “${payload.trigger.name}”`);
        setLink(payload.trigger);
        setCommands(payload.commands);
        setConnections(payload.connections);
        setWorkflows(payload.workflows);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [linkName]);

  return (
    <LinkEdit link={link} commands={commands} connections={connections} title={title} workflows={workflows} />
  );
};

export default LinkEditBody;

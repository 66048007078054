import React from 'react';
import { BiCopy } from 'react-icons/bi';
import { useToast} from 'hooks/use-toast'

export default function CopyToClipboard({ itemTopCopy, copyClass, toastText }) {
  const toast = useToast();
  const copyToClipboard = async (itemTopCopy) => {
    try {
      await navigator.clipboard.writeText(itemTopCopy);
      toast({
        title: 'Successfully',
        description: `${toastText}`
      });
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };
  return (
    <BiCopy
      onClick={() => copyToClipboard(itemTopCopy)}
      className={`${copyClass} right-4 top-8 h-5 w-5 cursor-pointer p-0.5 text-gray-700 hover:rounded-md hover:bg-gray-200 group-hover:block`}
    />
  );
}

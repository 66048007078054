import { FlagsProvider } from 'contexts/FlagProvider';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const StripePlanStatusGuard = ({ children }) => {
  const userState = useSelector((state) => state.userState);

  return userState.stripeInfo?.status === 'active' ||
    userState.stripeInfo?.status === 'trialing' ? (
    <FlagsProvider value={userState.featureFlags}>{React.cloneElement(children)}</FlagsProvider>
  ) : (
    <Navigate to="/pricing" />
  );
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';

const sendSaveNewProjectRequest = async ({ name, description }) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_PROJECTS,
      {
        project: name,
        description
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export { sendSaveNewProjectRequest };

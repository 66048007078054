/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

import styled from './styled';

const LoginFormActivationWrap = ({ children, onSubmit }) => (
  <styled.section>
    <styled.form onSubmit={onSubmit}>{children}</styled.form>
  </styled.section>
);

export default LoginFormActivationWrap;

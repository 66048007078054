import React, { Component } from 'react';

class MainTitle extends Component {
  render() {
    const { title } = this.props;
    return <h1 className="title-main">{title}</h1>;
  }
}

export default MainTitle;

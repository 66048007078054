import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userRole: { fullName: '', role: '', onboarded: '', onboardedStep: '' }, // read-only  - operator  - admin
  currentCategory: { categoryName: 'Initial Category' },
  categories: [],
  isLoggedIn: false,
  stripeInfo: {},
  featureFlags: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setLoginState: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setStripeInfo: (state, action) => {
      state.stripeInfo = action.payload;

      const features = action.payload.features;
      const featureFlags = [];

      Object.entries(features).forEach((feature) => {
        const [key, value] = feature;
        if (typeof value == 'boolean') featureFlags.push({ name: key, isActive: value });
        else if (value === -1) featureFlags.push({ name: key, isActive: true });
        else featureFlags.push({ name: key, isActive: true, boundary: value });
      });
      state.featureFlags = featureFlags;
    }
  }
});

export const { setUserRole, setLoginState, setStripeInfo } = userSlice.actions;
export default userSlice.reducer;

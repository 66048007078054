/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchData } from './io';
import CronEdit from '../../TriggerEdit/CronEdit';

const CronEditBody = () => {
  const params = useParams(); // get param
  const cronName = params.cronName;

  const [title, setTitle] = useState('…');
  const [cron, setCron] = useState({});
  const [commands, setCommands] = useState([]);
  const [connections, setConnections] = useState([]);
  const [workflows, setWorkflows] = useState([]);

  useEffect(() => {
    fetchData({ cronName }).then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        setTitle(`Edit “${payload.trigger.name}”`);
        setCron(payload.trigger);
        setCommands(payload.commands);
        setConnections(payload.connections);
        setWorkflows(payload.workflows);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [cronName]);

  return <CronEdit cron={cron} commands={commands} connections={connections} title={title} workflows={workflows} />;
};

export default CronEditBody;

import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import dlv from 'dlv';
import { deleteAccount } from 'features/account/network';
import { useToast } from 'hooks/use-toast';
import { useNavigate } from 'react-router-dom';

export const DeleteVerificationModal = ({ showVerificationModal, setShowVerificationModal }) => {
  const oneRef = useRef();
  const twoRef = useRef();
  const threeRef = useRef();
  const fourRef = useRef();
  const fiveRef = useRef();
  const sixRef = useRef();
  const buttonRef = useRef();
  const { toast } = useToast();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      one: '',
      two: '',
      three: '',
      four: '',
      five: '',
      six: ''
    }
  });

  const { ref: refOne, ...restOne } = register('one', {
    required: true
  });

  const { ref: refTwo, ...restTwo } = register('two', {
    required: true
  });

  const { ref: refThree, ...restThree } = register('three', {
    required: true
  });

  const { ref: refFour, ...restFour } = register('four', {
    required: true
  });

  const { ref: refFive, ...restFive } = register('five', {
    required: true
  });

  const { ref: refSix, ...restSix } = register('six', {
    required: true
  });

  const navigate = useNavigate();

  const [open, setOpen] = useState(showVerificationModal);

  const userState = useSelector((state) => state.userState);

  useEffect(() => {
    setOpen(showVerificationModal);
  }, [showVerificationModal]);

  useEffect(() => {
    if (!open) {
      setShowVerificationModal(false);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
                  <div className="flex flex-col items-center justify-center space-y-2 text-center">
                    <div className="text-3xl font-semibold">
                      <p>Email Verification</p>
                    </div>
                    <div className="flex flex-row text-sm font-medium text-gray-400">
                      <p>We have sent a code to your email {userState.stripeInfo.email}</p>
                    </div>
                  </div>

                  <div>
                    <form
                      action=""
                      method="post"
                      onSubmit={handleSubmit(async (data) => {
                        const code = `${dlv(data, 'one')}${dlv(data, 'two')}${dlv(
                          data,
                          'three'
                        )}${dlv(data, 'four')}${dlv(data, 'five')}${dlv(data, 'six')}`;

                        const { success } = await deleteAccount({ code: code });

                        if (!success) {
                          toast({
                            variant: 'destructive',
                            title: 'Uh oh! Something went wrong.',
                            description: 'There was a problem. Try again later.'
                          });
                          return;
                        } else {
                          toast({
                            title: 'Successfully',
                            description: 'Account deleted.'
                          });
                          navigate('/logout');
                        }
                      })}
                    >
                      <div className="flex flex-col space-y-16">
                        <div className="mx-auto flex w-full max-w-xs flex-row items-center justify-between gap-2">
                          <div className="h-16 w-16 ">
                            <input
                              {...restOne}
                              ref={(e) => {
                                refOne(e);
                                oneRef.current = e;
                              }}
                              maxLength={1}
                              onChange={(e) => {
                                twoRef.current.focus();
                              }}
                              className="flex h-full w-full flex-col items-center justify-center rounded-xl border border-gray-200 bg-white px-2 text-center text-lg outline-none ring-blue-700 focus:bg-gray-50 focus:ring-1"
                            />
                          </div>
                          <div className="h-16 w-16 ">
                            <input
                              {...restTwo}
                              ref={(e) => {
                                refTwo(e);
                                twoRef.current = e;
                              }}
                              onChange={(e) => {
                                threeRef.current.focus();
                              }}
                              className="flex h-full w-full flex-col items-center justify-center rounded-xl border border-gray-200 bg-white px-2 text-center text-lg outline-none ring-blue-700 focus:bg-gray-50 focus:ring-1"
                            />
                          </div>
                          <div className="h-16 w-16 ">
                            <input
                              {...restThree}
                              ref={(e) => {
                                refThree(e);
                                threeRef.current = e;
                              }}
                              onChange={(e) => {
                                fourRef.current.focus();
                              }}
                              className="flex h-full w-full flex-col items-center justify-center rounded-xl border border-gray-200 bg-white px-2 text-center text-lg outline-none ring-blue-700 focus:bg-gray-50 focus:ring-1"
                            />
                          </div>
                          <div className="h-16 w-16 ">
                            <input
                              {...restFour}
                              ref={(e) => {
                                refFour(e);
                                fourRef.current = e;
                              }}
                              onChange={(e) => {
                                fiveRef.current.focus();
                              }}
                              className="flex h-full w-full flex-col items-center justify-center rounded-xl border border-gray-200 bg-white px-2 text-center text-lg outline-none ring-blue-700 focus:bg-gray-50 focus:ring-1"
                            />
                          </div>
                          <div className="h-16 w-16 ">
                            <input
                              {...restFive}
                              ref={(e) => {
                                refFive(e);
                                fiveRef.current = e;
                              }}
                              onChange={(e) => {
                                sixRef.current.focus();
                              }}
                              className="flex h-full w-full flex-col items-center justify-center rounded-xl border border-gray-200 bg-white px-2 text-center text-lg outline-none ring-blue-700 focus:bg-gray-50 focus:ring-1"
                            />
                          </div>
                          <div className="h-16 w-16 ">
                            <input
                              {...restSix}
                              ref={(e) => {
                                refSix(e);
                                sixRef.current = e;
                              }}
                              onChange={(e) => {
                                buttonRef.current.focus();
                              }}
                              className="flex h-full w-full flex-col items-center justify-center rounded-xl border border-gray-200 bg-white px-2 text-center text-lg outline-none ring-blue-700 focus:bg-gray-50 focus:ring-1"
                            />
                          </div>
                        </div>

                        <div className="flex flex-col space-y-5">
                          <div>
                            <button
                              ref={buttonRef}
                              className="flex w-full flex-row items-center justify-center rounded-xl border border-none bg-red-700 py-5 text-center text-sm text-white shadow-sm outline-none"
                            >
                              Delete Workspace
                            </button>
                          </div>

                          {/* <div className="flex flex-row items-center justify-center space-x-1 text-center text-sm font-medium text-gray-500">
                            <p>Didn't recieve code?</p>{' '}
                            <a
                              className="flex flex-row items-center text-secondary"
                              href="http://"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Resend
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import React, { useEffect, useState } from 'react';
import { deleteKey, fetchData } from './io';
import { keyListViewStates } from 'constants/index';
import Loading from 'components/loading/Loading';
import ListTabs from 'components/listTabs/ListTabs';
import { secretTabOptions, createSecretsOptions } from 'assets/allData';
import Helper from 'components/help/Helper';
import secretImg from 'assets/img/secrets.png';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './ListView';
import GridView from './Gridview';
import { useToast } from 'hooks/use-toast';

const KeyListSsh = () => {
  const [viewState, setViewState] = useState(keyListViewStates.LOADING);
  const [keyList, setKeyList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedKinds, setSelectedKinds] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [layoutView, setLayoutView] = useState('list');
  const { toast } = useToast();

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    if (selectedKinds.length === 0) {
      setTableData(keyList);
    } else {
      let filteredDatas = [];
      selectedKinds.forEach((kind) => {
        filteredDatas = [...filteredDatas, ...keyList.filter((listItem) => listItem.kind === kind)]; // array.prototype.flat
      });
      setTableData(filteredDatas);
    }
  }, [selectedKinds, keyList]);

  useEffect(() => {
    let filteredDatas;
    if (selectedKinds.length > 0) {
      filteredDatas = keyList.filter((listItem) => selectedKinds.includes(listItem.kind));
    } else {
      filteredDatas = keyList;
    }
    filteredDatas = filteredDatas.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.kind.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, keyList, selectedKinds]);

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;

        setKeyList(secrets);
        setTableData(secrets);

        setViewState(keyListViewStates.DONE);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  const handleSelectedList = (selectedFilters) => {
    setSelectedKinds(selectedFilters);
  };

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  const deleteSecret = (keyToBeDeleted, connectedConnections) => {
    if (connectedConnections.length > 0) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:`This secret is being used in ${connectedConnections} connections`
      });
      return;
    }
    setViewState(keyListViewStates.EXECUTING);
    deleteKey(keyToBeDeleted).then(
      () => {
        const newKeyList = keyList.filter((item) => item.name !== keyToBeDeleted);
        setKeyList(newKeyList);
        setTableData(newKeyList);
        setViewState(keyListViewStates.DONE);
        toast({
          title: 'Successfully',
          description: 'Command successfully updated'
        });
      },
      (err) => {
        console.error('Failed to delete the key:', err);
        setViewState(keyListViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
      }
    );
  };

  if (viewState === keyListViewStates.LOADING) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Helper
        infoIcon={true}
        message={
          'Secrets store your credentials, providing secure authorization access for connections. You can choose from various authorization options to tailor them to your specific needs.'
        }
      />
      <ListTabs
        filtersKind={secretTabOptions}
        onSelectedFilterKind={handleSelectedList}
        handleSearch={handleSearch}
        creatableTypeOptions={createSecretsOptions}
      />
      {keyList.length === 0 ? (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="flex justify-center">
            <img src={secretImg} className="h-64 w-64" alt="create secret" />
          </div>
          <div
            style={{
              maxWidth: '510px',
              margin: '0 auto'
            }}
            className="!my-6 text-center"
          >
            <Helper
              message={
                'Secrets store your credentials, providing secure authorization access for connections. You can choose from various authorization options to tailor them to your specific needs.'
              }
            />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
          {layoutView === 'list' ? (
            <ListView
              list={tableData}
              deleteSecret={(keyToBeDeleted, connectedConnections) =>
                deleteSecret(keyToBeDeleted, connectedConnections)
              }
            />
          ) : (
            <GridView
              list={tableData}
              deleteSecret={(keyToBeDeleted, connectedConnections) =>
                deleteSecret(keyToBeDeleted, connectedConnections)
              }
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default KeyListSsh;

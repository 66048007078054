import {sendFetchWorkspaceSettings, sendUpdateWorkspaceSettingsRequest} from './network';

const fetchWorkspaceSettingsData = async () => {
  try {
    const { success, payload } = await sendFetchWorkspaceSettings();

    if (!payload || !success) {
      return { success: false, payload: { settings: [], workspaceLoginSettings: {} } };
    }
    
    const { settings = [], workspaceLoginSettings = {} } = payload;
    
    return { success: true, payload: { settings, workspaceLoginSettings } };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { settings: [], workspaceLoginSettings: {} } };
  }
};

const updateWorkspaceSettings = async ({
  workspaceSettings
}) => {
  try {
    const { success } = await sendUpdateWorkspaceSettingsRequest({
      workspaceSettings
    });

    if (!success) {
      return { success: false };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { fetchWorkspaceSettingsData,updateWorkspaceSettings };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import {
  sendCommandExistsRequest,
  sendAddOrUpdateCommandRequest,
  sendFetchGroupsRequest
} from './network';

import { sendFetchCommandsRequest } from 'network/sendFetchCommandsRequest';

const commandExists = async (key) => {
  try {
    const { success, payload } = await sendCommandExistsRequest(key);

    if (!success) {
      return { success: false, payload: { exists: true } };
    }

    const exists = payload.exists;

    return {
      success: true,
      payload: { exists }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { exists: true } };
  }
};

const addOrUpdateCommand = async (formModel, commandName) => {
  console.log(formModel);
  try {
    const { success } = await sendAddOrUpdateCommandRequest(formModel, commandName);

    return { success };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const fetchGroups = async () => {
  try {
    const { success, payload } = await sendFetchGroupsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          groups: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { groups: [] } };
    }

    const { groups } = payload;

    return {
      success: true,
      payload: { groups }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { group: [] } };
  }
};

const fetchCommandsData = async () => {
  try {
    const { success, payload } = await sendFetchCommandsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          commands: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { commands: [] } };
    }

    const { commands } = payload;

    return {
      success: true,
      payload: { commands }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { commands: [] } };
  }
};

export { commandExists, addOrUpdateCommand, fetchGroups, fetchCommandsData };

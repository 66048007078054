import LayoutView from 'components/LayoutView/LayoutView';
import ListTabs from 'components/listTabs/ListTabs';
import React, { useEffect, useState } from 'react';
import { getFiles } from './network';
import ListView from './ListView';
import { useNavigate } from 'react-router-dom';
import { GridView } from './GridView';

export const FileStorage = () => {
  const navigate = useNavigate();
  const [layoutView, setLayoutView] = useState('list');

  const [selectedKinds, setSelectedKinds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [tableData, setTableData] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  const handleSelectedList = (selectedFilters) => {
    setSelectedKinds(selectedFilters);
  };

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  useEffect(() => {
    if (refresh)
      getFiles().then(
        ({ success, payload }) => {
          if (!success) {
            return;
          }
          const { files } = payload;
          setFileList(files);
          setTableData(files);
          setRefresh(false);
        },
        (err) => {
          console.log('error', err);
        }
      );
  }, [refresh]);

  useEffect(() => {
    let filteredData = fileList.filter((listItem) =>
      listItem.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredData.length === 0) setTableData(fileList);
    else setTableData(filteredData);
  }, [searchTerm]);

  return (
    <React.Fragment>
      <ListTabs
        onSelectedFilterKind={handleSelectedList}
        handleSearch={handleSearch}
        handleAddClick={() => {
          navigate('../new/file');
        }}
      />
      <React.Fragment>
        <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
        {layoutView === 'list' ? (
          <ListView list={tableData} rowClick={(item) => {}} setRefresh={setRefresh} />
        ) : (
          <GridView list={tableData} rowClick={(item) => {}} setRefresh={setRefresh} />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger
} from 'components/ui/dropdown-menu';
import { Calendar } from 'lucide-react';
import { useState } from 'react';

export function DropdownMenus({ options, label, onChange }) {
  const [position, setPosition] = useState(options[0].label);
  
  const handleChange = (value) => {
    setPosition(value);
    const selectedOption = options.find(option => option.label === value);
    onChange(selectedOption);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <Calendar className='w-4 h-4' />
          {label}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuRadioGroup value={position} onValueChange={handleChange}> 
          {options.map((option, index) => {
            return (
              <DropdownMenuRadioItem key={index} value={option.label}>
                {option.label}
              </DropdownMenuRadioItem>
            );
          })}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

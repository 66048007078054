import { LinkIcon } from '@heroicons/react/20/solid';
import IconIntegrationSlack from 'components/icons/IconIntegrationSlack';
import IconIntegrationWebhooks from 'components/icons/IconIntegrationWebhooks';
import IconTriggerCron from 'components/icons/IconTriggerCron';
import { features } from 'constants/index';
import { Flags } from 'contexts/FlagProvider';
import { Link } from 'react-router-dom';

export default function SelectTrigger() {
  return (
    <div className="mx-auto my-2 max-w-3xl overflow-auto py-2">
      <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
        <h2 className="mb-4 mt-0 text-center text-base font-bold">SELECT TRIGGER</h2>
        <div className="flex items-center justify-center ">
          <Flags
            authorizedFlags={[features.WEBHOOK_TRIGGER]}
            renderOn={() => {
              return (
                <Link to="/triggers/webhook" className="trigger-card">
                  <div className="px-4 py-5 text-center sm:p-6">
                    <div>
                      <IconIntegrationWebhooks />
                    </div>
                    <div className="mb-2 mt-2 text-xl font-bold">Webhook</div>
                  </div>
                </Link>
              );
            }}
            renderOff={() => {
              return (
                <div className="has-tooltip">
                  <div className="grayscale-btn  px-4 py-5 text-center sm:p-6">
                    <div>
                      <IconIntegrationWebhooks />
                    </div>
                    <div className="mb-2 mt-2 text-xl font-bold">Webhook</div>
                  </div>
                  <Link to='/settings/subcription-plan' className='upgrade-plan'>
                    Upgrade Plan
                  </Link>
                </div>
              );
            }}
          />
          <Flags
            authorizedFlags={[features.CRON_TRIGGER]}
            renderOn={() => {
              return (
                <Link to="/triggers/cron" className="trigger-card">
                  <div className="px-4 py-5 text-center sm:p-6">
                    <div>
                      <IconTriggerCron />
                    </div>
                    <div className="mb-2 mt-2 text-xl font-bold">Cron</div>
                  </div>
                </Link>
              );
            }}
            renderOff={() => {
              return (
                <div className="has-tooltip">
                  <div className="grayscale-btn">
                    <div className="px-4 py-5 text-center sm:p-6">
                      <div>
                        <IconTriggerCron />
                      </div>
                      <div className="mb-2 mt-2 text-xl font-bold">Cron</div>
                    </div>
                  </div>
                  <Link to='/settings/subcription-plan' className='upgrade-plan'>
                    Upgrade Plan
                  </Link>
                </div>
              );
            }}
          />

          <Flags authorizedFlags={[features.SLACK_TRIGGER]}>
            <Link to="/projects" className="trigger-card">
              <div className="px-4 py-5 text-center sm:p-6">
                <div>
                  <IconIntegrationSlack />
                </div>
                <div className="mb-2 mt-2 text-xl font-bold">Slack</div>
              </div>
            </Link>
          </Flags>

            <Link to="/triggers/link" className="trigger-card">
              <div className="px-4 py-5 text-center sm:p-6">
                <div className='flex items-center justify-center'>
                  <LinkIcon className='text-secondary w-12 h-12'/>
                </div>
                <div className="mb-2 mt-2 text-xl font-bold">Link</div>
              </div>
            </Link>
        </div>
      </div>
    </div>
  );
}

import { roleMap } from 'constants/routesWithRoles';
import { useSelector } from 'react-redux';
import React from 'react';
import { Navigate } from 'react-router-dom';

export const UserRoleGuard = ({ children, routeRole }) => {
  const userState = useSelector((state) => state.userState);

  return roleMap.get(userState.userRole.role) >= roleMap.get(routeRole) ? (
    React.cloneElement(children)
  ) : (
    <Navigate to="/notFound" />
  );
};

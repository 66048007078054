import React from 'react';
import { Link } from 'react-router-dom';

import notfound_img from '../../assets/img/notfound.png';

const NotFound = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     //navigate(-1); // go back - you can use -1 -2 ...
  //     navigate('/', { replace: true });
  //   }, 2000);
  // }, [navigate]);

  return (
    <div className="not-found mt-24 flex flex-col items-center justify-center text-center">
      <div className="image-container">
        <img className="w-52" src={notfound_img} alt="notfound-page" />
      </div>

      <p className="mb-6 mt-3 text-3xl font-bold md:text-5xl">Page Not Found!</p>
      <p className="text-slate-400">
        Whoops, this is embarassing. <br /> Looks like the page you were looking for wasn't found.
      </p>

      <Link
        to="/"
        className=" mt-6 inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Back to Home
      </Link>

      <div className="footer-container mt-5 text-gray-400">
        {/*<p>You are being redirected to the Home Page.</p>  */}
      </div>
    </div>
  );
};

export default NotFound;

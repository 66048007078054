/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import {
  sendFetchCommandsRequest,
  sendFetchCommandDetailRequest
} from 'network/sendFetchCommandsRequest';

import { sendDeleteCommandRequest } from './network';

const fetchData = async () => {
  try {
    const { success, payload } = await sendFetchCommandsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          commands: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { commands: [] } };
    }

    const { commands } = payload;

    return {
      success: true,
      payload: { commands }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { commands: [] } };
  }
};

const fetchCommandDataDetail = async (commandName) => {
  try {
    const { success, payload } = await sendFetchCommandDetailRequest(commandName);

    if (!success) {
      return {
        success: false,
        payload: {
          command: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { command: [] } };
    }

    const { command } = payload;

    return {
      success: true,
      payload: { command }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { command: [] } };
  }
};

const deleteCommand = async (commandName) => {
  try {
    await sendDeleteCommandRequest(commandName);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

export { fetchData, fetchCommandDataDetail, deleteCommand };

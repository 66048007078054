import React, { useEffect, useState } from 'react';
import { fetchConnectionData } from './io';
import { connectionListViewStates } from 'constants/index';
import Loading from 'components/loading/Loading';
import { connectionTabOptions, createConnectionOptions, connectionsNavigationInfos } from 'assets/allData';
import Helper from 'components/help/Helper';
import ListTabs from 'components/listTabs/ListTabs';
import ListView from './ListView';
import { useNavigate } from 'react-router-dom';
import LayoutView from '../../../components/LayoutView/LayoutView';
import GridView from './GridView';

const ConnectionList = () => {
  const [viewState, setViewState] = useState(connectionListViewStates.LOADING);
  const [connectionList, setConnectionList] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedKinds, setSelectedKinds] = useState([]);

  const [tableData, setTableData] = useState([]);

  const [layoutView, setLayoutView] = useState('list');

  const navigate = useNavigate();

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    if (selectedKinds.length === 0) {
      setTableData(connectionList);
    } else {
      let filteredDatas = [];
      selectedKinds.forEach((kind) => {
        filteredDatas = [
          ...filteredDatas,
          ...connectionList.filter((listItem) => listItem.kind === kind)
        ]; // array.prototype.flat
      });
      setTableData(filteredDatas);
    }
  }, [selectedKinds, connectionList]);

  useEffect(() => {
    let filteredDatas;
    if (selectedKinds.length > 0) {
      filteredDatas = connectionList.filter((listItem) => selectedKinds.includes(listItem.kind));
    } else {
      filteredDatas = connectionList;
    }
    filteredDatas = filteredDatas.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.kind.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, connectionList, selectedKinds]);

  useEffect(() => {
    fetchConnectionData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }

        const { connections } = payload;

        setConnectionList(connections);
        setTableData(connections);

        setViewState(connectionListViewStates.DONE);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  if (viewState === connectionListViewStates.LOADING) {
    return <Loading />;
  }

  const handleSelectedList = (selectedFilters) => {
    setSelectedKinds(selectedFilters);
  };

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  }
  return (
    <React.Fragment>
      <Helper
        message={
          'Connections form the backbone of your OpsBeacon integration, linking your OpsBeacon environment to various external systems and resources. These can include SSH servers, AWS accounts, database servers, custom API endpoints, or on-premise worker nodes, also known as Agents.'
        }
        infoIcon={true}
      />
      <ListTabs
        filtersKind={connectionTabOptions}
        onSelectedFilterKind={handleSelectedList}
        handleSearch={handleSearch}
        creatableTypeOptions={createConnectionOptions}
      />
      {connectionList.length === 0 ? (
        <Helper
          content={true}
          contentTitle={'Click the connection type to add your first Connection below'}
          iconList={'connectionList'}
        />
      ) : (
        <React.Fragment>
          <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
         {layoutView === 'list' ? (
           <ListView
           list={tableData}
           rowClick={(item) => {
             const navigation = connectionsNavigationInfos.find((navigationInfo) => {
               return navigationInfo.type === item.kind;
             });
             navigate(`${navigation.createNewPath}/${item.name}`);
           }}
         />
         ):(
          <GridView
          list={tableData}
           rowClick={(item) => {
             const navigation = connectionsNavigationInfos.find((navigationInfo) => {
               return navigationInfo.type === item.kind;
             });
             navigate(`${navigation.createNewPath}/${item.name}`);
           }}
          />
         )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ConnectionList;

import { Fragment, useEffect, useState } from 'react';
import { Steps, useSteps } from 'react-step-builder';
import connections from 'assets/img/connections.png';
import commands from 'assets/img/commands.png';
import triggers from 'assets/img/triggers.png';
import configure from 'assets/img/configure.png';
import { useSelector } from 'react-redux';
import { updateUserOnboard } from 'network/sendOnboardingRequest';
import { useToast} from 'hooks/use-toast'
import {Link } from 'react-router-dom';

const stepsContent = [
  {
    title: 'Welcome to OpsBeacon',
    description:
      "You have begun your journey with us. Don't hesitate to reach out to us whenever you need assistance or have any questions. We're here to support you every step of the way. Now, let us introduce some key features of OpsBeacon to help you familiarize yourself with the platform."
  },
  {
    title: 'Connections',
    description:
      'Connections form the backbone of your OpsBeacon integration, linking your OpsBeacon environment to various external systems and resources. These can include SSH servers, AWS accounts, database servers, custom API endpoints, or on-premise worker nodes, also known as Agents.',
    image: connections
  },
  {
    title: 'Commands',
    description:
      'Commands are the fundamental units of your OpsBeacon workflow. Think of them as individual steps in your automation process, designed to execute specific actions on your established Connections.',
    image: commands
  },
  {
    title: 'Triggers',
    description:
      'Triggers are the catalysts that initiate the execution of your commands in OpsBeacon. They can be configured in various ways to suit your operational needs. You have the option to set up triggers based on Slack interactions, API-based webhooks, or schedules.',
    image: triggers
  },
  {
    title: 'Execution Policy',
    description:
      'This final step in OpsBeacon setup involves defining permissions and policies for your Commands, Connections, and Triggers. By default, every new automation is subject to a “Deny All” policy. To activate a command for user access, an administrator must explicitly include it in an execution policy.',
    image: configure
  },
  {
    description: 'You have now explored the key components of OpsBeacon. To ensure you get the most out of the platform, we invite you to schedule a complimentary 60-minute onboarding call. Please select a time that works for you here.'
  }
];

const initialModel = {
  onboarded: true,
  onboardedStep: ''
};

export default function OnboardModal() {
  const [onboardingModel, setOnboardingModel] = useState(initialModel);
  const { next, total, current } = useSteps();
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  useEffect(() => {
    const temporaryForm = {
      onboarded: userState.userRole.onboarded,
      onboardedStep: userState.userRole.onboardedStep
    };
    setOnboardingModel(temporaryForm);
  }, []);

  const completeOnboard = async () => {
    try {
      const { success: successAdd } = await updateUserOnboard({
        ...onboardingModel,
        onboarded: true
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem saving your onboarding. Please try again later.'
        });
        return;
      }
      setOnboardingModel({
        ...onboardingModel,
        onboarded: true
      });
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem saving your onboarding. Please try again later.'
      });
    }
  };

  return (
    onboardingModel.onboarded === false && (
      <Steps>
        {stepsContent.map((step, index) => (
          <div as={Fragment}>
            <div className="relative z-50">
              <div as={Fragment}>
                <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
              </div>
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div as={Fragment}>
                    <div className="relative transform overflow-hidden rounded-lg bg-white p-0 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                      <div className="relative isolate overflow-hidden bg-gray-200 px-6 pt-16 shadow-2xl sm:rounded-lg sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:pl-12 lg:pr-96 lg:pt-0">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                          aria-hidden="true"
                        >
                          <circle cx={512} cy={512} r={512} fill="#2563EB" fillOpacity="0.7" />
                          <defs>
                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                              <stop stopColor="#7775D6" />
                              <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                          </defs>
                        </svg>
                        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-16 lg:pb-32 lg:text-left">
                          <h2 className="relative text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
                            {step.title}
                            <span className="absolute top-0 flex h-12 w-12 opacity-5">
                              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-secondary"></span>
                              <span className="relative inline-flex h-8 w-8 rounded-full bg-secondary"></span>
                            </span>
                          </h2>
                          <p className="mt-6 text-[16px] leading-7 text-black">
                            {step.description}
                          </p>
                          {total === current &&
                          <Link
                          to="https://calendly.com/ob2ai/ob2-onboarding?back=1"
                          target="_blank"
                          className='text-blue-500 font-black text-[16px] leading-7'
                          >
                            Meet us 
                          </Link>
                          }
                          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                            <div
                              onClick={total === current ? () => completeOnboard() : next}
                              className="cursor-pointer rounded-md border bg-secondary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-blue-600 hover:bg-white hover:text-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                              {total === current
                                ? 'Get Started'
                                : current === 1
                                ? 'Start Onboarding'
                                : 'Next Step'}
                            </div>
                            <span className="text-black text-opacity-50">
                              {current} of {total}
                            </span>
                          </div>
                        </div>
                        {step.image ? (
                          <div className="relative mt-16 h-80 lg:mt-8">
                            <img
                              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                              src={step.image}
                              autoPlay={true}
                              alt={`${step.title} screenshot`}
                              width={1824}
                              height={1080}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Steps>
    )
  );
}

import { IoListOutline, IoGridOutline } from 'react-icons/io5';

export default function LayoutView({ changeLayout, layoutView }) {
  return (
    <div className="my-4 flex justify-end">
      <div className="inline-flex rounded-full border-[1px] border-secondary bg-secondary text-sm leading-none">
        <button
          onClick={()=> changeLayout('grid')}
          className={`${layoutView === 'grid' ? 'text-white font-black' : 'text-secondary bg-gray-100'} inline-flex items-center gap-2 rounded-r-full px-4 py-2 transition-colors duration-300 ease-in focus:outline-none rounded-full`}
          id="grid"
        >
          <IoGridOutline className="h-4 w-4" />
          <span>Grid</span>
        </button>
        <button
        onClick={()=> changeLayout('list')}
          className={`${layoutView === 'list' ? 'text-white font-black' : 'text-secondary bg-gray-100'} inline-flex items-center gap-2 rounded-r-full px-4 py-2 transition-colors duration-300 ease-in focus:outline-none`}
          id="list"
        >
          <IoListOutline className="h-4 w-4" />
          <span>List</span>
        </button>
      </div>
    </div>
  );
}

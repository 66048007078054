import { InformationCircleIcon } from '@heroicons/react/20/solid';
import IconIntegrationAws from 'components/icons/IconIntegrationAws';
import IconIntegrationMySql from 'components/icons/IconIntegrationMySql';
import IconIntegrationRest from 'components/icons/IconIntegrationRest';
import IconIntegrationSsh from 'components/icons/IconIntegrationSsh';
import { features } from 'constants/index';

import { Link } from 'react-router-dom';
import {
  commandInfoBASH,
  commandInfoREST,
  commandInfoAWS,
  commandInfoSQL,
  connectionInfoSSH,
  connectionInfoAWS,
  connectionInfoREST,
  connectionInfoSQL,
  commandInfoJIRA,
  connectionInfoJIRA,
  connectionInfoAGENT
} from 'assets/allData';
import { IconIntegrationAgent } from 'components/icons/IconIntegrationAgent';
import { Flags } from 'contexts/FlagProvider';
import IconIntegrationJira from 'components/icons/IconIntegrationJira';

const helpList = [
  {
    commandList: [
      {
        title: 'SSH',
        href: commandInfoBASH.createNewPath,
        icon: <IconIntegrationSsh />
      },
      {
        title: 'AWS',
        href: commandInfoAWS.createNewPath,
        icon: <IconIntegrationAws />
      },
      {
        title: 'REST',
        href: commandInfoREST.createNewPath,
        icon: <IconIntegrationRest />
      },
      {
        title: 'SQL',
        href: commandInfoSQL.createNewPath,
        icon: <IconIntegrationMySql />
      },
      {
        title: 'JIRA',
        href: commandInfoJIRA.createNewPath,
        icon: <IconIntegrationJira />
      }
    ]
  },
  {
    connectionList: [
      {
        title: 'SSH',
        href: connectionInfoSSH.createNewPath,
        icon: <IconIntegrationSsh />
      },
      {
        title: 'AWS',
        href: connectionInfoAWS.createNewPath,
        icon: <IconIntegrationAws />
      },
      {
        title: 'REST',
        href: connectionInfoREST.createNewPath,
        icon: <IconIntegrationRest />
      },
      {
        title: 'SQL',
        href: connectionInfoSQL.createNewPath,
        icon: <IconIntegrationMySql />
      },
      {
        title: 'JIRA',
        href: connectionInfoJIRA.createNewPath,
        icon: <IconIntegrationJira />
      },
      {
        title: 'AGENT',
        href: connectionInfoAGENT.createNewPath,
        icon: <IconIntegrationAgent />
      }
    ]
  }
];

export default function Helper(props) {
  return (
    <div className="help-container">
      {props.message && (
        <div className="help-message">
          {props.infoIcon === true && (
            <div className="w-fit">
              <InformationCircleIcon className="h-10 w-10 text-secondary" />
            </div>
          )}
          <p className="help-description">{props.message}</p>
        </div>
      )}
      {props.content === true ? (
        <div>
          <h2 className="py-4 text-center text-[18px] font-black text-black">
            {props.contentTitle}
          </h2>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
            {props.iconList === 'commandList'
              ? helpList[0].commandList.map((item) => (
                  <Link
                    to={item.href}
                    key={item.title}
                    className="rounded-lg border border-gray-200 bg-white p-4 hover:border-blue-600"
                  >
                    <div className="flex items-center justify-center">
                      <div className="m-0">{item.icon}</div>
                    </div>
                    <div className="mt-4 flex flex-col justify-center gap-1">
                      <h2 className="text-center text-sm font-black">{item.title}</h2>
                    </div>
                  </Link>
                ))
              : helpList[1].connectionList.map((item) =>
                  item.title !== 'AGENT' ? (
                    <Link
                      to={item.href}
                      key={item.title}
                      className="rounded-lg border border-gray-200 bg-white p-4 hover:border-blue-600"
                    >
                      <div className="flex items-center justify-center">
                        <div className="m-0">{item.icon}</div>
                      </div>
                      <div className="mt-4 flex flex-col  justify-center gap-1">
                        <h2 className="text-center text-sm font-black">{item.title}</h2>
                      </div>
                    </Link>
                  ) : (
                    <Flags
                      authorizedFlags={[features.AGENT_CONNECTION]}
                      key={item.title}
                      renderOn={() => {
                        return (
                          <Link
                            to={item.href}
                            className="rounded-lg border border-gray-200 bg-white p-4 hover:border-blue-600"
                          >
                            <div className="flex items-center justify-center">
                              <div className="m-0">{item.icon}</div>
                            </div>
                            <div className="mt-4 flex flex-col  justify-center gap-1">
                              <h2 className="text-center text-sm font-black">{item.title}</h2>
                            </div>
                          </Link>
                        );
                      }}
                      renderOff={() => {
                        return (
                          <div className="has-tooltip">
                            <div className="connection-grayscale">
                              <Link
                                to={item.href}
                                key={item.title}
                                className=" m-4 border-gray-200 bg-white hover:border-blue-600"
                              >
                                <div className="flex items-center justify-center">
                                  <div className="m-0">{item.icon}</div>
                                </div>
                                <div className="mt-4 flex flex-col  justify-center gap-1">
                                  <h2 className="text-center text-sm font-black">{item.title}</h2>
                                </div>
                              </Link>
                            </div>
                            <Link to="/settings/subcription-plan" className="upgrade-plan">
                              Upgrade Plan
                            </Link>
                          </div>
                        );
                      }}
                    />
                  )
                )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

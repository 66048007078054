import axios from 'axios';
import { urls } from 'constants/index';

const updateUrl = (inUrl, urlKey, urlParameter) => {
  return inUrl.replace(urlKey, urlParameter);
};

export const getApps = async () => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_APPS}`, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { internalApps: [] }
      };
    }

    const { internalApps } = data;

    if (!internalApps) {
      return {
        success: false,
        payload: { internalApps: [] }
      };
    }

    return {
      success: true,
      payload: {
        internalApps
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { internalApps: [] } };
  }
};

export const postApps = async ({ formModel }) => {
  try {
    const result = await axios.post(urls.URL_WORKSPACE_APPS, formModel, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    void result;

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const putApps = async ({ appId, formModel }) => {
  const url = updateUrl(urls.URL_WORKSPACE_APP_UPDATE, /%appId%/, appId);

  try {
    const result = await axios.put(url, formModel, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    void result;

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const deleteApp = async ({ appId }) => {
  const url = updateUrl(urls.URL_WORKSPACE_APP_DELETE, /%appId%/, appId);

  try {
    const result = await axios.delete(url, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    void result;

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);

    return { success: false };
  }
};

export const getAppDetail = async ({ appId }) => {
  try {
    const url = updateUrl(urls.URL_WORKSPACE_APP_DETAIL, /%appId%/, appId);
    const result = await axios.get(url, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { internalApp: {} }
      };
    }

    const { internalApp } = data;

    if (!internalApp) {
      return {
        success: false,
        payload: { internalApp: {} }
      };
    }

    return {
      success: true,
      payload: {
        internalApp
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { internalApp: {} } };
  }
};

export const postCommand = async ({ appId, command }) => {
  try {
    const url = updateUrl(urls.URL_TRIGGER_COMMAND, /%appId%/, appId);

    const result = await axios.post(
      url,
      {
        commandLine: command
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { response: data.response }
      };
    }

    const { success, response } = data;

    if (success) {
      return {
        success: true,
        payload: {
          response
        }
      };
    }

    return {
      success: false,
      payload: { response: data.response }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { response: '' } };
  }
};

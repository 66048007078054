import { useToast } from 'hooks/use-toast';
import { Actions } from './Actions';

export default function ListView({ list, deleteSecret }) {
  const { toast } = useToast();
  return (
    <div className="relative shadow-md sm:rounded-lg">
      <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="bg-secondary text-xs uppercase text-white dark:bg-gray-700 dark:text-gray-400">
          <tr className="bg-secondary">
            <th scope="col" className="px-6 py-3 text-white">
              Secret name
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              Description
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              Kind
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              Connections
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((item, i) => (
              <tr className="cursor-pointer border-b bg-white hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600">
                <th
                  scope="row"
                  className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {item.name}
                </th>
                <td className="px-6 py-4">{item.description ? item.description : '-'}</td>
                <td className="px-6 py-4 font-black">{item.kind}</td>
                <td className="px-6 py-4">
                  {item.connections.length > 0 ? `${item.connections[0]}` : '-'}
                </td>
                <td className="px-6 py-4 text-right">
                  <Actions
                    item={item}
                    onDelete={async () => {
                      const response = await deleteSecret(item.name, item.connections);
                      const { success } = response;
                      if (success) {
                        toast({
                          title: 'Successfully',
                          description: 'Secret successfully deleted'
                        });
                      } else {
                        toast({
                          variant: 'destructive',
                          title: 'Uh oh! Something went wrong.',
                          description: 'There was a problem. Try again later.'
                        });
                      }
                    }}
                    rightOption={true}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-transparent hover:bg-transparent">
              <th className="whitespace-nowrap px-6 py-8 font-medium text-gray-900 dark:text-white">
                No items found
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import onboardReducer from './slices/onboardSlice';
import userSlice from './slices/userSlice';
import triggerSlice from './slices/triggerSlice';
import sidebarSlice from './slices/toggleSidebar';
import accountSlice from './slices/accountSlice';
import debuggerSlice from './slices/debuggerSlice';
import executionCommandSlice from './slices/executionCommandSlice';

export const store = configureStore({
  reducer: {
    onboardState: onboardReducer,
    userState: userSlice,
    triggerState: triggerSlice,
    sidebarState: sidebarSlice,
    accountState: accountSlice,
    debuggerState: debuggerSlice,
    executionCommandState: executionCommandSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat()
});

import { getInvocations } from 'features/appDashboard/network';
import { useEffect, useState } from 'react';

export const useInvocationTableFetch = ({
  initialInvocationKind,
  initialStartDate,
  initialEndDate,
  initialPage,
  initialLimit,
  initialDirection,
  initialContainsText
}) => {
  const [invocationKind, setInvocationKind] = useState(initialInvocationKind);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);
  const [direction, setDirection] = useState(initialDirection);
  const [containsText, setContainsText] = useState(initialContainsText);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { success, payload } = await getInvocations({
          startDate: startDate,
          endDate: endDate,
          page: page,
          limit: limit,
          invocationKind: invocationKind,
          direction,
          containsText: containsText
        });
        if (success) {
          setData(payload);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [invocationKind, startDate, endDate, page, limit, direction, containsText]);

  return {
    data,
    loading,
    error,
    setInvocationKind,
    setStartDate,
    setEndDate,
    setPage,
    setLimit,
    setDirection,
    setContainsText
  };
};

import { sendFetchCommandsRequest } from 'network/sendFetchCommandsRequest';
import { sendFetchConnectionsRequest } from 'network/sendFetchConnectionsRequestForTriggers';
import { sendFetchWorkflowsRequest } from 'network/sendFetchWorkflowsRequest';
import { sendSaveNewWebhookRequest } from './network';

const fetchCommands = async () => {
  try {
    const { success, payload } = await sendFetchCommandsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          commands: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { commands: [] } };
    }

    const { commands } = payload;

    return {
      success: true,
      payload: { commands }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { commands: [] } };
  }
};

const fetchConnections = async () => {
  try {
    const { success, payload } = await sendFetchConnectionsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          connections: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { connections: [] } };
    }

    const { connections } = payload;

    return {
      success: true,
      payload: { connections }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { connections: [] } };
  }
};

const fetchWorkflows = async () => {
  try {
    const { success, payload } = await sendFetchWorkflowsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          workflows: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { workflows: [] } };
    }

    const { workflows } = payload;

    return {
      success: true,
      payload: { workflows }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { workflows: [] } };
  }
};

const saveNewWebhook = async ({
  kind,
  name,
  description,
  connections,
  commands,
  workflows,
  webHookTriggerInfo
}) => {
  try {
    const result = await sendSaveNewWebhookRequest({
      kind: 'webHook',
      name,
      description,
      connections,
      commands,
      workflows,
      webHookTriggerInfo: {}
    });

    if (result.success) {
      return { success: true, url: result.url };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};
export { fetchCommands, saveNewWebhook, fetchConnections, fetchWorkflows };

import Loading from 'components/loading/Loading';
import { policyListViewStates } from 'constants';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPolicyData } from './io';
import Helper from 'components/help/Helper';
import policyImg from 'assets/img/execution-policies.png';
import ListTabs from 'components/listTabs/ListTabs';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './ListView';
import GridView from './GridView';
import { policyNavigationInfos } from 'assets/allData';

function PolicyList() {
  const [viewState, setViewState] = useState(policyListViewStates.LOADING);
  const [policyList, setPolicyList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const [layoutView, setLayoutView] = useState('list');

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    fetchPolicyData().then(
      ({ success, payload }) => {
        void success;
        const { policies } = payload;
        setPolicyList(policies);
        setTableData(policies);
        setViewState(policyListViewStates.DONE);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    if (searchTerm !== '') {
      const filteredDatas = tableData.filter(
        (listItem) =>
          listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTableData(filteredDatas);
    } else {
      setTableData(policyList);
    }
  }, [searchTerm, policyList]);

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  if (viewState === policyListViewStates.LOADING) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {policyList.length !== 0 && (
        <Helper
          infoIcon={true}
          message={
            'This final step in OpsBeacon setup involves defining permissions and policies for your Commands, Connections, Workflows and Triggers. By default, every new automation is subject to a “Deny All” policy. To activate a command for user access, an administrator must explicitly include it in an execution policy.'
          }
        />
      )}
      <ListTabs
        handleSearch={handleSearch}
        handleAddClick={() => navigate(`${policyNavigationInfos.createNewPath}`)}
      />
      {policyList.length === 0 ? (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="flex justify-center">
              <img src={policyImg} className="h-64 w-64" alt="create policy" />
            </div>
            <div
              style={{
                maxWidth: '510px',
                margin: '0 auto'
              }}
              className="!my-6 text-center"
            >
              <Helper
                message={
                  'This final step in OpsBeacon setup involves defining permissions and policies for your Commands, Connections, Workflows and Triggers. By default, every new automation is subject to a “Deny All” policy. To activate a command for user access, an administrator must explicitly include it in an execution policy.'
                }
              />
            </div>
          </div>
        </>
      ) : (
        <React.Fragment>
          <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
          {layoutView === 'list' ? (
            <ListView
              list={tableData}
              rowClick={(item) => {
                navigate(`${policyNavigationInfos.path}/${item.name}`);
              }}
            />
          ) : (
            <GridView
              list={tableData}
              rowClick={(item) => {
                navigate(`${policyNavigationInfos.path}/${item.name}`);
              }}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default PolicyList;

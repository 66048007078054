import 'flatpickr/dist/themes/material_blue.css';

import { useContext, useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { CalendarIcon } from '@heroicons/react/20/solid';
import { SchedulePatternContext } from 'contexts/cronEdit/SchedulePatternContext';
import timezones from '../../features/triggers/TriggerNew/CronNew/timezones.json';

const timeMapping = [
  { label: 'OFF', value: null },
  { label: '15 Minutes', value: 15 },
  { label: '30 Minutes', value: 30 },
  { label: '45 Minutes', value: 45 },
  { label: '1 Hours', value: 60 },
  { label: '2 Hours', value: 120 },
  { label: '4 Hours', value: 240 }
];

function getValueBetweenParentheses(inputString) {
  const match = inputString.match(/\((.*?)\)/);

  if (match) {
    const valueBetweenParentheses = match[1];
    return valueBetweenParentheses;
  } else {
    return null;
  }
}

export default function OnTimeSchedule() {
  const { scheduleExpression, scheduleExpressionTimezone, maximumWindowInMinutes, setFormModel } =
    useContext(SchedulePatternContext);

  const [selectedMaximumWindowInMinutes, setSelectedMaximumWindowInMinutes] = useState('');
  const [selectedScheduleExpressionTimezone, setSelectedScheduleExpressionTimezone] = useState('');

  const [selectedDate, setSelectedDate] = useState();
  const [selectHours, setSelectHours] = useState('');

  const [isUpdate, setUpdate] = useState(false);

  const [flexibleTimeOptions, setflexibleTimeOptions] = useState(
    timeMapping.map((value) => value.label)
  );

  useEffect(() => {
    setSelectedMaximumWindowInMinutes(
      timeMapping.filter((option) => option.value === maximumWindowInMinutes)[0].label
    );
  }, [maximumWindowInMinutes]);

  useEffect(() => {
    setSelectedScheduleExpressionTimezone(scheduleExpressionTimezone);
  }, [scheduleExpressionTimezone]);

  useEffect(() => {
    setUpdate(false);

    if (scheduleExpression.startsWith('at')) {
      const onTimeExpression = getValueBetweenParentheses(scheduleExpression).split('T');
      setSelectedDate(new Date(onTimeExpression[0]));
      setSelectHours(onTimeExpression[1]);
    }
  }, [scheduleExpression]);

  useEffect(() => {
    if (selectedDate && selectHours && isUpdate) {
      const year = selectedDate?.getFullYear();
      let month = selectedDate?.getMonth() + 1;
      let date = selectedDate?.getDate();
      // Add '0' prefix if month or date is a single digit
      month = month < 10 ? `0${month}` : month;
      date = date < 10 ? `0${date}` : date;

      setFormModel((prevState) => {
        return {
          ...prevState,
          cronTriggerInfo: {
            ...prevState.cronTriggerInfo,
            scheduleExpression: `at(${year}-${month}-${date}T${selectHours})`
          }
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectHours, isUpdate]);

  return (
    <div className="py-2 px-4">
      <div>
        <h2 className="py-1 font-extrabold">Date and time</h2>
        <p className="text-gray-500">The date and time to invoke the target.</p>
      </div>
      <div className="flex w-full items-center gap-4 py-4">
        <div className="block">
          <div className="flex items-center gap-1">
            <Flatpickr
              value={selectedDate}
              onChange={(date) => {
                setSelectedDate(new Date(date));
                setUpdate(true);
              }}
              className="input-main"
              options={{ minDate: 'today' }}
            />
            <CalendarIcon className="h-6 w-6" />
          </div>
          <div>
            <span className="text-xs text-gray-500">YYYY/MM/DD</span>
          </div>
        </div>
        <div>
          <input
            name="hours"
            id="hours"
            type="text"
            placeholder="hh:mm"
            defaultValue={selectHours}
            onChange={(e) => {
              setSelectHours(e.target.value);
              setUpdate(true);
            }}
            className="input-main"
          />
          <span className="text-xs text-gray-500">Use 24-hour format timestamp</span>
        </div>
        <div>
          <select
            id="scheduleExpressionTimezone"
            name="scheduleExpressionTimezone"
            className="input-main "
            value={selectedScheduleExpressionTimezone}
            onChange={(e) => {
              setSelectedScheduleExpressionTimezone(e.target.value);
            }}
          >
            <option value="" disabled>
              Select a timezone
            </option>
            {timezones.map((timezone, index) => {
              return (
                <option key={index} value={timezone}>
                  {timezone}
                </option>
              );
            })}
          </select>

          <span className="text-xs text-gray-500">Timezone</span>
        </div>
      </div>
      <div className="mt-2">
        <h2 className="py-1 font-extrabold">Flexible time window</h2>
        <p className="text-gray-500">
          If you choose a flexbile window, Scheduler invokes your schedule within the time window
          you specify. For example, if you choose 15 minutes, your schedule runs within 15 minutes
          after the schedule start time.
        </p>
        <div className="mt-2">
          <select
            id="maximumWindowInMinutes"
            name="maximumWindowInMinutes"
            className="input-main w-1/2"
            value={selectedMaximumWindowInMinutes}
            onChange={(e) => {
              setFormModel((prevState) => {
                return {
                  ...prevState,
                  cronTriggerInfo: {
                    ...prevState.cronTriggerInfo,
                    flexibleTimeWindow: {
                      ...prevState.cronTriggerInfo.flexibleTimeWindow,
                      maximumWindowInMinutes: timeMapping.filter(
                        (options) => options.label === e.target.value
                      )[0].value
                    }
                  }
                };
              });
              setSelectedMaximumWindowInMinutes(e.target.value);
            }}
          >
            <option value="" disabled>
              Select a flexible time
            </option>
            {flexibleTimeOptions.map((twindow, index) => {
              return (
                <option key={index} value={twindow}>
                  {twindow}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

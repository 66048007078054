import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tourActive: false,
  run: true,
  stepIndex: 0,
  steps: [
    {
      target: '[data-id="secrets"]',
      content: <div>Secrets</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="new-secret"]',
      content: <div>New Secret</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="rest-option"]',
      content: <div>Rest Auth</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="rest-token-auth-form"]',
      content: <div>Rest Auth Form</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="rest-token-auth-form-save"]',
      content: <div>Rest Auth Form Save</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="secret-list"]',
      content: <div>Secret List</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="connections"]',
      content: <div>Connections</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="new-connection"]',
      content: <div>New Connection</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="rest-connection-option"]',
      content: <div>Rest Connection</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="rest-connection-form"]',
      content: <div>Rest Connection Form</div>,
      disableBeacon: true
    },
    {
      target: '[data-id="rest-connection-form-save"]',
      content: <div>Rest Auth Form Save</div>,
      disableBeacon: true
    }
  ],
  restTokenAuthGuideObject: {
    name: 'Rest Auth',
    username: 'restAuth',
    password: 'restAuth'
  },
  restConnectionGuideObject: {
    name: 'Rest-connection',
    baseUrl: 'jsonplaceholder.typicode.com'
  }
};

export const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    setOnboardActiveStatus: (state, action) => {
      state.run = action.payload;
    },
    setNextStep: (state, action) => {
      state.stepIndex = action.payload;
    }
  }
});

export const { setOnboardActiveStatus, setNextStep } = onboardSlice.actions;
export default onboardSlice.reducer;

import { useEffect, useState } from 'react';

export default function RunPrint({ onChange, stepObject }) {
  const [printInput, setPrintInput] = useState('');

  useEffect(() => {
    if (stepObject.data.workflowStepType === 'run-print') {
      setPrintInput(stepObject.data.runPrintStep.input);
    }
  }, [stepObject]);

  const handleChangePrintInput = (e) => {
    setPrintInput(e.target.value);
    onChange({
      ...stepObject,
      data: {
        ...stepObject.data,
        runPrintStep: { ...stepObject.data.runPrintStep, input: e.target.value }
      }
    });
  };
  return (
    <div className="flex flex-col items-center justify-center px-4">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-1 items-center">{`id: ${stepObject.id}`}</div>
        <div className="my-4 flex shrink-0 rounded-lg border border-blue-600 p-2 text-xs text-secondary">
          {stepObject.data.workflowStepType}
        </div>
        <div className="flex-1"> </div>
      </div>
      <div className="relative w-full">
        <label className="label-main absolute -top-4 !text-[12px]">Input</label>
        <input
          name="print"
          type="text"
          className="input-main"
          placeholder="print"
          value={printInput}
          onChange={handleChangePrintInput}
        />
      </div>
    </div>
  );
}

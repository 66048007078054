import { Actions } from 'features/settings/environmentVariables/Actions';
import { deleteFile, getFileUrl } from './network';
import { useToast } from 'hooks/use-toast';

export default function ListView({ list, rowClick, setRefresh }) {
  const { toast } = useToast();
  return (
    <div className=" overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="bg-secondary text-xs uppercase text-white dark:bg-gray-700 dark:text-gray-400">
          <tr className="bg-secondary">
            <th scope="col" className="px-6 py-3 text-white">
              File Name
            </th>
            <th scope="col" className="px-6 py-3 text-white">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((item, i) => (
              <tr
                key={item}
                onClick={() => rowClick(item)}
                className="border-b bg-white hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {item}
                </th>

                <td className="px-6 py-4 text-right">
                  <Actions
                    item={item}
                    onDelete={async () => {
                      const response = await deleteFile({ fileName: item });
                      const { success } = response;
                      if (success) {
                        toast({
                          title: 'Successfully',
                          description: 'File successfully deleted'
                        });
                        setRefresh(true);
                      } else {
                        toast({
                          variant: 'destructive',
                          title: 'Uh oh! Something went wrong.',
                          description: 'There was a problem. Try again later.'
                        });
                      }
                    }}
                    onDownload={async () => {
                      const response = await getFileUrl({ fileName: item });
                      const { success, payload } = response;
                      if (success) {
                        toast({
                          title: 'Successfully',
                          description: 'File successfully downloaded'
                        });
                        window.open(payload.url);
                      } else {
                        toast({
                          variant: 'destructive',
                          title: 'Uh oh! Something went wrong.',
                          description: 'There was a problem. Try again later.'
                        });
                      }
                    }}
                    rightOption={true}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-transparent hover:bg-transparent">
              <th className="whitespace-nowrap px-6 py-8 font-medium text-gray-900 dark:text-white">
                No items found
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

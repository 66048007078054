export default function LogFilterSkeleton() {
  return (
    <section aria-labelledby="logs-heading" className="w-full pt-2 md:w-1/4">
      <div className="-mt-2 flex items-baseline justify-between border-b border-gray-200 pb-2">
        <h1 className="mb-4 h-2.5 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></h1>

        <div className="flex items-center">
          <div className="relative inline-block text-left">
            <div>
              <div className="group inline-flex cursor-pointer items-center justify-center gap-1 text-sm font-medium text-gray-400 hover:text-gray-500">
                <div className="mb-4 h-2.5 w-8 rounded-full bg-gray-200 dark:bg-gray-700" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-4 animate-pulse">
        <form className="hidden lg:block">
          <div className="flex items-center gap-2">
            <div
              className="mb-4 h-2.5 w-6 rounded-full bg-gray-200 dark:bg-gray-700"
              aria-hidden="true"
            />
            <h3 className="mb-4 h-2.5 w-16 rounded-full bg-gray-200 dark:bg-gray-700"></h3>
          </div>
          <div className="border-b border-gray-200 py-6">
            <h3 className="-my-3 flow-root">
              <div className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                <span className="font-medium text-gray-900"></span>
                <span className="ml-6 flex items-center">
                  <div className="h-5 w-5" aria-hidden="true" />

                  <div
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </h3>
            <div>
              <div className="flex items-center justify-between">
                <div className="h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                <div className="h-2.5 w-4 rounded-full bg-gray-200 dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-200 py-6">
            <h3 className="-my-3 flow-root">
              <div className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                <span className="font-medium text-gray-900"></span>
                <span className="ml-6 flex items-center">
                  <div className="h-5 w-5" aria-hidden="true" />

                  <div
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </h3>
            <div>
              <div className="flex items-center justify-between">
                <div className="h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                <div className="h-2.5 w-4 rounded-full bg-gray-200 dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { sendUpdateProjectRequest, sendDeleteProjectRequest } from './network';

const deleteProject = async ({ id }) => {
  try {
    const { success } = await sendDeleteProjectRequest({
      id
    });

    if (!success) {
      return { success: false };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const updateProject = async ({
  name,
  id,
  description,
  commands,
  connections,
  workflows,
  slackChannelId,
  slackChannelName,
  gptPrompt,
  gptEnable,
  unleashCommand,
  leashCommand
}) => {
  try {
    const { success,err } = await sendUpdateProjectRequest({
      name,
      id,
      description,
      commands,
      connections,
      workflows,
      slackChannelId,
      slackChannelName,
      gptPrompt,
      gptEnable,
      unleashCommand,
      leashCommand
    });
    if (!success) {
      return { success: false, err:err };
    }
    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { updateProject, deleteProject };

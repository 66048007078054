import {
  sendDeleteGroupRequest,
  sendFetchGroupDetailRequest,
  sendFetchGroupRequest
} from './network';

const fetchGroupsDataDetail = async (groupName) => {
  try {
    const { success, payload } = await sendFetchGroupDetailRequest(groupName);

    if (!success) {
      return {
        success: false,
        payload: {
          groups: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { groups: [] } };
    }

    const { groups } = payload;

    return {
      success: true,
      payload: { groups }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { groups: [] } };
  }
};

const fetchGroupsData = async () => {
  try {
    const { success, payload } = await sendFetchGroupRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          groups: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { groups: [] } };
    }

    const { groups } = payload;

    return {
      success: true,
      payload: { groups }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { groups: [] } };
  }
};

const deleteGroup = async (groupName) => {
  try {
    await sendDeleteGroupRequest(groupName);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

export { fetchGroupsData, fetchGroupsDataDetail, deleteGroup };

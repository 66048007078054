/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationSsh = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" className="m-auto">
    <title>SSH</title>
    <g>
      <path d="M24,47A23,23,0,1,1,47,24,23.025,23.025,0,0,1,24,47Z" fill="#a4dae0" />
      <path
        d="M15,2.836V27H10a1,1,0,0,0-.759,1.65l6,7a1,1,0,0,0,1.518,0l6-7A1,1,0,0,0,22,27H17V2.093C16.319,2.311,15.652,2.557,15,2.836Z"
        fill="#335262"
      />
      <path
        d="M32.759,12.349a1,1,0,0,0-1.518,0l-6,7A1,1,0,0,0,26,21h5V45.907c.681-.218,1.348-.464,2-.743V21h5a1,1,0,0,0,.759-1.65Z"
        fill="#335262"
      />
    </g>
  </svg>
);

export default IconIntegrationSsh;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationEmail = () => (
  <svg className="m-auto" width="48" height="48" viewBox="0 0 48 48">
    <g fill="#16bdca">
      <path d="M42,5H6a5,5,0,0,0-5,5v4a1,1,0,0,0,.521.878l22,12a1,1,0,0,0,.958,0l22-12A1,1,0,0,0,47,14V10A5,5,0,0,0,42,5Z" />
      <path
        d="M25.437,28.634a3,3,0,0,1-2.87,0L1.023,16.885,1,16.9V38a5,5,0,0,0,5,5H42a5,5,0,0,0,5-5V16.9l-.023-.014Z"
        fill="#0694a2"
      />
    </g>
  </svg>
);

export default IconIntegrationEmail;

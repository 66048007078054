import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import CreatableTypeModal from 'components/modal/CreatableTypeModal';

import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';

export default function ListTabs({
  filtersKind,
  onSelectedFilterKind,
  handleSearch,
  handleAddClick = null,
  creatableTypeOptions
}) {
  const userState = useSelector((state) => state.userState);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const toggleFilter = (option) => {
    if (selectedFilters.includes(option.selected)) {
      const newFilters = selectedFilters.filter((f) => f !== option.selected);
      setSelectedFilters(newFilters);
      onSelectedFilterKind(newFilters);
    } else {
      const newFilters = [...selectedFilters, option.selected];
      setSelectedFilters(newFilters);
      onSelectedFilterKind(newFilters);
    }
  };


  const removeFilter = (filterName) => {
    const newFilters = selectedFilters.filter((f) => f !== filterName);
    setSelectedFilters(newFilters);
    onSelectedFilterKind(newFilters);
  };

  const handleSearchTerm = (e) => {
    handleSearch(e.target.value);
  };
  return (
    <div className="my-4 bg-transparent">
      <section aria-labelledby="filter-heading">
        <div className="rounded-lg border-b border-gray-200 bg-white py-4">
          <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
            <div>
              <label
                htmlFor="search"
                className="relative z-50 -mb-3 ml-3 block w-fit bg-white text-sm font-medium leading-6 text-gray-400"
              >
                Search
              </label>
              <div className="relative flex items-center">
                <input
                  type="text"
                  name="search"
                  id="search"
                  onChange={handleSearchTerm}
                  className="block w-full rounded-md border-0 py-1.5 pl-3 pr-14 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                    <MagnifyingGlassIcon className="h-3 w-3" aria-hidden="true" />
                  </kbd>
                </div>
              </div>
            </div>
            <div className="block">
              <div className="flow-root">
                <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                  {filtersKind && (
                    <Popover className="relative inline-block px-4 text-left">
                      <Popover.Button className="flex">
                        {' '}
                        <span>Kind</span>
                        {selectedFilters && selectedFilters.length !== 0 && (
                          <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold text-gray-700">
                            {selectedFilters.length}
                          </span>
                        )}
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <div
                          aria-hidden="true"
                          className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"
                        />
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute right-0 z-10 mt-2 min-w-max origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="grid grid-cols-4 gap-0.5">
                            {filtersKind &&
                              filtersKind
                                .filter((option) => option.selected !== 'all')
                                ?.map((option, optionIdx) => (
                                  <div key={option.name} className="flex items-center">
                                    <input
                                      id={`filter-${option.name}-${optionIdx}`}
                                      name={option.name}
                                      defaultValue={option.name}
                                      checked={selectedFilters.includes(option.selected)}
                                      onChange={() => toggleFilter(option)}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-${option.name}-${optionIdx}`}
                                      className="ml-3 whitespace-nowrap pr-3 text-sm font-medium text-gray-900"
                                    >
                                      {option.name}
                                    </label>
                                  </div>
                                ))}
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  )}

                  {userState.userRole.role !== userRoles.READ_ONLY && (
                    <button
                      type="button"
                      onClick={handleAddClick !== null ? handleAddClick : () => setShowModal(true)}
                      className="rounded-full bg-secondary p-1.5 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  )}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>


        {/* Active filters */}
        <div className={`${selectedFilters && selectedFilters.length !== 0 ? 'block' : 'hidden'}`}>
          <div className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
            <h3 className="text-sm font-medium text-gray-500">
              Filters
              <span className="sr-only">, active</span>
            </h3>
            <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />
            <div className="mt-2 sm:ml-4 sm:mt-0">
              <div className="-m-1 flex flex-wrap items-center">
                {selectedFilters &&
                  selectedFilters
                    .filter((filters) => filters !== 'all')
                    ?.map((activeFilter) => (
                      <span
                        key={activeFilter}
                        className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                      >
                        <span className="capitalize">{activeFilter}</span>
                        <button
                          type="button"
                          onClick={() => removeFilter(activeFilter)}
                          className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                        >
                          <span className="sr-only">Remove filter for {activeFilter}</span>
                          <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                      </span>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <CreatableTypeModal
        creatableTypeOptions={creatableTypeOptions}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { sendFetchCommandsRequest } from 'network/sendFetchCommandsRequest';

import { sendSaveNewProjectRequest } from './network';

const fetchCommands = async () => {
  try {
    const { success, payload } = await sendFetchCommandsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          commands: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { commands: [] } };
    }

    const { commands } = payload;

    return {
      success: true,
      payload: { commands }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { commands: [] } };
  }
};

const saveNewProject = async ({ name, description, gptEnable, gptPropmt }) => {
  try {
    const { success } = sendSaveNewProjectRequest({
      name,
      description,
      gptEnable,
      gptPropmt
    });

    if (success) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { fetchCommands, saveNewProject };

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import CopyToClipboard from 'components/copyToClipboard';

export const ViewApiKeyModal = ({ showViewApiKeyModal, setShowViewApiKeyModal }) => {
  const [open, setOpen] = useState(showViewApiKeyModal);

  const accountState = useSelector((state) => state.accountState);

  useEffect(() => {
    setOpen(showViewApiKeyModal);
  }, [showViewApiKeyModal]);

  useEffect(() => {
    if (!open) {
      setShowViewApiKeyModal(false);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="mx-auto flex w-full max-w-md flex-col space-y-2">
                  <div className="flex flex-col items-center justify-center space-y-2 text-center">
                    <div className="text-xl font-semibold">
                      <p>API Key Successfully Created</p>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-2 rounded-md border px-2 py-3">
                    <h3 className="text-base font-bold">API Key</h3>
                    <div className="flex flex-row justify-between ">
                      <div className=" text-gray-500">{accountState.apiKey}</div>
                      <CopyToClipboard
                        itemTopCopy={accountState.apiKey}
                        toastText="API Key copied to clipboard"
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

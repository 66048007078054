import { Outlet } from 'react-router-dom';

import AppTabs from 'features/apps/AppTabs';

export const AppDetailLayout = () => {
  return (
    <>
      <AppTabs />
      <Outlet />
    </>
  );
};

import { planMap } from 'constants/routesWithRoles';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Navigate, useLoaderData, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStripeInfo, setUserRole } from 'redux/slices/userSlice';

export const RoutingStripeGuard = ({ children, routePlan }) => {
  const userState = useSelector((state) => state.userState);
  let location = useLocation();

  const dispatch = useDispatch();
  const { success, payload, successStripeInfo, payloadStripeInfo } = useLoaderData();

  const [startProcess, setStartProcess] = useState(false);

  useEffect(() => {
    if (success) dispatch(setUserRole(payload));
    if (successStripeInfo) {
      dispatch(setStripeInfo(payloadStripeInfo));
      setStartProcess(true); // we can't know when 'subscriptionPlan' state is updated, this flags helps to us for render logic
    }
  }, [location.pathname, userState.stripeInfo.subscriptionPlan]);

  if (userState.stripeInfo.status === 'canceled' || userState.stripeInfo.status === 'inactive')
    return startProcess && <Navigate to="/pricing" />;
  else
    return (
      startProcess &&
      (planMap.get(userState.stripeInfo.subscriptionPlan) >= planMap.get(routePlan) ? (
        React.cloneElement(children)
      ) : (
        <Navigate to="/notFound" />
      ))
    );
};

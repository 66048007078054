import PageHeader from 'components/pageHeader/PageHeader';
import { useEffect, useState } from 'react';
import { fetchCommands, fetchWorkflows } from './io';
import Select from 'react-select';
import { addOrUpdateCommand, fetchGroups } from 'features/command/commandNew/io';
import { addOrUpdateWorkflow } from 'features/workflow/WorkflowNew/io';
import Helper from 'components/help/Helper';
import { useToast } from 'hooks/use-toast';

const info = {
  title: 'Approval Policy',
  btnName: '',
  btnPath: ''
};

export default function ApprovalPolicies() {
  const [commands, setCommands] = useState([]);
  const [command, setCommand] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [workflow, setWorkflow] = useState({});

  const [submit, setIsSubmit] = useState(false);
  const [submitWorkflow, setIsSubmitWorkflow] = useState(false);
  const [approvalGroup, setApprovalGroup] = useState('');

  const [groups, setGroups] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const { toast } = useToast();

  useEffect(() => {
    if (submit) {
      const postCommand = async () => {
        const { success } = await addOrUpdateCommand(
          { ...command, approvalGroup: approvalGroup },
          command.name
        );
        if (!success) {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:'There was a problem. Please try again later.'
          });
        } else {
          toast({
            title: 'Successfully',
            description: 'Updated approval policy.'
          });
          
        }
      };

      const fetchAllCommands = async () => {
        const { success, payload } = await fetchCommands();
        const { commands } = payload;
        setCommands(commands);
      };
      postCommand().then(() => {
        fetchAllCommands();
      });

      setIsSubmit(false);
    }
  }, [submit]);

  useEffect(() => {
    if (submitWorkflow) {
      const postWorkflow = async () => {
        const { success } = await addOrUpdateWorkflow(
          { ...workflow, approvalGroup: approvalGroup },
          workflow.name
        );
        if (!success) {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:'There was a problem saving your workflow. Please try again later.'
          });
        } else {
          toast({
            title: 'Successfully',
            description: 'Updated approval policy.'
          });
        }
      };
      const fetchAllWorkflows = async () => {
        const { success, payload } = await fetchWorkflows();
        const { workflows } = payload;
        setWorkflows(workflows);
      };

      postWorkflow().then(() => {
        fetchAllWorkflows();
      });
      setIsSubmitWorkflow(false);
    }
  }, [submitWorkflow]);

  useEffect(() => {
    fetchCommands().then(
      ({ success, payload }) => {
        void success;

        const { commands } = payload;
        setCommands(commands);
      },
      (err) => {
        console.log('error', err);
      }
    );
    fetchWorkflows().then(
      ({ success, payload }) => {
        void success;

        const { workflows } = payload;
        setWorkflows(workflows);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    fetchGroups().then(
      ({ success, payload }) => {
        void success;
        const { groups } = payload;
        setGroups(groups);
        setSelectOptions(groups.map((group) => ({ value: group.name, label: group.name })));
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  return (
    <div className="projects form-container">
      <PageHeader info={info} />

      <Helper
        message={
          'In OpsBeacon, Approval Policies ensure an additional layer of security and control over sensitive operations.. You can assign an approver group to a command. When this command is triggered, a user requires approval from the specified approver group to execute the corresponding command.'
        }
        infoIcon={true}
      />
      
        <div className={`${commands.some((command) => command.approvalGroup) ?'table-container my-6': 'hidden'}`}>
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="w-1/2 text-left">Command</th>
                <th className="w-1/2 text-left">Approver Group</th>
              </tr>
            </thead>
            <tbody>
              {commands.map((command, i) => {
                return (
                  command.approvalGroup !== '' && (
                    <tr key={i}>
                      <td className="w-1/2 py-2.5">
                        <span className="text-base font-extrabold">{command.name}</span>
                      </td>
                      <td className="absolute w-1/2 py-2.5">
                        <Select
                          options={selectOptions}
                          onChange={(e) => {
                            setApprovalGroup(e.value);
                            setCommand(command);
                            setIsSubmit(true);
                          }}
                          styles={{
                            container: (base) => {
                              return {
                                ...base,
                                position: 'absolute',
                                top: '33%'
                              };
                            }
                          }}
                          className="w-max xl:w-96"
                          placeholder="Select Approver Group"
                          value={{
                            value: command.approvalGroup,
                            label: command.approvalGroup
                          }}
                        />
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        </div>

        <div className={`${workflows.some((workflow) => workflow.approvalGroup) ?'table-container': 'hidden'}`}>
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="w-1/2 text-left">Workflow</th>
                <th className="w-1/2 text-left">Approver Group</th>
              </tr>
            </thead>
            <tbody>
              {workflows.map((workflow, i) => {
                return (
                  workflow.approvalGroup !== '' && (
                    <tr key={i}>
                      <td className="w-1/2 py-2.5">
                        <span className="text-base font-extrabold">{workflow.name}</span>
                      </td>
                      <td className="absolute w-1/2 py-2.5">
                        <Select
                          options={selectOptions}
                          onChange={(e) => {
                            setApprovalGroup(e.value);
                            setWorkflow(workflow);
                            setIsSubmitWorkflow(true);
                          }}
                          styles={{
                            container: (base) => {
                              return {
                                ...base,
                                position: 'absolute',
                                top: '33%'
                              };
                            }
                          }}
                          className="w-max xl:w-96"
                          placeholder="Select Approver Group"
                          value={{
                            value: workflow.approvalGroup,
                            label: workflow.approvalGroup
                          }}
                        />
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        </div>
    </div>
  );
}

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationBitbucket = () => (
  <svg className="m-auto" width="48" height="48" viewBox="0 0 62.42 56.13">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon fill="none" points="24.73 37.25 37.78 37.25 40.93 18.86 21.26 18.86 24.73 37.25" />
        <path
          fill="#2684ff"
          d="M2,0A2,2,0,0,0,0,2a2,2,0,0,0,0,.35L8.52,53.86a2.71,2.71,0,0,0,2.66,2.27H51.91a2,2,0,0,0,2-1.68L62.4,2.33A2,2,0,0,0,60.74,0a2,2,0,0,0-.34,0ZM37.78,37.25h-13L21.26,18.86H40.93Z"
        />
        <path
          fill="#0a5fd9"
          d="M59.7,18.86H40.93L37.78,37.25h-13L9.43,55.47a2.71,2.71,0,0,0,1.75.66H51.92a2,2,0,0,0,2-1.68Z"
        />
      </g>
    </g>
  </svg>
);

export default IconIntegrationBitbucket;

import React, { useEffect, useState } from 'react';
import { fetchData } from './io';
import { triggerListViewStates } from 'constants/index';
import Loading from 'components/loading/Loading';
import CreateNewWebhookInfo from '../../CreateNewWebhookInfo';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setWebhookTriggers } from 'redux/slices/triggerSlice';
import { useNavigate } from 'react-router-dom';
import Helper from 'components/help/Helper';
import ListTabs from 'components/listTabs/ListTabs';
import { webhookNavigationInfos } from 'assets/allData';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './ListView';
import GridView from './GridView';

const hydrate = async () => {
  const { success, payload } = await fetchData();
  return { success, payload };
};

const TriggerWebhookList = () => {
  const [viewState, setViewState] = useState(triggerListViewStates.LOADING);
  const dispatch = useDispatch();
  const triggerState = useSelector((state) => state.triggerState);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [layoutView, setLayoutView] = useState('list');

  const navigate = useNavigate();

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    setTableData(triggerState.webhookTriggers);
  },[triggerState.webhookTriggers]);

  useEffect(() => {
    let filteredDatas;
    filteredDatas = triggerState.webhookTriggers.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, triggerState.webhookTriggers]);

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  useEffect(() => {
    hydrate()
      .then(({ success, payload: { triggers } }) => {
        if (!success) {
          setViewState(triggerListViewStates.ERROR);
          return;
        }

        dispatch(setWebhookTriggers(triggers));
        setViewState(triggerListViewStates.DONE);
      })
      .catch(() => {
        setViewState(triggerListViewStates.ERROR);
      });
  }, [dispatch]);

  if (viewState === triggerListViewStates.LOADING) {
    return <Loading />;
  }

  if (viewState === triggerListViewStates.ERROR) {
    return <CreateNewWebhookInfo />;
  }

  const webhookCount = triggerState.webhookTriggers.length;

  if (webhookCount === 0) {
    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
       <CreateNewWebhookInfo />
      </div>
    );
  }

  return (
    <React.Fragment>
    <Helper
      message={
        "Webhooks bring together commands, connections, and workflows within OpsBeacon. The webhook trigger generates a unique URL, enabling you to execute operations by sending POST requests to this API endpoint. To ensure successful utilization, it's important to follow the provided instructions for constructing the correct request body."
      }
      infoIcon={true}
    />
    <ListTabs
      handleSearch={handleSearch}
      handleAddClick={() => navigate(`${webhookNavigationInfos.createNewPath}`)}
    />
    <React.Fragment>
      <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
      {layoutView === 'list' ? (
        <ListView
          list={tableData}
          rowClick={(item) => navigate(`${webhookNavigationInfos.edit}/${item.name}`)}
        />
      ) : (
        <GridView
          list={tableData}
          rowClick={(item) => navigate(`${webhookNavigationInfos.edit}/${item.name}`)}
        />
      )}
    </React.Fragment>
  </React.Fragment>
  )
};

export default TriggerWebhookList;

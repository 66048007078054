/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from 'constants/index';

const sendFetchGroupDetailRequest = async (groupName) => {
  try {
    const result = await axios.get(
      `${urls.URL_WORKSPACE_GROUP}${groupName ? '/' + groupName : ''}`,
      {
        withCredentials: true,
        data: {},
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    const { groups } = data;

    if (!groups) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    return {
      success: true,
      payload: {
        groups
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { policies: [] } };
  }
};

const sendFetchGroupRequest = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_GROUP, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    const { groups } = data;

    if (!groups) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    return {
      success: true,
      payload: {
        groups
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { groups: [] } };
  }
};

const sendDeleteGroupRequest = async (groupName) => {
  try {
    const result = await axios.delete(`${urls.URL_WORKSPACE_GROUP}/${groupName}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    void result;

    return { success: true };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { sendDeleteGroupRequest, sendFetchGroupDetailRequest, sendFetchGroupRequest };

export default function IconTriggerCron({customWidth, customHeight}) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={customWidth ? customWidth : "48"}
      height={customHeight ? customHeight: "48"}
      viewBox="0 0 64.000000 64.000000"
      className="mx-auto"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M196 616 c-66 -28 -116 -71 -152 -132 -25 -43 -47 -131 -34 -139 5
        -3 6 -22 3 -44 -10 -77 65 -188 161 -239 47 -25 61 -27 170 -27 100 -1 120 -3
        131 -18 16 -23 47 -22 63 3 7 11 18 17 27 14 23 -9 46 15 39 41 -4 17 0 24 15
        28 26 6 28 42 4 56 -15 8 -18 26 -18 138 0 116 -2 132 -25 176 -70 138 -243
        202 -384 143z m241 -32 c61 -29 97 -68 131 -142 20 -45 24 -65 20 -123 -2 -40
        -9 -74 -17 -81 -12 -11 -13 -10 -7 7 26 72 10 189 -33 245 -31 40 -34 17 -4
        -39 21 -41 27 -67 27 -114 0 -34 -5 -71 -12 -82 -6 -11 -12 -14 -12 -7 0 18
        -47 15 -54 -4 -4 -10 -14 -14 -25 -11 -30 7 -54 -12 -47 -39 5 -17 1 -26 -15
        -34 -14 -8 -19 -19 -17 -38 3 -26 2 -27 -57 -30 -77 -4 -155 33 -201 95 -158
        209 79 485 311 363 32 -17 60 -28 62 -26 10 10 -12 29 -61 52 -102 48 -224 27
        -311 -54 -33 -30 -32 -28 6 12 85 88 200 107 316 50z m121 -376 c25 0 28 -3
        25 -22 -3 -13 2 -30 12 -39 15 -15 15 -18 1 -33 -9 -8 -16 -27 -16 -40 0 -21
        -4 -24 -20 -19 -13 4 -25 -1 -37 -14 l-17 -20 -19 20 c-12 13 -25 18 -38 14
        -15 -5 -19 -1 -19 19 0 13 -7 32 -16 40 -14 15 -14 17 0 32 9 8 16 26 16 38 0
        19 6 24 26 24 15 0 31 7 37 17 9 18 10 18 23 0 8 -11 25 -18 42 -17z m-351
        -123 c41 -18 134 -24 172 -11 16 5 21 5 17 -2 -11 -18 -92 -25 -145 -12 -58
        14 -116 50 -151 94 -20 25 -16 23 22 -11 26 -23 64 -49 85 -58z"
          fill="#4b4b4b"
        />
        <path
          d="M300 540 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0
        -10 -9 -10 -20z"
          fill="#c73a63"
        />
        <path
          d="M192 519 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8 3
        -12 -1 -9 -9z"
          fill="#c73a63"
        />
        <path d="M407 523 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z" fill="#c73a63" />
        <path
          d="M286 488 c-11 -34 -12 -169 -2 -177 7 -6 41 -7 81 -3 61 6 70 10 70
        27 0 17 -8 20 -47 23 l-47 3 -3 67 c-3 61 -5 67 -26 70 -12 2 -24 -3 -26 -10z
        m31 -160 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"
          fill="#c73a63"
        />
        <path
          d="M120 435 c7 -8 16 -12 21 -9 13 8 1 24 -18 24 -13 0 -13 -3 -3 -15z"
          fill="#c73a63"
        />
        <path
          d="M475 439 c-11 -17 0 -20 21 -4 17 13 17 14 2 15 -9 0 -20 -5 -23 -11z"
          fill="#c73a63"
        />
        <path d="M88 333 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" fill="#c73a63" />
        <path d="M508 333 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" fill="#c73a63" />
        <path
          d="M115 230 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
        -18 -4 -21 -10z"
          fill="#c73a63"
        />
        <path
          d="M195 159 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"
          fill="#c73a63"
        />
        <path
          d="M300 130 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0
        -10 -9 -10 -20z"
          fill="#c73a63"
        />
        <path
          d="M462 184 c-46 -32 -16 -124 41 -124 38 0 59 23 63 68 3 34 -1 44 -19
        57 -27 19 -57 19 -85 -1z"
          fill="#c73a63"
        />
      </g>
    </svg>
  );
}

import { getAuditLogs } from 'features/log/io';
import { useEffect, useState } from 'react';

export const useAuditLogListFetch = ({
  initialStartDate,
  initialEndDate,
  initialPage,
  initialLimit,
  initialOrderBy,
  initialDirection,
  initialActionType,
  initialEntityType
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);
  const [direction, setDirection] = useState(initialDirection);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [actionType, setActionType] = useState(initialActionType);
  const [entityType, setEntityType] = useState(initialEntityType);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { success, payload } = await getAuditLogs({
          startDate: startDate,
          endDate: endDate,
          page: page,
          limit: limit,
          orderBy: orderBy,
          direction,
          actionType,
          entityType
        });
        if (success) {
          setData(payload);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, page, limit, orderBy, direction, actionType, entityType]);

  return {
    data,
    loading,
    error,
    setStartDate,
    setEndDate,
    setPage,
    setLimit,
    setOrderBy,
    setDirection,
    setActionType,
    setEntityType
  };
};

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getEnvironments, postEnvironment } from '../network';
import { useToast } from 'hooks/use-toast';

export const NewEnvironmentVariableForm = () => {
  const navigate = useNavigate();
  const [environmentList, setEnvironmentList] = useState([]);
  const [uniqueKeyError, setUniqueKeyError] = useState(false);
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, defaultValues }
  } = useForm({
    defaultValues: {
      kind: false,
      key: '',
      Description: '',
      value: ''
    }
  });

  useEffect(() => {
    getEnvironments().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { environments } = payload;
        setEnvironmentList(environments);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  const checkIfKeyExists = (key) => {
    if (key !== '') {
      if (environmentList.find((env) => env.key.toLowerCase() === key.toLowerCase())) {
        setUniqueKeyError(true);
      } else {
        setUniqueKeyError(false);
      }
    }
  };

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW ENVIRONMENT VARIABLE</h2>
          {/* <div className="mb-4 rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm leading-5 text-blue-700">
                  This is a two step authentication flow mechanism. We will first make an HTTP Basic
                  Auth request with these username/password credentials and then use the token from
                  the response as the HTTP Bearer token for subsequent requests. The Authentication
                  URL will be provided as part of connection settings.
                </p>
              </div>
            </div>
          </div> */}
          <div className="flex flex-col gap-6 ">
            <form
              onSubmit={handleSubmit(
                async (data) => {
                  let environmentObject = { ...data };
                  delete environmentObject.value;
                  delete environmentObject.kind;

                  if (data.kind) {
                    environmentObject = {
                      ...environmentObject,
                      secretValue: data.value,
                      kind: 'secret'
                    };
                  } else {
                    environmentObject = {
                      ...environmentObject,
                      variableValue: data.value,
                      kind: 'variable'
                    };
                  }

                  if (!uniqueKeyError) {
                    const { success: successAdd } = await postEnvironment({
                      environment: environmentObject
                    });
                    if (!successAdd) {
                      toast({
                        variant: 'destructive',
                        title: 'Uh oh! Something went wrong.',
                        description: 'There was a problem. Try again later.'
                      });
                      return;
                    } else {
                      toast({
                        title: 'Successfully',
                        description: 'New environment variable added.'
                      });
                      navigate('../settings/environmentVariables');
                    }
                  }
                },
                (error) => {
                  console.log(error);
                }
              )}
            >
              <div>
                <label htmlFor="key" className="label-main">
                  Key
                </label>

                <input
                  {...register('key', {
                    required: 'This area is required!',
                    onChange: (e) => {
                      checkIfKeyExists(e.target.value);
                    }
                  })}
                  className={`input-main`}
                />

                {errors.key !== undefined && errors.key.type === 'required' && (
                  <span className="label-second my-1 block !text-red-500">
                    {errors.key.message}
                  </span>
                )}

                {uniqueKeyError && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${defaultValues.key.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="value" className="label-main">
                  Value
                </label>

                <input
                  {...register('value', {
                    required: 'This area is required!'
                  })}
                  className={`input-main`}
                />
                {errors.value !== undefined && errors.value.type === 'required' && (
                  <span className="label-second my-1 block !text-red-500">
                    {errors.value.message}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>
                <textarea rows="3" className="input-main" {...register('Description')} />
              </div>

              <br></br>
              <div>
                <div className="flex flex-row gap-3 ">
                  <input {...register('kind')} type="checkbox" />
                  <label htmlFor="kind" className="label-main">
                    Configure as secret
                  </label>
                </div>
              </div>

              <br></br>

              <div className="flex justify-center">
                <button type="button" className="btn-danger" onClick={() => reset()}>
                  Clear
                </button>

                <button type="submit" className="btn-success">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

import axios from 'axios';
import { urls } from 'constants/index';
import { sendFetchAuditLogDetailRequest } from 'network/sendFetchLogsRequest';

const getAuditLogs = async ({
  startDate,
  endDate,
  page,
  limit,
  orderBy,
  direction,
  logTypes,
  containsText
}) => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_EXECUTIONLOGS}`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        page: page,
        limit: limit,
        orderBy: orderBy,
        direction: direction,
        logTypes: logTypes,
        containsText: containsText
      },
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { logs: [], dataCount: 1, currentPage: 1 }
      };
    }

    const { logs, dataCount, currentPage } = data;
    if (!logs) {
      return {
        success: false,
        payload: { logs: [], dataCount: 1, currentPage: 1 }
      };
    }

    return {
      success: true,
      payload: {
        logs,
        dataCount,
        currentPage
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { logs: [], dataCount: 1, currentPage: 1 } };
  }
};

const getAuditLogDetails = async (id) => {
  try {
    const { success, payload } = await sendFetchAuditLogDetailRequest(id);

    if (!success) {
      return {
        success: false,
        payload: {
          log: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { log: [] } };
    }

    const { log } = payload;

    return {
      success: true,
      payload: { log }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { log: [] } };
  }
};
export { getAuditLogs, getAuditLogDetails };

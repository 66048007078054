import React, { useEffect } from 'react';
import { useState } from 'react';
import LoginFooter from './LoginFooter';
import LoginHeader from 'features/login/LoginHeader';
import { globalViewStates } from 'constants';
import { Link, useNavigate } from 'react-router-dom';
import { logGoogleIn, SignupRequest } from './network';
import LoginGoogleandSlack from './LoginGoogleandSlack';
import { useGoogleLogin } from '@react-oauth/google';
import logodark from '../../assets/img/opsbeacon-logo.png';
import { useToast } from 'hooks/use-toast';

const Signup = () => {
  const [viewState, setViewState] = useState(globalViewStates.DEFAULT);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [optIn, setOptIn] = useState(true);

  const [lookupKey, setLookupKey] = useState('');
  const [trial, setTrial] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    setLookupKey(currentUrl.searchParams.get('package'));
    if (currentUrl.searchParams.get('trial') === 'true') {
      setTrial(true);
    } else {
      setTrial(false);
    }
  }, []);

  const onFormSubmit = async (evt) => {
    evt.preventDefault();

    const sanitizedEmail = email.trim();
    const atSign = email.includes('@');

    if (!sanitizedEmail || !atSign) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'Please provide a valid email'
      });
      return;
    }
    try {
      const result = await SignupRequest({
        email: sanitizedEmail,
        lookupKey: 'opsbeacon-enterprise-free',
        trial: true
      });
      if (result.success) {
        toast({
          title: 'Successfully',
          description: 'We have sent an email to you. Please check your inbox.'
        });

        setTimeout(function () {
          //navigate(-1); // go back - you can use -1 -2 ...
          navigate('/', { replace: true });
        }, 1000);

        setViewState(globalViewStates.SUCCESS);
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'Your e-mail is already signed up'
        });
      }
    } catch (err) {
      console.error(err);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'Your e-mail is already signed up'
      });
    }
  };

  const onGoogleSubmit = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      let accessToken = tokenResponse.access_token;
      logGoogleIn({ accessToken, lookupKey: 'opsbeacon-enterprise-free', trial: true, optIn })
        .then(({ success }) => {
          if (success) {
            navigate('/projects');
            return;
          }
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:'Google login error, please try again.'
          });
          setViewState(globalViewStates.DEFAULT);
        })
        .catch(() => {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:'Google login error, please try again.'
          });
          setViewState(globalViewStates.DEFAULT);
        });
    }
  });

  return (
    <div className="flex min-h-screen flex-col justify-center items-center bg-gray-100 py-12 pb-48 sm:px-6 lg:px-8">
      <img src={logodark} className='h-12' alt='opsbeacon logo' />

      <div className="mt-8 px-4 py-4 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <LoginHeader title={'We suggest using the email address you use at work.'} />
        <LoginGoogleandSlack onGoogleSubmit={onGoogleSubmit} />
        <form onSubmit={onFormSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email *
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                type="email"
                placeholder="e.g., hermionie.granger@hogwarts.magic"
                value={email}
                onChange={(onChange) => setEmail(onChange.target.value.trim())}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div className="mt-4">
          <label
              htmlFor="acceptPolicy"
              className="mt-2 cursor-pointer text-center text-sm text-gray-400"
            >
            By continuing, you agree to the{' '}
              <Link
                to="https://www.ob2.ai/terms-of-service"
                target="_blank"
                className="font-black text-[#374151] underline"
              >
                Terms of Service
              </Link>{' '}
              and
              <Link
                to="https://www.ob2.ai/privacy"
                target="_blank"
                className="font-black text-[#374151] underline"
              >
                {' '}
                Privacy Policy
              </Link>{' '}
            </label>
          </div>

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <button
                className="flex w-full justify-center rounded-md border px-4 py-4 text-white"
                type="submit"
                style={{ backgroundColor: 'rgba(37, 98, 234, 1)' }}
              >
                Sign Up
              </button>
            </span>
          </div>
        </form>

        <p className="mt-6 block text-slate-400">You will receive a link to signup via email.</p>
      </div>

      <LoginFooter />
    </div>
  );
};

export default Signup;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { sendSecretExistsRequest, sendAddNewSecretRequest } from './network';

const secretExists = async ({ key }) => {
  try {
    const { success, payload } = await sendSecretExistsRequest({ key });

    if (!success) {
      return { success: false, payload: { exists: true } };
    }

    const exists = payload.exists;

    return {
      success: true,
      payload: { exists }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { exists: true } };
  }
};

const addNewSecret = async (formModel) => {
  try {
    const { success } = await sendAddNewSecretRequest(formModel);

    return { success };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { secretExists, addNewSecret };

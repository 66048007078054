import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  slackTriggers: [],
  webhookTriggers: [],
  cronTriggers: [],
  linkTriggers: [],
};

const triggerSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    setSlackTriggers: (state, action) => {
      state.slackTriggers = action.payload;
    },
    setCronTriggers: (state, action) => {
      state.cronTriggers = action.payload;
    },
    setWebhookTriggers: (state, action) => {
      state.webhookTriggers = action.payload;
    },
    setLinkTriggers: (state, action) => {
      state.linkTriggers = action.payload;
    },
  }
});

export const { setSlackTriggers, setCronTriggers, setWebhookTriggers, setLinkTriggers } = triggerSlice.actions;
export default triggerSlice.reducer;

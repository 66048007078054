import { Actions } from 'features/settings/environmentVariables/Actions';
import { ValueCell } from 'features/settings/environmentVariables/ValueCell';
import { useEffect, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { NewConnectionEnvironmentModal } from './NewConnectionEnvironmentModal';
import { EditConnectionEnvironmentModal } from './EditConnectionEnvironmentModal';
import { useParams } from 'react-router-dom';
import { useToast} from 'hooks/use-toast'
import { deleteEnvironment } from './network';

export default function ListView({ list, rowClick, setRefresh }) {
  const params = useParams();
  const [showNewEnvForm, setNewEnvForm] = useState(false);
  const [showEditEnvForm, setEditEnvForm] = useState(false);
  const { toast } = useToast();
  const [selectedRow, setSelectedRow] = useState({});

  const handleAddClick = (e) => {
    e.preventDefault();
    setNewEnvForm(true);
  };

  useEffect(() => {
    if (selectedRow?.key !== undefined) setEditEnvForm(true);
  }, [selectedRow]);

  useEffect(() => {
    if (!showEditEnvForm) setSelectedRow({});
  }, [showEditEnvForm]);

  return (
    <>
      <div className="overflow-x-auto shadow-sm sm:rounded-lg">
        <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
          <thead className="bg-secondary text-xs uppercase text-white dark:bg-gray-700 dark:text-gray-400">
            <tr className="bg-secondary">
              <th scope="col" className="px-6 py-3 text-white">
                Key
              </th>
              <th scope="col" className="px-6 py-3 text-white">
                Value
              </th>
              <th scope="col" className="px-6 py-3 text-white">
                Description
              </th>
              <th scope="col" className="px-6 py-3 text-end text-white">
                <button className="inline-flex text-xl">
                  <FaPlusCircle className="hover:text-gray-300" onClick={(e)=> handleAddClick(e)} />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 ? (
              list.map((item, i) => (
                <tr
                  onClick={() => rowClick(item)}
                  className="border-b bg-white hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  <th
                    scope="row"
                    className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                  >
                    {item.key}
                  </th>
                  <ValueCell item={item} />

                  <td className="px-6 py-4">{item.description ? item.description : '-'}</td>
                  <td className="px-6 py-4 text-right">
                    <Actions
                      item={item}
                      onEdit={() => {
                        setSelectedRow(item);
                      }}
                      onDelete={() => {
                        deleteEnvironment({
                          connectionName: params.connectionName,
                          key: item.key
                        }).then(({ success }) => {
                          if (success) {
                            toast({
                              title: 'Successfully',
                              description:'Key successfully deleted'
                            });
                            setRefresh(true);
                          } else {
                            toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
                          }
                        });
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-transparent hover:bg-transparent">
                <th className="whitespace-nowrap px-6 py-8 font-medium text-gray-900 dark:text-white">
                  No items found
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <NewConnectionEnvironmentModal
        showModal={showNewEnvForm}
        setShowModal={setNewEnvForm}
        setRefresh={setRefresh}
      />
      <EditConnectionEnvironmentModal
        showModal={showEditEnvForm}
        setShowModal={setEditEnvForm}
        item={selectedRow}
        setRefresh={setRefresh}
      />
    </>
  );
}

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { putProfile } from 'features/account/network';
import { useToast } from 'hooks/use-toast';

export const ChangeUsernameModal = ({ showChangeNameModal, setShowChangeNameModal }) => {
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      fullName: ''
    }
  });

  const [open, setOpen] = useState(showChangeNameModal);

  useEffect(() => {
    setOpen(showChangeNameModal);
  }, [showChangeNameModal]);

  useEffect(() => {
    if (!open) {
      setShowChangeNameModal(false);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="mx-auto flex w-full max-w-md flex-col space-y-2">
                  <div className="flex flex-col items-center justify-center space-y-2 text-center">
                    <div className="text-xl font-semibold">
                      <p>Change Full Name</p>
                    </div>
                  </div>

                  <div>
                    <form
                      action=""
                      method="post"
                      onSubmit={handleSubmit(async (data) => {
                        const { success } = await putProfile({ fullName: data.fullName });
                        if (!success) {
                          toast({
                            variant: 'destructive',
                            title: 'Uh oh! Something went wrong.',
                            description: 'There was a problem. Try again later.'
                          });
                          return;
                        } else {
                          toast({
                            title: 'Successfully',
                            description: 'Full name is updated'
                          });

                          window.location.reload();
                        }
                      })}
                    >
                      <div className="flex flex-col space-y-8">
                        <div className="mx-auto flex w-full max-w-xs flex-row items-center justify-between gap-2"></div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Full Name
                          </label>
                          <div className="mt-2">
                            <input
                              {...register('fullName', {
                                required: 'This area is required!'
                              })}
                              className="block w-full rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.fullName !== undefined && (
                              <span className="label-second my-1 block !text-red-500">
                                {errors.fullName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                        >
                          Change Full Name
                        </button>
                        <div></div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import { userRoles } from 'constants/index';
import { useSelector } from 'react-redux';
import Helper from 'components/help/Helper';
import PageHeader from 'components/pageHeader/PageHeader';
import IconIntegrationWebhooks from 'components/icons/IconIntegrationWebhooks';

const info = {
  title: 'Webhooks',
  btnName: '',
  btnPath: ''
};

const CreateNewWebhookInfo = () => {
  const userState = useSelector((state) => state.userState);
  return (
    <>
      <PageHeader info={info} />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="flex justify-center">
          <IconIntegrationWebhooks customWidth='256px' customHeight='256px'/>
        </div>
        <div
          style={{
            maxWidth: '510px',
            margin: '0 auto'
          }}
          className="!my-6 text-center"
        >
          <Helper
            message={
              "Webhooks bring together commands, connections, and workflows within OpsBeacon. The webhook trigger generates a unique URL, enabling you to execute operations by sending POST requests to this API endpoint. To ensure successful utilization, it's important to follow the provided instructions for constructing the correct request body."
            }
          />
        </div>
        {userState.userRole.role !== userRoles.READ_ONLY && (
          <div className="mb-8 border-t border-gray-200 pt-5 dark:border-gray-800">
            <div className="flex justify-center">
              <span className="inline-flex rounded-md">
                <span className="inline-flex rounded-md shadow-sm">
                  <Link
                    to="/triggers/webhook/new"
                    className="focus:shadow-outline-teal inline-flex cursor-pointer justify-center rounded-md border border-transparent bg-secondary px-6 py-2 text-base font-medium leading-5 text-white transition duration-150 ease-out hover:border-blue-600 hover:bg-white hover:text-secondary focus:border-blue-600 focus:bg-white focus:outline-none active:bg-white"
                  >
                    Create a New Webhook
                  </Link>
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateNewWebhookInfo;

import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { router } from 'AppRoutes';
import { Toaster } from 'components/ui/toaster';
const googleClientId = window.ob.config.googleClientId;

const App = () => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Toaster />
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </GoogleOAuthProvider>
  );
};

export default App;

import IconSlackProject from 'components/icons/IconSlackProject';
import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function GridView({ list, rowClick, onConnectToSlackClick }) {
  return (
    <div>
      {list.length > 0 ? (
        <ul className="mt-3 grid grid-cols-2 gap-12 xl:grid-cols-4">
          {list.map((item, i) => (
            <li
              onClick={() => rowClick(item)}
              key={i}
              className={`${
                item.slackIntegration ? 'h-fit' : 'h-20'
              } col-span-1 flex cursor-pointer flex-col rounded-md shadow-sm`}
            >
              <div className="flex">
                <div
                  className={classNames(
                    item.bgColor,
                    'flex w-12 flex-shrink-0 items-center justify-center rounded-l-md border-b border-r border-t border-gray-200 text-sm font-medium uppercase'
                  )}
                  style={{
                    backgroundColor: 'rgb(240 249 255)',
                    color: 'rgb(3 105 161)'
                  }}
                >
                  P
                </div>
                <div className="command-card">
                  <div className="flex-1 px-4 py-4">
                    <h2 className="text-md dark:text-blue-400">{item.name}</h2>
                    <p className="text-md line-clamp-1 text-gray-500 dark:text-gray-200">
                      {item.description ? item.description : '-'}
                    </p>
                  </div>
                </div>
              </div>
              {!item.slackIntegration && (
                <div className="flex items-center rounded-b-md bg-white px-4 py-2 text-secondary shadow-sm">
                  <button onClick={() => onConnectToSlackClick(item.id)}>
                    <IconSlackProject />
                    Connect Project to Slack
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className="my-48 flex justify-center text-2xl text-gray-400">No items found</div>
      )}
    </div>
  );
}

import axios from 'axios';
import { urls } from 'constants/index';

export const sendFetchAuditLogDetailRequest = async (id) => {
  try {
    const result = await axios.get(
      `${urls.URL_WORKSPACE_EXECUTIONLOG_DETAILS}${id ? '/' + id : ''}`,
      {
        withCredentials: true,
        data: {},
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { log: [] }
      };
    }

    const { log } = data;

    if (!log) {
      return {
        success: false,
        payload: { log: [] }
      };
    }

    return {
      success: true,
      payload: {
        log
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { log: [] } };
  }
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { sendFetchConnectionsRequest } from 'network/sendFetchConnections';
import { sendFetchCommandsRequest } from 'network/sendFetchCommandsRequest';
import { sendAddOrUpdatePolicyRequest } from './network';

const fetchConnections = async () => {
  try {
    const { success, payload } = await sendFetchConnectionsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          connections: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { connections: [] } };
    }

    const { connections } = payload;

    return {
      success: true,
      payload: { connections }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { connections: [] } };
  }
};

const fetchCommands = async () => {
  try {
    const { success, payload } = await sendFetchCommandsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          commands: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { commands: [] } };
    }

    const { commands } = payload;

    return {
      success: true,
      payload: { commands }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { commands: [] } };
  }
};

const addOrUpdatePolicy = async (formModel, policyName) => {
  try {
    const { success } = await sendAddOrUpdatePolicyRequest(formModel, policyName);

    return { success };
  } catch (e) {
    console.log('addOrUpdatePolicy:error', e);

    return { success: false };
  }
};

export { fetchConnections, addOrUpdatePolicy, fetchCommands };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from 'constants/index';

const sendFetchGroupDetailRequest = async (groupName) => {
  try {
    const result = await axios.get(
      `${urls.URL_WORKSPACE_GROUP}${groupName ? '/' + groupName : ''}`,
      {
        withCredentials: true,
        data: {},
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { group: [] }
      };
    }

    const { group } = data;

    if (!group) {
      return {
        success: false,
        payload: { group: [] }
      };
    }

    return {
      success: true,
      payload: {
        group
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { group: [] } };
  }
};

const sendFetchGroupsRequest = async () => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_GROUP}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    const { groups } = data;

    if (!groups) {
      return {
        success: false,
        payload: { group: [] }
      };
    }

    return {
      success: true,
      payload: {
        groups
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { group: [] } };
  }
};

const sendAddOrUpdateGroupRequest = async (formModel, groupName) => {
  try {
    const result = groupName
      ? await axios.put(`${urls.URL_WORKSPACE_GROUP}/${groupName}`, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        })
      : await axios.post(urls.URL_WORKSPACE_GROUP, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        });

    void result;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export { sendAddOrUpdateGroupRequest, sendFetchGroupDetailRequest, sendFetchGroupsRequest };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import MainTitle from 'components/title/MainTitle';
import React from 'react';
import { Link } from 'react-router-dom';

const IncidentsMain = () => (
  <div className="incidents form-container">
    <div className="mb-6 flex items-center justify-between">
      <MainTitle title="Outstanding Incidents" />
      <span className="ml-3 inline-flex rounded-md shadow-sm">
        <Link to="/commands/new" className="btn-main">
          Custom Incident
        </Link>
      </span>
    </div>

    <div className="mb-4 rounded-md bg-yellow-50 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-base font-bold leading-5 text-yellow-800">
            This Is Just Sample Data
          </h3>
          <div className="mt-2 text-base leading-5 text-yellow-700">
            <p>We are still working on this section, we’ll let you know as soon as it’s ready.</p>
          </div>
        </div>
      </div>
    </div>

    <div className="flex flex-col">
      <div className="inline-block min-w-full overflow-hidden align-middle shadow sm:rounded-lg">
        <table className="min-w-full">
          <thead>
            <tr>
              <th>Severity</th>
              <th>Message</th>
              <th>Time</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="whitespace-no-wrap px-6 py-6 text-sm font-medium leading-5 text-gray-900">
                <span className="btn-second !w-20 !bg-red-500 !text-black">critical</span>
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-sm leading-5 text-gray-500 dark:text-gray-400">
                Server logs full.
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-sm leading-5 text-gray-500 dark:text-gray-400">
                Jan, 21, 2020 01:12:44am
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-right text-sm font-medium leading-5">
                <svg
                  className="float-right h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </td>
            </tr>
            <tr>
              <td className="whitespace-no-wrap px-6 py-6 text-sm font-medium leading-5 text-gray-900">
                <span className="btn-second !w-20 !bg-yellow-400 !text-black">low</span>
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-sm leading-5 text-gray-500 dark:text-gray-400">
                Section 508 compliance
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-sm leading-5 text-gray-500 dark:text-gray-400">
                Jan, 21, 2020 01:12:44am
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-right text-sm font-medium leading-5">
                <svg
                  className="float-right h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </td>
            </tr>
            <tr>
              <td className="whitespace-no-wrap px-6 py-4 text-sm font-medium leading-5 text-gray-900">
                <span className="btn-second !w-20 !bg-orange-500 !text-black">high</span>
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-sm leading-5 text-gray-500 dark:text-gray-400">
                Possible breach in bastion
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-sm leading-5 text-gray-500 dark:text-gray-400">
                Jan, 21, 2020 01:12:44am
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-right text-sm font-medium leading-5">
                <svg
                  className="float-right h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </td>
            </tr>
            <tr>
              <td className="whitespace-no-wrap px-6 py-6 text-sm font-medium leading-5 text-gray-900">
                <span className="btn-second !w-20 !bg-yellow-400 !text-black">low</span>
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-sm leading-5 text-gray-500 dark:text-gray-400">
                Blocked unauthenticated access to staging
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-sm leading-5 text-gray-500 dark:text-gray-400">
                Jan, 21, 2020 01:12:44am
              </td>
              <td className="whitespace-no-wrap px-6 py-6 text-right text-sm font-medium leading-5">
                <svg
                  className="float-right h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default IncidentsMain;

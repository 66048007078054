/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from 'constants/index';

const updateUrl = (inUrl, key) => {
  // console.log(inUrl.replace(/%key%/, key));
  return inUrl.replace(/%key%/, key);
};

const sendConnectionExistsRequest = async ({ key }) => {
  try {
    const url = updateUrl(urls.URL_WORKSPACE_CONNECTIONS, key);

    const result = await axios.get(url, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return { success: false, payload: { exists: true } };
    }

    return { success: true, payload: { exists: !!data.key } };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { exists: true } };
  }
};

const sendAddOrUpdateConnectionRequest = async (formModel, connectionName) => {
  try {
    const result = connectionName
      ? await axios.put(`${urls.URL_WORKSPACE_CONNECTIONS}/${connectionName}`, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        })
      : await axios.post(urls.URL_WORKSPACE_CONNECTIONS, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        });

    void result;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const sendAddOrUpdateAgentConnectionRequest = async (formModel, connectionName) => {
  try {
    const result = connectionName
      ? await axios.put(`${urls.URL_WORKSPACE_CONNECTIONS}/${connectionName}`, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        })
      : await axios.post(urls.URL_WORKSPACE_CONNECTIONS, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        });

    void result;

    // Agent URL
    const configurationToken = result.data.configurationToken;
    return { result: true, configurationToken: configurationToken };
  } catch (e) {
    console.log(e);
    return { result: false };
  }
};

export {
  sendAddOrUpdateConnectionRequest,
  sendConnectionExistsRequest,
  sendAddOrUpdateAgentConnectionRequest
};

// Secrets
export const secretTabOptions = [
  {
    name: 'ALL',
    selected: 'all'
  },
  {
    name: 'SSH',
    selected: 'ssh'
  },
  {
    name: 'REST',
    selected: 'rest'
  },
  {
    name: 'AWS CREDENTIAL',
    selected: 'aws-credential'
  },
  {
    name: 'AWS ROLE BASED AUTH',
    selected: 'aws-role-base'
  },
  {
    name: 'SQL',
    selected: 'sql-credential'
  },
  {
    name: 'JIRA',
    selected: 'jira-credential'
  },
  {
    name: 'CMS',
    selected: 'cms-credential'
  },
  {
    name: 'BASIC AUTH',
    selected: 'credential'
  },
  {
    name: 'REST TOKEN AUTH',
    selected: 'rest-token-auth'
  },
  {
    name: 'BEARER TOKEN',
    selected: 'bearer-token'
  },
  {
    name: 'SSH CREDENTIAL',
    selected: 'ssh-username'
  }
];

export const createSecretsOptions = [
  {
    name: 'SSH KEY',
    link: '/secrets/new/ssh'
  },
  {
    name: 'API KEY',
    link: '/secrets/new/rest'
  },
  {
    name: 'BASIC AUTH',
    link: '/secrets/new/credential'
  },
  {
    name: 'REST TOKEN AUTH',
    link: '/secrets/new/credential/rest-token-auth'
  },
  {
    name: 'BEARER TOKEN',
    link: '/secrets/new/bearer-token'
  },
  {
    name: 'SSH CREDENTIALS',
    link: '/secrets/new/ssh-credential'
  },
  {
    name: 'AWS CREDENTIALS',
    link: '/secrets/new/aws-credential'
  },
  {
    name: 'SQL CREDENTIALS',
    link: '/secrets/new/sql-credential'
  },
  {
    name: 'JIRA CREDENTIAL',
    link: '/secrets/new/jira-credential'
  },
  {
    name: 'CMS CREDENTIAL',
    link: '/secrets/new/cms-credential'
  },
  {
    name: 'AWS ROLE BASED AUTH',
    link: '/secrets/new/aws-role-based-auth'
  }
];

// Connections
export const connectionInfoSSH = {
  title: 'SSH',
  type: 'ssh',
  createNewPath: '/connections/new/ssh'
};

export const connectionInfoREST = {
  title: 'REST',
  type: 'rest',
  createNewPath: '/connections/new/rest'
};

export const connectionInfoSAFE = {
  title: 'SAFE',
  type: 'safe',
  createNewPath: '/connections/new/rest'
};

export const connectionInfoAWS = {
  title: 'aws',
  type: 'aws',
  createNewPath: '/connections/new/aws'
};

export const connectionInfoSQL = {
  title: 'sql',
  type: 'sql',
  createNewPath: '/connections/new/sql'
};

export const connectionInfoAGENT = {
  title: 'agent',
  type: 'agent',
  createNewPath: '/connections/new/agent'
};
export const connectionInfoJIRA = {
  title: 'jira',
  type: 'jira',
  createNewPath: '/connections/new/jira'
};
export const connectionInfoSSM = {
  title: 'ssm',
  type: 'ssm',
  createNewPath: '/connections/new/ssm'
};

export const connectionsNavigationInfos = [
  {
    title: 'SSH',
    type: 'ssh',
    createNewPath: '/connections/new/ssh'
  },
  {
    title: 'REST',
    type: 'rest',
    createNewPath: '/connections/new/rest'
  },
  {
    title: 'AWS',
    type: 'aws',
    createNewPath: '/connections/new/aws'
  },
  {
    title: 'SQL',
    type: 'sql',
    createNewPath: '/connections/new/sql'
  },
  {
    title: 'agent',
    type: 'agent',
    createNewPath: '/connections/new/agent'
  },
  {
    title: 'jira',
    type: 'jira',
    createNewPath: '/connections/new/jira'
  },
  {
    title: 'ssm',
    type: 'ssm',
    createNewPath: '/connections/new/ssm'
  }
];

export const connectionTabOptions = [
  {
    name: 'ALL',
    selected: 'all'
  },
  {
    name: 'SSH',
    selected: 'ssh'
  },
  {
    name: 'REST',
    selected: 'rest'
  },
  {
    name: 'AWS',
    selected: 'aws'
  },
  {
    name: 'SQL',
    selected: 'sql'
  },
  {
    name: 'AGENT',
    selected: 'agent'
  },
  {
    name: 'JIRA',
    selected: 'jira'
  },
  {
    name: 'SSM',
    selected: 'ssm'
  }
];

export const createConnectionOptions = [
  {
    name: 'SSH',
    link: '/connections/new/ssh'
  },
  {
    name: 'REST',
    link: '/connections/new/rest'
  },
  {
    name: 'AWS',
    link: '/connections/new/aws'
  },
  {
    name: 'SQL',
    link: '/connections/new/sql'
  },
  {
    name: 'AGENT',
    link: '/connections/new/agent'
  },
  {
    name: 'JIRA',
    link: '/connections/new/jira'
  },
  {
    name: 'SSM',
    link: '/connections/new/ssm'
  }
];

// Commands
export const commandInfoBASH = {
  title: 'BASH',
  type: 'ssh',
  createNewPath: '/commands/new/bash'
};

export const commandInfoREST = {
  title: 'REST',
  type: 'rest',
  createNewPath: '/commands/new/rest'
};

export const commandInfoAWS = {
  title: 'AWS',
  type: 'aws',
  createNewPath: '/commands/new/aws'
};

export const commandInfoSQL = {
  title: 'SQL',
  type: 'sql',
  createNewPath: '/commands/new/sql'
};

export const commandInfoJIRA = {
  title: 'JIRA',
  type: 'jira',
  createNewPath: '/commands/new/jira'
};

export const commandInfoSCRIPT = {
  title: 'SCRIPT',
  type: 'script',
  createNewPath: '/commands/new/script'
};

export const commandsNavigationInfos = [
  {
    title: 'BASH',
    type: 'ssh',
    createNewPath: '/commands/new/bash'
  },
  {
    title: 'REST',
    type: 'rest',
    createNewPath: '/commands/new/rest'
  },
  {
    title: 'AWS',
    type: 'aws',
    createNewPath: '/commands/new/aws'
  },
  {
    title: 'SQL',
    type: 'sql',
    createNewPath: '/commands/new/sql'
  },
  {
    title: 'JIRA',
    type: 'jira',
    createNewPath: '/commands/new/jira'
  },
  {
    title: 'SCRIPT',
    type: 'script',
    createNewPath: '/commands/new/script'
  }
];

export const commandTabOptions = [
  {
    name: 'ALL',
    selected: 'all'
  },
  {
    name: 'BASH',
    selected: 'ssh'
  },
  {
    name: 'REST',
    selected: 'rest'
  },
  {
    name: 'AWS',
    selected: 'aws'
  },
  {
    name: 'SQL',
    selected: 'sql'
  },
  {
    name: 'JIRA',
    selected: 'jira'
  },
  {
    name: 'SCRIPT',
    selected: 'script'
  }
];

export const environmentVarTabOptions = [
  {
    name: 'ALL',
    selected: 'all'
  },
  {
    name: 'Secrets',
    selected: 'secrets'
  },
  {
    name: 'Variables',
    selected: 'variables'
  }
];

export const createCommandOptions = [
  {
    name: 'BASH',
    link: '/commands/new/bash'
  },
  {
    name: 'REST',
    link: '/commands/new/rest'
  },
  {
    name: 'AWS',
    link: '/commands/new/aws'
  },
  {
    name: 'SQL',
    link: '/commands/new/sql'
  },
  {
    name: 'JIRA',
    link: '/commands/new/jira'
  },
  {
    name: 'SCRIPT',
    link: '/commands/new/script'
  }
];

export const createWorkflowOptions = [
  {
    name: 'NATIVE',
    link: '/workflows/new'
  },
  {
    name: 'LAMBDA',
    link: '/workflows/lambda'
  }
];

export const workflowTabOptions = [
  {
    name: 'ALL',
    selected: 'all'
  },
  {
    name: 'NATIVE',
    selected: 'native'
  },
  {
    name: 'LAMBDA',
    selected: 'script'
  }
];

// Users
export const userInfo = {
  createNewPath: '/settings/users/new'
};

export const userNavigationInfos = {
  createNewPath: '/settings/users'
};

// Groups
export const groupNavigationInfos = {
  path: '/settings/group',
  createNewPath: '/settings/group/new'
};

// Policies
export const policyNavigationInfos = {
  path: '/settings/execution-policies',
  createNewPath: '/settings/execution-policies/new'
};

// Workflow
export const workflowNavigationInfo = [
  {
    title: 'NATIVE',
    type: 'native',
    createNewPath: '/workflows/edit',
  },
  {
    title: 'LAMBDA',
    type: 'script',
    createNewPath: '/workflows/lambda',
  }
];

// Webhook
export const webhookNavigationInfos = {
  title: 'WEBHOOK',
  type: 'webhook',
  createNewPath: '/triggers/webhook/new',
  edit: '/triggers/webhook'
};

// Link
export const linkNavigationInfos = {
  title: 'LINK',
  type: 'link',
  createNewPath: '/triggers/link/new',
  edit: '/triggers/link'
};

// Cron
export const cronNativagationInfos = {
  title: 'CRON',
  type: 'cron',
  createNewPath: '/triggers/cron/new',
  edit: '/triggers/cron'
};

// Project
export const projectNavigationInfos = {
  title: 'PROJECT',
  type: 'project',
  createNewPath: '/projects/new',
  edit: '/projects'
};

export const appsNavigationInfos = {
  title: 'APP',
  type: 'app',
  createNewPath: '/apps/new',
  edit: '/apps'
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

import styled from './styled';
import Logo from 'components/logo/Logo';

const LoginFormHeading = () => (
  <styled.h2>
    <Logo width={'72px'} height={'42px'} textSize={'text-2xl'} />
    <styled.ops>Ops</styled.ops>
    <styled.beacon>Beacon</styled.beacon>
  </styled.h2>
);

export default LoginFormHeading;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from 'constants/index';

const sendAddOrUpdatePolicyRequest = async (formModel, policyName) => {
  try {
    const result = policyName
      ? await axios.put(`${urls.URL_WORKSPACE_POLICY}/${policyName}`, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        })
      : await axios.post(urls.URL_WORKSPACE_POLICY, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        });

    void result;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export { sendAddOrUpdatePolicyRequest };

import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';

const operatorOptionsByString = [
  { label: 'equal', value: 'equal' },
  { label: 'not-equal', value: 'not-equal' },
  { label: 'contains', value: 'contains' },
  { label: 'startsWith', value: 'startsWith' },
  { label: 'endsWith', value: 'endsWith' }
];

const operatorOptionsByBool = [
  { label: 'equal', value: 'equal' },
  { label: 'not-equal', value: 'not-equal' }
];

const operatorOptionsByInteger = [
  { label: 'equal', value: 'equal' },
  { label: 'not-equal', value: 'not-equal' },
  { label: 'less-than', value: 'less-than' },
  { label: 'greater-than', value: 'greater-than' },
  { label: 'less-than-or-equal', value: 'less-than-or-equal' },
  { label: 'greater-than-or-equal', value: 'greater-than-or-equal' }
];

const typeOptions = [
  { label: 'string', value: 'string' },
  { label: 'integer', value: 'integer' },
  { label: 'bool', value: 'bool' }
];

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span> please select 'type' first</span>
    </components.NoOptionsMessage>
  );
};

export const RunCondition = ({ stepObject, onChange }) => {
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [selectedOperatorOption, setSelectedOperatorOption] = useState({});
  const [selectedTypeOption, setSelectedTypeOption] = useState({});

  const [referenceValue, setReferenceValue] = useState('');
  const [comparisonValue, setComparisonValue] = useState('');

  useEffect(() => {
    setReferenceValue(stepObject.data.runConditionStep.conditionComparisonStep[0].referenceValue);
    setComparisonValue(stepObject.data.runConditionStep.conditionComparisonStep[0].comparisonValue);

    if (stepObject.data.runConditionStep.conditionalType === 'string')
      setOperatorOptions(operatorOptionsByString);
    else if (stepObject.data.runConditionStep.conditionalType === 'bool')
      setOperatorOptions(operatorOptionsByBool);
    else if (stepObject.data.runConditionStep.conditionalType === 'integer')
      setOperatorOptions(operatorOptionsByInteger);
    setSelectedTypeOption(
      typeOptions.find((option) => {
        return option.value === stepObject.data.runConditionStep.conditionalType;
      })
    );
    setSelectedOperatorOption();
  }, []);

  useEffect(() => {
    if (stepObject.data.runConditionStep.conditionComparisonStep[0].conditionComparisonOperators)
      setSelectedOperatorOption(
        operatorOptions.find((option) => {
          return (
            option.value ===
            stepObject.data.runConditionStep.conditionComparisonStep[0].conditionComparisonOperators
          );
        })
      );
  }, [operatorOptions]);

  const handleChangeOperatorOption = (e) => {
    setSelectedOperatorOption(e);

    const conditionComparisonStep = [...stepObject.data.runConditionStep.conditionComparisonStep];
    conditionComparisonStep[0].conditionComparisonOperators = e.value;

    onChange(
      {
        ...stepObject,
        data: {
          ...stepObject.data,
          runConditionStep: {
            ...stepObject.data.runConditionStep,
            conditionComparisonStep: conditionComparisonStep
          }
        }
      },
      'RunCondition'
    );
  };

  const handleChangeTypeOption = (e) => {
    setSelectedTypeOption(e);
    if (e.value === 'string') setOperatorOptions(operatorOptionsByString);
    else if (e.value === 'bool') setOperatorOptions(operatorOptionsByBool);
    else if (e.value === 'integer') setOperatorOptions(operatorOptionsByInteger);

    onChange(
      {
        ...stepObject,
        data: {
          ...stepObject.data,
          runConditionStep: { ...stepObject.data.runConditionStep, conditionalType: e.value }
        }
      },
      'RunCondition'
    );
  };

  const handleChangeReferenceValue = (e) => {
    setReferenceValue(e.target.value);

    const conditionComparisonStep = [...stepObject.data.runConditionStep.conditionComparisonStep];
    conditionComparisonStep[0].referenceValue = e.target.value;

    onChange(
      {
        ...stepObject,
        data: {
          ...stepObject.data,
          runConditionStep: {
            ...stepObject.data.runConditionStep,
            conditionComparisonStep: conditionComparisonStep
          }
        }
      },
      'RunCondition'
    );
  };

  const handleChangeComparisonValue = (e) => {
    setComparisonValue(e.target.value);

    const conditionComparisonStep = [...stepObject.data.runConditionStep.conditionComparisonStep];
    conditionComparisonStep[0].comparisonValue = e.target.value;

    onChange(
      {
        ...stepObject,
        data: {
          ...stepObject.data,
          runConditionStep: {
            ...stepObject.data.runConditionStep,
            conditionComparisonStep: conditionComparisonStep
          }
        }
      },
      'RunCondition'
    );
  };

  return (
    <div className="flex flex-col items-center justify-center px-4">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-1 items-center">{`id: ${stepObject.id}`}</div>
        <div className="my-4 flex shrink-0 rounded-lg border border-blue-600 p-2 text-xs text-secondary">
          {stepObject.data.workflowStepType}
        </div>
        <div className="flex-1"> </div>
      </div>
      <div className="flex w-full items-center gap-4 px-4">
        <div className=" w-full">
          <label className="label-main  -top-4 !text-[12px]">Reference Value</label>
          <input
            name="referenceInput"
            type="text"
            className="input-main"
            value={referenceValue}
            onChange={handleChangeReferenceValue}
          />
        </div>
        <div className="w-full">
          <label className="label-main -top-4 !text-[12px]">Operators</label>
          <Select
            className="mb-2 mt-2 h-11 w-full"
            options={operatorOptions}
            value={selectedOperatorOption}
            onChange={handleChangeOperatorOption}
            components={{ NoOptionsMessage }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </div>
        <div className=" w-full">
          <label className="label-main  -top-4 !text-[12px]">Type</label>
          <Select
            className="mb-2 mt-2 h-11 w-full"
            options={typeOptions}
            value={selectedTypeOption}
            onChange={handleChangeTypeOption}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </div>
        <div className=" w-full">
          <label className="label-main  -top-4 !text-[12px]">Comparison Value</label>
          <input
            name="comparisonInput"
            type="text"
            className="input-main"
            value={comparisonValue}
            onChange={handleChangeComparisonValue}
          />
        </div>
      </div>
    </div>
  );
};

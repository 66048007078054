import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { Spinner } from 'components/Spinner';
import { IoMdDownload } from 'react-icons/io';

export const Actions = ({ item, onEdit, onDelete, onDownload = null, rightOption = false }) => {
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [showDownloadSpinner, setShowDownloadSpinner] = useState(false);

  const handleDeleteClick = () => {
    setShowDeleteSpinner(true);
  };

  const handleDownloadClick = () => {
    setShowDownloadSpinner(true);
  };

  useEffect(() => {
    if (showDeleteSpinner) {
      onDelete();
      setTimeout(() => {
        setShowDeleteSpinner(false);
      }, [2000]);
    }
  }, [showDeleteSpinner]);

  useEffect(() => {
    if (showDownloadSpinner) {
      onDownload();
      setTimeout(() => {
        setShowDownloadSpinner(false);
      }, [2000]);
    }
  }, [showDownloadSpinner]);

  return (
    <Popover>
      <Popover.Button className="inline-flex items-center rounded-xl bg-gray-200 text-sm font-semibold leading-6 text-gray-900 hover:bg-black hover:text-white">
        <BiDotsVerticalRounded className=" h-6 w-6  " />
      </Popover.Button>

      <Transition as={Fragment}>
        <Popover.Panel
          className={`absolute  z-50 flex  max-w-min px-4 ${rightOption && 'right-1'}`}
        >
          <div className="w-32 shrink rounded-xl bg-white p-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            <div className="flex flex-col gap-2 ">
              {item.kind !== 'secret' && onEdit && (
                <div
                  className="flex flex-row items-center justify-center gap-1 rounded-md p-2 hover:bg-gray-50"
                  onClick={onEdit}
                >
                  <FaRegEdit className=" text-sm text-blue-500" />
                  <button className="mt-1 text-sm text-blue-500">Edit</button>
                </div>
              )}
              {onDownload && (
                <div
                  className="flex flex-row items-center justify-center gap-1 rounded-md p-2 hover:bg-gray-50"
                  onClick={handleDownloadClick}
                >
                  {showDownloadSpinner ? (
                    <Spinner fillColor="fill-blue-600" />
                  ) : (
                    <>
                      <IoMdDownload className=" text-sm text-blue-500" />
                      <button className="mt-1 text-sm text-blue-500">Download</button>
                    </>
                  )}
                </div>
              )}
              <div
                className="flex flex-row items-center justify-center gap-1 rounded-md p-2 hover:bg-gray-50"
                onClick={handleDeleteClick}
              >
                {showDeleteSpinner ? (
                  <Spinner fillColor="fill-red-600" />
                ) : (
                  <>
                    <MdOutlineDeleteForever className=" text-xl text-red-500" />
                    <button className="mt-1 text-sm text-red-500">Delete</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchData } from './io';
import WebhookEdit from '../../TriggerEdit/WebhookEdit';

const WebhookEditBody = () => {
  const params = useParams(); // get param
  const webhookName = params.webhookName;

  const [title, setTitle] = useState('…');
  const [webhook, setWebhook] = useState({});
  const [commands, setCommands] = useState([]);
  const [connections, setConnections] = useState([]);
  const [workflows, setWorkflows] = useState([]);

  useEffect(() => {
    fetchData({ webhookName }).then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        setTitle(`Edit “${payload.trigger.name}”`);
        setWebhook(payload.trigger);
        setCommands(payload.commands);
        setConnections(payload.connections);
        setWorkflows(payload.workflows);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [webhookName]);

  return (
    <WebhookEdit webhook={webhook} commands={commands} connections={connections} title={title} workflows={workflows} />
  );
};

export default WebhookEditBody;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import { withRouter } from 'react-router';

import { fetchData } from './io';

import ProjectEdit from 'features/project/ProjectEdit';

const ProjectEditBody = ({ match }) => {
  // const projectId = match.params.projectId;

  const params = useParams(); // get param
  const projectId = params.projectId;

  const [payload, setPayload] = useState({});

  useEffect(() => {
    fetchData({ projectId }).then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        setPayload(payload);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [projectId]);

  return <ProjectEdit payload={payload} />;
};

export default ProjectEditBody;

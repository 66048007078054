/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { sendUpdateCronRequest, sendDeleteCronRequest } from './network';

const deleteCron = async ({ name }) => {
  try {
    const { success } = await sendDeleteCronRequest({
      name
    });

    if (!success) {
      return { success: false };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const updateCron = async ({ cron }) => {
  try {
    const { success } = await sendUpdateCronRequest({
      cron
    });

    if (!success) {
      return { success: false };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { updateCron, deleteCron };

import { fetchGroups } from 'features/command/commandNew/io';
import { useEffect, useState } from 'react';
import Select from 'react-select';

export default function ApprovalWorkflow({ defaultGroup, onChange }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [expandedApprovalGroup, setExpandedApprovalGroup] = useState(false);
  const toggleExpanded = (value) => setExpandedApprovalGroup(value);
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    fetchGroups().then(
      ({ success, payload }) => {
        void success;
        const { groups } = payload;
        setSelectOptions(groups.map((group) => ({ value: group.name, label: group.name })));
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    if (defaultGroup.value !== '') {
      setChecked(true);
      toggleExpanded(true);
    }
  }, [defaultGroup]);

  const handleChangeApprovalGroupSwitch = (e) => {
    if (!e.target.checked) {
      onChange('');
      setChecked(false);
      toggleExpanded(false);
    } else {
      toggleExpanded(true);
      setChecked(true);
    }
  };

  const handleChangeGroupSelect = (e) => {
    onChange(e.value);
  };
  return (
    <div className="flex flex-col gap-1 rounded-md border p-4 dark:border-gray-700">
      <div className="flex items-center">
        <label className="switch">
          <input
            type="checkbox"
            id="approval-group"
            name="approval-group"
            checked={isChecked}
            onChange={handleChangeApprovalGroupSwitch}
          />
          <span className="slider round"></span>
        </label>

        <label htmlFor="approval-group" className="label-main mt-1 ml-2 cursor-pointer ">
          Enable Approval Workflow
        </label>
      </div>
      <div
        className={`flex gap-2  transition-[max-height] duration-200 ease-in-out ${
          expandedApprovalGroup ? ' max-h-40 p-4' : ' max-h-0 overflow-hidden'
        }`}
      >
        <label className="label-second mt-2 block">Approver Group</label>
        <Select
          className="flex-1"
          options={selectOptions}
          onChange={handleChangeGroupSelect}
          value={defaultGroup}
        />
      </div>

      <label className="label-second mt-2 block">
        Turn this on to submit each command trigger for approval to a specified group of approvers.
      </label>
    </div>
  );
}

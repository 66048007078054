import { CheckMarkIcon } from 'components/icons/checkMarkIcon';
import { fetchStripePortal } from 'features/login/LoginMain/network';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDeleteCode, getProfile } from './network';
import DeleteAccountModal from 'components/modal/DeleteAccountModal';
import { useEffect, useState } from 'react';
import { DeleteVerificationModal } from 'components/modal/DeleteVerificationModal';
import { userRoles } from 'constants/index';
import { ChangeUsernameModal } from 'components/modal/ChangeUsernameModal';
import { CreateApiKeyModal } from 'components/modal/CreateApiKeyModal';
import { ViewApiKeyModal } from 'components/modal/ViewApiKeyModal';
import { BiTrash } from 'react-icons/bi';
import DeleteApiKeyModal from 'components/modal/DeleteApiKeyModal';

export const Account = () => {
  const userState = useSelector((state) => state.userState);

  const [profileInfo, setProfileInfo] = useState(null);

  const [showSureModal, setSureModal] = useState(false);
  const [showApiKeySureModal, setApiKeySureModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showChangeNameModal, setShowChangeNameModal] = useState(false);
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);
  const [showViewApiKeyModal, setShowViewApiKeyModal] = useState(false);
  const [successApiKeyCreation, setSuccessApiKeyCreation] = useState(false);

  const [isDeleteAccountClicked, setIsDeleteAccountClicked] = useState(false);
  const [isDeleteApiKeyClicked, setIsDeleteApiKeyClicked] = useState(false);

  const manageBillingUrl = () => {
    fetchStripePortal().then(({ success, payload }) => {
      if (success) {
        window.location.href = payload.sessionUrl;
      }
    });
  };

  const handleDeleteClick = (e) => {
    setSureModal(true);
  };

  const handleChangeNameClick = (e) => {
    setShowChangeNameModal(true);
  };

  const handleGenerateApiKeyClick = (e) => {
    setShowApiKeyModal(true);
  };

  useEffect(() => {
    getProfile().then(({ success, profileInfo }) => {
      if (success) {
        setProfileInfo(profileInfo);
        setIsDeleteApiKeyClicked(false);
      }
    });
  }, [successApiKeyCreation, isDeleteApiKeyClicked]);

  useEffect(() => {
    if (isDeleteAccountClicked) {
      getDeleteCode().then(({ success }) => {
        if (success) {
          setIsDeleteAccountClicked(false);
          setShowVerificationModal(true);
        }
      });
    }
  }, [isDeleteAccountClicked]);

  useEffect(() => {
    if (successApiKeyCreation) setShowViewApiKeyModal(true);
  }, [successApiKeyCreation]);

  useEffect(() => {
    if (!showViewApiKeyModal) setSuccessApiKeyCreation(false);
  }, [showViewApiKeyModal]);

  const handleApiKeyDeleteClick = (e) => {
    setApiKeySureModal(true);
  };

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">ACCOUNT</h2>
            <div className="flex flex-col gap-6">
              <div className="flex w-full flex-col gap-2 rounded-md border px-2 py-3">
                <h3 className="text-base font-bold">{userState.userRole.fullName}</h3>
                <div className="text-gray-500">Change the name.</div>
                <button
                  onClick={handleChangeNameClick}
                  type="button"
                  className="w-32 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-blue-500 shadow-sm ring-1 ring-inset ring-blue-500 hover:bg-blue-50"
                >
                  Change Name
                </button>
              </div>
              <div className="flex w-full flex-col gap-2 rounded-md border px-2 py-3">
                <h3 className="text-base font-bold">Subscription</h3>
                <div className="flex flex-row gap-1">
                  <div className="self-center">
                    <CheckMarkIcon />
                  </div>
                  <div className="text-gray-500">
                    {userState.stripeInfo.subscriptionPlan} ({userState.stripeInfo.timeInfo})
                  </div>
                </div>
                <Link
                  onClick={manageBillingUrl}
                  className="text-xs font-medium text-secondary hover:underline dark:text-blue-500"
                >
                  Manage Billing
                </Link>
              </div>

              <div className="flex w-full flex-col gap-2 rounded-md border px-2 py-3">
                <h3 className="text-base font-bold">API Key</h3>
                {profileInfo?.apiKeyInfo?.name === '' && (
                  <div className="text-gray-500">Generate a API Key</div>
                )}

                {profileInfo?.apiKeyInfo?.name === '' ? (
                  <button
                    onClick={handleGenerateApiKeyClick}
                    type="button"
                    className="w-32 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-blue-500 shadow-sm ring-1 ring-inset ring-blue-500 hover:bg-blue-50"
                  >
                    Generate Key
                  </button>
                ) : (
                  <div className="flex w-full flex-row justify-between gap-2 rounded-md border px-2 py-3">
                    <span>{profileInfo?.apiKeyInfo?.name}</span>
                    <button
                      className="text-xl text-red-600 hover:text-red-700"
                      onClick={handleApiKeyDeleteClick}
                    >
                      <BiTrash />
                    </button>
                  </div>
                )}
              </div>

              {userState.userRole.role === userRoles.SUPERADMIN && (
                <div className="flex w-full flex-col gap-2 rounded-md border border-red-600 px-2 py-3">
                  <button
                    type="button"
                    className="w-36 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700"
                    onClick={handleDeleteClick}
                  >
                    Delete Workspace
                  </button>
                  <span>This will delete your workspace an all your profiles forever</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteAccountModal
        showModal={showSureModal}
        setSureModal={setSureModal}
        setIsDeleteAccountClicked={setIsDeleteAccountClicked}
      />
      <DeleteApiKeyModal
        showModal={showApiKeySureModal}
        setSureModal={setApiKeySureModal}
        setIsDeleteApiKeyClicked={setIsDeleteApiKeyClicked}
      />
      <DeleteVerificationModal
        showVerificationModal={showVerificationModal}
        setShowVerificationModal={setShowVerificationModal}
      />
      <ChangeUsernameModal
        showChangeNameModal={showChangeNameModal}
        setShowChangeNameModal={setShowChangeNameModal}
      />
      <CreateApiKeyModal
        showApiKeyModal={showApiKeyModal}
        setShowApiKeyModal={setShowApiKeyModal}
        setSuccessApiKeyCreation={setSuccessApiKeyCreation}
      />
      <ViewApiKeyModal
        showViewApiKeyModal={showViewApiKeyModal}
        setShowViewApiKeyModal={setShowViewApiKeyModal}
      />
    </>
  );
};

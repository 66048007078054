import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toggleSidebar: false
};

const sidebarSlice = createSlice({
  name: 'toggleSidebar',
  initialState,
  reducers: {
    setToggleSidebar: (state, action) => {
      state.toggleSidebar = action.payload;
    }
  }
});

export const { setToggleSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationOpsGenie = () => (
  <svg
    className="m-auto"
    width="48"
    height="48"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 60.05 71.5"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle fill="#2c61d2" cx="30.02" cy="17.84" r="17.84" />
        <path
          fill="#2f65d6"
          className="cls-2"
          d="M28.5,71.06A86,86,0,0,1,.25,41.53,2,2,0,0,1,1,38.81l.09-.05,13.51-6.63a2,2,0,0,1,2.61.8,66.78,66.78,0,0,0,29,26.25,86.12,86.12,0,0,1-14.7,11.88A2.87,2.87,0,0,1,28.5,71.06Z"
        />
        <path
          fill="#4584f7"
          className="cls-3"
          d="M31.54,71.06A85.86,85.86,0,0,0,59.79,41.53,2,2,0,0,0,59,38.81l-.09-.05L45.41,32.13a2,2,0,0,0-2.61.8,66.78,66.78,0,0,1-29,26.25A86.12,86.12,0,0,0,28.5,71.06,2.87,2.87,0,0,0,31.54,71.06Z"
        />
      </g>
    </g>
  </svg>
);

export default IconIntegrationOpsGenie;

import { Fragment, useContext, useEffect, useState } from 'react';
import CronBasedSchedule from './CronBasedSchedule';
import RateBasedSchedule from './RateBasedSchedule';
import timezones from '../../features/triggers/TriggerNew/CronNew/timezones.json';

import { SchedulePatternContext } from 'contexts/cronEdit/SchedulePatternContext';

const recurringRadioInfos = [
  {
    name: 'Cron-based schedule',
    desc: 'A schedule set using a cron expression that runs at a specific time, such as 8:00 a.m. PST on the first Monday of every month.'
  },
  {
    name: 'Rate-based schedule',
    desc: 'A schedule that runs at a regular rate, such as every 10 minutes.'
  }
];

export default function RecurringSchedule() {
  const { scheduleExpression, scheduleExpressionTimezone, setFormModel } =
    useContext(SchedulePatternContext);

  const [selectedRecurringSchedule, setSelectedRecurringSchedule] = useState(null);
  const [selectedScheduleExpressionTimezone, setSelectedScheduleExpressionTimezone] = useState('');

  useEffect(() => {
    setSelectedRecurringSchedule(
      scheduleExpression?.startsWith('cron') ? 'Cron-based schedule' : 'Rate-based schedule'
    );
  }, [scheduleExpression]);

  useEffect(() => {
    setSelectedScheduleExpressionTimezone(scheduleExpressionTimezone);
  }, [scheduleExpressionTimezone]);

  return (
    <div className="py-2 px-4">
      <div className="mt-2">
        <h2 className="py-1 font-extrabold">Schedule Type</h2>
        <p className="text-gray-500">Choose the schedule type that best meets your needs.</p>
      </div>
      <div className="flex gap-2 py-4">
        <div className="flex w-full  items-stretch gap-2">
          {recurringRadioInfos.map((schedule, index) => {
            const isSelected = selectedRecurringSchedule === schedule.name;
            return (
              <Fragment key={index}>
                <div
                  className={`block w-full rounded-md border py-2 px-4 dark:border-slate-600 ${
                    isSelected ? 'border-blue-600 bg-blue-50 dark:border-slate-600 dark:bg-slate-700' : ''
                  }`}
                >
                  <label className="mt-2 block w-full items-center">
                    <input
                      type="radio"
                      defaultValue={schedule.name}
                      checked={isSelected}
                      onChange={(e) => setSelectedRecurringSchedule(e.target.value)}
                    />
                    <span className="pl-2">{schedule.name}</span>
                    <div className="mt-2 text-gray-500 dark:text-gray-200">{schedule.desc}</div>
                  </label>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      {selectedRecurringSchedule === 'Cron-based schedule' && <CronBasedSchedule />}
      {selectedRecurringSchedule === 'Rate-based schedule' && <RateBasedSchedule />}
      <div className="my-4 rounded-md border dark:border-slate-600">
        <div className="bg-gray-100 py-2 px-4 dark:bg-slate-700">
          <h2 className="text-lg font-extrabold">Timeframe</h2>
        </div>
        <div className="py-2 px-4">
          <div>
            <h2 className="py-1 font-extrabold">Daylight saving time</h2>
            <p className="text-gray-500">
              EventBridge Scheduler automatically adjusts your schedule for daylight saving time.
              When time shifts forward in the Spring, if a cron expression falls on a non-existent
              date and time, your schedule invocation is skipped. When time shifts backwards in the
              Fall, your schedule runs only once and does not repeat its invocation. The following
              invocations occur normally at the specified date and time.
            </p>
          </div>
          <div className="my-4">
            <span className="text-md font-extrabold">Timezone</span>
            <p className="text-xs text-gray-500">The timezone for the schedule.</p>
            <select
              id="scheduleExpressionTimezone"
              name="scheduleExpressionTimezone"
              className="input-main "
              value={selectedScheduleExpressionTimezone}
              onChange={(e) => {
                setFormModel((prevState) => {
                  return {
                    ...prevState,
                    cronTriggerInfo: {
                      ...prevState.cronTriggerInfo,
                      scheduleExpressionTimezone: e.target.value
                    }
                  };
                });
                setSelectedScheduleExpressionTimezone(e.target.value);
              }}
            >
              <option value="" disabled>
                Select a timezone
              </option>
              {timezones.map((timezone, index) => {
                return (
                  <option key={index} defaultValue={timezone}>
                    {timezone}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

import { nanoid } from 'nanoid';

const isString = (value) => {
  const letterWithAllSymbolsRegex = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

  const onlyNumbersRegex = /^[0-9]+$/;

  const isLetterWithAllSymbols = letterWithAllSymbolsRegex.test(value);
  const isOnlyNumbers = onlyNumbersRegex.test(value);

  return isLetterWithAllSymbols && !isOnlyNumbers;
};

const isInteger = (value) => {
  const regex = new RegExp(/^\d+$/);
  return regex.test(value);
};

const isEmail = (value) => {
  const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
  return regex.test(value);
};

export const isInvalidArguments = (validatedArguments) => {
  const invalidArguments = validatedArguments?.filter(
    (argument) => argument.invalidName === true || argument.invalidType === true
  );
  return invalidArguments.length !== 0 ? true : false;
};

function isValidByName(argumentObjects, argumentName) {
  let dupliceArguments = argumentObjects.filter((argumentObject) => {
    return argumentObject.name === argumentName;
  });
  return dupliceArguments.length === 1 ? true : false;
}

export const validateArguments = (argumentObjects) => {
  const copyArguments = [...argumentObjects];

  copyArguments.forEach((argument, index) => {
    let invalidObject = { name: '', type: '' };
    let invalidMessage = '';

    if (argument.name === '') {
      invalidObject.name = true;
      invalidMessage = 'Empty fields must be filled';
    } else {
      //unique argument name validation
      if (argument.duplicatedName) {
        if (!isValidByName([...copyArguments], argument.name)) {
          invalidObject.name = true;
          invalidMessage = 'Argument name should be unique';
        } else {
          invalidObject.name = false;
          invalidMessage = '';
          copyArguments[index].duplicatedName = false;
        }
      } else {
        invalidObject.name = false;
        invalidMessage = '';
      }
    }

    if (argument.type === '') {
      invalidObject.type = true;
      invalidMessage = 'Empty fields must be filled';
    } else {
      invalidObject.type = false;
      invalidMessage = checkBeforeErrors(invalidMessage, '');
    }

    if (argument.type !== 'bool') {
      if (argument.required && argument.default !== '') {
        const { invalidDefault, invalidDefaultValueMessage } = defaultValueTypeValidation(
          argument.type,
          argument.default
        );
        invalidObject.default = invalidDefault;
        invalidMessage = checkBeforeErrors(invalidMessage, invalidDefaultValueMessage);
      } else if (!argument.required && argument.default !== '') {
        const { invalidDefault, invalidDefaultValueMessage } = defaultValueTypeValidation(
          argument.type,
          argument.default
        );
        invalidObject.default = invalidDefault;
        invalidMessage = checkBeforeErrors(invalidMessage, invalidDefaultValueMessage);
      } else {
        invalidObject.default = false;
        invalidMessage = checkBeforeErrors(invalidMessage, '');
      }
    }

    copyArguments[index].invalidName = invalidObject.name;
    copyArguments[index].invalidType = invalidObject.type;
    copyArguments[index].invalidDefault = invalidObject.default;
    copyArguments[index].invalidMessage = invalidMessage;
  });
  return copyArguments;
};

function defaultValueTypeValidation(argumentType, argumentDefault) {
  let invalidDefault = false;
  let invalidDefaultValueMessage = '';
  if (argumentType === 'email') {
    invalidDefault = !isEmail(argumentDefault);
  } else if (argumentType === 'string') {
    invalidDefault = !isString(argumentDefault);
  } else if (argumentType === 'integer') {
    invalidDefault = !isInteger(argumentDefault);
  }
  invalidDefaultValueMessage = invalidDefault
    ? `Default value should be in ${argumentType} format`
    : '';

  return { invalidDefault, invalidDefaultValueMessage };
}

function checkBeforeErrors(currentInvalidMessage, newInvalidMessage) {
  return currentInvalidMessage !== '' ? currentInvalidMessage : newInvalidMessage;
}

export const createCommandArgumentObject = (args) => {
  return args?.map((arg) => ({
    id: nanoid(),
    name: arg.name,
    shortName: arg.shortName,
    required: arg.required,
    type: arg.type,
    default: arg.default,
    invalidName: false,
    invalidDefault: false,
    invalidType: false,
    invalidMessage: '',
    duplicatedName: false
  }));
};

import { useState } from 'react';

const workflowStepType = [
  { label: 'run-command', value: 'run-command' },
  { label: 'run-parser', value: 'run-parser' },
  { label: 'run-print', value: 'run-print' },
  { label: 'run-condition', value: 'run-condition' }
];

export default function SelectedStepType({ stepObject, onChange }) {
  const [selectedStepType, setSelectedStepType] = useState('');

  const handleChangeStepType = (selectedType) => {
    setSelectedStepType(selectedType);

    // Create a base step object
    let updatedStepObject = {
      ...stepObject,
      data: {
        ...stepObject.data,
        workflowStepType: selectedType,
        runCommandStep: null,
        runParserStep: null,
        runPrintStep: null,
        runConditionStep: null
      }
    };

    // Check the selected type and update the step object accordingly
    if (selectedType === 'run-command') {
      updatedStepObject = {
        ...updatedStepObject,
        data: {
          ...updatedStepObject.data,
          runCommandStep: {
            connection: '',
            command: '',
            argument: ''
          }
        }
      };
    } else if (selectedType === 'run-parser') {
      updatedStepObject = {
        ...updatedStepObject,
        data: {
          ...updatedStepObject.data,
          runParserStep: {
            input: '',
            output: ''
          }
        }
      };
    } else if (selectedType === 'run-print') {
      updatedStepObject = {
        ...updatedStepObject,
        data: {
          ...updatedStepObject.data,
          runPrintStep: {
            input: ''
          }
        }
      };
    } else if (selectedType === 'run-condition') {
      updatedStepObject = {
        ...updatedStepObject,
        data: {
          ...updatedStepObject.data,
          runConditionStep: {
            conditionalType: '',
            conditionComparisonStep: [
              {
                conditionComparisonOperators: '',
                comparisonValue: '',
                ReferenceValue: '',
                nextStepId: ''
              }
            ]
          }
        }
      };
    }
    onChange(updatedStepObject);
  };
  return (
    <>
      <div className="flex items-center justify-center py-4">
        <h1 className="label-main font-black">Select Step Type</h1>
      </div>
      <div className="flex items-center justify-center gap-4">
        {workflowStepType.map((step) => (
          <div
            key={step.value}
            onClick={() => handleChangeStepType(step.value)}
            className="label-main cursor-pointer rounded-lg border p-3 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:bg-white hover:!text-secondary"
          >
            {step.value}
          </div>
        ))}
      </div>
    </>
  );
}

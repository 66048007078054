import axios from 'axios';
import { urls } from 'constants/index';

const sendSaveNewLinkRequest = async ({
  kind,
  name,
  description,
  connections,
  commands,
  workflows,
  linkTriggerInfo
}) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_TRIGGERS,
      {
        kind: 'link',
        name,
        description,
        connections,
        commands,
        workflows,
        linkTriggerInfo: {}
      },
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json; charset=utf-8'
        }
      }
    );
    // Link URL
    const url = result.data.url;
    return { success: true, url: url };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export { sendSaveNewLinkRequest };

import { DocumentMagnifyingGlassIcon, PencilIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AppTabs() {
  const params = useParams();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    if (window.location.pathname === `/apps/${params.id}`) setCurrentTab('View');
    else if (window.location.pathname === `/apps/${params.id}/edit`) setCurrentTab('Edit');
  }, [window.location.pathname]);

  return (
    <div className="mb-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          value={currentTab}
          onChange={(e) => {
            if (e.target.value === 'View') {
              navigate(`/apps/${params.id}`);
            } else if (e.target.value === 'Edit') {
              navigate(`/apps/${params.id}/edit`);
            }
          }}
        >
          <option>View</option>
          <option>Edit</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <NavLink
              to={`/apps/${params.id}`}
              className={classNames(
                window.location.pathname === `/apps/${params.id}`
                  ? 'border-blue-500 text-secondary'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium'
              )}
            >
              <DocumentMagnifyingGlassIcon
                className={classNames(
                  window.location.pathname === `/apps/${params.id}`
                    ? 'text-blue-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
                aria-hidden="true"
              />
              <span>View</span>
            </NavLink>
            <NavLink
              to={`/apps/${params.id}/edit`}
              className={classNames(
                window.location.pathname === `/apps/${params.id}/edit`
                  ? 'border-blue-500 text-secondary'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium'
              )}
            >
              <PencilIcon
                className={classNames(
                  window.location.pathname === `/apps/${params.id}/edit`
                    ? 'text-blue-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
                aria-hidden="true"
              />
              <span>Edit</span>
            </NavLink>
          </nav>
        </div>
      </div>
    </div>
  );
}

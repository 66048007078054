import axios from 'axios';
import { useEffect, useState } from 'react';
import { urls } from 'constants/index';

export default function useFetchGroups() {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${urls.URL_WORKSPACE_GROUP}`, {
          withCredentials: true,
          data: {},
          headers: { 'content-type': 'application/json; charset=utf-8' }
        });
        setGroups(response.data?.groups);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(true);
      }
    };
    fetchGroups();
  }, []);

  return { groups, loading, error };
}

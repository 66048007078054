import CopyToClipboard from 'components/copyToClipboard';
import { useState } from 'react';

import { IoMdEye } from 'react-icons/io';
import { RiLockLine } from 'react-icons/ri';

export const ValueCell = ({ item }) => {
  const [showValue, setShowValue] = useState(false);

  return (
    <>
      {item.kind === 'variable' ? (
        <td className="flex flex-row gap-1 px-6 py-4 font-black">
          {showValue ? (
            <div className="flex flex-row gap-1">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  console.log('copy');
                }}
              >
                <CopyToClipboard
                  itemTopCopy={item.variableValue}
                  toastText="Value copied to clipboard."
                />
              </button>
              <div>{item.variableValue}</div>
            </div>
          ) : (
            <div className="flex flex-row gap-1">
              <IoMdEye
                onClick={() => {
                  setShowValue(true);
                }}
              />
              <span>*********</span>
            </div>
          )}
        </td>
      ) : (
        <td className="flex flex-row gap-1 px-6 py-4 font-black">
          <div className="flex flex-row items-center gap-1">
            <RiLockLine />
            <span className="text-gray-400">secret</span>
          </div>
        </td>
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { addNewSecret } from './io';
import { fetchData } from '../KeyList/io';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, MinusIcon } from '@heroicons/react/20/solid';
import { useToast } from 'hooks/use-toast';

const KeyNewCms = ({ isInnerForm = false, setIsInnerFormSuccess = null }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, defaultValues }
  } = useForm({
    defaultValues: {
      name: '',
      kind: 'cms-credential',
      description: '',
      cmsCredentialInfo: {
        username: 'cms-web-client',
        password: 'cms-web-pass',
        cmsSecretBody: {
          urlEncodedBody: [
            { key: 'username', value: '' },
            { key: 'password', value: '' },
            { key: 'grant_type', value: 'password' },
            { key: 'client_id', value: 'cms-web-client' }
          ],
          contentType: 'application/x-www-form-urlencoded'
        }
      }
    }
  });
  const navigate = useNavigate();
  const [secretList, setSecretList] = useState([]);
  const [uniqueNameError, setUniqueNameError] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { secrets } = payload;
        setSecretList(secrets);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  const checkIfNameExists = (name) => {
    if (name !== '') {
      if (secretList.find((secret) => secret.name.toLowerCase() === name.toLowerCase())) {
        setUniqueNameError(true);
      } else {
        setUniqueNameError(false);
      }
    }
  };

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW CMS KEY</h2>
          <div className="mb-4 rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm leading-5 text-blue-700">
                  This is a two step authentication flow mechanism. We will first make an HTTP Basic
                  Auth request with these username/password credentials and then use the token from
                  the response as the HTTP Bearer token for subsequent requests. The Authentication
                  URL will be provided as part of connection settings.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 ">
            <form
              onSubmit={handleSubmit(
                async (data) => {
                  if (!uniqueNameError) {
                    const { success: successAdd } = await addNewSecret(data);

                    if (!successAdd) {
                      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
                      return;
                    } else {
                      toast({
                        title: 'Successfully',
                        description: 'New secret added.'
                      });
                      if (!isInnerForm) {
                        navigate('/secrets');
                      } else {
                        setIsInnerFormSuccess(true);
                      }
                    }
                  }
                },
                (error) => {
                  console.log(error);
                }
              )}
            >
              <div>
                <label htmlFor="name" className="label-main">
                  Name
                </label>

                <input
                  {...register('name', {
                    required: 'This area is required!',
                    onChange: (e) => {
                      checkIfNameExists(e.target.value);
                    }
                  })}
                  className={`input-main`}
                />

                {errors.name !== undefined && errors.name.type === 'required' && (
                  <span className="label-second my-1 block !text-red-500">
                    {errors.name.message}
                  </span>
                )}

                {uniqueNameError && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${defaultValues.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>
                <textarea rows="3" className="input-main" {...register('description')} />
              </div>
              <h2 className="text-xl font-extrabold">CMS Credentials</h2>
              <br></br>
              <div>
                <label htmlFor="name" className="label-main">
                  Username
                </label>

                <input
                  {...register('cmsCredentialInfo.cmsSecretBody.urlEncodedBody.0.value', {
                    required: 'This area is required!'
                  })}
                  className={`input-main`}
                />
                {errors.cmsCredentialInfo !== undefined &&
                  errors.cmsCredentialInfo.cmsSecretBody?.urlEncodedBody[0]?.value.type ===
                    'required' && (
                    <span className="label-second my-1 block !text-red-500">
                      {errors.cmsCredentialInfo.cmsSecretBody.urlEncodedBody[0].value.message}
                    </span>
                  )}
              </div>
              <div>
                <label htmlFor="name" className="label-main">
                  Password
                </label>

                <input
                  {...register('cmsCredentialInfo.cmsSecretBody.urlEncodedBody.1.value', {
                    required: 'This area is required!'
                  })}
                  className={`input-main`}
                  type="password"
                />

                {errors.cmsCredentialInfo !== undefined &&
                  errors.cmsCredentialInfo.cmsSecretBody?.urlEncodedBody[1]?.value.type ===
                    'required' && (
                    <span className="label-second my-1 block !text-red-500">
                      {errors.cmsCredentialInfo.cmsSecretBody.urlEncodedBody[1].value.message}
                    </span>
                  )}
              </div>

              <br></br>

              <Disclosure as="div" className="border-b border-gray-200 py-6">
                {({ open }) => (
                  <>
                    <h3 className="-my-3 flow-root">
                      <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm">
                        <h2 className="text-xl font-extrabold">Advanced Settings</h2>
                        <span className="ml-6 flex items-center">
                          {open ? (
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <ChevronDownIcon
                              className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="mb-4 rounded-md bg-blue-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm leading-5 text-blue-700">
                              Make sure what you are doing before editing below fields.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="space-y-4">
                        <div>
                          <label htmlFor="name" className="label-main">
                            Basic Auth Username
                          </label>

                          <input
                            {...register('cmsCredentialInfo.username', {
                              required: 'This area is required!'
                            })}
                            className={`input-main`}
                          />

                          {errors.cmsCredentialInfo !== undefined &&
                            errors.cmsCredentialInfo.username?.type === 'required' && (
                              <span className="label-second my-1 block !text-red-500">
                                {errors.cmsCredentialInfo.username.message}
                              </span>
                            )}
                        </div>
                        <div>
                          <label htmlFor="name" className="label-main">
                            Basic Auth Password
                          </label>

                          <input
                            {...register('cmsCredentialInfo.password', {
                              required: 'This area is required!'
                            })}
                            className={`input-main`}
                          />

                          {errors.cmsCredentialInfo !== undefined &&
                            errors.cmsCredentialInfo.password?.type === 'required' && (
                              <span className="label-second my-1 block !text-red-500">
                                {errors.cmsCredentialInfo.password.message}
                              </span>
                            )}
                        </div>
                        <div>
                          <label htmlFor="name" className="label-main">
                            Grant Type
                          </label>

                          <input
                            {...register('cmsCredentialInfo.cmsSecretBody.urlEncodedBody.2.value', {
                              required: 'This area is required!'
                            })}
                            className={`input-main`}
                          />
                          {errors.cmsCredentialInfo !== undefined &&
                            errors.cmsCredentialInfo.cmsSecretBody?.urlEncodedBody[2]?.value
                              .type === 'required' && (
                              <span className="label-second my-1 block !text-red-500">
                                {
                                  errors.cmsCredentialInfo.cmsSecretBody.urlEncodedBody[2].value
                                    .message
                                }
                              </span>
                            )}
                        </div>
                        <div>
                          <label htmlFor="name" className="label-main">
                            Client Id
                          </label>

                          <input
                            {...register('cmsCredentialInfo.cmsSecretBody.urlEncodedBody.3.value', {
                              required: 'This area is required!'
                            })}
                            className={`input-main`}
                          />
                          {errors.cmsCredentialInfo !== undefined &&
                            errors.cmsCredentialInfo.cmsSecretBody?.urlEncodedBody[3]?.value
                              .type === 'required' && (
                              <span className="label-second my-1 block !text-red-500">
                                {
                                  errors.cmsCredentialInfo.cmsSecretBody.urlEncodedBody[3].value
                                    .message
                                }
                              </span>
                            )}
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>

              <div className="flex justify-center">
                <button type="button" className="btn-danger" onClick={() => reset()}>
                  Clear
                </button>

                <button type="submit" className="btn-success">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyNewCms;

import { useEffect, useState } from 'react';

export default function RunParser({ stepObject, onChange }) {
  const [parserInput, setParserInput] = useState('');
  const [parserOutput, setParserOutput] = useState('');

  useEffect(() => {
    if (stepObject.data.workflowStepType === 'run-parser') {
      setParserInput(stepObject.data.runParserStep.input);
      setParserOutput(stepObject.data.runParserStep.output);
    }
  }, [stepObject]);

  const handleChangeParserInput = (e) => {
    setParserInput(e.target.value);
    onChange({
      ...stepObject,
      data: {
        ...stepObject.data,
        runParserStep: { ...stepObject.data.runParserStep, input: e.target.value }
      }
    });
  };

  const handleChangeParserOutput = (e) => {
    setParserOutput(e.target.value);
    onChange({
      ...stepObject,
      data: {
        ...stepObject.data,
        runParserStep: { ...stepObject.data.runParserStep, output: e.target.value }
      }
    });
  };

  return (
    <div className="flex flex-col items-center justify-center px-4">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-1 items-center">{`id: ${stepObject.id}`}</div>
        <div className="my-4 flex shrink-0 rounded-lg border border-blue-600 p-2 text-xs text-secondary">
          {stepObject.data.workflowStepType}
        </div>
        <div className="flex-1"> </div>
      </div>
      <div className="flex w-full items-center gap-4 px-4">
        <div className="relative w-full">
          <label className="label-main absolute -top-4 !text-[12px]">Input</label>
          <input
            name="parserInput"
            type="text"
            className="input-main"
            placeholder="input"
            value={parserInput}
            onChange={handleChangeParserInput}
          />
        </div>
        <div className="relative w-full">
          <label className="label-main absolute -top-4 !text-[12px]">Output</label>
          <input
            name="parserOutPut"
            type="text"
            className="input-main"
            placeholder="output"
            value={parserOutput}
            onChange={handleChangeParserOutput}
          />
        </div>
      </div>
    </div>
  );
}

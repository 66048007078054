import { LineChart } from 'components/linechart';
import { useEffect, useState } from 'react';

const lineChartConfig = {
  create: {
    label: 'Create',
    color: '#ffbd59'
  },
  update: {
    label: 'Update',
    color: '#001840'
  },
  delete: {
    label: 'Delete',
    color: '#EBE3D9'
  },
  login: {
    label: 'Login',
    color: '#FF8B00'
  }
};

export default function LogCharts({ dailyDataTable, title, subTitle }) {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!dailyDataTable) return;

    const seriesData = [];
    const createData = Array.isArray(dailyDataTable.create) ? dailyDataTable.create : [];
    const updateData = Array.isArray(dailyDataTable.update) ? dailyDataTable.update : [];
    const deleteData = Array.isArray(dailyDataTable.delete) ? dailyDataTable.delete : [];
    const loginData = Array.isArray(dailyDataTable.login) ? dailyDataTable.login : [];

    const dates = [
      ...new Set([
        ...createData.map((item) => item[0]),
        ...updateData.map((item) => item[0]),
        ...deleteData.map((item) => item[0]),
        ...loginData.map((item) => item[0])
      ])
    ];

    dates.forEach((date) => {
      const createValue = createData.find((item) => item[0] === date)?.[1] || '0';
      const updateValue = updateData.find((item) => item[0] === date)?.[1] || '0';
      const deleteValue = deleteData.find((item) => item[0] === date)?.[1] || '0';
      const loginValue = loginData.find((item) => item[0] === date)?.[1] || '0';

      seriesData.push({
        date: new Date(date).toLocaleDateString('tr-TR'),
        create: parseInt(createValue, 10),
        update: parseInt(updateValue, 10),
        delete: parseInt(deleteValue, 10),
        login: parseInt(loginValue, 10)
      });
    });

    setSeries(seriesData);
  }, [dailyDataTable]);
  return (
    <LineChart title={title} subTitle={subTitle} chartData={series} chartConfig={lineChartConfig} />
  );
}

export const validatePassword = (password) => {
  // 12 char, 1 uppercase, 1 lowercase ve 1 special char and 1 number
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*\.]).{12,}$/;
  return passwordRegex.test(password);
};

export const passwordRequirementsMet = (password) => {
  return {
    length: password.length >= 12,
    specialChar: /[!@#$%^&*\.]/.test(password),
    uppercase: /[A-Z]/.test(password),
    number: /\d/.test(password)
  };
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import * as qs from 'query-string';
import axios from 'axios';

import { urls } from 'constants/index';

const registerUser = async ({ userEmail, userPassword, userFullName, userToken }) => {
  if (!userEmail) {
    return {
      success: false
    };
  }

  if (!userPassword) {
    return {
      success: false
    };
  }

  if (userPassword.length < 8) {
    return {
      success: false
    };
  }

  if (!userToken) {
    return {
      success: false
    };
  }

  try {
    const result = await axios.post(
      urls.URL_IDM_REGISTER,
      {
        email: userEmail,
        password: userPassword,
        fullName: userFullName,
        token: userToken
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    const { email, fullName } = data;

    return {
      success: true,
      payload: {
        email,
        fullName
      }
    };
  } catch (ex) {
    // Handle.
    console.log(ex);

    return {
      success: false
    };
  }
};

const fetchUserActivationMeta = async () => {
  const { email: queryEmail, token } = qs.parse(window.location.search);

  if (!queryEmail) {
    return {
      success: false
    };
  }

  if (!token) {
    return {
      success: false
    };
  }

  try {
    const encodedToken = encodeURIComponent(`${token}`);
    const encodedEmail = encodeURIComponent(`${queryEmail}`);

    const result = await axios.get(
      `${urls.URL_IDM_ACTIVATION_META}?email=${encodedEmail}&token=${encodedToken}`,
      {
        withCredentials: true,
        data: {},
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    const { email, fullName } = data;

    return {
      success: true,
      token,
      email,
      fullName
    };
  } catch (ex) {
    // handle.
    void ex;

    return {
      success: false
    };
  }
};

export { registerUser, fetchUserActivationMeta };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { globalViewStates } from 'constants';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

const IntegrationNew = () => {
  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const btnExtraClassName = ''; // disabled ? 'opacity-50 cursor-not-allowed' : '';

  const onFormSubmit = () => {};

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(globalViewStates.REDIRECT_TO_PARENT);
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/integrations" />;
  }

  return (
    <div className="new-integration form-container">
      <div className="mx-auto my-2 max-w-3xl overflow-auto py-2">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <form action="/" onSubmit={onFormSubmit}>
            <h2 className="mb-4 mt-0 text-center text-base font-bold"> New Integration </h2>

            <div className="mb-4 flex rounded-md bg-blue-50 p-4">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm leading-5 text-blue-700">
                  We take your security and privacy very seriously:{' '}
                  <strong>Your data will be encrypted</strong> in transit and at rest.
                  <br />
                  We also <strong>encrypt your SSH private key</strong> using a secure token
                  uniquely generated for you.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Name
                </label>
                <input
                  id="name"
                  placeholder="e.g., pk-hogwarts"
                  maxLength="150"
                  required
                  className={`${btnExtraClassName} input-main`}
                />
              </div>

              <div className="col-span-6 -mt-4 sm:-mt-4">
                <p className="text-sm text-gray-400">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </p>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="description" className="label-main">
                  Description
                </label>
                <div className="mt-1 flex rounded-md">
                  <input
                    id="description"
                    required
                    placeholder="e.g., Private key to Hogwarts School of Witchcraft and Wizardry"
                    className={`${btnExtraClassName} input-main`}
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="key" className="label-main">
                  SSH Private Key
                </label>
                <div className="mt-1 rounded-md">
                  <textarea
                    id="key"
                    rows="3"
                    required
                    placeholder="Your SSH private key goes here."
                    className={`${btnExtraClassName} input-main`}
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Your private key typically begins with ‘ssh-rsa’, ‘ssh-ed25520’,
                  ‘ecdsa-sha2-nistp256’, ‘ecdsa-sha2-nistp384’, or ‘ecdsa-sha2-nistp521’.
                </p>
              </div>
            </div>

            <div className="flex justify-end">
              <span className="inline-flex rounded-md">
                <button
                  type="button"
                  onClick={onBackClick}
                  className={`${btnExtraClassName} btn-main !bg-red-600 hover:!bg-red-500`}
                >
                  Cancel
                </button>
              </span>
              <span className="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  className={`${btnExtraClassName} btn-main !bg-green-600 hover:!bg-green-500`}
                >
                  Save
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default IntegrationNew;

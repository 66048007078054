import axios from 'axios';
import { urls } from 'constants/index';

const authWithPassword = async (email, password) => {
  try {
    const result = await axios.post(
      urls.URL_IDM_LOGIN_WITH_PASSWORD,
      {
        email,
        password
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    if (result && result.data && result.data.token) {
      console.log(result.data.token);
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

const authWithMicrosoft = async ({ optIn, trial, lookupKey }) => {
  try {
    const response = await axios.post(
      urls.URL_IDM_LOGIN_WITH_MICROSOFT,
      {
        optIn,
        trial,
        lookupKey
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status === 200) {
      const location = response.data.url;
      return { success: true, location };
    }
  } catch (error) {
    return { success: false };
  }
};

const authWithGoogle = async ({ accessToken, optIn, trial, lookupKey }) => {
  try {
    const result = await axios.post(
      urls.URL_IDM_GOOGLE_LOGIN,
      {
        accessToken,
        optIn,
        trial,
        lookupKey
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.token) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

const authWithSlack = async ({ accessToken, optIn, trial, lookupKey }) => {
  try {
    const result = await axios.post(
      urls.URL_IDM_SLACK_LOGIN,
      {
        accessToken,
        optIn,
        trial,
        lookupKey
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.token) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

export { authWithPassword, authWithMicrosoft, authWithGoogle, authWithSlack };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { sendFetchCommandsRequest } from 'network/sendFetchCommandsRequest';
import sendFetchSlackChannelsRequest from 'network/sendFetchSlackChannelsRequest';
import { sendFetchWorkflowsRequest } from 'network/sendFetchWorkflowsRequest';
import { sendFetchProjectInfoRequest, sendFetchWorkspaceConnectionsRequest } from './network';

const fetchData = async ({ projectId }) => {
  try {
    const {
      payload: { info }
    } = await sendFetchProjectInfoRequest(projectId);
    const {
      payload: { commands }
    } = await sendFetchCommandsRequest();
    const {
      payload: { connections }
    } = await sendFetchWorkspaceConnectionsRequest();
    const {
      payload: { workflows }
    } = await sendFetchWorkflowsRequest();
    //const { payload: slackChannels } = await sendFetchSlackChannelsRequest(projectId);
    const { payload: slackChannels } = info.project.slackChannel
      ? ''
      : await sendFetchSlackChannelsRequest(projectId);
    const currentSlackChannel = info.project.slackChannel ? info.project.slackChannel.name : '';

    return {
      success: true,
      payload: {
        project: info.project,
        commands,
        connections,
        workflows,
        //slackChannels: slackChannels.channels
        slackChannels: info.project.slackChannel ? currentSlackChannel : slackChannels.channels
      }
    };
  } catch (ex) {
    return {
      success: false,
      payload: {
        project: {},
        commands: [],
        connections: [],
        workflows:[],
        slackChannels: []
      }
    };
  }
};

export { fetchData };

const CommandArgumentInfo = ({ formModel, nameOptions }) => {
    const selectedOption = nameOptions.find(
      (option) => option.label === (formModel.awsCommandInfo.command)
    );
  
    if (!selectedOption) {
      return null;
    }
  
    const { info, example, about, href } = selectedOption;
  
    return (
      <div>
        <p>{info}</p>
        <p>{example}</p>
        <p className="flex gap-1 py-2">
          {about}
          <a className="font-bold underline" target="_blank" href={href} rel="noreferrer">
            Documentation
          </a>
        </p>
      </div>
    );
  };

  export default CommandArgumentInfo;
import React from 'react';
import Chat from './Chat';
import { useSelector } from 'react-redux';

export default function ExecutionCommand() {
  const debuggerSize = useSelector((state) => state.debuggerState.debuggerSize);  
  const debuggerState = useSelector((state) => state.debuggerState.debugger);
  console.log(debuggerSize);
  return (
    <div
      className="relative flex w-full overflow-hidden rounded-md"
      style={{  height: debuggerState ? `calc(85vh - ${debuggerSize}px)`:'85vh' }}
    >
      <Chat />
    </div>
  );
}

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const LoginFooter = () => (
  <div className="mt-2 text-center text-sm text-gray-500">Copyright © 2024 OpsBeacon Inc.</div>
);

export default LoginFooter;

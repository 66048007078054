import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { postFile } from '../network';
import { useToast } from 'hooks/use-toast';

export const NewFileForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      file: '',
      filename: ''
    }
  });
  const navigate = useNavigate();
  const { toast } = useToast();
  const [selectedFileName, setSelectedFileName] = useState('');

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW FILE</h2>

          <div className="flex flex-col gap-6 ">
            <form
              onSubmit={handleSubmit(
                async (data) => {
                  let formData = new FormData();
                  formData.append('file', data.file[0], data.filename);
                  formData.append('filename', data.filename);

                  const { success: successAdd } = await postFile({ data: formData });

                  if (!successAdd) {
                    toast({
                      variant: 'destructive',
                      title: 'Uh oh! Something went wrong.',
                      description: 'There was a problem. Try again later.'
                    });
                    return;
                  } else {
                    toast({
                      title: 'Successfully',
                      description: 'New file added.'
                    });
                    navigate('../file-storage');
                  }
                },
                (error) => {
                  console.log(error);
                }
              )}
            >
              <div>
                <label className="label-main">File Name</label>

                <input
                  {...register('filename', {
                    required: 'This area is required!'
                  })}
                  className={`input-main`}
                />

                {errors.filename !== undefined && errors.filename.type === 'required' && (
                  <span className="label-second my-1 block !text-red-500">
                    {errors.filename.message}
                  </span>
                )}

                <label className="label-main">File</label>

                <div className="flex w-full items-center justify-center">
                  <label
                    for="dropzone-file"
                    className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                      <svg
                        className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                      </p>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        {selectedFileName}
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      className="hidden"
                      type="file"
                      {...register(
                        'file',
                        { required: 'This area is required!' },
                        (onchange = (e) => {
                          setValue('filename', e.target.files[0].name);
                          setSelectedFileName(e.target.files[0].name);
                        })
                      )}
                    />
                  </label>
                </div>
              </div>

              <br></br>

              <div className="flex justify-center">
                <button type="button" className="btn-danger" onClick={() => reset()}>
                  Clear
                </button>

                <button type="submit" className="btn-success">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { RadioGroup } from '@headlessui/react';
import {
  fetchStripeCheckout,
  fetchStripePortal,
} from 'features/login/LoginMain/network';
import { useSelector } from 'react-redux';
import { useLocation, useLoaderData, Link } from 'react-router-dom';
import { AccountMenu } from 'components/AccountMenu';
import { useDispatch } from 'react-redux';
import { setStripeInfo, setUserRole } from 'redux/slices/userSlice';
import { Spinner } from 'components/Spinner';

const pricing = {
  frequencies: [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month', lookup: 'monthly' },
    // { value: 'yearly', label: 'Annually', priceSuffix: '/year', lookup: 'yearly' }
  ]
};


const startUpTier = {
  name: 'Start-up',
  title:'Automation and Security Essentials for selected start-ups',
  subtitle:'Grow Secure Program (12 month*)',
  id: 'opsbeacon-startup-monthly',
  plan: 'start-up',
  href: '',
  price: { monthly: '$25', yearly: '$2700' },
  description: 'We will have a special offer for Grow-Secure Program participants at the end of the 12-month period',
  features: [
  'Comprehensive Automation',
  'Webhook, Cron, Slack Triggers',
  'Approval & Execution Policies',
  'Unlimited Step Workflows',
  'Audit Logs (Limited retention)',
  'Unlimited admin, operator, read-only roles',
  'Seamless Data Integration',
  'Operational Insights Dashboard',
  'Flexible SSO options',
  'Internal App',
  'Security Expertise at Your Fingerstips'
  ],
  note:'Be the first to Beta test all our new features!',
  mostPopular: true,
  isCurrentPlan: false,
  selected: false
};

const enterpriseTier = {
  name: 'Enterprise - Custom',
  id: 'opsbeacon-enterprise-',
  plan: 'enterprise',
  href: '',
  description: 'A plan that scales with your rapidly growing business.',
  features: [
    'Professional features',
    'SSO',
    'OnPrem',
    'Audit Logs (unlimited)',
    'Logging Integration',
    'Monitoring Integration'
  ],
  mostPopular: false,
  isCurrentPlan: false,
  selected: false
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Pricing() {
  const [frequency, setFrequency] = useState(pricing.frequencies[0]);

  const userState = useSelector((state) => state.userState);
  const [isInactive, setIsInactive] = useState(false);
  const [pathname, setPathname] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [currentPlan, setCurrentPlan] = useState('');

  const location = useLocation();
  const dispatch = useDispatch();

  const { success, payload, successStripeInfo, payloadStripeInfo } = useLoaderData();

  useEffect(() => {
    if (success) dispatch(setUserRole(payload));
    if (successStripeInfo) dispatch(setStripeInfo(payloadStripeInfo));
  }, []);

  useEffect(() => {
    setFrequency(pricing.frequencies[0]);
    setPathname(location.pathname);
    if (userState.stripeInfo === 'inactive') {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }

    setCurrentPlan(userState.stripeInfo.subscriptionPlan);
  }, [pathname]);

  useEffect(() => {
    if (pathname !== '/pricing')
      if (userState.stripeInfo.timeInfo !== '') {
        setFrequency(
          pricing.frequencies.filter(
            (frequency) => frequency.value === userState.stripeInfo.timeInfo
          )[0]
        );
      }
  }, [pathname]);

  const handlestartUpTierClick = () => {
    if (userState.stripeInfo.status === 'active') {
      fetchStripePortal().then(({ success, payload }) => {
        if (success) {
          window.location.href = payload.sessionUrl;
        }
      });
    } else {
      fetchStripeCheckout({ lookupKey: `opsbeacon-startup-monthly` }).then(
        ({ success, payload }) => {
          if (success) {
            window.location.href = payload.sessionUrl;
          }
        }
      );
    }
  };

  return (
    <div className='py-8'>
      {pathname === '/pricing' && (
          <AccountMenu />
      )}
      <div>
        <main>
          {/* Pricing section */}
          <div className="mx-auto mt-6 max-w-7xl px-6 lg:-mt-5 lg:px-8">
            <div className="flex justify-center">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-1 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                {pricing.frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      classNames(
                        checked ? 'bg-secondary text-white' : 'text-gray-500',
                        'cursor-pointer rounded-full px-2.5 py-1'
                      )
                    }
                  >
                    <span>{option.label}</span>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:gap-16 xl:gap-20 md:max-w-2xl md:grid-cols-2 lg:mt-4 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-2">
              <div
                key={startUpTier.id}
                className={classNames(
                  currentPlan === 'start-up' &&
                    userState.stripeInfo.status !== 'canceled' &&
                    userState.stripeInfo.timeInfo === frequency.value &&
                    pathname !== '/pricing'
                    ? 'ring-2 ring-blue-600'
                    : pathname === '/pricing' && startUpTier.mostPopular
                    ? 'ring-2 ring-blue-600'
                    : 'ring-1 ring-gray-200 hover:ring-2 ',
                  'rounded-3xl p-6'
                )}
              >
                <div className="-mt-2 flex justify-between">
                  <h2
                    id={startUpTier.id}
                    className={classNames(
                      currentPlan === 'start-up' &&
                        userState.stripeInfo.status !== 'canceled' &&
                        userState.stripeInfo.timeInfo === frequency.value
                        ? 'text-secondary'
                        : (isInactive || userState.stripeInfo.status === 'canceled') &&
                          startUpTier.mostPopular
                        ? 'text-secondary'
                        : 'text-gray-900',
                      'text-lg font-semibold leading-8'
                    )}
                  >
                    {startUpTier.name}
                  </h2>
                </div>
                <h3 className='text-gray-500 text-lg font-bold -mt-2 pb-2.5'>{startUpTier.subtitle}</h3>
                <p className="text-sm leading-5 text-gray-600">{startUpTier.description}</p>
                <p className="mt-3 flex items-baseline gap-x-1">
                  <span className="text-3xl font-bold tracking-tight text-gray-900">
                    {startUpTier.price[frequency?.value]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {startUpTier.plan !== 'enterprise' && frequency?.priceSuffix}
                  </span>
                </p>
               <div className='py-2.5'>
               <p className='border-t border-b -mx-4 lg:-mx-6 pt-1 pb-0 px-2.5 font-black tracking-tight text-gray-900 text-base'>{startUpTier.title}</p>
               </div>
               
                <ul className="pt-2.5 text-sm leading-5 text-gray-600">
                  {startUpTier.features.map((feature, index) => (
                    <li key={feature} className={`${[1, 2, 3, 4, 5].includes(index) ? 'ml-8' : ''} flex gap-x-3 p-0.5`}>
                      <CheckIcon className="h-6 w-5 flex-none text-secondary" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <div className='text-black font-bold text-base pt-2.5'>{startUpTier.note}</div>
                <button
                  onClick={handlestartUpTierClick}
                  className={classNames(
                    currentPlan === 'start-up' &&
                      userState.stripeInfo.status !== 'canceled' &&
                      userState.stripeInfo.timeInfo === frequency.value
                      ? 'pointer-events-none bg-slate-500 text-white shadow-sm'
                      : startUpTier.mostPopular &&
                        (currentPlan === 'none' || userState.stripeInfo.status === 'canceled')
                      ? 'bg-secondary text-white shadow-sm hover:bg-blue-500'
                      : 'text-secondary ring-1 ring-inset ring-blue-200 hover:bg-secondary hover:text-white hover:ring-blue-300 ',
                    'mt-4 block w-full justify-center rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                  )}
                >
                  {userState.stripeInfo.status === 'active' ||
                  userState.stripeInfo.status === 'trialing'
                    ? currentPlan === 'start-up' &&
                      userState.stripeInfo.status !== 'canceled' &&
                      userState.stripeInfo.timeInfo === frequency.value
                      ? 'Current Plan'
                      : 'Update Plan'
                    : (userState.stripeInfo.status === 'canceled' ||
                        userState.stripeInfo.status === 'inactive') &&
                      'Buy Plan'}
                  {selectedPlan === 'start-up' && <Spinner />}
                </button>
              </div>
              <div
                key={enterpriseTier.id}
                className={classNames(
                  currentPlan === 'enterprise' &&
                    userState.stripeInfo.status !== 'canceled' &&
                    pathname !== '/pricing'
                    ? 'ring-2 ring-blue-600'
                    : pathname === '/pricing' && enterpriseTier.mostPopular
                    ? 'ring-2 ring-blue-600'
                    : 'ring-1 ring-gray-200 hover:ring-2 ',
                  'rounded-3xl p-6'
                )}
              >
                <div className="-mt-2 flex justify-between">
                  <h2
                    id={enterpriseTier.id}
                    className={classNames(
                      currentPlan === 'enterprise' && userState.stripeInfo.status !== 'canceled'
                        ? 'text-secondary'
                        : (isInactive || userState.stripeInfo.status === 'canceled') &&
                          enterpriseTier.mostPopular
                        ? 'text-secondary'
                        : 'text-gray-900',
                      'text-lg font-semibold leading-8'
                    )}
                  >
                    {enterpriseTier.name}
                  </h2>
                </div>

                <p className="mb-0  text-sm leading-5 text-gray-600">
                  {enterpriseTier.description}
                </p>

                <p className="mt-3 flex items-baseline gap-x-1">
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {enterpriseTier.plan !== 'enterprise' && frequency.priceSuffix}
                  </span>
                </p>
                <Link
                  to="https://calendly.com/ob2ai/enterprise"
                  target="_blank"
                  className={classNames(
                    currentPlan === 'enterprise' &&
                      userState.stripeInfo.status !== 'canceled' &&
                      userState.stripeInfo.timeInfo === frequency.value
                      ? 'pointer-events-none bg-slate-500 text-white shadow-sm'
                      : enterpriseTier.mostPopular &&
                        (currentPlan === 'none' || userState.stripeInfo.status === 'canceled')
                      ? 'bg-secondary text-white shadow-sm hover:bg-blue-500'
                      : 'text-secondary ring-1 ring-inset ring-blue-200 hover:bg-secondary hover:text-white hover:ring-blue-300 ',
                    'mt-4 block w-full justify-center rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                  )}
                >
                  {currentPlan === 'enterprise' &&
                  userState.stripeInfo.status !== 'canceled' &&
                  userState.stripeInfo.timeInfo === frequency.value
                    ? 'Current Plan'
                    : 'Contact Us'}
                  {selectedPlan === 'enterprise' && <Spinner />}
                </Link>
                <ul className="pt-2.5 text-sm leading-5 text-gray-600">
                  {enterpriseTier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3 p-0.5">
                      <CheckIcon className="h-6 w-5 flex-none text-secondary" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { editTriggerViewStates } from '../../../../constants';
import { Navigate } from 'react-router-dom';
import { deleteCron, updateCron } from './io';
import Loading from 'components/loading/Loading';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { SchedulePatternContext } from 'contexts/cronEdit/SchedulePatternContext';
import SchedulePattern from 'components/cronEdit/SchedulePattern';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { useToast } from 'hooks/use-toast';

const CronEdit = ({
  cron,
  commands: allCommands,
  connections: allConnections,
  workflows: allWorkflows,
  title
}) => {
  const [viewState, setViewState] = useState(editTriggerViewStates.FETCHING);
  const [name, setName] = useState('');
  const [commands, setCommands] = useState([]);
  const [selectedCommands, setSelectedCommands] = useState('');
  const [connections, setConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState('');
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflows, setSelectedWorkflows] = useState('');
  const [description, setDescription] = useState('');
  const [argument, setArgument] = useState('');
  const [workflowArgument, setWorkflowArgument] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedConnectionKind, setSelectedConnectionKind] = useState(null);

  const [formModel, setFormModel] = useState({});
  const [isSubmit, setSubmit] = useState();
  const [permissions, setPermissions] = useState(['Connections & Commands', 'Workflows']);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  const cronId = cron ? cron.id : '';
  const cronName = cron.name;
  const cronCommand = cron.commands;
  const cronConnections = cron?.connections;
  const cronWorkflows = cron?.workflows;
  const cronDescription = cron.description;
  const cronArgument = cron?.cronTriggerInfo?.argument;
  const cronWorkflowArgument = cron?.cronTriggerInfo?.workflowArgument;

  useEffect(() => {
    if (!cronId) {
      return;
    }
    setFormModel(cron);
    setName(cronName);
    setCommands(allCommands || []); // null control
    setSelectedCommands(cronCommand || '');
    setDescription(cronDescription);
    setConnections(allConnections || []);
    setSelectedConnections(cronConnections || '');
    setWorkflows(allWorkflows || []);
    setSelectedWorkflows(cronWorkflows || '');
    setArgument(cronArgument);
    setWorkflowArgument(cronWorkflowArgument);
    setSelectedPermission(cronWorkflows !== '' ? 'Workflows' : 'Connections & Commands');
    setViewState(editTriggerViewStates.IDLE);
  }, [cron]);

  useEffect(() => {
    if (selectedPermission === 'Workflows') {
      setSelectedCommands('');
      setSelectedConnections('');
      setArgument('');
    } else {
      setSelectedWorkflows('');
      setWorkflowArgument('');
    }
  }, [selectedPermission]);

  // useEffect(() => {
  //   if (!selectedConnections) {
  //     setSelectedCommands('');
  //   }
  // }, [selectedConnections]);

  useEffect(() => {
    connections.forEach((connection) => {
      if (connection.name === selectedConnections) {
        setSelectedConnectionKind(connection.kind);
      }
    });
  }, [connections,selectedConnections]);

  useEffect(() => {
    if (!selectedConnectionKind) {
      setCommands(allCommands);
    } else if (selectedConnectionKind === 'agent') {
      const filteredCommands = allCommands.filter((command) => command.kind === 'ssh');
      setCommands(filteredCommands);
    } else {
      const filteredCommands = allCommands.filter((command) => command.kind === selectedConnectionKind);
      setCommands(filteredCommands);
    }
  }, [selectedConnectionKind, allCommands]);


  useEffect(() => {
    if (isSubmit) {
      setSubmit(false);
      updateCron({ cron: formModel }).then(
        () => {
          setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
          toast({
            title: 'Successfully',
            description: 'Cronupdated'
          });
        },
        (err) => {
          setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: 'There was a problem saving your cron. Please try again later.'
          });
        }
      );
    }
  }, [formModel]);

  if (!cron.id) {
    return (
      <form action="/cron/edit" onSubmit={() => {}}>
        <Loading />
      </form>
    );
  }

  const disabled = false;
  const btnExtraClassName = '';

  const onDescriptionChange = (evt) => {
    setDescription(evt.target.value);
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    setFormModel((prevState) => {
      return {
        ...prevState,
        description: description,
        cronTriggerInfo: {
          ...prevState.cronTriggerInfo,
          argument: argument,
          workflowArgument: workflowArgument
        },
        connections: selectedConnections,
        commands: selectedCommands,
        workflows: selectedWorkflows
      };
    });

    setSubmit(true);
  };

  const onDeleteClick = (evt) => {
    evt.preventDefault();
    setShowModal(!showModal);
  };

  const confirmDelete = () => {
    setShowModal(!showModal);
    deletionConfirmed();
  };

  const deletionConfirmed = () => {
    deleteCron({ name: cronName }).then(
      () => {
        setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'Cron added'
        });
        
      },
      () => {
        setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
      }
    );
  };

  if (viewState === editTriggerViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/triggers/cron" />;
  }

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <form action="/triggers/triggers/edit" onSubmit={onFormSubmit}>
              <div className="flex flex-col gap-6">
                <h2 className="mb-4 mt-0 text-center text-base font-bold">UPDATE CRON</h2>
                <div>
                  <label htmlFor="name" className="label-main">
                    Cron Name
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      id="name"
                      required
                      defaultValue={name}
                      maxLength="150"
                      disabled={disabled}
                      readOnly={!!cronName}
                      placeholder="cron name"
                      className={`${btnExtraClassName} ${
                        cronName ? 'input-main-disabled cursor-default' : 'input-main'
                      }`}
                    />
                  </div>
                  <span className="label-second">
                    Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                  </span>
                </div>

                <div>
                  <label htmlFor="description" className="label-main">
                    Cron Description
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      id="description"
                      defaultValue={description}
                      disabled={disabled}
                      onChange={onDescriptionChange}
                      maxLength={125}
                      placeholder="cron description"
                      className={`${btnExtraClassName} input-main`}
                    />
                  </div>
                </div>

                <div className="my-4 rounded-md border dark:border-slate-700">
                  <div className="bg-gray-100 px-4 py-2 dark:bg-slate-700">
                    <h2 className="text-lg font-extrabold">Permission</h2>
                  </div>
                  <div className="flex items-center gap-2 px-4 py-2">
                    <div className="flex w-full items-center gap-2">
                      {permissions.map((permission, index) => {
                        const isSelected = selectedPermission === permission;
                        return (
                          <label
                            className={`flex w-full items-center gap-2 rounded-md border px-4 py-2 dark:border-slate-700 ${
                              isSelected
                                ? 'border-blue-600 bg-blue-300 dark:border-slate-700 dark:bg-slate-600'
                                : ''
                            }`}
                            key={index}
                          >
                            <input
                              type="radio"
                              value={permission}
                              checked={isSelected}
                              onChange={(e) => setSelectedPermission(e.target.value)}
                            />
                            {permission}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div className="px-4 py-2">
                    {selectedPermission === 'Connections & Commands' && (
                      <>
                        <div className="pt-3">
                          <label htmlFor="connections" className="label-main mb-2">
                            Connections
                          </label>
                          <select
                            id="connections"
                            className={`input-main`}
                            value={selectedConnections}
                            required
                            onChange={(e) => {
                              setSelectedConnections(e.target.value);
                            }}
                          >
                            {connections.length === 0 ? (
                              <option>There is no connections.</option>
                            ) : (
                              <option value="">select a connection</option>
                            )}

                            {connections &&
                              connections.map((connection, index) => {
                                return (
                                  <option value={connection.name} key={index}>
                                    {connection.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div>
                          <label htmlFor="commands" className="label-main mb-2">
                            Commands
                          </label>
                          <select
                            id="commands"
                            className={`input-main`}
                            value={selectedCommands}
                            required
                            onChange={(e) => {
                              setSelectedCommands(e.target.value);
                            }}
                          >
                            {commands.length === 0 ? (
                              <option>There is no commands.</option>
                            ) : (
                              <option value="">
                                {selectedConnections
                                  ? 'Select a command'
                                  : 'First select a connection'}
                              </option>
                            )}
                            {selectedConnections &&
                              commands.map((command, index) => {
                                return (
                                  <option value={command.name} key={index}>
                                    {command.name}
                                  </option>
                                );
                              })}
                          </select>

                          <p className="mt-2 block w-full p-2 sm:w-2/5">
                            <a
                              href="/connections/new"
                              className="hidden rounded-sm p-2 pl-0 underline duration-150 ease-out hover:text-secondary"
                            >
                              create a new connection
                            </a>
                          </p>
                        </div>
                        <div className="-mt-3">
                          <label htmlFor="argument" className="label-main">
                            Argument
                          </label>
                          <input
                            id="argument"
                            value={argument}
                            disabled={disabled}
                            onChange={(e) => {
                              setArgument(e.target.value);
                            }}
                            maxLength="500"
                            placeholder="argument"
                            className={`${btnExtraClassName} input-main`}
                          />
                        </div>
                      </>
                    )}
                    {selectedPermission === 'Workflows' && (
                      <>
                        <div className="pt-3">
                          <label htmlFor="workflows" className="label-main mb-2">
                            Workflows
                          </label>
                          <select
                            id="workflows"
                            className={`input-main`}
                            value={selectedWorkflows}
                            required
                            onChange={(e) => {
                              setSelectedWorkflows(e.target.value);
                            }}
                          >
                            {workflows.length === 0 ? (
                              <option>There is no workflows.</option>
                            ) : (
                              <option value="">select a workflow</option>
                            )}

                            {workflows &&
                              workflows.map((workflow, index) => {
                                return (
                                  <option value={workflow.name} key={index}>
                                    {workflow.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="mt-3">
                          <label htmlFor="workflowArgument" className="label-main">
                            Argument
                          </label>
                          <input
                            id="workflowArgument"
                            value={formModel.workflowArgument}
                            disabled={disabled}
                            onChange={(e) => {
                              setWorkflowArgument(e.target.value);
                            }}
                            maxLength="500"
                            placeholder="Workflow argument"
                            className={`${btnExtraClassName} input-main`}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <SchedulePatternContext.Provider
                  value={{
                    scheduleExpressionTimezone:
                      formModel?.cronTriggerInfo?.scheduleExpressionTimezone,
                    maximumWindowInMinutes:
                      formModel?.cronTriggerInfo?.flexibleTimeWindow?.maximumWindowInMinutes,
                    scheduleExpression: formModel?.cronTriggerInfo?.scheduleExpression,
                    setFormModel: setFormModel
                  }}
                >
                  <SchedulePattern />
                </SchedulePatternContext.Provider>

                {userState.userRole.role !== userRoles.READ_ONLY && (
                  <div>
                    <h2 className="text-xl font-extrabold">Danger Zone</h2>

                    <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                      <p className="label-second">
                        By clicking the button below you delete this cron. This action is{' '}
                        <strong>irreversible</strong>.
                      </p>
                      <p className="label-second mb-4">
                        You can still re-use this cron connections and commands in other crons.
                      </p>
                      <button
                        disabled={disabled}
                        type="button"
                        onClick={onDeleteClick}
                        className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                      >
                        I understand the consequences. Delete this cron
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="flex justify-end">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={onBackClick}
                      className={`${btnExtraClassName} cancel-button`}
                    >
                      Cancel
                    </button>
                  </span>
                  <span className="ml-3 inline-flex rounded-md shadow-sm">
                    <button
                      disabled={disabled}
                      type="submit"
                      className={`${btnExtraClassName} save-button`}
                    >
                      Save
                    </button>
                  </span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this cron?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CronEdit;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';

const updateUrl = (inUrl, key) => {
  // console.log(inUrl.replace(/%key%/, key));
  return inUrl.replace(/%key%/, key);
};

const sendCommandExistsRequest = async (key) => {
  try {
    const url = updateUrl(urls.URL_WORKSPACE_CONNECTIONS, key);

    const result = await axios.get(url, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return { success: false, payload: { exists: true } };
    }

    return { success: true, payload: { exists: !!data.key } };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { exists: true } };
  }
};

const sendAddOrUpdateCommandRequest = async (formModel, commandName) => {
  try {
    const result = commandName
      ? await axios.put(`${urls.URL_WORKSPACE_COMMANDS}/${commandName}`, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        })
      : await axios.post(urls.URL_WORKSPACE_COMMANDS, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        });

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const sendFetchGroupsRequest = async () => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_GROUP}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    const { groups } = data;

    if (!groups) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    return {
      success: true,
      payload: {
        groups
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { groups: [] } };
  }
};

export { sendAddOrUpdateCommandRequest, sendCommandExistsRequest, sendFetchGroupsRequest };

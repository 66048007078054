import { useContext, useEffect, useState } from 'react';
import OnTimeSchedule from './OnTimeSchedule';
import RecurringSchedule from './RecurringSchedule';
import { SchedulePatternContext } from 'contexts/cronEdit/SchedulePatternContext';

const schedules = ['On-time schedule', 'Recurring schedule'];

export default function SchedulePattern() {
  const { scheduleExpression } = useContext(SchedulePatternContext);

  const [selectedSchedule, setSelectedSchedule] = useState(null);

  useEffect(() => {
    setSelectedSchedule(
      scheduleExpression?.startsWith('rate') || scheduleExpression?.startsWith('cron')
        ? 'Recurring schedule'
        : 'On-time schedule'
    );
  }, [scheduleExpression]);

  return (
    <div className="rounded-md border dark:border-slate-600">
      <div className="bg-gray-100 py-2 px-4 dark:bg-slate-700">
        <h2 className="text-lg font-extrabold">Schelude pattern</h2>
      </div>
      <div className="py-2 px-4">
        <div>
          <h2 className="py-1 font-extrabold">Ocurrance</h2>
          <p className="text-gray-500">You can define an one-time or recurent schedule.</p>
        </div>
      </div>
      <div className="flex items-center gap-2 py-2 px-4">
        <div className="flex w-full items-center gap-2">
          {schedules.map((schedule, index) => {
            const isSelected = selectedSchedule === schedule;
            return (
              <label
                className={`flex w-full items-center gap-2 rounded-md border py-2 px-4 dark:border-slate-600 ${
                  isSelected ? 'border-blue-600 bg-blue-50 dark:border-slate-600 dark:bg-slate-700' : ''
                }`}
                key={index}
              >
                <input
                  type="radio"
                  defaultValue={schedule}
                  checked={isSelected}
                  onChange={(e) => {
                    return setSelectedSchedule(e.target.value);
                  }}
                />
                {schedule}
              </label>
            );
          })}
        </div>
      </div>
      {selectedSchedule === 'On-time schedule' && <OnTimeSchedule />}
      {selectedSchedule === 'Recurring schedule' && <RecurringSchedule />}
    </div>
  );
}

import { addNewTriggersViewStates } from 'constants';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { fetchCommands, fetchConnections, saveNewLink } from './io';
import Modal, { ModalBody, ModalHeader } from 'components/modal/Modal';
import { BiCopy } from 'react-icons/bi';
import { fetchData } from '../../TriggerList/LinkList/io';
import Select from 'react-select';
import { useToast } from 'hooks/use-toast';

export default function LinkNew() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [viewState, setViewState] = useState(
    addNewTriggersViewStates.FETCHING_COMMANDS,
    addNewTriggersViewStates.FETCHING_CONNECTIONS
  );
  const [showModal, setShowModal] = useState(false);
  const [linkUrl, setLinkUrl] = useState(null);
  const [commands, setCommands] = useState([]);
  const [selectedCommands, setSelectedCommands] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState(null);
  const [selectedConnectionKind, setSelectedConnectionKind] = useState(null);
  const [filteredCommands, setFilteredCommands] = useState([]);
  const { toast } = useToast();

  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false
  });
  const [linkList, setLinkList] = useState([]);

  //copy url
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(linkUrl);
      toast({
        title: 'Successfully',
        description:'URL copied to clipboard'
      });
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    setViewState(
      addNewTriggersViewStates.FETCHING_COMMANDS,
      addNewTriggersViewStates.FETCHING_CONNECTIONS
    );

    fetchCommands().then(
      ({ success, payload: { commands } }) => {
        if (!success) {
          alert('There was a problem fetching the command list. Please try again later.');
          setViewState(addNewTriggersViewStates.ERROR);
          return;
        }
        const initialCommands = commands.filter(
          (command) => command.kind === 'aws' || command.kind === 'rest' || command.kind === 'script'
        ).map((command) => ({
          value: command.name,
          label: command.name,
          kind: command.kind
        }));
        setCommands(initialCommands);
        setViewState(addNewTriggersViewStates.IDLE);
      },
      (err) => {
        console.log(err);
        setViewState(addNewTriggersViewStates.IDLE);
      }
    );

    fetchConnections().then(
      ({ success, payload: { connections } }) => {
        if (!success) {
          alert('There was a problem fetching the command list. Please try again later.');
          setViewState(addNewTriggersViewStates.ERROR);
          return;
        }
        const filteredConnections = connections.filter(
          (connection) => connection.kind === 'aws' || connection.kind === 'rest' || connection.kind === 'agent'
        );
        setConnections(
          filteredConnections.map((connection) => ({
            value: connection.name,
            label: connection.name,
            kind: connection.kind
          }))
        );
        setViewState(addNewTriggersViewStates.IDLE);
      },
      (err) => {
        console.log(err);
        setViewState(addNewTriggersViewStates.IDLE);
      }
    );
  }, []);

  useEffect(() => {
    const selectedConnection = connections.find(
      (connection) => connection.value === selectedConnections?.value
    );
    setSelectedConnectionKind(selectedConnection ? selectedConnection.kind : null);
  }, [selectedConnections]);

  useEffect(() => {
    if (selectedConnectionKind) {
      const newFilteredCommands = commands.filter(
        (command) => selectedConnectionKind && selectedConnectionKind !== 'agent' ? command.kind === selectedConnectionKind : command.kind === 'script'
      );
      setFilteredCommands(newFilteredCommands);
    } else {
      setFilteredCommands([]);
    }
  }, [selectedConnectionKind]);
 
  const onNameChange = (evt) => {
    const trimmedValue = evt.target.value.trim();
    setName(trimmedValue);
  };

  const onDescriptionChange = (evt) => {
    setDescription(evt.target.value);
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(addNewTriggersViewStates.REDIRECT_TO_PARENT);
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    const obj = {
      name: name.trim() === '',
      uniqueName: linkList.find((link) => link.name.toLowerCase() === name.toLowerCase())
        ? true
        : false
    };
    setFormErrorModel(obj);
    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    }

    saveNewLink({
      name,
      description,
      connections: selectedConnections.value,
      commands: selectedCommands.value
    }).then(
      (response) => {
        setLinkUrl(response.url);
        setShowModal(true);
      },
      (err) => {
        console.log(err);
        setViewState(addNewTriggersViewStates.REDIRECT_TO_PARENT);
      }
    );
  };

  useEffect(() => {
    if (!showModal && linkUrl) {
      toast({
        title: 'Successfully',
        description: 'Added new Link'
      });
      setViewState(addNewTriggersViewStates.REDIRECT_TO_PARENT);
    }
  }, [showModal, linkUrl]);

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const filteredLink = payload.triggers.filter((type) => type.kind === 'link');
        setLinkList(filteredLink);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (linkList.find((link) => link.name.toLowerCase() === name.toLowerCase())) {
        setFormErrorModel({
          ...formErrorModel,
          uniqueName: true
        });
      } else {
        setFormErrorModel({
          ...formErrorModel,
          uniqueName: false
        });
      }
    };
    checkIfNameExists();
  }, [name, linkList]);

  const handleChangeCommands = (selectedCommands) => {
    setSelectedCommands(selectedCommands);
  };

  const handleChangeConnections = (selectedConnections) => {
    setSelectedConnections(selectedConnections);
    setSelectedCommands(null);
  };

  if (viewState === addNewTriggersViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/triggers/link" />;
  }

  const disabled = viewState !== addNewTriggersViewStates.IDLE;
  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <form action="/triggers/link" onSubmit={onFormSubmit}>
              <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW LINK</h2>
              <div className="flex flex-col gap-6">
                <div>
                  <label htmlFor="name" className="label-main">
                    Name
                  </label>
                  <input
                    id="name"
                    value={name}
                    maxLength="150"
                    onChange={onNameChange}
                    disabled={disabled}
                    placeholder="Link name"
                    className={`${btnExtraClassName} input-main ${
                      formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                    }`}
                  />
                  <span className="label-second">
                    Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                  </span>

                  {formErrorModel.name && (
                    <span className="label-second my-1 block !text-red-500">
                      This area is required!
                    </span>
                  )}

                  {formErrorModel.uniqueName && (
                    <span className="label-second my-1 block !text-red-500">
                      {`${name.toLocaleLowerCase()} is  already taken`}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="description" className="label-main">
                    Description
                  </label>
                  <input
                    id="description"
                    value={description}
                    disabled={disabled}
                    onChange={onDescriptionChange}
                    maxLength={125}
                    placeholder="Link description"
                    className={`${btnExtraClassName} input-main`}
                  />
                </div>
              </div>
              {/*Connections */}
              <div className="py-2">
                <label htmlFor="connections" className="label-main mb-2">
                  Connections
                </label>
                <Select
                  options={connections}
                  value={selectedConnections}
                  onChange={handleChangeConnections}
                />
              </div>
              {/*Commands */}
              <div className="py-2">
                <label htmlFor="commands" className="label-main mb-2">
                  Commands
                </label>
                <Select
                  options={filteredCommands}
                  value={selectedCommands}
                  onChange={handleChangeCommands}
                  placeholder={
                    selectedConnections ? 'Select a command' : 'Select a connection for commands'
                  }
                  isDisabled={!selectedConnections}
                />
              </div>
              {linkUrl ? (
                <>
                  <div className="label-second -mb-8 mt-4">
                    To execute your command, send a HTTP POST request using the provided request
                    body. Click the "Regenerate URL" button to obtain the updated URL for your
                    request.
                  </div>
                  {linkUrl && (
                    <div className="-mb-6 mt-12 flex w-full items-center gap-4">
                      <div className="agent-copy" onClick={linkUrl}>
                        <BiCopy className="h-7 w-7 text-secondary" />
                      </div>
                      <div className="truncate font-black text-secondary">{linkUrl}</div>
                    </div>
                  )}
                </>
              ) : null}
              <div className="mt-6 flex justify-end">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={onBackClick}
                    className={`${btnExtraClassName} cancel-button`}
                  >
                    Cancel
                  </button>
                </span>
                <span className="ml-3 inline-flex rounded-md shadow-sm">
                  <button
                    disabled={disabled}
                    type="submit"
                    className={`${btnExtraClassName} save-button`}
                  >
                    Save
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        height={'h-250'}
        setShow={setShowModal}
      >
        <ModalHeader height={'h-auto'}>
          <h2>LINK URL</h2>
          <p className="px-10 py-4">
            You will only see this URL once, make sure to copy this to a safe place
          </p>
        </ModalHeader>

        <ModalBody>
          <div className="flex items-center justify-center gap-2">
            <div className="flex items-center gap-2 rounded-md border bg-gray-100 ">
              <div className="url-section mx-2 w-24 overflow-x-auto overflow-y-hidden">
                <span className="whitespace-nowrap">{linkUrl}</span>
              </div>
              <button className="copy-btn" onClick={copyToClipboard}>
                <BiCopy className="h-6 w-6" />
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

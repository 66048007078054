/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { registerUser, fetchUserActivationMeta } from './network';

const sendRegisterUserRequest = async ({ email, password, token, fullName }) => {
  const { success } = await registerUser({
    userEmail: email,
    userPassword: password,
    userFullName: fullName,
    userToken: token
  });

  if (!success) {
    return { success: false, message: 'Registration failed.' };
  }

  return { success: true, message: 'Everything is awesome;' };
};

const fetchData = async () => {
  const { email, fullName, token, success } = await fetchUserActivationMeta();

  if (!success) {
    return { success: false };
  }

  return { success: true, email, fullName, token };
};

export { sendRegisterUserRequest, fetchData };

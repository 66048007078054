// helper function : find the matched entities
export function getMatchedEntity(entityKind, entities) {
  const matchedEntity = entities.find((entity) => entity.value === entityKind);

  return matchedEntity
    ? {
        value: matchedEntity.value,
        label: matchedEntity.label,
        kind: matchedEntity.kind, // kind may be don't have in some entities
        description: matchedEntity.description
      }
    : { value: entityKind, label: entityKind };
}

// Create selected entities function
export function generateSelectedEntities(entityKinds, entityKey, entities) {
  if (!entityKinds[entityKey]) return [];
  return entityKinds[entityKey].map((entity) => getMatchedEntity(entity, entities));
}

import React, { useEffect, useState } from 'react';
import { fetchData } from './io';
import { commandListViewStates } from 'constants/index';
import Loading from 'components/loading/Loading';
import { commandTabOptions, createCommandOptions, commandsNavigationInfos } from 'assets/allData';
import Helper from 'components/help/Helper';
import ListTabs from 'components/listTabs/ListTabs';
import ListView from './ListView';
import { useNavigate } from 'react-router-dom';
import LayoutView from '../../../components/LayoutView/LayoutView';
import GridView from './GridView';


const CommandList = () => {
  const [viewState, setViewState] = useState(commandListViewStates.LOADING);
  const [commandList, setCommandList] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedKinds, setSelectedKinds] = useState([]);

  const [tableData, setTableData] = useState([]);

  const [layoutView, setLayoutView] = useState('list');

  const navigate = useNavigate();

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    if (selectedKinds.length === 0) {
      setTableData(commandList);
    } else {
      let filteredDatas = [];
      selectedKinds.forEach((kind) => {
        filteredDatas = [
          ...filteredDatas,
          ...commandList.filter((listItem) => listItem.kind === kind)
        ]; // array.prototype.flat
      });
      setTableData(filteredDatas);
    }
  }, [selectedKinds, commandList]);

  useEffect(() => {
    let filteredDatas;
    if (selectedKinds.length > 0) {
      filteredDatas = commandList.filter((listItem) => selectedKinds.includes(listItem.kind));
    } else {
      filteredDatas = commandList;
    }
    filteredDatas = filteredDatas.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.kind.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, commandList, selectedKinds]);

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }

        const { commands } = payload;

        setCommandList(commands);
        setTableData(commands);

        setViewState(commandListViewStates.DONE);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  if (viewState === commandListViewStates.LOADING) {
    return <Loading />;
  }

  const handleSelectedList = (selectedFilters) => {
    setSelectedKinds(selectedFilters);
  };

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };
  return (
    <React.Fragment>
      <Helper
        message={
          'Commands are the fundamental units of your OpsBeacon workflow. Think of them as individual steps in your automation process, designed to execute specific actions on your established Connections.'
        }
        infoIcon={true}
      />
      <ListTabs

        filtersKind={commandTabOptions}
        onSelectedFilterKind={handleSelectedList}
        handleSearch={handleSearch}
        creatableTypeOptions={createCommandOptions}
      />
      {commandList.length === 0 ? (
        <Helper
          content={true}
          contentTitle={'Click the command type to add your first Command below'}
          iconList={'commandList'}
        />
      ) : (
        <React.Fragment>
          <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
          {layoutView === 'list' ? (
            <ListView
              list={tableData}
              rowClick={(item) => {
                const navigation = commandsNavigationInfos.find((navigationInfo) => {
                  return navigationInfo.type === item.kind;
                });
                navigate(`${navigation.createNewPath}/${item.name}`);
              }}
            />
          ) : (
            <GridView
              list={tableData}
              rowClick={(item) => {
                const navigation = commandsNavigationInfos.find((navigationInfo) => {
                  return navigationInfo.type === item.kind;
                });
                navigate(`${navigation.createNewPath}/${item.name}`);
              }}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CommandList;

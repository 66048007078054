import { sendAddExecutionCommand } from "./network";

const addExecutionCommand = async (commandLine) => {
    try {
      const { success , response} = await sendAddExecutionCommand(commandLine);
      return { success,response };
    } catch (e) {
      console.log('addExecutionCommand:error', e);
  
      return { success: false };
    }
  };

  export {addExecutionCommand};
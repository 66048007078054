/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';

const updateUrl = (inUrl, project) => {
  return inUrl.replace(/%project%/, project);
};

const sendFetchSlackChannelsRequest = async (project) => {
  try {
    const url = updateUrl(urls.URL_SLACK_LIST_CHANNELS, project);

    const result = await axios.get(url, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return { success: false, payload: [] };
    }

    return { success: true, payload: data };
  } catch (ex) {
    console.log('sendFetchSlackChannelsRequest 002:ex', ex);
    return { success: false, payload: [] };
  }
};

export default sendFetchSlackChannelsRequest;

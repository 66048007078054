/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationSlack = ({ customWidth, customHeight,customClassName }) => (
  <svg
    width={customWidth ? customWidth : '48'}
    height={customHeight ? customHeight : '48'}
    className={customClassName ? customClassName : "m-auto"}
    viewBox="0 0 150.852 150.852"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <use x=".926" y=".926" />
    <g strokeWidth="1.852">
      <path
        d="M40.74 93.55c0-8.735 6.607-15.772 14.815-15.772S70.37 84.815 70.37 93.55v38.824c0 8.737-6.607 15.774-14.815 15.774S40.74 141.11 40.74 132.376z"
        fill="#e01e5a"
        stroke="#e01e5a"
      />
      <path
        d="M93.55 107.408c-8.735 0-15.772-6.607-15.772-14.815s7.037-14.815 15.772-14.815h38.826c8.735 0 15.772 6.607 15.772 14.815s-7.037 14.815-15.772 14.815z"
        fill="#ecb22d"
        stroke="#ecb22d"
      />
      <path
        d="M77.778 15.772C77.778 7.037 84.385 0 92.593 0s14.815 7.037 14.815 15.772v38.826c0 8.735-6.607 15.772-14.815 15.772s-14.815-7.037-14.815-15.772z"
        fill="#2fb67c"
        stroke="#2fb67c"
      />
      <path
        d="M15.772 70.37C7.037 70.37 0 63.763 0 55.556S7.037 40.74 15.772 40.74h38.826c8.735 0 15.772 6.607 15.772 14.815S63.333 70.37 54.598 70.37z"
        fill="#36c5f1"
        stroke="#36c5f1"
      />
      <g strokeLinejoin="miter">
        <path
          d="M77.778 133.333c0 8.208 6.607 14.815 14.815 14.815s14.815-6.607 14.815-14.815-6.607-14.815-14.815-14.815H77.778z"
          fill="#ecb22d"
          stroke="#ecb22d"
        />
        <path
          d="M133.334 70.37H118.52V55.556c0-8.207 6.607-14.815 14.815-14.815s14.815 6.607 14.815 14.815-6.607 14.815-14.815 14.815z"
          fill="#2fb67c"
          stroke="#2fb67c"
        />
        <path
          d="M14.815 77.778H29.63v14.815c0 8.207-6.607 14.815-14.815 14.815S0 100.8 0 92.593s6.607-14.815 14.815-14.815z"
          fill="#e01e5a"
          stroke="#e01e5a"
        />
        <path
          d="M70.37 14.815V29.63H55.556c-8.207 0-14.815-6.607-14.815-14.815S47.348 0 55.556 0 70.37 6.607 70.37 14.815z"
          fill="#36c5f1"
          stroke="#36c5f1"
        />
      </g>
    </g>
  </svg>
);

export default IconIntegrationSlack;

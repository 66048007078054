import { sendFetchCommandsRequest } from 'network/sendFetchCommandsRequest';
import { sendFetchConnectionsRequest } from 'network/sendFetchConnectionsRequestForTriggers';
import { sendFetchWorkflowsRequest } from 'network/sendFetchWorkflowsRequest';
import { sendCronExistsRequest, sendSaveNewCronRequest } from './network';
const fetchCommands = async (kind) => {
  try {
    const { success, payload } = await sendFetchCommandsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          commands: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { commands: [] } };
    }

    const { commands } = payload;

    if (kind) {
      const filteredCommands = commands.filter((command) => command.kind === kind);
      return {
        success: true,
        payload: { commands: filteredCommands }
      };
    }

    return {
      success: true,
      payload: { commands }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { commands: [] } };
  }
};

const fetchConnections = async () => {
  try {
    const { success, payload } = await sendFetchConnectionsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          connections: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { connections: [] } };
    }

    const { connections } = payload;

    return {
      success: true,
      payload: { connections }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { connections: [] } };
  }
};

const fetchWorkflows = async () => {
  try {
    const { success, payload } = await sendFetchWorkflowsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          workflows: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { workflows: [] } };
    }

    const { workflows } = payload;

    return {
      success: true,
      payload: { workflows }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { workflows: [] } };
  }
};

const cronExists = async ({ key }) => {
  try {
    const { success, payload } = await sendCronExistsRequest({ key });

    if (!success) {
      return { success: false, payload: { exists: true } };
    }

    const exists = payload.exists;

    return {
      success: true,
      payload: { exists }
    };
  } catch (ex) {
    return { success: false, payload: { exists: true } };
  }
};

const saveNewCron = async (formModel) => {
  try {
    const { success } = await sendSaveNewCronRequest(formModel);
    if (!success) {
      throw new Error('Failed to save new cron');
    }
    return { success };
  } catch (e) {
    throw e; // Or throw a new error message
  }
};

export { fetchCommands, saveNewCron, fetchConnections, fetchWorkflows };

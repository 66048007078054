/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { dashboardLogoutViewStates } from 'constants/index';

import { logOut } from './io';

const DashboardMainLogout = () => {
  const [viewState, setViewState] = useState(dashboardLogoutViewStates.LOADING);

  const initialize = async () => {
    try {
      const { success } = await logOut();

      if (!success) {
        // We should handle this better.
        // Also we need a better design for the LOADING view state.
        alert('There was a problem logging you out. Please try again later.');
        return;
      }

      setViewState(dashboardLogoutViewStates.REDIRECT_TO_DEFAULT);
    } catch (ex) {
      // Handle
      console.log(ex);
      alert('There was a problem logging you out. Please try again later.');
    }
  };

  useEffect(() => {
    initialize().then(
      () => {},
      () => {}
    );
  });

  return viewState === dashboardLogoutViewStates.LOADING ? (
    <p>You are being logged out…</p>
  ) : (
    <Navigate to="/" />
  );
};

export default DashboardMainLogout;

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */
import axios from 'axios';
import { urls } from 'constants/index';

const sendFetchUsersRequest = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_USERS, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { users: [] }
      };
    }

    const { users } = data;

    if (!users) {
      return {
        success: false,
        payload: { users: [] }
      };
    }

    return {
      success: true,
      payload: {
        users
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { commands: [] } };
  }
};

const sendDeleteUserRequest = async (userMail) => {
  try {
    const result = await axios.delete(`${urls.URL_WORKSPACE_USERS}/${userMail}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    void result;

    return { success: true };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

const sendAddNewUserRoleRequest = async (formModel) => {
  try {
    const result = await axios.post(urls.URL_WORKSPACE_USERS, formModel, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    void result;

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    console.log(ex);

    return { success: false };
  }
};

const updateUserRoleRequest = async (userRole, userMail) => {
  try {
    const result = await axios.put(
      `${urls.URL_WORKSPACE_USERS}/${userMail}`,
      {
        role: userRole
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.success) {
      return { success: result.data.success };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    console.log(ex);

    return { success: false };
  }
};

const UserRegisterRequest = async ({ email, fullName, password, optIn, token }) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_USERS_REGISTER,
      {
        fullName,
        email,
        password,
        optIn,
        token
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.token) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

const UserGoogleRegisterRequest = async ({ accessToken, token, optIn }) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_USERS_GOOGLE_REGISTER,
      {
        accessToken,
        optIn,
        token
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.token) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

const UserSlackRegisterRequest = async ({ accessToken, token, optIn }) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_USERS_SLACK_REGISTER,
      {
        accessToken,
        optIn,
        token
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    void result;

    if (result && result.data && result.data.token) {
      return { success: true };
    }

    return { success: false };
  } catch (ex) {
    // handle.
    void ex;

    return { success: false };
  }
};

const sendFetchPoliciesRequest = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_POLICY, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { policies: [] }
      };
    }

    const { policies } = data;

    if (!policies) {
      return {
        success: false,
        payload: { policies: [] }
      };
    }

    return {
      success: true,
      payload: {
        policies
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { policies: [] } };
  }
};

const sendFetchGroupsRequest = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_GROUP, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    const { groups } = data;

    if (!groups) {
      return {
        success: false,
        payload: { groups: [] }
      };
    }

    return {
      success: true,
      payload: {
        groups
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { groups: [] } };
  }
};

const sendFetchUserDetailRequest = async (userMail) => {
  try {
    const result = await axios.get(`${urls.URL_WORKSPACE_USERS}${userMail ? '/' + userMail : ''}`, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const { data } = result;

    if (!data) {
      return {
        success: false,
        payload: { user: [] }
      };
    }

    const { user } = data;

    if (!user) {
      return {
        success: false,
        payload: { user: [] }
      };
    }

    return {
      success: true,
      payload: {
        user
      }
    };
  } catch (ex) {
    // Handle

    if (ex && ex.response && ex.response.status === 400) {
      return {
        success: false,
        reason: 'sessionTimeout'
      };
    }

    return { success: false, payload: { user: [] } };
  }
};

const sendAddOrUpdateUserRequest = async (formModel, userMail) => {
  try {
    const result = userMail
      ? await axios.put(`${urls.URL_WORKSPACE_USERS}/${userMail}`, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        })
      : await axios.post(urls.URL_WORKSPACE_USERS, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
        });

    void result;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const resendInvitation = async (payload) => {
  try {
    const result = await axios.post(`${urls.URL_IDM_RESEND_INVITE}`, payload, {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });
    console.log(result);
    return result;
    // return { success: true };
  } catch (e) {
    console.log(e);
    // return { success: false };
  }
};

export {
  sendFetchUsersRequest,
  sendDeleteUserRequest,
  sendAddNewUserRoleRequest,
  updateUserRoleRequest,
  UserRegisterRequest,
  UserGoogleRegisterRequest,
  UserSlackRegisterRequest,
  sendFetchPoliciesRequest,
  sendFetchGroupsRequest,
  sendFetchUserDetailRequest,
  sendAddOrUpdateUserRequest,
  resendInvitation
};

import React from 'react';

const LoginHeader = ({ title, description }) => {
  return (
    <div className="mb-8 flex flex-col justify-items-start text-left">
      <h1 className="text-lg font-semibold text-black"> {title || ''} </h1>
    </div>
  );
};

export default LoginHeader;

import { AiOutlineFile } from 'react-icons/ai';
import { Popover } from '@headlessui/react';
import { Actions } from './Actions';
import { deleteFile, getFileUrl } from './network';
import { useToast } from 'hooks/use-toast';

export const GridView = ({ list, rowClick, setRefresh }) => {
  const { toast } = useToast();
  return (
    <div className="flex flex-row flex-wrap gap-5 sm:gap-20">
      {list.length > 0 &&
        list.map((item) => {
          return (
            <Popover>
              <Popover.Button className="inline-flex items-center rounded-xl  bg-white text-sm font-semibold leading-6 text-gray-900 hover:bg-black hover:text-white">
                <button
                  className="rounded-lg p-2 outline-dotted hover:bg-gray-300"
                  onClick={rowClick}
                  key={item}
                >
                  <AiOutlineFile className="text-8xl" />
                  {item}
                </button>
              </Popover.Button>
              <Actions
                item={item}
                onDelete={async () => {
                  const response = await deleteFile({ fileName: item });
                  const { success } = response;
                  if (success) {
                    toast({
                      title: 'Successfully',
                      description: 'File successfully deleted'
                    });

                    setRefresh(true);
                  } else {
                    toast({
                      variant: 'destructive',
                      title: 'Uh oh! Something went wrong.',
                      description: 'There was a problem. Try again later.'
                    });
                  }
                }}
                onDownload={async () => {
                  const response = await getFileUrl({ fileName: item });
                  const { success, payload } = response;
                  if (success) {
                    toast({
                      title: 'Successfully',
                      description: 'File successfully downloaded'
                    });
                    window.open(payload.url);
                  } else {
                    toast({
                      variant: 'destructive',
                      title: 'Uh oh! Something went wrong.',
                      description: 'There was a problem. Try again later.'
                    });
                  }
                }}
              />
            </Popover>
          );
        })}
    </div>
  );
};

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { fetchDowngradePlan } from 'features/settings/ExecutionPolicies/PolicyList/io';
import { useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';

export default function DowngradeModal({ execPolicies, showDowngradeModal }) {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [selectedPolicies, setSelectedPolicies] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (execPolicies.length === 0) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [execPolicies]);

  useEffect(() => {
    if (selectedPolicies.length === 0) {
      setDisabled(true);
    } else if (selectedPolicies.length <= 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedPolicies]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Downgrade successful
                    </Dialog.Title>
                    <div className="mt-2 flex flex-col">
                      <p className="mb-1 flex">Please select maximum two execution policies;</p>
                      {execPolicies.map((execPolicy) => {
                        return (
                          <div className="mb-2 flex flex-row">
                            <input
                              id={execPolicy.name}
                              type="checkbox"
                              className="mr-3"
                              onChange={(e) => {
                                if (e.target.checked)
                                  setSelectedPolicies((prevState) => {
                                    let result = prevState.find((policy) => policy === e.target.id);
                                    return result === undefined
                                      ? [...prevState, e.target.id]
                                      : [...prevState];
                                  });
                                else {
                                  setSelectedPolicies((prevState) => {
                                    let filteredPolicies = prevState.filter(
                                      (policy) => policy !== e.target.id
                                    );
                                    return [...filteredPolicies];
                                  });
                                }
                              }}
                            />
                            <p>{execPolicy.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    disabled={disabled}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-gray-300"
                    onClick={() => {
                      fetchDowngradePlan({ executionPolicy: [...selectedPolicies] }).then(
                        ({ success }) => {
                          if (success) {
                            setOpen(false);
                            navigate('/', { replace: true });
                          }
                        }
                      );
                    }}
                  >
                    Finish Downgrade Process
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';

const sendLogoutRequest = async () => {
  try {
    const result = await axios.post(
      urls.URL_IDM_LOGOUT,
      {},
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    // Handle this.
    void result;

    return { success: true };
  } catch (ex) {
    console.log(ex);
    return { success: false };
  }
};

export { sendLogoutRequest };

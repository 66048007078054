import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToast} from 'hooks/use-toast'
import { deleteApp, getAppDetail, getApps, putApps } from '../network';
import { useNavigate, useParams } from 'react-router-dom';

import CodeMirror from '@uiw/react-codemirror';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { linter, lintGutter } from '@codemirror/lint';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { js_beautify } from 'js-beautify';
import AppTabs from '../AppTabs';

export const AppEdit = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, defaultValues }
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      features: {}
    }
  });

  const [uniqueNameError, setUniqueNameError] = useState(false);
  const [appList, setAppList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { toast } = useToast();
  const params = useParams();
  const navigate = useNavigate();

  const options = { indent_size: 2, space_in_empty_paren: true };

  useEffect(() => {
    getApps().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { internalApps } = payload;
        setAppList(internalApps);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    getAppDetail({ appId: params.id }).then(({ success, payload }) => {
      if (success) {
        const { internalApp } = payload;
        setValue('name', internalApp.name);
        setValue('description', internalApp.description);
        setValue('features', internalApp.features);
      }
    });
  }, []);

  const checkIfNameExists = (name) => {
    if (name !== '') {
      if (appList.find((app) => app.name.toLowerCase() === name.toLowerCase())) {
        setUniqueNameError(true);
      } else {
        setUniqueNameError(false);
      }
    }
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const confirmDelete = async () => {
    setShowModal(false);
    const { success } = await deleteApp({ appId: params.id });
    if (!success) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
      return;
    } else {
      toast({
        title: 'Successfully',
        description: 'Success app delete'
      });
      navigate('/apps');
    }
  };

  return (
    <>
      <AppTabs />
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold ">Edit APP</h2>

            <div className="flex flex-col gap-6 ">
              <form
                onSubmit={handleSubmit(
                  async (data) => {
                    if (!uniqueNameError) {
                      const { success } = await putApps({ appId: params.id, formModel: data });
                      if (!success) {
                        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
                        return;
                      } else {
                        toast({
                          title: 'Successfully',
                          description: 'Success app update'
                        });
                        navigate('/apps');
                      }
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                )}
              >
                <div>
                  <label htmlFor="name" className="label-main">
                    Name
                  </label>

                  <input
                    {...register('name', {
                      required: 'This area is required!',
                      onChange: (e) => {
                        checkIfNameExists(e.target.value);
                      }
                    })}
                    className={`input-main`}
                  />

                  {errors.name !== undefined && errors.name.type === 'required' && (
                    <span className="label-second my-1 block !text-red-500">
                      {errors.name.message}
                    </span>
                  )}

                  {uniqueNameError && (
                    <span className="label-second my-1 block !text-red-500">
                      {`${defaultValues.name.toLocaleLowerCase()} is  already taken`}
                    </span>
                  )}
                </div>
                <div>
                  <label htmlFor="description" className="label-main">
                    Description
                  </label>
                  <textarea rows="3" className="input-main" {...register('description')} />
                </div>
                <div>
                  <CodeMirror
                    height="50vh"
                    className="rounded-md"
                    value={js_beautify(JSON.stringify(getValues('features')), options)}
                    onChange={(value, e) => {
                      setValue('features', JSON.parse(value));
                    }}
                    extensions={[json(), lintGutter(), linter(jsonParseLinter())]}
                    options={{
                      mode: json(),
                      theme: 'material',
                      keyMap: 'sublime',
                      lineWrapping: true,
                      lineNumbers: true,
                      lint: true,
                      autoCloseBrackets: true,
                      gutters: ['CodeMirror-lint-markers']
                    }}
                  />
                </div>
                <h2 className="mt-6 text-xl font-extrabold">Danger Zone</h2>

                <div className="mb-8 mt-2 rounded-md border border-red-500 p-4 text-center">
                  <p className="label-second">
                    By clicking the button below you delete this app. This action is
                    <strong> irreversible</strong>.
                  </p>

                  <button
                    type="button"
                    onClick={onDeleteClick}
                    className={`text-bolder focus:shadow-outline-red mt-2 inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                  >
                    I understand the consequences. Delete this command
                  </button>
                </div>

                <div className="flex justify-center">
                  <button
                    type="button"
                    className="btn-danger"
                    onClick={() => {
                      setValue('features', {});
                      reset();
                    }}
                  >
                    Clear
                  </button>

                  <button type="submit" className="btn-success">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          size={'md'} // sm md lg xl full
          closeButton={true}
          setShow={setShowModal}
        >
          <ModalHeader>
            <h2>Confirm deletion!</h2>
          </ModalHeader>

          <ModalBody>
            <p>Are you sure you want to delete this app?</p>
          </ModalBody>

          <ModalFooter>
            <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
              Delete Item
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';
import { Link } from 'react-router-dom';

import IconLinkExternal from 'components/icons/IconLinkExternal';
import IconCheckCircle from 'components/icons/IconCheckCircle';
import IconIntegrationPagerDuty from 'components/icons/IconIntegrationPagerDuty';
import IconIntegrationGitHub from 'components/icons/IconIntegrationGitHub';
import IconIntegrationAws from 'components/icons/IconIntegrationAws';
import IconIntegrationOpsGenie from 'components/icons/IconIntegrationOpsgenie';
import IconIntegrationJira from 'components/icons/IconIntegrationJira';
import IconIntegrationSlack from 'components/icons/IconIntegrationSlack';
import IconIntegrationEmail from 'components/icons/IconIntegrationEmail';
import IconIntegrationWebhooks from 'components/icons/IconIntegrationWebhooks';
import IconIntegrationSsh from 'components/icons/IconIntegrationSsh';
import IconIntegrationStatusPage from 'components/icons/IconIntegrationStatusPage';
import IconIntegrationServiceDesk from 'components/icons/IconIntegrationServiceDesk';
import IconIntegrationBitbucket from 'components/icons/IconIntegrationBitbucket';
import IconIncomplete from 'components/icons/IconIncomplete';
import PageHeader from 'components/pageHeader/PageHeader';
import IconIntegrationChatGpt from 'components/icons/IconIntegrationChatGpt';
import IconIntegrationMySql from 'components/icons/IconIntegrationMySql';
import IconIntegrationRest from 'components/icons/IconIntegrationRest';

const info = {
  title: 'Integration',
  btnName: '',
  btnPath: ''
};

const IntegrationsMain = () => {
  return (
    <div className="integrations form-container">
      <PageHeader info={info} />

      <div
        className="my-2 rounded-lg border border-gray-300 bg-white p-3 text-center duration-150 ease-out dark:border-gray-800 dark:bg-slate-900
       dark:shadow-lg dark:shadow-theme_background"
      >
        <p className="text-center text-base leading-6 dark:text-gray-300">
          Integrations give your superpowers to help you <strong>#sleepmore</strong>. We constantly
          add new integrations to <strong>OpsBeacon</strong> to make your life easier.
          <br />
          Feel free to{' '}
          <a
            href="mailto:support@obsbeacon.com"
            className="text-blue-700 duration-150 ease-out hover:underline focus:underline"
          >
            send us an email
          </a>{' '}
          if you need a custom integration.
          <Link
            to="/"
            className="focus:text-whiteease-out inline-block rounded-sm p-1 text-base leading-6 text-blue-700 duration-150 hover:underline"
          >
            Learn more about integrations
            <IconLinkExternal />
          </Link>
        </p>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-6 py-12">
        <div className="integration-card">
          <div className="px-4 py-5 text-center sm:p-6">
            <IconIntegrationSlack />
            <h3 className="mb-2 mt-2 text-xl font-bold dark:text-gray-200"> Slack </h3>
            <IconCheckCircle />
          </div>
        </div>

        <div className="integration-card">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationSsh />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">SSH</div>
            <IconCheckCircle />
          </div>
        </div>

        <div className="integration-card">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationWebhooks />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">Webhooks</div>
            <IconCheckCircle />
          </div>
        </div>

        <div className="integration-card">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationAws />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">AWS</div>
            <IconCheckCircle />
          </div>
        </div>

        <div className="integration-card">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationChatGpt />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">ChatGPT</div>
            <IconCheckCircle />
          </div>
        </div>

        <div className="integration-card">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationMySql />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">MySQL</div>
            <IconCheckCircle />
          </div>
        </div>

        <div className="integration-card">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationRest />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">REST</div>
            <IconCheckCircle />
          </div>
        </div>

        <div className="integration-card">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationJira />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">Jira</div>
            <IconCheckCircle />
          </div>
        </div>

        <div className="integration-card-disable">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationEmail />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">Email</div>
            <IconIncomplete />
          </div>
        </div>

        <div className="integration-card-disable">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationStatusPage />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">Statuspage</div>
            <IconIncomplete />
          </div>
        </div>

        <div className="integration-card-disable">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationOpsGenie />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">OpsGenie</div>
            <IconIncomplete />
          </div>
        </div>

        <div className="integration-card-disable">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationServiceDesk />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">ServiceDesk</div>
            <IconIncomplete />
          </div>
        </div>

        <div className="integration-card-disable">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationBitbucket />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">Bitbucket</div>
            <IconIncomplete />
          </div>
        </div>

        <div className="integration-card-disable">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationGitHub />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">GitHub</div>
            <IconIncomplete />
          </div>
        </div>

        <div className="integration-card-disable">
          <div className="px-4 py-5 text-center sm:p-6">
            <div>
              <IconIntegrationPagerDuty />
            </div>
            <div className="mb-2 mt-2 text-xl font-bold dark:text-gray-200">PagerDuty</div>
            <IconIncomplete />
          </div>
        </div>

        <div className="project-card h44 w-52 overflow-hidden rounded-lg bg-white text-gray-800 shadow dark:border-0 dark:hover:border-0"></div>
      </div>
    </div>
  );
};

export default IntegrationsMain;
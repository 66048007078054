import React, { useEffect, useState } from 'react';
import { fetchData } from './io';
import { triggerListViewStates } from 'constants/index';
import Loading from 'components/loading/Loading';
import CreateNewLinkInfo from '../../CreateNewLinkInfo';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setLinkTriggers } from 'redux/slices/triggerSlice';
import { useNavigate } from 'react-router-dom';
import Helper from 'components/help/Helper';
import ListTabs from 'components/listTabs/ListTabs';
import { linkNavigationInfos } from 'assets/allData';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './ListView';
import GridView from './GridView';

const hydrate = async () => {
  const { success, payload } = await fetchData();
  return { success, payload };
};

const TriggerLinkList = () => {
  const [viewState, setViewState] = useState(triggerListViewStates.LOADING);
  const dispatch = useDispatch();
  const triggerState = useSelector((state) => state.triggerState);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [layoutView, setLayoutView] = useState('list');

  const navigate = useNavigate();

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    setTableData(triggerState.linkTriggers);
  },[triggerState.linkTriggers]);

  useEffect(() => {
    let filteredDatas;
    filteredDatas = triggerState.linkTriggers.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, triggerState.linkTriggers]);

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  useEffect(() => {
    hydrate()
      .then(({ success, payload: { triggers } }) => {
        if (!success) {
          setViewState(triggerListViewStates.ERROR);
          return;
        }

        dispatch(setLinkTriggers(triggers));
        setViewState(triggerListViewStates.DONE);
      })
      .catch(() => {
        setViewState(triggerListViewStates.ERROR);
      });
  }, [dispatch]);

  if (viewState === triggerListViewStates.LOADING) {
    return <Loading />;
  }

  if (viewState === triggerListViewStates.ERROR) {
    return <CreateNewLinkInfo />;
  }

  const linkCount = triggerState.linkTriggers.length;

  if (linkCount === 0) {
    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
       <CreateNewLinkInfo />
      </div>
    );
  }

  return (
    <React.Fragment>
    <Helper
      message={
        "Link allows users to create a connector-like link that generates a URL. This URL can be used to make requests with custom payloads, headers, and query parameters. When users create the link, they select a specific command that the custom data will interact with. This enables them to input their data in a desired format and use it as parameters or arguments within the command and connection, enhancing flexibility and integration with various systems."
      }
      infoIcon={true}
    />
    <ListTabs
      handleSearch={handleSearch}
      handleAddClick={() => navigate(`${linkNavigationInfos.createNewPath}`)}
    />
    <React.Fragment>
      <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
      {layoutView === 'list' ? (
        <ListView
          list={tableData}
          rowClick={(item) => navigate(`${linkNavigationInfos.edit}/${item.name}`)}
        />
      ) : (
        <GridView
          list={tableData}
          rowClick={(item) => navigate(`${linkNavigationInfos.edit}/${item.name}`)}
        />
      )}
    </React.Fragment>
  </React.Fragment>
  )
};

export default TriggerLinkList;

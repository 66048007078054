import Loading from 'components/loading/Loading';
import { groupListViewStates } from 'constants';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchGroupsData } from './io';
import Helper from 'components/help/Helper';
import groupImg from 'assets/img/groups.png';
import ListTabs from 'components/listTabs/ListTabs';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './ListView';
import { groupNavigationInfos } from 'assets/allData';
import GridView from './GridView';
function GroupList() {
  const [viewState, setViewState] = useState(groupListViewStates.LOADING);
  const [groupList, setGrouplist] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const [layoutView, setLayoutView] = useState('list');

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    fetchGroupsData().then(
      ({ success, payload }) => {
        void success;
        const { groups } = payload;
        setGrouplist(groups);
        setTableData(groups);
        setViewState(groupListViewStates.DONE);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    if (searchTerm !== '') {
      const filteredDatas = tableData.filter(
        (listItem) =>
          listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTableData(filteredDatas);
    } else {
      setTableData(groupList);
    }
  }, [searchTerm, groupList]);

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  if (viewState === groupListViewStates.LOADING) {
    return <Loading />;
  }

  return (
    <React.Fragment>
    {groupList && groupList.length !== 0 && (
      <Helper
        infoIcon={true}
        message={
          'Groups include users, subgroups, and execution policies. Within this structure, you can efficiently manage the attachment of execution policies to users as a group.'
        }
      />
    )}
    <ListTabs
      handleSearch={handleSearch}
      handleAddClick={() => navigate(`${groupNavigationInfos.createNewPath}`)}
    />
    {!groupList || groupList.length === 0 ? (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="flex justify-center">
          <img src={groupImg} className="h-64 w-64" alt="create group" />
        </div>
        <div
          style={{
            maxWidth: '510px',
            margin: '0 auto'
          }}
          className="!my-6 text-center"
        >
          <Helper
            message={
              'Groups include users, subgroups, and execution policies. Within this structure, you can efficiently manage the attachment of execution policies to users as a group.'
            }
          />
        </div>
      </div>
    ) : (
      <React.Fragment>
        <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
        {layoutView === 'list' ? (
          <ListView
            list={tableData || []}
            rowClick={(item) => {
              navigate(`${groupNavigationInfos.path}/${item.name}`);
            }}
          />
        ) : (
          <GridView
            list={tableData || []}
            rowClick={(item) => {
              navigate(`${groupNavigationInfos.path}/${item.name}`);
            }}
          />
        )}
      </React.Fragment>
    )}
  </React.Fragment>
  );
}

export default GroupList;

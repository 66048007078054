/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import {
  sendFetchConnectionsRequest,
  sendFetchConnectionDetailRequest,
  sendDeleteConnectionRequest
} from './network';

const fetchConnectionDataDetail = async (connectionName) => {
  try {
    const { success, payload } = await sendFetchConnectionDetailRequest(connectionName);

    if (!success) {
      return {
        success: false,
        payload: {
          connection: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { connection: [] } };
    }

    const { connection } = payload;

    return {
      success: true,
      payload: { connection }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { connection: [] } };
  }
};

const fetchConnectionData = async () => {
  try {
    const { success, payload } = await sendFetchConnectionsRequest();

    if (!success) {
      return {
        success: false,
        payload: {
          connections: []
        }
      };
    }

    if (!payload) {
      return { success: false, payload: { connections: [] } };
    }

    const { connections } = payload;

    return {
      success: true,
      payload: { connections }
    };
  } catch (ex) {
    console.log(ex);
    return { success: false, payload: { connections: [] } };
  }
};

const deleteConnection = async (connectionName) => {
  try {
    await sendDeleteConnectionRequest(connectionName);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

export { fetchConnectionData, fetchConnectionDataDetail, deleteConnection };

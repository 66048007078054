/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';
import { urls } from '../../../constants';

const updateUrl = (inUrl, key) => {
  return inUrl.replace(/%project%/, key);
};

const sendDeleteProjectRequest = async ({ id }) => {
  try {
    const projectId = id.trim();
    if (!projectId) {
      return {
        success: false
      };
    }

    const result = await axios.delete(updateUrl(urls.URL_WORKSPACE_PROJECT, projectId), {
      withCredentials: true,
      headers: { 'content-type': 'application/json; charset=utf-8' },
      params: {},
      data: {}
    });

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    return {
      success: true
    };
  } catch (ex) {
    console.log(ex);
    return {
      success: false
    };
  }
};

const sendUpdateProjectRequest = async ({
  name,
  id,
  description,
  commands,
  connections,
  workflows,
  slackChannelId,
  slackChannelName,
  gptPrompt,
  gptEnable,
  unleashCommand,
  leashCommand
}) => {
  try {
    const projectName = name.trim();
    if (!projectName) {
      return {
        success: false
      };
    }

    const projectChannelId = slackChannelId.trim();
    if (!projectChannelId) {
      return {
        success: false
      };
    }

    const projectChannelName = slackChannelName.trim();
    if (!projectChannelName) {
      return {
        success: false
      };
    }

    const projectId = id.trim();
    if (!projectId) {
      return {
        success: false
      };
    }

    const projectCommands = commands || [];
    const projectConnections = connections || [];
    const projectWorkflows = workflows || [];
    const projectEnableGpt = gptEnable;
    const projectPrompt = gptPrompt;

    const result = await axios.put(
      updateUrl(urls.URL_WORKSPACE_PROJECT, projectId),
      {
        project: projectName,
        description,
        connections: projectConnections,
        workflows:projectWorkflows,
        commands: projectCommands,
        slackChannelId: projectChannelId,
        slackChannelName: projectChannelName,
        gptPrompt: projectPrompt,
        gptEnable: projectEnableGpt,
        unleashCommand: unleashCommand,
        leashCommand: leashCommand
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;
    if (!data.success) {
      return { success: false, err:data.err };
    }
    const { email, fullName } = data;

    return {
      success: true,
      payload: {
        email,
        fullName
      }
    };
  } catch (ex) {
    // Handle.
    console.log(ex);

    return {
      success: false
    };
  }
};

export { sendUpdateProjectRequest, sendDeleteProjectRequest };

export default function LogListSkeleton() {
  return (
    <ul>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <li className="relative flex animate-pulse cursor-pointer items-center space-x-4 px-1 py-5 hover:bg-gray-200">
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-gray-400/10 p-0.5 text-gray-400">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="mb-2 h-2.5 w-36 min-w-0 rounded-full bg-gray-200 text-sm font-semibold leading-6 dark:bg-gray-700">
              <div className="my-5 h-2.5 w-96 rounded-full bg-gray-200 dark:bg-gray-700">
                <span className="truncate text-lg"></span>
                <span className="text-lg text-gray-400"></span>
                <span className="whitespace-nowrap text-lg"></span>
                <span className="absolute inset-0" />
              </div>
            </h2>
          </div>
          <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
            <div className="block items-center gap-1 lg:flex">
              <div className="flex items-center gap-1">
                <p className="eventlog-description"> </p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p className="eventlog-description"></p>
                <p className="eventlog-description text-[#374151]"></p>
              </div>
              <div className="flex items-center gap-1">
                <p className="eventlog-description"></p>
                <p> </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-2 h-2.5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"
          aria-hidden="true"
        ></div>
      </li>
      <div role="status" class="flex animate-pulse items-center justify-between">
        <p className="mx-2 my-4 h-2.5 w-64 rounded-full bg-gray-200 dark:bg-gray-700"></p>
        <p className="mx-2 my-4 h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></p>
      </div>
    </ul>
  );
}

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import { sendFetchCommandsRequest } from 'network/sendFetchCommandsRequest';
import { sendFetchConnectionsRequest } from 'network/sendFetchConnectionsRequestForTriggers';
import { sendFetchWorkflowsRequest } from 'network/sendFetchWorkflowsRequest';
import { sendFetchCronInfoRequest } from './network';

const fetchData = async ({ cronName }) => {
  try {
    const { payload } = await sendFetchCronInfoRequest(cronName);
    const {
      payload: { info }
    } = await sendFetchCronInfoRequest(cronName);
    const {
      payload: { commands }
    } = await sendFetchCommandsRequest();
    const {
      payload: { connections }
    } = await sendFetchConnectionsRequest();
    const {
      payload: { workflows }
    } = await sendFetchWorkflowsRequest();
    return {
      success: true,
      payload: {
        trigger: info.trigger,
        connections,
        commands,
        workflows
      }
    };
  } catch (ex) {
    return {
      success: false,
      payload: {
        trigger: {},
        commands: [],
        connections: [],
        workflows:[]
      }
    };
  }
};

export { fetchData };

/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import axios from 'axios';

import { urls } from 'constants/index';

const fetchUserInfo = async () => {
  try {
    const result = await axios.get(urls.URL_IDM_INFO, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    const { fullName, role, onboarded, onboardedStep } = data;

    return {
      success: true,
      payload: {
        fullName,
        role,
        onboarded,
        onboardedStep
      }
    };
  } catch (ex) {
    // handle
    void ex;

    return {
      success: false
    };
  }
};

const fetchStripeInfo = async () => {
  try {
    const result = await axios.get(urls.URL_WORKSPACE_STRIPE_INFO, {
      withCredentials: true,
      data: {},
      headers: { 'content-type': 'application/json; charset=utf-8' }
    });

    const data = result.data;

    if (!data) {
      return {
        successStripeInfo: false
      };
    }

    const { stripeSubscriptionIndo } = data;

    return {
      successStripeInfo: true,
      payloadStripeInfo: stripeSubscriptionIndo
    };
  } catch (ex) {
    // handle
    void ex;

    return {
      successStripeInfo: false
    };
  }
};

const fetchStripeCheckout = async ({ lookupKey }) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_STRIPE_CHECKOUT,
      {
        lookupKey
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    return {
      success: true,
      payload: { ...data }
    };
  } catch (ex) {
    // handle
    void ex;

    return {
      success: false
    };
  }
};

const fetchStripeTrial = async ({ lookupKey }) => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_STRIPE_TRIAL,
      {
        lookupKey,
        trial: true
      },
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    return {
      success: true,
      payload: { ...data }
    };
  } catch (ex) {
    // handle
    void ex;

    return {
      success: false
    };
  }
};

const fetchStripePortal = async () => {
  try {
    const result = await axios.post(
      urls.URL_WORKSPACE_STRIPE_PORTAL,
      {},
      {
        withCredentials: true,
        headers: { 'content-type': 'application/json; charset=utf-8' }
      }
    );

    const data = result.data;

    if (!data) {
      return {
        success: false
      };
    }

    return {
      success: true,
      payload: { ...data }
    };
  } catch (ex) {
    // handle
    void ex;

    return {
      success: false
    };
  }
};

export { fetchUserInfo, fetchStripeInfo, fetchStripeCheckout, fetchStripePortal, fetchStripeTrial };

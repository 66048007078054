/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';

const IconIntegrationJira = () => (
  <svg
    width="48"
    height="48"
    className="m-auto"
    viewBox="0 0 68.25 71.25"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <use x="3.125" y="3.125" />
    <defs>
      <linearGradient id="A" x1="91.90%" y1="40.22%" x2="28.49%" y2="81.63%">
        <stop offset="18%" stopColor="#0052cc" />
        <stop offset="100%" stopColor="#2684ff" />
      </linearGradient>
      <linearGradient id="B" x1="8.70%" y1="59.17%" x2="72.26%" y2="17.99%">
        <stop offset="18%" stopColor="#0052cc" />
        <stop offset="100%" stopColor="#2684ff" />
      </linearGradient>
    </defs>
    <g stroke="none" fillRule="nonzero">
      <path
        d="M61.16 30.21L30.95 0 .74 30.21a2.54 2.54 0 0 0 0 3.581l30.21 30.2 30.21-30.2a2.54 2.54 0 0 0 0-3.581zm-30.2 11.25l-9.462-9.462 9.462-9.462 9.462 9.462z"
        fill="#2684ff"
      />
      <path
        d="M30.95 22.6c-6.195-6.194-6.226-16.23-.07-22.46L10.114 20.774l11.268 11.268z"
        fill="url(#A)"
      />
      <path d="M40.437 31.973L30.95 41.46a15.93 15.93 0 0 1 0 22.536l20.75-20.75z" fill="url(#B)" />
    </g>
  </svg>
);

export default IconIntegrationJira;

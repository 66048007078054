import React, { useEffect } from 'react';
import { useState } from 'react';
import LoginFooter from './LoginFooter';
import LoginHeader from 'features/login/LoginHeader';
import { globalViewStates } from 'constants';
import Logo from 'components/logo/Logo';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { ResetPasswordRequest, logUserIn } from './network';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { validatePassword, passwordRequirementsMet } from 'utils/passwordUtils';
import { useToast } from 'hooks/use-toast';

const ResetPassword = () => {
  const [viewState, setViewState] = useState(globalViewStates.DEFAULT);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const { toast } = useToast();
  const params = useParams();

  useEffect(() => {
    const getEmailUrl = decodeURIComponent(params.email);
    const getTokenUrl = decodeURIComponent(params.token);

    setEmail(getEmailUrl);
    setToken(getTokenUrl);
  }, [params.email, params.token]);

  const onFormSubmit = async (evt) => {
    evt.preventDefault();

    const sanitizedEmail = email.trim();
    const atSign = email.includes('@');

    if (!sanitizedEmail || !atSign) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'Please provide a valid email'
      });
      return;
    }

    if (password !== confirmPassword) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'Password did not match! Please try again.'
      });
      return;
    }

    // password validation
    if (!isPasswordValid) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Password must be a minimum of 12 characters and include at least one special character, one number, and one uppercase letter'
      });
      return;
    }

    try {
      const result = await SubmitReset().then(() => {
        toast({
          title: 'Successfully',
          description:'Your password has been successfully changed'
        });

        setTimeout(function () {
          toast({
            title: 'Successfully',
            description:'You are being redirected to the homepage'
          });
        }, 1000);

        setTimeout(function () {
          DirectLogin();
        }, 2000);
      });

      void result;

      setViewState(globalViewStates.SUCCESS);
    } catch (err) {
      console.error(err);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem. Please try again later.'
      });
    }
  };

  const onPasswordChange = (onChange) => {
    const newPassword = onChange.target.value.trim();
    setPassword(newPassword);
    const isValid = validatePassword(newPassword);
    setIsPasswordValid(isValid);
  };

  const SubmitReset = async () => {
    const result = await ResetPasswordRequest({
      email,
      password,
      token
    });
    return result;
  };

  const DirectLogin = () => {
    logUserIn(email, password)
      .then(({ success }) => {
        if (success) {
          setViewState(globalViewStates.REDIRECT_TO_DASHBOARD);
          return;
        }

        setTimeout(function () {
          navigate('/', { replace: true });
        }, 1000);

        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.DEFAULT);
      })
      .catch(() => {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
        setTimeout(function () {
          navigate('/', { replace: true });
        }, 1000);
        setViewState(globalViewStates.DEFAULT);
      });
  };

  if (viewState === globalViewStates.REDIRECT_TO_DASHBOARD) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 pb-48 sm:px-6 lg:px-8">
      <Logo width={'72px'} height={'42px'} textSize={'text-2xl'} />

      <div className="m-5 mt-8 bg-white px-4 py-8 shadow sm:mx-auto sm:w-full sm:max-w-md sm:rounded-lg sm:px-10">
        <LoginHeader title={'Reset Password'} description={'Please enter your new password.'} />

        <form onSubmit={onFormSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email *
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                type="email"
                placeholder="e.g., hermionie.granger@hogwarts.magic"
                value={email}
                required
                disabled
                readOnly
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
              New Password *
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => onPasswordChange(e)}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
            <div className="password-required">
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${passwordRequirementsMet(password).length ? 'text-green-500' : ''}`}
                />
                Minimum 12 characters
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).specialChar ? 'text-green-500' : ''
                  }`}
                />
                One special character
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${passwordRequirementsMet(password).uppercase ? 'text-green-500' : ''}`}
                />{' '}
                One uppercase letter
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${passwordRequirementsMet(password).number ? 'text-green-500' : ''}`}
                />{' '}
                One number
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Confirm Password *
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(onChange) => setConfirmPassword(onChange.target.value.trim())}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <button className="btn-login" type="submit">
                Reset Password
              </button>
            </span>
          </div>
        </form>

        <div className="relative flex items-center py-8">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 flex-shrink text-sm text-gray-500">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div>
          <span className="block w-full rounded-md shadow-sm">
            <Link className="btn-main-outline !w-full" to="/">
              Sign In
            </Link>
          </span>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default ResetPassword;
